import constants from "../../config/constants";

export const Types = {
  setToastVisible: "setToastVisible/CONFIG"
};

const INITAL_STATE = {
  toastVisible: false,
  toastMessage: "DEFAULT",
  toastVariant: constants.toastVariants.warning
};

export default function login(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.setToastVisible:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
}

export const Actions = {
  setToastVisible: payload => ({
    type: Types.setToastVisible,
    payload
  })
};
