export const Types = {
  setSnabkbarVisibleSurveyForm: "setSnackbarSurveyForm",
  surveyFormRequest: "surveyForm/Request",
  surveyFormSuccess: "surveyForm/Success",
  surveyFormError: "surveyForm/Error",

  createSurveyFormRequest: "createSurveyForm/Request",
  createSurveyFormSuccess: "createSurveyForm/Success",
  createSurveyFormError: "createSurveyForm/Error",

  showSurveyFormRequest: "showSurveyForm/Request",
  showSurveyFormSuccess: "showSurveyForm/Success",
  showSurveyFormError: "showSurveyForm/Error",

  updateSurveyFormStatusRequest: "updateSurveyFormStatus/Request",
  updateSurveyFormStatusSuccess: "updateSurveyFormStatus/Success",
  updateSurveyFormStatusError: "updateSurveyFormStatus/Error"
};

const INITIAL_STATE = {
  loading: false,
  data: [],
  error: null,
  currentSubTeam: null,
  page: 0,
  perPage: 20,
  lastPage: 0,
  total: 0,
  snackbarVisible: false,
  snackbarMessage: "",
  snackbarType: "",
  currentSurveyForm: null
};

export default function surveyForm(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.setSnabkbarVisibleSurveyForm:
      return {
        ...state,
        snackbarVisible: action.payload.status,
        snackbarMessage: "",
        snackbarType: ""
      };

    case Types.surveyFormRequest:
      return INITIAL_STATE;

    case Types.surveyFormSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data
      };

    case Types.surveyFormError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.createSurveyFormRequest:
      return {
        ...state,
        loading: true,
        error: false,
        snackbarVisible: false,
        snackbarMessage: "",
        snackbarType: ""
      };

    case Types.createSurveyFormSuccess:
      return {
        ...state,
        loading: false,
        currentSurveyForm: action.payload.data
      };

    case Types.createSurveyFormError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true,
        snackbarMessage: action.payload.error.message,
        snackbarType: "error"
      };

    case Types.showSurveyFormRequest:
      return INITIAL_STATE;

    case Types.showSurveyFormSuccess:
      return {
        ...state,
        loading: false,
        currentSurveyForm: action.payload.data
      };

    case Types.showSurveyFormError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true,
        snackbarMessage: action.payload.error.message,
        snackbarType: "error"
      };

    case Types.updateSurveyFormStatusRequest:
      return {
        ...state,
        loading: true,
        error: false,
        snackbarVisible: false,
        snackbarMessage: "",
        snackbarType: ""
      };

    case Types.updateSurveyFormStatusSuccess:
      return {
        ...state,
        ...action.payload.data,
        loading: false
      };

    case Types.updateSurveyFormStatusError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true,
        snackbarMessage: action.payload.error.message,
        snackbarType: "error"
      };
    default:
      return state;
  }
}

export const Actions = {
  surveyFormRequest: data => ({
    type: Types.surveyFormRequest,
    payload: { data }
  }),

  surveyFormSuccess: data => ({
    type: Types.surveyFormSuccess,
    payload: { data }
  }),

  surveyFormError: error => ({
    type: Types.surveyFormError,
    payload: { error }
  }),

  createSurveyFormRequest: data => ({
    type: Types.createSurveyFormRequest,
    payload: { data }
  }),

  createSurveyFormSuccess: data => ({
    type: Types.createSurveyFormSuccess,
    payload: { data }
  }),

  createSurveyFormError: error => ({
    type: Types.createSurveyFormError,
    payload: { error }
  }),

  setSnabkbarVisibleSurveyForm: status => ({
    type: Types.setSnabkbarVisibleSurveyForm,
    payload: { status }
  }),

  showSurveyFormRequest: id => ({
    type: Types.showSurveyFormRequest,
    payload: { id }
  }),

  showSurveyFormSuccess: data => ({
    type: Types.showSurveyFormSuccess,
    payload: { data }
  }),

  showSurveyFormError: error => ({
    type: Types.showSurveyFormError,
    payload: { error }
  }),

  updateSurveyFormStatusRequest: id => ({
    type: Types.updateSurveyFormStatusRequest,
    payload: { id }
  }),

  updateSurveyFormStatusSuccess: data => ({
    type: Types.updateSurveyFormStatusSuccess,
    payload: { data }
  }),

  updateSurveyFormStatusError: error => ({
    type: Types.updateSurveyFormStatusError,
    payload: { error }
  })
};
