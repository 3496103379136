import React from "react";

import { Header, Container, Logo, TextSuccess } from "./styles";

// assets
import LogoImage from "../../assets/Lockup_Handover.svg";

function reducerCost(props) {
  return (
    <Container>
      <Header>
        <Logo src={LogoImage} alt="logo" />
      </Header>
      <TextSuccess>Senha alterada com sucesso!</TextSuccess>
    </Container>
  );
}

export default reducerCost;
