import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

class SimpleTable extends Component {
  static propTypes = {};

  static defaultProps = {
    columns: [
      {
        id: Math.random(),
        text: "DEFAULT COLUMN"
      }
    ],
    rowValues: [
      {
        id: Math.random(),
        data: [
          {
            id: Math.random(),
            value: "DEFAULT VALUE"
          }
        ]
      }
    ]
  };

  useStyles = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto"
      },
      table: {
        minWidth: 650
      }
    }));

  render() {
    const classes = this.useStyles();
    const { columns, rowValues } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align="center">
                  {column.text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowValues &&
              rowValues.map(row => (
                <TableRow key={row.id}>
                  {row.data.map(item => (
                    <TableCell id={item.id} align="center">
                      {item.value}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default SimpleTable;
