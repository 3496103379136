import React, { Component } from "react";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Hidden,
  Drawer,
  List,
  ListSubheader,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { withStyles, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import BallotIcon from "@material-ui/icons/Ballot";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { ArrowForwardIos } from "@material-ui/icons";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Spinner from "../../components/Spinner";
import LanguageSelector from "../../components/LanguageSelector";
import { withTranslation } from 'react-i18next';

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LoginActions } from "../../store/ducks/login";
import { Actions as TeamsActions } from "../../store/ducks/teams";
import { Actions as ConfigsActions } from "../../store/ducks/configs";

import history from "../../routes/history";

import { ListOptions } from "./listItems";

// assets
import Logo from "../../assets/Lockup_Handover_White.svg";

//components
import Snackbar from "../../components/Snackbar";
import DialogNotification from "../../components/DialogNotification";
import { StyledLink } from "./styled";
import checkHandoverEmail from "../../services/checkHandoverEmail";

const drawerWidth = 240;

const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: ["Livvic", "Roboto", "sans-serif"].join(","),
    color: "error",
    fontSize: 12
  }
});

const useStyles = theme => ({
  // "@global": {
  //   "*::-webkit-scrollbar": {
  //     width: "0.4em"
  //   },
  //   "*::-webkit-scrollbar-thumb": props => ({
  //     display: props.isMouse && "none",
  //     backgroundColor: "rgba(0,0,0,.4)",
  //     borderRadius: "0.2em"
  //   })
  // },
  // notification: {
  //   [theme.breakpoints.up("xs")]: {
  //     marginRight: 0
  //   },

  //   marginRight: 10
  // },
  // scrollbar: {
  //   "& ul": {
  //     paddingTop: 0,
  //     "& div": {
  //       "& div:last-child": {
  //         borderBottom: "0 !important"
  //       }
  //     }
  //   }
  // },
  title: {
    flexGrow: 1,
    color: "#1b301b"
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    // marginTop: 50,
    maxWidth: 500,
    flexDirection: "center"
  },
  logo: {
    marginTop: 20,
    marginBottom: 10,
    // width: 120,
    height: 20
  },
  containerLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  containerOptions: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 0 60px"
    },
    overflowX: "hidden"
  },
  containerClose: {
    [theme.breakpoints.up("xs")]: {
      display: "none"
    },
    [theme.breakpoints.down("xs")]: {
      color: "#fff",
      display: "block",
      position: "absolute",
      top: "13px",
      left: "20px",
      fontSize: "20px"
    }
  },
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    backgroundColor: "#ffffffff",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex"
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1b301b",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  containerNotifications: {
    minHeight: 50
  }
});

const Grade = React.memo(
  ({
    notification,
    onMouseEnter,
    onMouseLeave,
    updateNotificationRequest,
    touch
  }) => {
    return (
      <List
        component="nav"
        aria-labelledby="subheader"
        subheader={
          <ListSubheader
            component="div"
            id="subheader"
            style={{
              backgroundColor: "#eee",
              height: 40,
              fontSize: 13,
              display: "flex",
              alignItems: "center",
              color: "#1b301b"
            }}
          >
            notificações
          </ListSubheader>
        }
      >
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={{ padding: touch ? "0 0 0 10px" : "0 10px 0 10px" }}
        >
          {notification.data.map(n => (
            <DialogNotification
              data={n}
              key={n._id}
              updateNotification={updateNotificationRequest}
            />
          ))}
        </div>
      </List>
    );
  }
);

class Layout extends Component {
  static propTypes = {
    container: PropTypes.instanceOf(
      typeof Element === "undefined" ? Object : Element
    ),
    title: PropTypes.string
  };
  t = this.props.t;

  static defaultProps = {
    title: "DEFAULT"
  };

  state = {
    mobileOpen: false,
    anchorEl: null,
    anchorNot: null,
    open: false,
    touch: false
  };
  // componentDidMount() {
  //   const ttChatLoaderS = document.createElement("script");
  //   document.tomticketChatLoaderScriptVersion = 2;
  //   ttChatLoaderS.src =
  //     "https://oihandover.tomticket.com/scripts-chat/chat.min.js" +
  //     "?id=EP59281" +
  //     "&autoOpen=0" +
  //     "&hideWhenOffline=0" +
  //     "&d=oihandover" +
  //     "&ts=" +
  //     new Date().getTime() +
  //     "&ref=" +
  //     encodeURIComponent(document.URL);
  //   document.body.appendChild(ttChatLoaderS);
  // }
  componentWillMount() {
    // const { getNotificationRequest } = this.props;
    // const { id } = JSON.parse(localStorage.getItem("auth")).currentTeam;
    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    this.setState({
      parameters
    });

    // getNotificationRequest({ idTeam: id });
  }

  handleDrawerToggle = () =>
    this.setState({ mobileOpen: !this.state.mobileOpen });
  setAnchorEl = () => {};
  handleMenu = event => this.setState({ anchorEl: event.currentTarget });
  handleMenuNotification = event => {
    const { getNotificationRequest } = this.props;
    const { id: idTeam } = JSON.parse(localStorage.getItem("auth")).currentTeam;

    this.setState({ anchorNot: event.currentTarget });
    getNotificationRequest({ idTeam });
  };
  openMenu = () => this.setState({ anchorEl: true });
  handleClose = () => {
    this.setState({ anchorEl: false });
  };
  handleCloseNotification = () => {
    this.setState({ anchorNot: false });
  };
  handleProfile = () => {
    history.push("/profile");
  };
  render() {
    const {
      classes,
      container,
      children,
      title,
      logout,
      configs,
      setToastVisible,
      notification,
      notificationUnRead,
      updateNotificationRequest
    } = this.props;
    const { mobileOpen, anchorEl, anchorNot, touch } = this.state;
    const user = JSON.parse(localStorage.getItem("auth")).user;
    // ------- Recebendo do Local Storage o botão que foi clicado ----------------
    const selectedBtn = localStorage.getItem("selectedButton");
    // --------------------------------------------------------------------------
    const btnCreateDelivery = {
      id: Math.random(),
      to: "/delivery/create",
      label: this.t("create_delivery"),
      icon: <BallotIcon />
    };
    const drawer = (
      <div
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div>
          <div className={classes.containerLogo}>
            {
              <>
                <Link
                  to="/home"
                  onClick={() => localStorage.setItem("selectedButton", "")}
                >
                  <img className={classes.logo} src={Logo} alt="logo" />
                </Link>
              </>
            }
          </div>
          <div
            onClick={this.handleDrawerToggle}
            className={classes.containerClose}
          >
            x
          </div>
          <List className={classes.containerOptions}>
            <ListOptions />
          </List>
        </div>

        {(!Boolean(this.state.parameters.useCondominium) ||
          checkHandoverEmail()) && (
          <StyledLink
            key={btnCreateDelivery.id}
            to={btnCreateDelivery.to}
            onClick={() => {
              // Resetando o botão aberto pois aqui se refere apenas aos botões sem filhos
              localStorage.setItem("selectedButton", "");
              // Setando o botão que foi clicado
              localStorage.setItem("selectedButton", btnCreateDelivery.to);
            }}
          >
            <ListItem
              button
              // Verificando se o botão clicado é o mesmo e trocando a cor
              style={{
                background: "#006660",
                height: "60px",
                borderRight:
                  selectedBtn === btnCreateDelivery.to
                    ? "4px solid white"
                    : "normal"
              }}
            >
              <ListItemIcon style={{ color: "white" }}>
                {btnCreateDelivery.icon}
              </ListItemIcon>
              <ListItemText
                id="list-item-text-menu"
                style={{ color: "white" }}
                primary={btnCreateDelivery.label}
              />
              <ListItemIcon style={{ justifyContent: "flex-end" }}>
                <ArrowForwardIos style={{ color: "white", width: "15px" }} />
              </ListItemIcon>
            </ListItem>
          </StyledLink>
        )}
      </div>
    );

    return (
      <ThemeProvider theme={defaultTheme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className="head-sm">
              <LanguageSelector className={classes.title}/>

              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
                id="head-sm__title"
              >
                <MenuIcon />
              </IconButton>
              <Typography
                className={classes.title}
                id="head-user"
                variant="h6"
                noWrap
              >
                {title}
              </Typography>
              <div
                className="head-sm__name"
                style={{ display: "flex", flexWrap: "nowrap" }}
              >
                {/* <IconButton
                  className={classes.notification}
                  color="inherit"
                  aria-label="account of current user"
                  aria-controls="menu-notification"
                  aria-haspopup="true"
                  onClick={this.handleMenuNotification}
                >
                  <Badge
                    color="secondary"
                    badgeContent={notificationUnRead.length}
                  >
                    {!notification.data.length && !notification.data.loading ? (
                      <NotificationNoneIcon
                        style={{ color: "#1b301b", fontSize: 20 }}
                      />
                    ) : (
                      <NotificationsIcon
                        style={{ color: "#006660", fontSize: 20 }}
                      />
                    )}
                  </Badge>
                </IconButton> */}
                <Menu
                  id="menu-notification"
                  anchorEl={anchorNot}
                  isMouse={touch}
                  className={classes.scrollbar}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={anchorNot}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: "70ch",
                      overflowY: touch ? "scroll" : "hidden"
                    }
                  }}
                  onClose={this.handleCloseNotification}
                  // className={classes.containerNotifications}
                >
                  {notification.loading ? (
                    <Spinner />
                  ) : (
                    <Grade
                      notification={notification}
                      touch={touch}
                      onMouseEnter={() => this.setState({ touch: true })}
                      onMouseLeave={() => this.setState({ touch: false })}
                      updateNotificationRequest={updateNotificationRequest}
                    />
                  )}
                </Menu>
                <IconButton
                  style={{ borderRadius: 0 }}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="#006660"
                  id="head-user"
                >
                  <p id="name_user">{user ? user.name : ""}</p>
                  <AccountCircle style={{ marginLeft: 10, color: "#006660" }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={anchorEl}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleProfile}>
                    <p id="menu_user">{this.t("my_profile")}</p>
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <p id="menu_user">{this.t("logout")}</p>
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>

          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                id="siderbar-sm"
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content} id="main-reponsive">
            <div className={classes.toolbar} />
            <Snackbar
              open={configs.toastVisible}
              onClose={() => setToastVisible({ toastVisible: false })}
              message={configs.toastMessage}
              variant={configs.toastVariant}
            />
            {children}
          </main>
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  ...state
  // notificationUnRead: state.notification.data.filter(n => n.readed === false),
  // notification: {
  //   ...state.notification,
  //   data: state.notification.data.map(n => ({
  //     ...n,
  //     link: true
  //   }))
  // }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...LoginActions,
      ...TeamsActions,
      ...ConfigsActions
      // ...NotificationActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("appbar")(withStyles(useStyles)(Layout)));
