import menuOrdersRoles from "./orders";
import menuTeamRoles from "./teams";
import menuBusinessRoles from "./business";
import menuConfigurationRoles from "./configuration";
import menuDeliveryRoles from "./delivery";
import menuFinancialRoles from "./financial";
import menuLockerRoles from "./lockers";
import menuReportsRoles from "./reports";
import administrativeRole from "./administrative";
import configurationBusiness from "./configurationBusiness";
import subTeams from "./subTeams";
import apartment from "./apartment";
import deploymentStatus from "./deploymentStatus";
import partnerNeposTickts from "./partners/nepos/tickets";
import lockerSurvey from "./lockerSurvey";

export default {
  menuOrdersRoles,
  menuTeamRoles,
  menuBusinessRoles,
  menuConfigurationRoles,
  menuDeliveryRoles,
  menuFinancialRoles,
  menuLockerRoles,
  menuReportsRoles,
  administrativeRole,
  configurationBusiness,
  subTeams,
  apartment,
  deploymentStatus,
  partnerNeposTickts,
  lockerSurvey
};
