import React from "react";

import Container from "../../components/Container";

import { Content, ContentButton } from "./styles";
import { Button } from "@material-ui/core";
import history from "../../routes/history";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";

function tools() {
  return (
    <Container title="otimizador de rotas">
      <Content>
        <ContentButton>
          <Button
            variant="contained"
            onClick={() => history.push("/tools/truckRouteOptimizer")}
            style={{
              padding: 15,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              fontSize: 14
            }}
          >
            <LocalShippingIcon
              style={{
                color: "rgba(0, 0, 0, 0.87)",
                height: 60,
                width: 60,
                marginRight: 35,
                marginLeft: 15
              }}
            />
            Distribuição nos lockers partindo do CD
          </Button>
        </ContentButton>
        <ContentButton>
          <Button
            variant="contained"
            onClick={() => history.push("/tools/deliveryRouteOptimizer")}
            style={{
              padding: 18,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              fontSize: 14
            }}
          >
            <DirectionsBikeIcon
              style={{
                height: 60,
                width: 60,
                marginRight: 20
              }}
            />
            Entrega aos clientes partindo dos lockers
          </Button>
        </ContentButton>
      </Content>
    </Container>
  );
}

export default tools;
