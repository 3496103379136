import styled from "styled-components";
import { Link } from "react-router-dom";

import Background from "../../assets/images/background_new.png";

let color = {
  primary: "#2FE076",
  secondary: "#E1FFB0",
  darkkGreen: "#006660",
  notBlack: "#1B301B",
  lightGreen: "#EFF7EB",
  backgroundResponsive: "#FCFFFA"
};



export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${Background});
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: absolute;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 641px) {
    background-image: none;
    background-color: ${color.backgroundResponsive};
  }
`;


export const SelectorWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ComputerImage = styled.img`
  width: 288px;
  position: absolute;
  margin-left: -320px;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const SpanBeta = styled.span`
  background: #2fe076;
  border-radius: 40px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 2px 7px 2px 7px;
  vertical-align: middle;
  color: white;
  max-width: 30px;
  max-height: 20px;
  font-size: 15px;
`;

export const UList = styled.ul`
  list-style-type: none;
  margin-left: 10px;
  padding: 0;
  margin-top: 30px;
`;

export const ListItem = styled.li`
  display: flex;
  font-size: 16px;
  margin-bottom: 20px;
  position: relative;
  color: #4a4b4a !important;
  font-family: "Livvic", sans-serif;
`;

export const ImgCheck = styled.img`
  height: 40px;
  width: 50px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20%;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: 641px) {
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
    padding-top: 0;
    margin-top: 0;
    margin-top: 30px;
  }
`;

export const BoxBackground = styled.div`
  margin-top: 15px;
  background: ${color.lightGreen};
  z-index: 1;
  display: flex;
  margin-bottom: -2%;
  flex-direction: column;
  height: 100%;
`;

export const LogoStyle = styled.img`
  margin-top: 15%;
  margin-bottom: 15%;
  margin-left: 14%;
  @media (max-width: 641px) {
    margin-top: 1%;
    margin-bottom: 5%;
  }
`;

export const TitleLogo = styled.small`
  font-size: 13px;
  font-family: "Livvic", sans-serif;
  font-weight: bolder;
  margin-bottom: 30px;
  color: #fff;
`;

export const TitleLogin = styled.h2`
  color: ${color.notBlack};
  font-family: "Livvic", sans-serif;
  font-size: 20px;
  font-weight: Bold;
  margin-bottom: 10px;
`;

export const TextInfoLogin = styled.p`
  font-family: "Livvic", sans-serif;
  color: ${color.notBlack};
  font-size: 13px;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 10px;
`;

export const WrapperHandleLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
`;

export const LinkForgot = styled.button`
  font-size: 14px;
  text-decoration: none;
  color: #000000;
  border: 0;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: ${color.notBlack};
  }

  &:focus {
    outline: 0;
  }
`;

export const LinkSignup = styled(Link)`
  font-size: 13px;
  text-decoration: none;
  color: #757575;
  border: 0;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-1px);
    text-decoration: none;
    color: #7fc14a;
  }

  &:focus {
    outline: 0;
  }
`;

export const ButtonLogin = styled.button`
  width: 100%;
  border-radius: 5px;
  color: white;
  border: 0;
  box-shadow: 0;
  background-color: ${color.darkkGreen} !important;
  font-weight: 800;
  text-transform: none;
  font-size: 15px;
  height: 40px;
  font-size: 18px;
  margin-bottom: 10px;

  &:active {
    color: blue;
  }

  &:hover {
    background: linear-gradient(103deg, ${color.notBlack}, ${color.notBlack});
  }

  &:disabled {
    background: linear-gradient(103deg, #acacac, #acacac);
  }
`;

export const ModalRecover = styled.div`
  z-index: 100;
  position: absolute;
`;

export const Space = styled.span`
  position: relative;
  top: -5px;
  margin: 0 5px 0 5px;
  color: #aaa;
`;

export const Title = styled.strong`
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 18px;
`;
