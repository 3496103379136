import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import history from "../../routes/history";

import { Actions as DeliveryActions } from "../ducks/delivery";
import { Actions as BusinessActions } from "../ducks/business";

export function* getDeliveries(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/delivery?${action.payload.data}`
    );

    yield put(DeliveryActions.getDeliveriesSuccess(response.data));
  } catch (err) {
    yield put(DeliveryActions.getDeliveriesError(err));
  }
}

export function* showDelivery(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/delivery/${action.payload.id}`
    );

    const businessRequest = yield call(
      api.get,
      `/v1/adm/business?perPage=1000&status=1`
    );

    yield put(BusinessActions.getBusinessSuccess(businessRequest.data));

    yield put(DeliveryActions.showDeliverySuccess(response.data));
  } catch (err) {
    yield put(DeliveryActions.showDeliveryError(err));
  }
}

export function* createDelivery(action) {
  try {
    const response = yield call(api.post, "/v1/adm/delivery", {
      ...action.payload.data
    });

    yield put(DeliveryActions.createDeliverySuccess(response.data));
    history.push(`/delivery/edit/${response.data.id}`);
  } catch (err) {
    yield put(DeliveryActions.createDeliveryError(err.response));
  }
}

export function* searchDelivery(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/delivery?${action.payload.data}`
    );

    yield put(
      DeliveryActions.searchDeliverySuccess({
        data: response.data.data,
        total: response.data.total,
        perPage: response.data.perPage,
        page: response.data.page,
        lastPage: response.data.lastPage
      })
    );
  } catch (err) {
    yield put(DeliveryActions.searchDeliveryError(err));
  }
}

export function* updateDelivery(action) {
  try {
    yield call(api.put, `/v1/adm/delivery/${action.payload.data.id}`, {
      ...action.payload.data
    });

    yield put(
      DeliveryActions.updateDeliverySuccess({ ...action.payload.data })
    );
  } catch (err) {
    yield put(DeliveryActions.updateDeliveryError(err.response));
  }
}

export function* openDeliveryLocker(action) {
  try {
    const response = yield call(api.post, "/v1/adm/delivery/locker/open", {
      ...action.payload.data
    });

    yield put(DeliveryActions.openDeliveryLockerSuccess(response.data));
  } catch (err) {
    yield put(DeliveryActions.openDeliveryLockerError(err.response));
  }
}

export function* sendDeliveryEmailCodominiumRequest(action) {
  try {
    yield call(api.post, `/v1/adm/delivery/send/collect/email`, action.payload);
    yield put(DeliveryActions.sendDeliveryEmailCodominiumSuccess());
  } catch (err) {
    yield put(DeliveryActions.sendDeliveryEmailCodominiumError(err.response));
  }
}

export function* cancelDeliveryRequest(action) {
  try {
    const response = yield call(
      api.put,
      `/v1/adm/delivery/cancel/${action.payload.idDelivery}`,
      action.payload
    );

    yield put(DeliveryActions.cancelDeliverySuccess(response.data));
  } catch (err) {
    yield put(DeliveryActions.cancelDeliveryError(err.response));
  }
}
