import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions } from "../ducks/userCreditTicket";

export function* searchUserCreditTicketRequest(action) {
  try {
    const response = yield call(api.get, "/v1/adm/user/credit/tickets", {
      params: {
        ...action.payload.data
      }
    });

    yield put(Actions.searchUserCreditTicketSuccess(response.data));
  } catch (err) {
    yield put(Actions.searchUserCreditTicketError(err));
  }
}
