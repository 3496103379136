import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as BusinessActions } from "../ducks/business";
import { Actions as SubTeamActions } from "../ducks/subTeam";

import history from "../../routes/history";

export function* getMe(action) {
  try {
    const response = yield call(api.get, "/v1/adm/me");

    yield put(BusinessActions.businessMeSuccess(response.data));
  } catch (err) {
    yield put(BusinessActions.businessMeError(err.data));
  }
}

export function* getBusiness(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/business?${action.payload.data}`
    );

    yield put(BusinessActions.getBusinessSuccess(response.data));
  } catch (err) {
    yield put(BusinessActions.getBusinessError(err));
  }
}

export function* showBusiness(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/business/${action.payload.id}`
    );

    const subTeams = yield call(
      api.get,
      `/v1/adm/subTeams?perPage=1000&idTeam=${response.data.teams[0]}`
    );

    yield put(BusinessActions.showBusinessSuccess(response.data));
    yield put(SubTeamActions.getSubTeamsSuccess(subTeams.data));
  } catch (err) {
    yield put(BusinessActions.showBusinessError(err.response.data));
  }
}

export function* updateBusiness(action) {
  try {
    const response = yield call(
      api.put,
      `/v1/adm/business/${action.payload.data.id}`,
      {
        ...action.payload.data
      }
    );

    yield put(BusinessActions.updateBusinessSuccess(response.data));
  } catch (err) {
    yield put(BusinessActions.updateBusinessError(err.response));
  }
}

export function* createBusiness(action) {
  try {
    const response = yield call(api.post, "/v1/adm/business", {
      ...action.payload.data
    });

    yield put(BusinessActions.createBusinessSuccess(response.data));
    history.push(`/business/edit/${response.data.id}`);
  } catch (err) {
    yield put(BusinessActions.createBusinessError(err));
  }
}

export function* updateProfile(action) {
  try {
    yield call(
      api.put,
      `/v1/adm/business/${action.payload.id}`,
      action.payload
    );

    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("auth")),
        user: {
          ...JSON.parse(localStorage.getItem("auth")).user,
          ...action.payload
        }
      })
    );

    yield put(BusinessActions.updateProfileSucess({}));
  } catch (error) {
    yield put(BusinessActions.updateProfileError(error));
  }
}
