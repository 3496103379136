export const Types = {
  showSubTeamSubUnitsRequest: "showSubTeamSubUnits/Request",
  showSubTeamSubUnitsSuccess: "showSubTeamSubUnits/Sucess",
  showSubTeamSubUnitsError: "showSubTeamSubUnits/Error",

  getSubTeamSubUnitsRequest: "getSubTeamSubUnits/Request",
  getSubTeamSubUnitsSuccess: "getSubTeamSubUnits/Success",
  getSubTeamSubUnitsError: "getSubTeamSubUnits/Error",

  createSubTeamSubUnitsRequest: "createSubTeamSubUnits/Request",
  createSubTeamSubUnitsSuccess: "createSubTeamSubUnits/Success",
  createSubTeamSubUnitsError: "createSubTeamSubUnits/Error",

  updateSubTeamSubUnitsRequest: "updateSubTeamSubUnits/Request",
  updateSubTeamSubUnitsSuccess: "updateSubTeamSubUnits/Success",
  updateSubTeamSubUnitsError: "updateSubTeamSubUnits/Error",

  changeStatusSubTeamSubUnitsRequest: "changeStatusSubTeamSubUnits/Request",
  changeStatusSubTeamSubUnitsSuccess: "changeStatusSubTeamSubUnits/Success",
  changeStatusSubTeamSubUnitsError: "changeStatusSubTeamSubUnits/Error",

  setDialogCreateVisibleSubTeamSubUnits:
    "setDialogCreateVisibleSubTeamSubUnits/SubTeamSubUnits",

  resetSubTeamSubUnits: "resetSubTeamSubUnits",

  // Compartments
  getSubTeamSubUnitsByCompartmentRequest:
    "getSubTeamSubUnitsByCompartment/Request",
  getSubTeamSubUnitsByCompartmentSuccess:
    "getSubTeamSubUnitsByCompartment/Success",
  getSubTeamSubUnitsByCompartmentError: "getSubTeamSubUnitsByCompartment/Error",
  updateSubTeamSubUnitsByCompartmentRequest:
    "updateSubTeamSubUnitsByCompartment/Request",
  updateSubTeamSubUnitsByCompartmentSuccess:
    "updateSubTeamSubUnitsByCompartment/Success",
  updateSubTeamSubUnitsByCompartmentError:
    "updateSubTeamSubUnitsByCompartment/Error"
};

const INITIAL_STATE = {
  data: [],
  error: false,
  loading: false,
  currentSubTeamSubUnits: null,
  currentSubTeamSubUnitsCompartment: [],
  page: 0,
  perPage: 20,
  lastPage: 0,
  total: 0
};

export default function apartment(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.resetSubTeamSubUnits:
      return INITIAL_STATE;

    case Types.setDialogCreateVisibleSubTeamSubUnits:
      return {
        ...state,
        setDialogCreateVisibleSubTeamSubUnits: action.payload
      };

    case Types.getSubTeamSubUnitsRequest:
      return {
        ...state,
        loading: true
      };

    case Types.getSubTeamSubUnitsSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case Types.getSubTeamSubUnitsError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.getSubTeamSubUnitsByCompartmentRequest:
      return {
        ...state,
        loading: true
      };

    case Types.getSubTeamSubUnitsByCompartmentSuccess:
      return {
        ...state,
        loading: false,
        currentSubTeamSubUnitsCompartment: action.payload.data
      };

    case Types.getSubTeamSubUnitsByCompartmentError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.createSubTeamSubUnitsRequest:
      return {
        ...state,
        loading: true
      };

    case Types.createSubTeamSubUnitsSuccess:
      return {
        ...state,
        loading: false,
        error: false
      };

    case Types.createSubTeamSubUnitsError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.showSubTeamSubUnitsRequest:
      return {
        ...state,
        loading: true
      };

    case Types.showSubTeamSubUnitsSuccess:
      return {
        ...state,
        loading: false,
        currentSubTeamSubUnits: action.payload.data,
        error: false
      };

    case Types.showSubTeamSubUnitsError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.updateSubTeamSubUnitsRequest:
      return {
        ...state,
        loading: true
      };

    case Types.updateSubTeamSubUnitsSuccess:
      return {
        ...state,
        loading: false,
        currentSubTeamSubUnits: action.payload.data,
        error: false
      };

    case Types.updateSubTeamSubUnitsError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.changeStatusSubTeamSubUnitsRequest:
      return {
        ...state,
        loading: true
      };

    case Types.changeStatusSubTeamSubUnitsSuccess:
      return {
        ...state,
        loading: false,
        error: false,
        currentSubTeamSubUnits: {
          ...state.currentSubTeamSubUnits,
          ...action.payload
        }
      };

    case Types.changeStatusSubTeamSubUnitsError:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
}

export const Actions = {
  setDialogCreateVisibleSubTeamSubUnits: status => ({
    type: Types.setDialogCreateVisibleSubTeamSubUnits,
    payload: status
  }),

  getSubTeamSubUnitsRequest: data => ({
    type: Types.getSubTeamSubUnitsRequest,
    payload: { data }
  }),

  getSubTeamSubUnitsSuccess: data => ({
    type: Types.getSubTeamSubUnitsSuccess,
    payload: data
  }),

  getSubTeamSubUnitsError: () => ({
    type: Types.getSubTeamSubUnitsError
  }),

  showSubTeamSubUnitsRequest: id => ({
    type: Types.showSubTeamSubUnitsRequest,
    payload: { id }
  }),

  showSubTeamSubUnitsSuccess: data => ({
    type: Types.showSubTeamSubUnitsSuccess,
    payload: { data }
  }),

  showSubTeamSubUnitsError: () => ({
    type: Types.showSubTeamSubUnitsError
  }),

  createSubTeamSubUnitsRequest: (data, redirectToShow = false) => ({
    type: Types.createSubTeamSubUnitsRequest,
    payload: data,
    redirectToShow
  }),

  createSubTeamSubUnitsSuccess: data => ({
    type: Types.createSubTeamSubUnitsSuccess,
    payload: data
  }),

  createSubTeamSubUnitsError: () => ({
    type: Types.createSubTeamSubUnitsError
  }),

  updateSubTeamSubUnitsRequest: ({
    id,
    name,
    idSubTeam,
    idTeam,
    idSubTeamUnits
  }) => ({
    type: Types.updateSubTeamSubUnitsRequest,
    payload: {
      id,
      name,
      idTeam,
      idSubTeam,
      idSubTeamUnits
    }
  }),

  updateSubTeamSubUnitsSucess: data => ({
    type: Types.updateSubTeamSubUnitsSuccess,
    payload: { data }
  }),

  updateSubTeamSubUnitsError: () => ({
    type: Types.updateSubTeamSubUnitsError
  }),

  resetSubTeamSubUnits: () => ({
    type: Types.resetSubTeamSubUnits
  }),

  changeStatusSubTeamSubUnitsRequest: (id, status) => ({
    type: Types.changeStatusSubTeamSubUnitsRequest,
    payload: { id, status }
  }),

  changeStatusSubTeamSubUnitsSuccess: status => ({
    type: Types.changeStatusSubTeamSubUnitsSuccess,
    payload: { status }
  }),

  changeStatusSubTeamSubUnitsError: error => ({
    type: Types.changeStatusSubTeamSubUnitsError,
    payload: { error }
  }),

  getSubTeamSubUnitsByCompartmentRequest: idCompartment => ({
    type: Types.getSubTeamSubUnitsByCompartmentRequest,
    payload: { idCompartment }
  }),

  getSubTeamSubUnitsByCompartmentSuccess: data => ({
    type: Types.getSubTeamSubUnitsByCompartmentSuccess,
    payload: { data }
  }),

  getSubTeamSubUnitsByCompartmentError: error => ({
    type: Types.getSubTeamSubUnitsByCompartmentError,
    payload: { error }
  })
};
