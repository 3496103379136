import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const Spinner = ({ bgcolor, height, width, marginLeft, size }) => (
  <div
    style={{
      width: width? width : "100%",
      height: height? height : "100%",
      marginLeft: marginLeft? marginLeft : "",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#006660"
    }}
  >
    <Box
      display="flex"
      justifyContent="center"
      m={1}
      p={1}
      bgcolor={bgcolor || "#fafafa"}
    >
      <CircularProgress size={size? size : 50 } color="#006660"/>
    </Box>
  </div>
);

export default Spinner;
