const Keys = {
  email: "oihandover@email",
  auth: "auth",
  deliverySearch: "deliverySearch",
  search: "search",
  searchKeys: {
    delivery: "delivery",
    idLocker: "idLocker",
    idSubTeam: "idSubTeam",
    customerReferenceId: "customerReferenceId",
    email: "email",
    idCompartment: "idCompartment",
    status: "status"
  }
};

export default Keys;
