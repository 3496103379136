import React from "react";
import { useSelector } from "react-redux";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export default function AnimatedMulti({ onChange }) {
  const { thingTypes, groups } = useSelector(state => state);

  return (
    <>
      <Select
        isSearchable={true}
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        defaultValue={
          groups.currentGroup
            ? groups.currentGroup.thingTypes.map(item => {
                item.value = item.id;
                item.label = item.name;
                return item;
              })
            : []
        }
        options={thingTypes.data.map(item => {
          item.value = item.id;
          item.label = item.name;
          return item;
        })}
        onChange={onChange}
      />
    </>
  );
}
