import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { Actions as subTeamSubUnitsContactActions } from "../ducks/subTeamSubUnitsContact";
import { Actions as ConfigsActions } from "../ducks/configs";
import history from "../../routes/history";

import constants from "../../config/constants";

export function* getSubTeamSubUnitsContact(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/subTeamSubUnitsContact?${action.payload.data}`
    );

    yield put(
      subTeamSubUnitsContactActions.getSubTeamSubUnitsContactSuccess({
        ...response.data,
        data: response.data.data
      })
    );
  } catch (err) {
    yield put(subTeamSubUnitsContactActions.getSubTeamSubUnitsContactError());
  }
}

export function* createSubTeamSubUnitsContact(action) {
  try {
    const data = action.payload;

    const response = yield call(api.post, "/v1/adm/subTeamSubUnitsContact", {
      data
    });

    yield put(
      subTeamSubUnitsContactActions.createSubTeamSubUnitsContactSuccess()
    );

    yield put(
      subTeamSubUnitsContactActions.setDialogCreateVisibleSubTeamSubUnitsContact(
        false
      )
    );

    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Morador criado com sucesso!",
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );

    history.push(`/subTeamSubUnitsContact/edit/${response.data[0].id}`);
  } catch (err) {
    yield put(
      subTeamSubUnitsContactActions.createSubTeamSubUnitsContactError()
    );
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro geral.",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* showSubTeamSubUnitsContact(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/subTeamSubUnitsContact/${action.payload.id}`
    );

    yield put(
      subTeamSubUnitsContactActions.showSubTeamSubUnitsContactSuccess(
        response.data
      )
    );
  } catch (err) {
    yield put(subTeamSubUnitsContactActions.showSubTeamSubUnitsContactError());
  }
}

export function* updateSubTeamSubUnitsContact(action) {
  try {
    const {
      id,
      name,
      email,
      phone,
      idSubTeamSubUnits,
      slackChannelID
    } = action.payload;

    yield call(api.put, `/v1/adm/subTeamSubUnitsContact/${id}`, {
      name,
      email,
      phone,
      idSubTeamSubUnits,
      slackChannelID
    });

    // history.goBack();

    yield put(
      subTeamSubUnitsContactActions.updateSubTeamSubUnitsContactSuccess()
    );
    yield put(
      subTeamSubUnitsContactActions.showSubTeamSubUnitsContactRequest(id)
    );
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Morador alterado com sucesso!",
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );
  } catch (err) {
    yield put(
      subTeamSubUnitsContactActions.updateSubTeamSubUnitsContactError()
    );
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro geral! Verifique seus dados",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* deleteSubTeamSubUnitsContact(action) {
  try {
    const { id } = action.payload;

    yield call(api.delete, `/v1/adm/subTeamSubUnitsContact/${id}`);

    yield put(
      subTeamSubUnitsContactActions.deleteSubTeamSubUnitsContactSuccess()
    );

    history.goBack();

    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Morador removido com sucesso!",
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );
  } catch (err) {
    yield put(
      subTeamSubUnitsContactActions.deleteSubTeamSubUnitsContactError()
    );
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro geral! Verifique seus dados",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* handleStatusSubTeamSubUnitsContact(action) {
  try {
    const { status, id } = action.payload;

    yield call(api.put, `/v1/adm/subTeamSubUnitsContact/${id}/status`, {
      status
    });

    yield put(
      subTeamSubUnitsContactActions.changeStatusSubTeamSubUnitsContactSuccess(
        status ? 1 : 0
      )
    );
  } catch (err) {
    yield put(
      subTeamSubUnitsContactActions.changeStatusSubTeamSubUnitsContactError(err)
    );
  }
}
