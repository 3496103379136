import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Livvic", "Roboto", "sans-serif"].join(","),
    fontSize: 12
  },
  palette: {
    primary: {
      main: "#006660",
      contrastText: "#fff",
      black: "#1b301b"
    }
  }
});
const ThemeTextFildGreen = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
export default ThemeTextFildGreen;
