import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions } from "../ducks/deliveryEmails";

export function* getDeliveryEmails(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/delivery/collect/emails?idDelivery=${action.idDelivery}`
    );

    yield put(Actions.getDeliveryEmailsSuccess(response.data));
  } catch (err) {
    yield put(Actions.getDeliveryEmailsError(err));
  }
}
