import React, { useState } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { Actions } from "../../../../store/ducks/compartment";
import { Actions as CustomerLogAbbreviatinActions } from "../../../../store/ducks/customerLogAbbreviation";
import LinearProgess from "../../../../components/LinearProgess";
import { materialStyle } from "../../../../styles";

function FormDialog({ classes }) {
  const compartment = useSelector(state => state.compartment);
  const customerLogAbbreviation = useSelector(
    state => state.customerLogAbbreviation
  );
  const dispatch = useDispatch();

  return (
    <div>
      <Dialog
        open={compartment.modalChangeStatusVisible}
        onClose={() => {
          dispatch(Actions.setModalChangeStatusVisible(false));
          dispatch(
            CustomerLogAbbreviatinActions.resetCustomerLogAbbreviation()
          );
        }}
        aria-labelledby="form-dialog-title"
      >
        {customerLogAbbreviation.loading && <LinearProgess />}
        <DialogTitle id="form-dialog-title">Status da porta</DialogTitle>
        <DialogContent>
          <DialogContentText>Selecione abaixo:</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="block_name"
            label="Status"
            fullWidth
            onChange={e => {}}
            select
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            disabled={customerLogAbbreviation.loading}
          >
            {customerLogAbbreviation.data &&
              customerLogAbbreviation.data.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {`${item.abbreviation} - ${item.description}`}
                </MenuItem>
              ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(Actions.setModalChangeStatusVisible(false));
              dispatch(
                CustomerLogAbbreviatinActions.resetCustomerLogAbbreviation()
              );
            }}
            color="danger"
            disabled={customerLogAbbreviation.loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {}}
            color="primary"
            disabled={customerLogAbbreviation.loading}
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(materialStyle)(FormDialog);
