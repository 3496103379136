import React from "react";

// Material
import { Typography, withStyles, Paper, Grid } from "@material-ui/core";

import { materialStyle } from "../../../../styles";
import { Label } from "./styles";

function CardInfo({ classes, label, numberInfo }) {
  return (
    <Paper className={classes.paperSmall} id="block-report-sm">
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item xs>
          <Label>{numberInfo}</Label>
        </Grid>
        <Grid item xs alignItems="center" justify="center">
          <Typography
            style={{ color: "rgba(7,9,25,.5)" }}
            gutterBottom
            variant="p"
            align="center"
          >
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(materialStyle)(CardInfo);
