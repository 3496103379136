import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

// Material
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
  IconButton,
  InputAdornment,
  Checkbox,
  withStyles,
  FormControlLabel,
  MenuItem,
  Typography
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LocationActions } from "../../../../../store/ducks/location";
import { withTranslation } from "react-i18next";

import "./style.css";

// Services
import checkEmail from "../../../../../services/checkEmail.js";
import api from "../../../../../services/api";
import history from "../../../../../routes/history";
import getOnlyNumbers from "../../../../../services/getOnlyNumbers";

import Snackbar from "../../../../../components/Snackbar";

// Componets
import Stepper from "../stepper";

// Utils
import {
  maskCNPJ,
  maskCPF,
  maskCEP,
  maskCell,
  maskTel
} from "../../../../../utils/masks";
import { validationCPF, validarCNPJ } from "../../../../../utils/validation";

// Materials
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { TextField, Button, Grid, FormHelperText } from "@material-ui/core";
import constants from "../../../../../config/constants";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const styles = {
  root: {
    color: "rgba(0, 0, 0, 0.54)",

    "& span": {
      fontSize: 12
    }
  }
};

class containerStepper extends React.Component {
  t = this.props.t
  state = {
    loading: false,
    position: 0,
    showPassword: false,
    errorGeral: false,
    checked: false,
    confirmation: false,

    cnpj: "",
    name: "",
    email: "",
    mobile: "",

    userName: "",
    userEmail: "",
    password: "",
    passwordConfirmation: "",

    option: "email",
    mobileName: "",
    mobileCpf: "",
    mobileEmail: "",
    mobilePassword: "",
    mobilePasswordConfirmation: "",
    humanConfirmation: false,

    erro: {
      name: false,
      cnpj: false,
      email: false,
      mobile: false,

      userName: false,
      userEmail: false,
      password: false,
      passwordConfirmation: false,

      mobileName: false,
      mobileCpf: false,
      mobileEmail: false,
      mobilePassword: false,
      mobilePasswordConfirmation: false,
      humanConfirmation: false
    },
    disabled: {
      uf: false,
      city: false,
      neighborhood: false,
      streetName: false
    }
  };

  handleClickShowPassword = e => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  verificationOfFieldsPage = async () => {
    const state = this.state;
    const { token } = this.props;
    const { checked } = this.state;

    if (state.position === 0) {
      // Informacoes da empresa
      state.erro.email = checkEmail(state.email);
      state.erro.cnpj =
        state.cnpj.length === 18 && validarCNPJ(state.cnpj) ? false : true;
      state.erro.name = state.name ? false : true;
      state.erro.mobile = state.mobile.length >= 14 ? false : true;
      if (
        !state.erro.email &&
        !state.erro.cnpj &&
        !state.erro.name &&
        !state.erro.mobile
      ) {
        state.position =
          state.position === 0 ? state.position + 1 : state.position;
      }
    } else if (state.position === 1) {
      // info do usuario no painel
      state.erro.userName = state.userName.split(" ")[1] ? false : true;
      state.erro.userEmail = checkEmail(state.userEmail);
      state.erro.password =
        state.password === state.passwordConfirmation &&
        state.password.length >= 8
          ? false
          : true;
      if (
        !state.erro.userName &&
        !state.erro.userEmail &&
        !state.erro.password
      ) {
        state.position =
          state.position === 1 ? state.position + 1 : state.position;
      }
    } else if (state.position === 2) {
      // se email drop for diff email painel
      if (!state.checked) {
        state.erro.mobileName = state.mobileName.split(" ")[1] ? false : true;
        state.erro.mobileEmail = checkEmail(state.mobileEmail);
        state.erro.mobilePassword =
          state.mobilePassword === state.mobilePasswordConfirmation &&
          state.mobilePassword.length >= 8
            ? false
            : true;
        if (state.option === "email") {
          state.erro.mobileEmail = checkEmail(state.mobileEmail);
        } else {
          state.erro.mobileCpf =
            state.mobileCpf.length === 14 && validationCPF(state.mobileCpf)
              ? false
              : true;
        }
        // verifica erros de mobile
        this.setState(state);
        if (
          state.erro.mobilePassword ||
          state.erro.mobileName ||
          (state.option === "email" && state.erro.mobileEmail) ||
          (state.option === "cpf" && state.erro.mobileCpf)
        )
          return;
      } // fim se email drop for diff email painel

      state.confirmation = true;

      state.erro.humanConfirmation = state.humanConfirmation ? false : true;

      if (
        !state.erro.humanConfirmation &&
        !state.erro.mobileName &&
        !state.erro.mobileCpf &&
        !state.erro.mobileEmail &&
        !state.erro.mobilePassword
      ) {
        try {
          this.setState({ loading: true });

          await api
            .post(
              "/v1/public/subTeam",
              {
                token,
                subTeamName: state.name,
                subTeamEmail: state.email,
                cnpj: getOnlyNumbers(state.cnpj),
                subTeamContactNumber: getOnlyNumbers(state.mobile),

                businessName: state.userName,
                businessEmail: state.userEmail,
                businessPassword: state.password,
                businessPassword_confirmation: state.passwordConfirmation,

                businessDepositorName: checked
                  ? state.userName
                  : state.mobileName,
                businessDepositorEmail: checked
                  ? state.userEmail
                  : state.mobileEmail,
                businessDepositorPassword: checked
                  ? state.password
                  : state.mobilePassword,
                businessDepositorPassword_confirmation: checked
                  ? state.passwordConfirmation
                  : state.mobilePasswordConfirmation,
                businessDepositorLoginType: checked ? "email" : state.option,
                businessDepositorCPF: state.mobileCpf
              },
              {
                headers: {
                  public: true
                }
              }
            )
            .then(result => {
              state.position = 3;
            })
            .catch(err => (state.errorGeral = err.response.data.message));

          this.setState({ loading: false });
        } catch (error) {
          state.errorGeral = error.response.data.message || "Erro geral";
        }
      }
    }

    this.setState(state);
  };

  handleChange = async (name, value) => {
    const { erro } = this.state;
    if (name === "cnpj") {
      if (!value) {
        erro.cnpj = true;
        this.setState({ erro: erro });
      } else {
        erro.cnpj = false;
        this.setState({ erro: erro });
      }
      value = maskCNPJ(value);
    }
    if (name === "managerCPF") {
      value = maskCPF(value);
    }
    if (name === "zipCode") {
      value = maskCEP(value, this.state.zipCode);
      if (value.length === 9) {
        const zipCode = `${value.slice(0, 5)}${value.slice(6, 9)}`;
        const result = await this.getCEP(zipCode);
        const { data } = result;
        const state = this.state;
        state.uf = data.uf;
        state.city = data.localidade;
        state.neighborhood = data.bairro;
        state.streetName = data.logradouro;
        state.disabled.neighborhood = data.bairro ? true : false;
        state.disabled.streetName = data.logradouro ? true : false;
        state.disabled.uf = data.uf ? true : false;
        state.disabled.city = data.localidade ? true : false;
        this.setState(state);
      }
    }
    if (name === "streetNumber") {
      value = value
        .normalize("NFD")
        .replace(/[^a-zA-Zs0-9]/g, "")
        .toUpperCase();
    }
    if (name === "mobile") {
      value =
        value.length < 15
          ? maskTel(value)
          : maskCell(value, this.state.managerPhoneNumber);
    }
    if (name === "password") {
      if (value.length < 8) {
        erro.password = true;
      } else {
        erro.password = false;
      }
    }
    if (name === "passwordConfirmation") {
      if (value === this.state.password) {
        erro.passwordConfirmation = false;
      } else {
        erro.passwordConfirmation = true;
      }
    }
    if (name === "mobilePassword") {
      if (value.length < 8) {
        erro.mobilePassword = true;
      } else {
        erro.mobilePassword = false;
      }
    }
    if (name === "mobilePasswordConfirmation") {
      if (value === this.state.mobilePassword) {
        erro.mobilePasswordConfirmation = false;
      } else {
        erro.mobilePasswordConfirmation = true;
      }
    }
    if (name === "option") {
      if (this.state.option === "email") {
        this.setState({ mobileCpf: "" });
      } else {
        this.setState({ mobileEmail: "" });
      }
    }
    if (name === "mobileCpf") {
      if (this.state.option === "cpf") {
        value = maskCPF(value);
      }
    }

    if (name === "checked") {
      this.setState({ confirmation: value });
    }

    this.setState({ [name]: value, erro: erro });
  };

  render() {
    const styleButton = {
      green: {
        color: "rgb(255, 255, 255)",
        backgroundColor: "rgb(81,195,72)"
      },
      grey: {
        color: "rgb(255, 255, 255)",
        backgroundColor: "rgb(200,200,200)"
      }
    };

    const { classes, data } = this.props;
    const {
      position,
      confirmation,
      humanConfirmation,
      errorGeral,
      loading
    } = this.state;

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={errorGeral}
          autoHideDuration={3000}
          open={!!errorGeral}
          onClose={() => this.setState({ errorGeral: null })}
          variant="error"
        />
        {this.state.position !== 3 && (
          <Stepper position={this.state.position} />
        )}
        {this.state.position === 0 && ( // Pagina 0
          <>
            <h4>Insira os dados do seu estabelecimento</h4>
            <TextField
              name="businessName"
              margin="dense"
              value={data ? data.businessName : ""}
              size="small"
              disabled
              label="Estabelecimento de afiliação"
              variant="outlined"
              fullWidth
              // InputLabelProps={{
              //   shrink: true
              // }}
            />
            <TextField
              name="name"
              margin="dense"
              value={this.state.name}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              error={this.state.erro.name}
              size="small"
              label="Nome Fantasia"
              variant="outlined"
              fullWidth
            />
            <TextField
              name="cnpj"
              value={this.state.cnpj}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              error={this.state.erro.cnpj}
              size="small"
              label="CNPJ"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              name="mobile"
              value={this.state.mobile}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              error={this.state.erro.mobile}
              size="small"
              margin="dense"
              label="Telefone de contato"
              variant="outlined"
              fullWidth
            />
            <TextField
              name="email"
              margin="dense"
              value={this.state.email}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              error={this.state.erro.email}
              size="small"
              label="Email corporativo"
              variant="outlined"
              fullWidth
            />
            <FormHelperText
              error={this.state.erro.email}
              style={{ marginBottom: 10 }}
            >
              O e-mail deve ser preferencialmente corporativo, e-mail pessoal
              não deve ser utilizado.
            </FormHelperText>
          </>
        )}
        {this.state.position === 1 && ( // Pagina 1
          <>
            <h4>
              {this.t('sideTitle')}
            </h4>
            <TextField
              name="userName"
              value={this.state.userName}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              error={this.state.erro.userName}
              size="small"
              margin="dense"
              label="Nome completo"
              variant="outlined"
              fullWidth
            />
            <FormHelperText error={this.state.erro.userName}>
              &nbsp;Nome completo do gestor da operação.
            </FormHelperText>
            <TextField
              name="userEmail"
              margin="dense"
              value={this.state.userEmail}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              error={this.state.erro.userEmail}
              size="small"
              label="Email"
              variant="outlined"
              fullWidth
            />
            <FormHelperText error={this.state.erro.userEmail}>
              Este e-mail será usado para acessar o painel administrativo da
              handover
            </FormHelperText>
            <TextField
              name="password"
              error={this.state.erro.password}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth
              type={this.state.showPassword ? "text" : "password"}
              label="Senha"
              value={this.state.password}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ padding: 0 }}>
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {this.state.erro.password && (
              <FormHelperText error={this.state.erro.password}>
                &nbsp;Sua senha deve ter ao menos 8 dígitos.
              </FormHelperText>
            )}

            <TextField
              name="passwordConfirmation"
              error={this.state.password !== this.state.passwordConfirmation}
              fullWidth
              size="small"
              margin="dense"
              style={{
                marginBottom: 20
              }}
              variant="outlined"
              type={this.state.showPassword ? "text" : "password"}
              label="Confirme a senha"
              value={this.state.passwordConfirmation}
              onChange={e => this.handleChange(e.target.name, e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={this.state.checked}
                  onChange={e =>
                    this.handleChange(e.target.name, e.target.checked)
                  }
                  name="checked"
                />
              }
              label="Utilizar os dados acima para criar o acesso ao aplicativo handover Drop"
              className={classes.root}
            />
          </>
        )}
        {this.state.position === 2 && ( // Pagina 2
          <>
            {!this.state.checked && (
              <h4>Crie seu acesso para o handover Drop</h4>
            )}
            {this.state.confirmation && (
              <Grid>
                <Typography>Dados do estabelecimento</Typography>
                <small>Nome do estabelecimento: {this.state.name}</small>
                <br />
                <small>CNPJ: {this.state.cnpj}</small>
                <br />
                <small>Telefone de contato: {this.state.mobile}</small>
                <br />
                <small>Email corporativo: {this.state.email}</small>
                <br />
                <br />
                <Typography>Credenciais para acessar a plataforma</Typography>
                <small>Nome completo: {this.state.userName}</small>
                <br />
                <small>Email: {this.state.userEmail}</small>
                <br />
                <br />
                <Typography>Credenciais para acessar o aplicativo</Typography>
                <small>
                  Nome completo:{" "}
                  {this.state.checked
                    ? this.state.userName
                    : this.state.mobileName}
                </small>
                <br />
                <small>
                  Email:{" "}
                  {this.state.checked
                    ? this.state.userEmail
                    : this.state.mobileEmail}
                </small>
                {this.state.businessDepositorCPF && (
                  <small>{this.state.businessDepositorCPF}</small>
                )}
                <br />
              </Grid>
            )}
            {!this.state.checked &&
              !this.state.confirmation && (
                <>
                  <TextField
                    select
                    label="Credencial de acesso"
                    name="option"
                    margin="dense"
                    size="small"
                    fullWidth
                    value={this.state.option}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    variant="outlined"
                  >
                    {["email", "cpf"].map(item => (
                      <MenuItem key={item} value={item}>
                        {item === "cpf" ? "CPF" : "Email"}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    name="mobileName"
                    value={this.state.mobileName}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    error={this.state.erro.mobileName}
                    size="small"
                    margin="dense"
                    label="Nome completo"
                    variant="outlined"
                    fullWidth
                  />
                  <FormHelperText error={this.state.erro.mobileName}>
                    &nbsp;Nome completo do gestor da operação.
                  </FormHelperText>
                  <TextField
                    name={
                      this.state.option === "email"
                        ? "mobileEmail"
                        : "mobileCpf"
                    }
                    margin="dense"
                    value={
                      this.state.option === "email"
                        ? this.state.mobileEmail
                        : this.state.mobileCpf
                    }
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    error={
                      this.state.option === "email"
                        ? this.state.erro.mobileEmail
                        : this.state.erro.mobileCpf
                    }
                    size="small"
                    label={this.state.option === "email" ? "E-mail" : "Cpf"}
                    variant="outlined"
                    fullWidth
                  />
                  {this.state.option === "email" && (
                    <FormHelperText error={this.state.erro.mobileEmail}>
                      Este e-mail será usado para acessar o aplicativo handover
                      Drop
                    </FormHelperText>
                  )}
                  <TextField
                    name="mobilePassword"
                    error={this.state.erro.mobilePassword}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    type={this.state.showPassword ? "text" : "password"}
                    label="Senha"
                    value={this.state.mobilePassword}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ padding: 0 }}>
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {this.state.erro.mobilePassword && (
                    <FormHelperText error={this.state.erro.mobilePassword}>
                      &nbsp;Sua senha deve ter ao menos 8 dígitos.
                    </FormHelperText>
                  )}

                  <TextField
                    name="mobilePasswordConfirmation"
                    error={
                      this.state.mobilePassword !==
                      this.state.mobilePasswordConfirmation
                    }
                    fullWidth
                    size="small"
                    margin="dense"
                    style={{
                      marginBottom: 20
                    }}
                    variant="outlined"
                    type={this.state.showPassword ? "text" : "password"}
                    label="Confirme a senha"
                    value={this.state.mobilePasswordConfirmation}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </>
              )}
            <br />
            {this.state.confirmation && (
              <ReCAPTCHA
                style={{ marginBottom: "20px" }}
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={() => this.handleChange("humanConfirmation", true)}
              />
            )}
            {this.state.erro.humanConfirmation &&
              this.state.confirmation && (
                <FormHelperText error={this.state.erro.humanConfirmation}>
                  &nbsp;Valide o ReCAPTCHA.
                </FormHelperText>
              )}
          </>
        )}
        {this.state.position === 3 && ( // Pagina 3 ultima pagina
          <>
            <br />
            <br />
            <br />
            <p style={{ fontWeight: "bold" }}>
              Seus dados foram salvos em nossa base de dados!
            </p>
            <p
              style={{ fontSize: "16px", marginBottom: "20px" }}
            >{`O cadastro do seu estabelecimento foi realizado com sucesso, e vinculado a ${
              data ? data.businessName : ""
            }.`}</p>
            <p style={{ fontSize: "16px", marginBottom: "20px" }}>
              Se tudo estiver certo, em até 48 horas você receberá um e-mail
              informando que o seu acesso foi liberado.
            </p>
            <Button
              variant="contained"
              style={{ ...styleButton.green, marginBottom: 300 }}
              onClick={() => history.push("/")}
            >
              Ir para a página de login
            </Button>
          </>
        )}
        {this.state.position !== 3 && (
          <Grid container spacing={0}>
            {this.state.position > 0 && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (this.state.position === 0) return;
                    this.setState({
                      position: this.state.position - 1,
                      humanConfirmation: false
                    });
                  }}
                  startIcon={<ArrowBackIos>send</ArrowBackIos>}
                  style={loading ? styleButton.grey : styleButton.green}
                  disabled={loading ? true : false}
                >
                  Anterior
                </Button>
                &nbsp;&nbsp;
              </>
            )}
            <Button
              variant="contained"
              style={
                (position === 2 && confirmation && !humanConfirmation) ||
                loading
                  ? styleButton.grey
                  : styleButton.green
              }
              disabled={
                (position === 2 && confirmation && !humanConfirmation) ||
                loading
                  ? true
                  : false
              }
              onClick={() => {
                this.verificationOfFieldsPage();
              }}
              endIcon={<ArrowForwardIos>send</ArrowForwardIos>}
            >
              {position === 2 && confirmation ? "Concluir" : "Próximo"}
            </Button>
          </Grid>
        )}
        <br />
      </>
    );
  }
}

const mapStateToProps = state => ({
  location: state.location
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...LocationActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation('signup')(containerStepper)));
