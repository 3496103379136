import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as PaintConditionOptions } from "../ducks/paintConditionOptions";

export function* getPaintConditionOptions(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/paintConditionOptions?${action.payload}`
    );

    yield put(
      PaintConditionOptions.getPaintConditionOptionsSuccess(response.data)
    );
  } catch (err) {
    yield put(PaintConditionOptions.getPaintConditionOptionsError(err));
  }
}
