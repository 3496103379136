import React, { Component, Fragment } from "react";
import Container from "../../components/Container";
import Table from "./components/Table";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerActions } from "../../store/ducks/locker";

import Spinner from "../../components/Spinner";

class DeploymentStatus extends Component {
  state = {
    loading: true
  };
  componentWillMount() {
    const { lockersRequest } = this.props;

    lockersRequest(`perPage=1000`);
    this.setState({ loading: false });
  }

  render() {
    const { lockers } = this.props;

    return (
      <Fragment>
        <Container title="status de implantação">
          {this.state.loading ? <Spinner /> : <Table lockers={lockers.data} />}
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  lockers: state.locker
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(LockerActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentStatus);
