import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";

import api from "../../services/api";
import { Actions as SurveyFormActions } from "../ducks/surveyForm";

export function* getSurveyForms(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/surveyForm?${action.payload.data}`
    );

    yield put(SurveyFormActions.surveyFormSuccess(response.data));
  } catch (err) {
    yield put(SurveyFormActions.surveyFormError(err));
  }
}

export function* showSurveyForms(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/surveyForm/${action.payload.id}`
    );

    yield put(SurveyFormActions.showSurveyFormSuccess(response.data));
  } catch (err) {
    yield put(SurveyFormActions.showSurveyFormError(err.response.data));
  }
}

export function* createSurveyForm(action) {
  try {
    const response = yield call(
      api.post,
      "/v1/adm/surveyForm",
      action.payload.data
    );
    yield put(
      SurveyFormActions.createSurveyFormSuccess({
        ...response.data,
        snackbarVisible: true,
        snackbarMessage: "Vistoria criada",
        snackbarType: "success"
      })
    );
    yield put(push(`/surveyform/edit/${response.data.id}`));
  } catch (err) {
    yield put(SurveyFormActions.createSurveyFormError(err.response.data));
  }
}

export function* updateSurveyFormStatus(action) {
  try {
    const response = yield call(
      api.put,
      `/v1/adm/surveyForm/protocol/update/status/${action.payload.id}`
    );
    yield put(
      SurveyFormActions.updateSurveyFormStatusSuccess({
        currentSurveyForm: response.data,
        snackbarVisible: true,
        snackbarMessage: "Vistoria atualizada",
        snackbarType: "success"
      })
    );
  } catch (err) {
    yield put(SurveyFormActions.updateSurveyFormStatusError(err.response.data));
  }
}
