import React from "react";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextField } from "@material-ui/core";

const tmpOptions = ["false", "true"];

export default function FormProblemObs({
  title,
  optionKeyName,
  descriptionKeyName,
  label,
  change,
  value,
  descriptionValue,
  disabled,
  positiveText,
  negativeText,
  onlySelect
}) {
  return (
    <>
      <h6>{title}</h6>
      <RadioGroup
        style={{ display: "flex", flexDirection: "row" }}
        name={optionKeyName}
        value={tmpOptions.find(item => item === value)}
        onChange={e => change(e)}
        disabled={disabled}
      >
        <FormControlLabel
          value={tmpOptions[1]}
          control={<Radio />}
          label={positiveText || "Sim"}
          disabled={disabled}
        />

        <FormControlLabel
          value={tmpOptions[0]}
          control={<Radio />}
          label={negativeText || "Não"}
          disabled={disabled}
        />
      </RadioGroup>
      {!onlySelect && (
        <TextField
          name={descriptionKeyName}
          label={label || "Observação"}
          value={descriptionValue}
          onChange={e => change(e)}
          variant="outlined"
          fullWidth
          disabled={disabled}
        />
      )}
    </>
  );
}
