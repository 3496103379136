import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "../../components/Container";

const useStyle = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      // margin: theme.spacing(2),
      width: "95%",
      height: theme.spacing(10)
    }
  }
}));

function Notifications() {
  const classes = useStyle();

  return (
    <>
      <Container title="Notificações">
        <div className={classes.root}>
          <Paper elevation={2} />
        </div>
      </Container>
    </>
  );
}

export default Notifications;
