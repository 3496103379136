export const Types = {
  setSnackbarVisibleDelivery: "setSnackbarVisible/Delviery",

  getDeliveriesRequest: "getDeliveries/Request",
  getDeliveriesSuccess: "getDeliveries/Success",
  getDeliveriesError: "getDeliveries/Error",

  showDeliveryRequest: "showDelivery/Request",
  showDeliverySuccess: "showDelivery/Success",
  showDeliveryError: "showDelivery/Error",

  createDeliveryRequest: "createDelivery/Request",
  createDeliverySuccess: "createDelivery/Success",
  createDeliveryError: "createDelivery/Error",

  searchDeliveryRequest: "searchDelivery/Request",
  searchDeliverySuccess: "searchDelivery/Success",
  searchDeliveryError: "searchDelivery/Error",

  updateDeliveryRequest: "updateDelivery/Request",
  updateDeliverySuccess: "updateDelivery/Success",
  updateDeliveryError: "updateDelivery/Error",

  openDeliveryLockerRequest: "openDeliveryLocker/Request",
  openDeliveryLockerSuccess: "openDeliveryLocker/Success",
  openDeliveryLockerError: "openDeliveryLocker/Error",

  sendDeliveryEmailCodominiumRequest: "sendDeliveryEmailCodominium/Request",
  sendDeliveryEmailCodominiumSuccess: "sendDeliveryEmailCodominium/Success",
  sendDeliveryEmailCodominiumError: "sendDeliveryEmailCodominium/Error",

  setEditableDelivery: "setEditable/Delivery",

  cancelDeliveryRequest: "cancelDelivery/Request",
  cancelDeliverySuccess: "cancelDelivery/Success",
  cancelDeliveryError: "cancelDelivery/Error",

  resetDeliveryState: "resetDeliveryState",

  setSnackbar: "setSnackbar/delivery"
};

const INITAL_STATE = {
  data: [],
  loading: false,
  error: null,
  currentDelivery: null,
  snackbarVisible: false,
  snackbarType: "success",
  snackbarMessage: "",
  total: null,
  perPage: null,
  lastPage: null,
  page: null,
  setEditable: false
};

export default function delivery(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.resetDeliveryState:
      return INITAL_STATE;

    case Types.setSnackbarVisibleDelivery:
      return {
        ...state,
        snackbarVisible: action.payload.status,
        error: null
      };

    case Types.setEditableDelivery:
      return {
        ...state,
        setEditable: action.payload.status
      };

    case Types.getDeliveriesRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.getDeliveriesSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data
      };

    case Types.getDeliveriesError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.showDeliveryRequest:
      return {
        ...state,
        loading: true,
        error: null,
        currentDelivery: null
      };

    case Types.showDeliverySuccess:
      return {
        ...state,
        loading: false,
        currentDelivery: action.payload.data
      };

    case Types.showDeliveryError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        currentDelivery: null
      };

    case Types.createDeliveryRequest:
      return {
        ...state,
        loading: true,
        error: null,
        snackbarVisible: false
      };

    case Types.createDeliverySuccess:
      return {
        ...state,
        loading: false
      };

    case Types.createDeliveryError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true
      };

    case Types.searchDeliveryRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.searchDeliverySuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data,
        data: action.payload.data.data
      };

    case Types.searchDeliveryError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.updateDeliveryRequest:
      return {
        ...state,
        loading: true,
        error: null,
        snackbarVisible: false
      };

    case Types.updateDeliverySuccess:
      return {
        ...state,
        loading: false,
        currentDelivery: action.payload.data,
        snackbarVisible: true,
        snackbarType: "success",
        snackbarMessage: "Atualizado!"
      };

    case Types.updateDeliveryError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true,
        snackbarType: "error",
        snackbarMessage: "Não foi possível atualizar"
      };

    case Types.openDeliveryLockerRequest:
      return {
        ...state,
        loading: true,
        error: INITAL_STATE.error,
        snackbarVisible: INITAL_STATE.snackbarVisible
      };

    case Types.openDeliveryLockerSuccess:
      return {
        ...state,
        loading: false
      };

    case Types.openDeliveryLockerError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.sendDeliveryEmailCodominiumRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.sendDeliveryEmailCodominiumSuccess:
      return {
        ...state,
        loading: false,
        snackbarMessage: "E-mail enviado!",
        snackbarType: "success",
        snackbarVisible: true
      };

    case Types.sendDeliveryEmailCodominiumError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.cancelDeliveryRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.cancelDeliverySuccess:
      return {
        ...state,
        loading: false,
        snackbarMessage: "Entrega finalizada!",
        snackbarType: "success",
        snackbarVisible: true,
        setEditable: false,
        currentDelivery: {
          ...state.currentDelivery,
          ...action.payload.data
        },
        data: state.data.map(
          item =>
            item.id === action.payload.data.id
              ? { ...item, ...action.payload.data }
              : item
        )
      };

    case Types.cancelDeliveryError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.setSnackbar:
      return {
        ...state,
        ...action.payload.data
      };

    default:
      return state;
  }
}

export const Actions = {
  setSnackbarVisibleDelivery: status => ({
    type: Types.setSnackbarVisibleDelivery,
    payload: { status }
  }),

  getDeliveriesRequest: data => ({
    type: Types.getDeliveriesRequest,
    payload: { data }
  }),

  getDeliveriesSuccess: data => ({
    type: Types.getDeliveriesSuccess,
    payload: { data }
  }),

  getDeliveriesError: error => ({
    type: Types.getDeliveriesError,
    payload: { error }
  }),

  showDeliveryRequest: id => ({
    type: Types.showDeliveryRequest,
    payload: { id }
  }),

  showDeliverySuccess: data => ({
    type: Types.showDeliverySuccess,
    payload: { data }
  }),

  showDeliveryError: error => ({
    type: Types.showDeliveryError,
    payload: { error }
  }),

  createDeliveryRequest: data => ({
    type: Types.createDeliveryRequest,
    payload: { data }
  }),

  createDeliverySuccess: data => ({
    type: Types.createDeliverySuccess,
    payload: { data }
  }),

  createDeliveryError: error => ({
    type: Types.createDeliveryError,
    payload: { error }
  }),

  searchDeliveryRequest: data => ({
    type: Types.searchDeliveryRequest,
    payload: { data }
  }),

  searchDeliverySuccess: data => ({
    type: Types.searchDeliverySuccess,
    payload: { data }
  }),

  searchDeliveryError: error => ({
    type: Types.searchDeliveryError,
    payload: { error }
  }),

  updateDeliveryRequest: data => ({
    type: Types.updateDeliveryRequest,
    payload: { data }
  }),

  updateDeliverySuccess: data => ({
    type: Types.updateDeliverySuccess,
    payload: { data }
  }),

  updateDeliveryError: data => ({
    type: Types.updateDeliveryError,
    payload: { data }
  }),

  openDeliveryLockerRequest: data => ({
    type: Types.openDeliveryLockerRequest,
    payload: { data }
  }),

  openDeliveryLockerSuccess: data => ({
    type: Types.openDeliveryLockerSuccess,
    payload: { data }
  }),

  openDeliveryLockerError: error => ({
    type: Types.openDeliveryLockerError,
    payload: { error }
  }),

  setEditableDelivery: status => ({
    type: Types.setEditableDelivery,
    payload: { status }
  }),

  sendDeliveryEmailCodominiumRequest: idDelivery => ({
    type: Types.sendDeliveryEmailCodominiumRequest,
    payload: { idDelivery }
  }),

  sendDeliveryEmailCodominiumSuccess: () => ({
    type: Types.sendDeliveryEmailCodominiumSuccess
  }),

  sendDeliveryEmailCodominiumError: error => ({
    type: Types.sendDeliveryEmailCodominiumError,
    payload: { error }
  }),

  cancelDeliveryRequest: (idDelivery, idDeliveryUpdateInfoSubType) => ({
    type: Types.cancelDeliveryRequest,
    payload: { idDelivery, idDeliveryUpdateInfoSubType }
  }),

  cancelDeliverySuccess: data => ({
    type: Types.cancelDeliverySuccess,
    payload: { data }
  }),

  cancelDeliveryError: error => ({
    type: Types.cancelDeliveryError,
    payload: { error }
  }),

  resetDeliveryState: () => ({
    type: Types.resetDeliveryState
  }),

  setSnackbar: data => ({
    type: Types.setSnackbar,
    payload: { data }
  })
};
