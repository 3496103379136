import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import Cookies from "js-cookie";

import api from "../../services/api";

import { Actions as LoginActions } from "../ducks/login";

export function* login(action) {
  try {
    const response = yield call(api.post, "/business/login", {
      ...action.payload
    });

    localStorage.setItem(
      "auth",
      JSON.stringify({
        token: `Bearer ${response.data.token}`
      })
    );

    const teams = yield call(api.get, "/teams");

    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("auth")),
        currentTeam: teams.data[0]
      })
    );

    localStorage.setItem("oihandover@email", action.payload.email);

    const permissions = yield call(api.get, "/business/permissions");

    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("auth")),
        ...permissions.data
      })
    );

    yield put(
      LoginActions.loginSuccess({
        permissions: permissions.data
      })
    );

    const user = yield call(api.get, "/v1/adm/me");

    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("auth")),
        user: user.data
      })
    );

    // define 1hr de expiracao do token
    const expires = new Date();
    expires.setHours(expires.getHours() + 1);
    yield Cookies.set("auth", expires, {
      expires
    });

    yield put(push("/home"));
  } catch (err) {
    localStorage.clear();
    yield put(LoginActions.loginError(err));
  }
}

export function* logout(action) {
  try {
    Cookies.remove("auth");
    yield put(push("/"));
    yield localStorage.clear();
  } catch (err) {
    yield put(LoginActions.logoutError(err));
  }
}
