import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Material
import { TextField, Grid, TextareaAutosize } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

// Styles
import materialStyle from "../../../../../../styles/material";
import TextMaskCustomMobile from "../../../../../../components/TextMaskCustomMobile";

class LockerInfo extends Component {
  state = {};

  t = this.props.t;

  render() {
    const { classes, currentLocker } = this.props;

    if (!currentLocker) return <></>;

    return (
      <>
        <h5>{this.t("info.general")}</h5>
        <Grid container spacing={2}>
          {currentLocker.hubName && (
            <Grid item xs={12} md={4} className="form-colaboradore-sm">
              <TextField
                id="outlined-name"
                label={this.t("info.hub")}
                className={classes.textField}
                value={currentLocker.hubName}
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
          )}
          <Grid item xs={12} md={4} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label={this.t("info.address")}
              className={classes.textField}
              value={currentLocker.address}
              margin="normal"
              variant="outlined"
              disabled={true}
            />
          </Grid>
          {currentLocker.operatingHours && (
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                id="outlined-name"
                label={this.t("info.working_hours")}
                className={classes.textField}
                value={currentLocker.operatingHours}
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
          )}
        </Grid>
        <h5>{this.t("info.contact")}</h5>
        <Grid spacing={2} container>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label={this.t("info.name")}
              className={classes.textField}
              value={currentLocker.contactName}
              margin="normal"
              variant="outlined"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label={this.t("info.phone")}
              className={classes.textField}
              value={currentLocker.contactNumber}
              margin="normal"
              variant="outlined"
              disabled={true}
              InputProps={{
                inputComponent: TextMaskCustomMobile
              }}
            />
          </Grid>
          {currentLocker.contactEmail && (
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                id="outlined-name"
                label={this.t("info.name")}
                className={classes.textField}
                value={currentLocker.contactEmail}
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
          )}
        </Grid>
        {currentLocker.hubAccessType && (
          <>
            <h5>{this.t("info.access")}</h5>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className="form-colaboradore-sm">
                <TextareaAutosize
                  id="outlined-name"
                  label={this.t("info.access_place")}
                  className={classes.textField}
                  value={currentLocker.hubAccessType}
                  margin="normal"
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentLocker: state.locker.currentLocker
});

export default connect(mapStateToProps)(withTranslation("lockers")(withStyles(materialStyle)(LockerInfo)));
