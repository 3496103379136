import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

// Materials
import { Stepper, Step, StepLabel, StepConnector } from "@material-ui/core";

// Icons
import BusinessIcon from "@material-ui/icons/Business";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 136deg, rgb(81,195,72) 0%, rgb(150,219,145) 50%, rgb(174,230,170) 100%)"
    }
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 136deg, rgb(81,195,72) 0%, rgb(150,219,145) 50%, rgb(174,230,170) 100%)"
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  // Parametros de cores
  root: {
    backgroundColor: "rgb(200, 200, 200)",
    zIndex: 1,
    color: "rgb(255,255,255)",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(174,230,170) 0%, rgb(150,219,145) 50%, rgb(81,195,72) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(174,230,170) 0%, rgb(150,219,145) 50%, rgb(81,195,72) 100%)"
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons = {
    1: <BusinessIcon />,
    2: <AccountCircleIcon />,
    3: <VpnKeyIcon />
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export default function CustomizedSteppers(props) {
  const classes = useStyles();

  return (
    <div style={{ marginTop: "10px" }} className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={props.position}
        connector={<ColorlibConnector />}
      >
        <Step key={1}>
          <StepLabel StepIconComponent={ColorlibStepIcon} />
        </Step>
        <Step key={2}>
          <StepLabel StepIconComponent={ColorlibStepIcon} />
        </Step>
        <Step key={3}>
          <StepLabel StepIconComponent={ColorlibStepIcon} />
        </Step>
      </Stepper>
    </div>
  );
}
