import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
`;

export const ContentButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -45px;
    left: 10px;
    background: #4ac151;
    border-radius: 8px;
    box-shadow: 3px 3px #bbb;
    width: 20%;

    strong {
      font-size: 14px;
      color: white;
    }
  }
`;
