import { put, call } from "redux-saga/effects";
import apiNotification from "../../services/apiNotification";

import { Actions as NotificationActions } from "../ducks/notification";

export function* getNotification(action) {
  try {
    const notification = yield call(
      apiNotification.get,
      "/v1/notification/users"
    );

    yield put(NotificationActions.getNotificationSuccess(notification.data));
  } catch (err) {
    yield put(NotificationActions.getNotificationError(err));
  }
}

export function* createNotification(action) {
  try {
    const { title, content } = action.payload;

    yield call(apiNotification.post, "/notification", {
      title,
      content
    });

    yield put(NotificationActions.createNotificationSuccess());
  } catch (err) {
    yield put(NotificationActions.createNotificationError());
  }
}

export function* updateNotification(action) {
  try {
    const { id } = action.payload;

    yield call(apiNotification.put, `/v1/notification/users/${id}`);

    yield put(NotificationActions.updateNotificationSuccess(id));
  } catch (err) {
    yield put(NotificationActions.updateNotificationError());
  }
}
