import React, { Component } from "react";
import apiDS from "../../services/apiDS";
import LockerAddress from "../../components/DataScience/LockerAddress";

// Material
import { Grid, Button, TextField } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";

import Container from "../../components/Container";
import { Loading, TextLoading } from "./styles";

import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

class ToolsDatascience extends Component {
  state = {
    data: [],
    checkIA: true,
    checkCicle: true,
    lockerQtd: 1,
    radiusForLocker: 2000,
    fileName: "",
    file: {},
    mapHtml: null,
    loading: false
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeChecked = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  fetchLocationRoute = async () => {
    try {
      const formData = new FormData();

      this.setState({
        loading: true,
        error: null
      });

      if (this.state.data.length > 0) {
        const data = this.returnDataString(this.state.data);
        formData.append("point_locker", JSON.stringify(data));
      } else {
        formData.append("point_locker", JSON.stringify(this.state.data));
      }
      formData.append("file", this.state.file, this.state.fileName);
      formData.append("checkCicle", this.state.checkCicle);
      formData.append("checkIA", this.state.checkIA);
      formData.append("lockerQtd", this.state.lockerQtd);
      formData.append("radiusForLocker", this.state.radiusForLocker);

      const { data } = await apiDS.post(`/position_locker/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*"
        }
      });

      this.setState({
        mapHtml: data,
        loading: false
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: err
      });
    }
  };

  handleFile = ({ target }) => {
    if (!target.files.length) {
      this.setState({ fileName: "" });
      return;
    }

    this.setState({ file: target.files[0], fileName: target.files[0].name });
  };

  change = data => {
    this.setState({ data });
  };

  LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9"
    }
  }))(Tooltip);

  render() {
    return (
      <Container title="posicionador de locker">
        <ThemeTextFildGreen>
          <div className="helper-spacing-top-mobile" />

          <Loading loading={this.state.loading}>
            <TextLoading>Calculando...</TextLoading>
          </Loading>

          <Alert severity="info" style={{ width: "100%" }}>
            <AlertTitle>Funcionalidade:</AlertTitle>
            <p style={{ fontSize: "14px" }}>
              Esta ferramenta analisa os endereços de entrega informados e
              aponta o local de posicionamento do locker para que a operação a
              mais eficiente possível.
            </p>
            <p style={{ fontSize: "14px" }}>
              A análise é limitada em até 200 endereços, se você possuir mais do
              que o limite, fale com seu consultor handover e faremos a análise
              para você.
            </p>
            <AlertTitle>Como utilizar?</AlertTitle>

            <ol style={{ marginTop: 10 }}>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Baixe{" "}
                <a
                  href="https://drive.google.com/file/d/10nEEujSrl307p_1rA110Dx89NrQgJnNw/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aqui
                </a>{" "}
                o arquivo template com histórico das entregas, e insira as datas
                e endereços de cada entrega.
              </li>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Preencha os dados conforme o exemplo na planilha, salve mantendo
                o formato .xlsx e realize o upload no campo abaixo.
              </li>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Preencha todos os demais campos abaixo e clique em POSICIONAR
              </li>
            </ol>

            <AlertTitle>Observação</AlertTitle>

            <ul>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                O tempo para análise e cálculo pode levar até 60 segundos,
                dependo da quantidade de endereços, lockers e raio de entrega.
              </li>
            </ul>
          </Alert>

          <br />
          <h4
            style={{
              marginBottom: -10,
              marginTop: 20,
              textAlign: "left",
              fontSize: "16px"
            }}
          >
            Arquivo com histórico de entregas
          </h4>
          <Grid container spacing={2} style={{ marginBottom: 10 }}>
            <Grid item xs={6} md={4}>
              <TextField
                label="Insira o arquivo .xlsx com histórico"
                disabled
                fullWidth
                name="origin"
                value={this.state.fileName}
                margin="normal"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4} md={3}>
              <input
                type="file"
                accept=".xlsx"
                onChange={this.handleFile}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                style={{
                  marginTop: 25,
                  marginLeft: 30,
                  backgroundColor: "#006660",
                  color: "#fff",
                  fontSize: 14,
                  textTransform: "lowercase",
                  height: 35,
                }}
                component="label"
              >
                Upload
              </Button>
            </Grid>
          </Grid>
          <LockerAddress
            type={this.state.type}
            LightTooltip={this.LightTooltip}
            checkIAChange={checkIA => this.setState({ checkIA })}
            lockerQtdChange={lockerQtd => this.setState({ lockerQtd })}
            radiusForLockerChange={radiusForLocker =>
              this.setState({ radiusForLocker })
            }
            change={data => this.change(data)}
          />

          <Button
            onClick={this.fetchLocationRoute}
            variant="contained"
            style={{
              backgroundColor: "#006660",
              color: "#fff",
              textTransform: "lowercase",
              fontSize: 14,
              height: 35,
            }}
          >
            Posicionar
          </Button>

          <br />

          {this.state.mapHtml && (
            <iframe
              title="ddd"
              srcdoc={this.state.mapHtml}
              width={"100%"}
              height={"480px"}
            />
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

export default ToolsDatascience;
