import React, { Component } from "react";

// Material
import { TextField, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as RolesActions } from "../../../../../store/ducks/roles";

// Global Components
import Container from "../../../../../components/Container";
import Snackbar from "../../../../../components/Snackbar";

// Global Styles
import { materialStyle } from "../../../../../styles";
import ThemeTextFildGreen from "../../../../../styles/ThemeTextFildGreen";
import { withTranslation } from "react-i18next";

class CreateRole extends Component {
  t = this.props.t
  state = {
    name: "",
    slug: "",
    description: "",
    isEmpty: false
  };

  handleChange = key => event => {
    this.setState({ [key]: event.target.value });
  };

  handleCreate = () => {
    const { name, slug, description } = this.state;
    const { createRoleRequest } = this.props;
    this.setState({ isEmpty: false });

    if (!name || !slug || !description) {
      this.setState({ isEmpty: true });
    }

    createRoleRequest({
      name,
      slug,
      description
    });
  };

  render() {
    const {
      classes,
      loading,
      snackbarMessage,
      snackbarType,
      snackbarVisible,
      setSnackbarVisible
    } = this.props;
    const { name, slug, description, isEmpty } = this.state;

    return (
      <Container title={this.t('createTitle')}>
        <ThemeTextFildGreen>
          <Snackbar
            open={isEmpty}
            onClose={() => this.setState({ isEmpty: false })}
            variant="warning"
            message={this.t('common:fillEveryField')}
          />
          <Snackbar
            open={snackbarVisible}
            onClose={() => setSnackbarVisible(false)}
            variant={snackbarType}
            message={snackbarMessage}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                required
                variant="outlined"
                label={this.t('ruleName')}
                className={classes.textField}
                margin="normal"
                value={name}
                onChange={this.handleChange("name")}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                required
                variant="outlined"
                label="Slug"
                className={classes.textField}
                margin="normal"
                value={slug}
                onChange={this.handleChange("slug")}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} md={4} className="form-colaboradore-sm">
              <TextField
                required
                variant="outlined"
                label={this.t('common:description')}
                className={classes.textField}
                margin="normal"
                value={description}
                onChange={this.handleChange("description")}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                onClick={this.handleCreate}
                disabled={loading}
                style={{ background: "#4AC151", color: "#fff" }}
              >
                {this.t('createBtn')}
              </Button>
            </Grid>
          </Grid>
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.roles.loading,
  snackbarVisible: state.roles.snackbarVisible,
  snackbarType: state.roles.snackbarType,
  snackbarMessage: state.roles.snackbarMessage
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(RolesActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(withTranslation('roles')(CreateRole)));
