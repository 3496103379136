import styled from "styled-components";

import Background from "../../../assets/f2.svg";

export const Container = styled.div`
  flex: 1;
  height: 100%;
  background-repeat: repeat;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const LogoStyle = styled.img`
  margin-top: -60px;
  margin-left: 100px;
  cursor: pointer;
`;

export const LogoStyle2 = styled.img`
  margin-top: -60px;
`;

export const TitleLogo = styled.small`
  font-size: 10px;
  font-weight: bolder;
  margin-left: 145px;
  color: slategray;
`;

export const BannerTitle = styled.h1`
  font-family: Montserrat, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 30px;
  margin-left: 40px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 130px;
  line-height: 1.3;
`;

export const Footer = styled.div`
  margin: 25px 0 10px;
`;

export const Content = styled.div`
  display: flex;
  margin-top: 100px;
`;

export const Backgournd = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${Background});
  overflow-y: auto;
`;
