import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions } from "../ducks/azureActiveDirectory";

export function* azureActiveDirectoryRequest(action) {
  try {
    const response = yield call(api.get, "/v1/adm/azure/ad");

    yield put(Actions.azureActiveDirectorySuccess(response.data));
  } catch (err) {
    yield put(Actions.azureActiveDirectoryError(err));
  }
}

export function* createAzureActiveDirectoryRequest(action) {
  try {
    const response = yield call(
      api.post,
      "/v1/adm/azure/ad",
      action.payload.data
    );

    yield put(Actions.createAzureActiveDirectorySuccess(response.data));
  } catch (err) {
    yield put(Actions.createAzureActiveDirectoryError(err));
  }
}

export function* updateAzureActiveDirectoryRequest(action) {
  try {
    const response = yield call(
      api.put,
      `/v1/adm/azure/ad/${action.payload.data.id}`,
      action.payload.data
    );

    yield put(Actions.updateAzureActiveDirectorySuccess(response.data));
  } catch (err) {
    yield put(Actions.updateAzureActiveDirectoryError(err));
  }
}
