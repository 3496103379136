import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as ThinsActions } from "../ducks/things";
import { Actions as ConfigActions } from "../ducks/configs";
import { constants } from "../../config";

export function* thingsRequest(action) {
  try {
    const response = yield call(api.get, "/v1/adm/things");

    yield put(ThinsActions.thingsSuccess(response.data));
  } catch (err) {
    yield put(ThinsActions.thingsError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}

export function* thingsCreateRequest(action) {
  try {
    const response = yield call(
      api.post,
      "/v1/adm/things",
      action.payload.data
    );

    yield put(ThinsActions.thingsCreateSuccess(response.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Thing criado",
        toastVariant: constants.toastVariants.success
      })
    );
  } catch (err) {
    yield put(ThinsActions.thingsCreateError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}

export function* thingsShowRequest(action) {
  try {
    const response = yield call(api.get, `/v1/adm/things/${action.payload.id}`);

    yield put(ThinsActions.thingsShowSuccess(response.data));
  } catch (err) {
    yield put(ThinsActions.thingsShowError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}

export function* thingsUpdateRequest(action) {
  try {
    const response = yield call(
      api.put,
      `/v1/adm/things/${action.payload.id}`,
      action.payload.data
    );

    yield put(ThinsActions.thingsUpdateSuccess(response.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Thing atualizado",
        toastVariant: constants.toastVariants.success
      })
    );
  } catch (err) {
    yield put(ThinsActions.thingsUpdateError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}
