import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as IntegrationsActions } from "../ducks/integrations";

export function* getIntegrationDataPartnerRequest(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/integration/partners/${action.payload.id}`
    );

    yield put(
      IntegrationsActions.getIntegrationDataPartnerSuccess(response.data)
    );
  } catch (err) {
    yield put(IntegrationsActions.getIntegrationDataPartnerError(err.data));
  }
}

export function* getIntegrationDataTeamRequest(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/integration/teams/${action.payload.id}`
    );

    yield put(IntegrationsActions.getIntegrationDataTeamSuccess(response.data));
  } catch (err) {
    yield put(IntegrationsActions.getIntegrationDataTeamError(err.data));
  }
}
