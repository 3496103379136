import styled from "styled-components";
import { colors } from "../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

export const WrapperImage = styled.div`
  min-height: 260px;
  display: flex;
  align-items: center;
`;

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  width: 32%;
  /* box-shadow: inset 0 0 1em ${colors.dark1}; */

  p {
    font-size: 16px;
  }

  & input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  & input + label {
    font-size: 1.25em;
    font-weight: 700;
    padding: 5px;
    color: white;
    background-color: #ff3356;
    display: inline-block;
  }

  & input:focus + label,
  input + label:hover {
    background-color: red;
  }

  & button {
    font-size: 14px;
    padding: 5px;
    width: 114px;
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const Image = styled.img`
  height: 240px;
  width: 220px;
  margin-bottom: 10px;
  object-fit: cover;
  margin-bottom: 10px;
`;
