import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core';
import { materialStyle } from '../../styles';
import { useTranslation } from 'react-i18next';

const MultiEmailInput = ({
  extractEmails,
  defaultValue,
  classes,
  disabled,
  helperText,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [emails, setEmails] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);

  useEffect(() => {
    const _emails = String(defaultValue)
      .toLowerCase()
      .split(',')
      .filter(item => _isValidEmail(item));
    setEmails(_emails);
  }, []);

  const handleInputChange = event => {
    // verifica se usuário digitou um espaço e valida email e adiciona
    const checkForMobile =
      String(event.target.value).length >= 1 &&
      String(event.target.value).lastIndexOf(' ') !== -1;

    if (checkForMobile) {
      if (_isValidEmail(inputValue)) {
        setEmails([...emails, inputValue.trim()]);
        handleExtractEmails();
        setInputValue('');
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    } else {
      setInputValue(event.target.value);
      setIsValidEmail(true);
    }
  };

  const handleExtractEmails = () => {
    extractEmails([...emails, inputValue.trim()].join(','));
  };

  const handleInputKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (_isValidEmail(inputValue)) {
        setEmails([...emails, inputValue.trim()]);
        handleExtractEmails();
        setInputValue('');
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    }
  };

  const tt = useCallback(emailToDelete => {
    setEmails(emails => {
      const result = emails.filter(email => email !== emailToDelete);

      extractEmails(result.join(','));

      return result;
    });
  }, []);

  const _isValidEmail = email => {
    // Expressão regular para validar emails simples (pode ser aprimorada para maior precisão).
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const renderChips = () => {
    return emails.map((email, index) => (
      <Chip
        disabled={disabled}
        key={index}
        label={email}
        onDelete={() => tt(email)}
        style={{ margin: '2px' }}
      />
    ));
  };

  return (
    <TextField
      className={classes.textField}
      disabled={emails.length >= 20 || disabled}
      label={t('common:notification_email')}
      variant="outlined"
      fullWidth
      value={inputValue}
      onChange={handleInputChange}
      onKeyPress={handleInputKeyPress}
      error={!isValidEmail}
      helperText={
        !isValidEmail
          ? t('common:invalid_email')
          : emails.length >= 20
            ? t('common:max_emails_reached')
            : helperText || t('common:multiemail_helper_text')
      }
      InputProps={{
        startAdornment: renderChips(),
      }}
      placeholder={placeholder}
    />
  );
};

MultiEmailInput.defaultProps = {
  extractEmails: () => {},
  defaultValue: '',
  disabled: false,
  helperText: '',
  placeholder: '',
};

export default withStyles(materialStyle)(MultiEmailInput);
