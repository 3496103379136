export const Types = {
  deliveryUpdateInfoSubTypeRequest: "deliveryUpdateInfoSubType/Request",
  deliveryUpdateInfoSubTypeSuccess: "deliveryUpdateInfoSubType/Success",
  deliveryUpdateInfoSubTypeError: "deliveryUpdateInfoSubType/Error"
};

const INIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  total: null,
  perPage: null,
  lastPage: null,
  page: null
};

export default function deliveryUpdateInfoSubType(state = INIAL_STATE, action) {
  switch (action.type) {
    case Types.deliveryUpdateInfoSubTypeRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.deliveryUpdateInfoSubTypeSuccess:
      return {
        ...state,
        data: action.payload.data,
        error: null,
        loading: false
      };

    case Types.deliveryUpdateInfoSubTypeError:
      return {
        ...state,
        error: true,
        loading: false,
        data: []
      };

    default:
      return state;
  }
}

export const Actions = {
  deliveryUpdateInfoSubTypeRequest: () => ({
    type: Types.deliveryUpdateInfoSubTypeRequest
  }),

  deliveryUpdateInfoSubTypeSuccess: data => ({
    type: Types.deliveryUpdateInfoSubTypeSuccess,
    payload: { data }
  }),

  deliveryUpdateInfoSubTypeError: error => ({
    type: Types.deliveryUpdateInfoSubTypeError,
    payload: { error }
  })
};
