export const Types = {
  showSubTeamSubUnitsContactRequest: "showSubTeamSubUnitsContact/Request",
  showSubTeamSubUnitsContactSuccess: "showSubTeamSubUnitsContact/Sucess",
  showSubTeamSubUnitsContactError: "showSubTeamSubUnitsContact/Error",

  getSubTeamSubUnitsContactRequest: "getSubTeamSubUnitsContact/Request",
  getSubTeamSubUnitsContactSuccess: "getSubTeamSubUnitsContact/Success",
  getSubTeamSubUnitsContactError: "getSubTeamSubUnitsContact/Error",

  createSubTeamSubUnitsContactRequest: "createSubTeamSubUnitsContact/Request",
  createSubTeamSubUnitsContactSuccess: "createSubTeamSubUnitsContact/Success",
  createSubTeamSubUnitsContactError: "createSubTeamSubUnitsContact/Error",

  updateSubTeamSubUnitsContactRequest: "updateSubTeamSubUnitsContact/Request",
  updateSubTeamSubUnitsContactSuccess: "updateSubTeamSubUnitsContact/Success",
  updateSubTeamSubUnitsContactError: "updateSubTeamSubUnitsContact/Error",

  deleteSubTeamSubUnitsContactRequest: "deleteSubTeamSubUnitsContactRequest",
  deleteSubTeamSubUnitsContactSuccess: "deleteSubTeamSubUnitsContactSuccess",
  deleteSubTeamSubUnitsContactError: "deleteSubTeamSubUnitsContactError",

  setDialogCreateVisibleSubTeamSubUnitsContact:
    "setDialogCreateVisible/SubTeamSubUnitsContact",

  resetSubTeamSubUnitsContact: "resetSubTeamSubUnitsContact",

  changeStatusSubTeamSubUnitsContactRequest:
    "changeStatusSubTeamSubUnitsContactRequest",
  changeStatusSubTeamSubUnitsContactSuccess:
    "changeStatusSubTeamSubUnitsContactSuccess",
  changeStatusSubTeamSubUnitsContactError:
    "changeStatusSubTeamSubUnitsContactError"
};

const INITIAL_STATE = {
  data: [],
  error: false,
  loading: false,
  currentSubTeamSubUnitsContact: null,
  page: 0,
  perPage: 20,
  lastPage: 0,
  total: 0,
  query: "",
  setDialogCreateVisibleSubTeamSubUnitsContact: false
};

export default function apartment(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.resetSubTeamSubUnitsContact:
      return INITIAL_STATE;

    case Types.setDialogCreateVisibleSubTeamSubUnitsContact:
      return {
        ...state,
        setDialogCreateVisibleSubTeamSubUnitsContact: action.payload
      };

    case Types.getSubTeamSubUnitsContactRequest:
      return {
        ...state,
        loading: true,
        query: action.payload.data
      };

    case Types.getSubTeamSubUnitsContactSuccess:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload
      };

    case Types.getSubTeamSubUnitsContactError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.createSubTeamSubUnitsContactRequest:
      return {
        ...state,
        loading: true
      };

    case Types.createSubTeamSubUnitsContactSuccess:
      return {
        ...state,
        loading: false,
        error: false
      };

    case Types.createSubTeamSubUnitsContactError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.showSubTeamSubUnitsContactRequest:
      return {
        ...state,
        loading: true
      };

    case Types.showSubTeamSubUnitsContactSuccess:
      return {
        ...state,
        loading: false,
        currentSubTeamSubUnitsContact: action.payload.data,
        error: false
      };

    case Types.showSubTeamSubUnitsContactError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.updateSubTeamSubUnitsContactRequest:
      return {
        ...state,
        loading: true
      };

    case Types.updateSubTeamSubUnitsContactSuccess:
      return {
        ...state,
        loading: false,
        error: false
      };

    case Types.updateSubTeamSubUnitsContactError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.deleteSubTeamSubUnitsContactRequest:
      return {
        ...state,
        loading: true
      };

    case Types.deleteSubTeamSubUnitsContactSuccess:
      return {
        ...state,
        loading: false,
        error: false
      };

    case Types.deleteSubTeamSubUnitsContactError:
      return {
        ...state,
        error: true,
        loading: false
      };

    case Types.changeStatusSubTeamSubUnitsContactRequest:
      return {
        ...state,
        loading: true
      };

    case Types.changeStatusSubTeamSubUnitsContactSuccess:
      return {
        ...state,
        loading: false,
        error: false,
        currentSubTeamSubUnitsContact: {
          ...state.currentSubTeamSubUnitsContact,
          status: action.payload.status
        }
      };

    case Types.changeStatusSubTeamSubUnitsContactError:
      return {
        ...state,
        error: true,
        loading: false
      };

    default:
      return state;
  }
}

export const Actions = {
  setDialogCreateVisibleSubTeamSubUnitsContact: status => ({
    type: Types.setDialogCreateVisibleSubTeamSubUnitsContact,
    payload: status
  }),

  getSubTeamSubUnitsContactRequest: data => ({
    type: Types.getSubTeamSubUnitsContactRequest,
    payload: { data }
  }),

  getSubTeamSubUnitsContactSuccess: data => ({
    type: Types.getSubTeamSubUnitsContactSuccess,
    payload: data
  }),

  getSubTeamSubUnitsContactError: () => ({
    type: Types.getSubTeamSubUnitsContactError
  }),

  showSubTeamSubUnitsContactRequest: id => ({
    type: Types.showSubTeamSubUnitsContactRequest,
    payload: { id }
  }),

  showSubTeamSubUnitsContactSuccess: data => ({
    type: Types.showSubTeamSubUnitsContactSuccess,
    payload: { data }
  }),

  showSubTeamSubUnitsContactError: () => ({
    type: Types.showSubTeamSubUnitsContactError
  }),

  createSubTeamSubUnitsContactRequest: (data, redirectToShow = false) => ({
    type: Types.createSubTeamSubUnitsContactRequest,
    payload: data,
    redirectToShow
  }),

  createSubTeamSubUnitsContactSuccess: () => ({
    type: Types.createSubTeamSubUnitsContactSuccess
  }),

  createSubTeamSubUnitsContactError: () => ({
    type: Types.createSubTeamSubUnitsContactError
  }),

  updateSubTeamSubUnitsContactRequest: payload => ({
    type: Types.updateSubTeamSubUnitsContactRequest,
    payload
  }),

  updateSubTeamSubUnitsContactSuccess: () => ({
    type: Types.updateSubTeamSubUnitsContactSuccess
  }),

  updateSubTeamSubUnitsContactError: () => ({
    type: Types.updateSubTeamSubUnitsContactError
  }),

  deleteSubTeamSubUnitsContactRequest: ({ id }) => ({
    type: Types.deleteSubTeamSubUnitsContactRequest,
    payload: { id }
  }),

  deleteSubTeamSubUnitsContactSuccess: () => ({
    type: Types.deleteSubTeamSubUnitsContactSuccess
  }),

  deleteSubTeamSubUnitsContactError: () => ({
    type: Types.deleteSubTeamSubUnitsContactError
  }),

  resetSubTeamSubUnitsContact: () => ({
    type: Types.resetSubTeamSubUnitsContact
  }),

  changeStatusSubTeamSubUnitsContactRequest: (id, status) => ({
    type: Types.changeStatusSubTeamSubUnitsContactRequest,
    payload: { id, status }
  }),

  changeStatusSubTeamSubUnitsContactSuccess: status => ({
    type: Types.changeStatusSubTeamSubUnitsContactSuccess,
    payload: { status }
  }),

  changeStatusSubTeamSubUnitsContactError: error => ({
    type: Types.changeStatusSubTeamSubUnitsContactError,
    payload: { error }
  })
};
