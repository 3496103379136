import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Container from "../../../../components/Container";
import Image from "../Image";

function Tutorials() {
  return (
    <Container title="">
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography
            variant="h4"
            align="center"
            style={{ textTransform: "lowercase" }}
          >
            Criando um estabelecimento
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            Neste tutorial veremos como podemos criar um Estabelecimento que
            poderá ser vinculado a um colaborador para podermos identificar de
            qual unidade uma entrega é feita
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography>
            1. No menu laterial esquerdo <em>Configurações</em>, e no seu
            subMenu
            <em>Estabelecimentos</em>, veremos a página com todos os
            estabelecimentos cadastros e podemos criar um novo clicanco no botão{" "}
            <em>Criar estabelecimento</em>
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/subTeam-empty-page.png"
            alt="img"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography>
            2. Dentro da página de <em>Criar estabelecimento</em>, precisamos
            preencher todos os campos que são:
          </Typography>
          <ul>
            <li>
              Nome do estabelecimento (Nome fantasia, Identificação da unidade,
              etc)
            </li>
            <li>Email</li>
            <li>CNPJ</li>
            <li>Número de contato</li>
            <li>Status</li>
          </ul>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>
            3. Após preenchermos todos os campos, devemos clicar no botão{" "}
            <em>Criar</em>
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/subTeam-create.png"
            alt="img"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography>
            4. Após criarmos, somos redirecionados para página que mostra o
            registro criado e onde podemos também editá-lo.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/subTeam-show.png"
            alt="img"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography>
            5. Ao voltar para página com a lista dos <em>Estabelecimentos</em>{" "}
            criados, veremos o que acabamos de criar.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/subTeam-list.png"
            alt="img"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Tutorials;
