import React from "react";
import "./style.css";
import LanguageSelector from "../../components/LanguageSelector";

//Materials
import { Grid, withStyles } from "@material-ui/core";

// Components
import ContainerSteppers from "./components/containerStepper";

// Assets
import Logo from "../../assets/Lockup_Handover_White.svg";
import Logo2 from "../../assets/Lockup_Handover.svg";
import { withTranslation } from "react-i18next";

// styled
import {
  Container,
  LogoStyle,
  TitleLogo,
  BannerTitle,
  Footer,
  Content,
  Backgournd
} from "./styled";

const styles = {
  bannerGrid: {
    background: "#006660ff",
    width: 333.33
  },
  gridForm: {
    background: "#f2f8f0",
    width: 663.66
  }
};

class SignUp extends React.Component {
  t = this.props.t
  render() {
    const { classes, history } = this.props;
    return (
      <Backgournd>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=e76f4895-a68e-45d6-bfe3-93fd5030b77e"
        >
          {" "}
        </script>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px'}}>
          <LanguageSelector />
        </div>
        <Container id="container">
          <Content>
            <Grid
              container
              id="box_logo_cadastro"
              className={classes.bannerGrid}
              justify="center"
            >
              <Grid>
                <LogoStyle
                  onClick={() => history.push("/")}
                  src={Logo}
                  alt=""
                />
                <TitleLogo
                  class="font-weight-normal"
                  style={{
                    fontSize: "20px",
                    marginLeft: "127px",
                    color: "#ffffff"
                  }}
                >
                  empresas
                </TitleLogo>
                <BannerTitle>
                  {this.t('mainTitle')}
                </BannerTitle>
              </Grid>
            </Grid>
            <Grid item id="containeSteppers" className={classes.gridForm}>
              <Grid
                item
                id="gridSteppers"
                md={10}
                xs={12}
                style={{
                  marginLeft: 40,
                  marginRight: 40,
                  background: "#f2f8f0"
                }}
              >
                <LogoStyle id="Logo_Cadastro_Style" src={Logo2} alt="" />
                <ContainerSteppers />
              </Grid>
            </Grid>
          </Content>
          <Footer>
            <p
              style={{
                marginBottom: "auto",
                textAlign: "center",
                fontSize: 13,
                fontFamily: "'Livvic', sans-serif",
                color: "#1b301b",
                width: "bold"
              }}
            >
              &copy; {new Date().getFullYear()}. Handover - All rights reserved
              |
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#1b301b" }}
                href="https://oihandover.com/termos-de-uso/"
              >
                &nbsp;{this.t('useTerm')}&nbsp;
              </a>{" "}
              |
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#1b301b" }}
                href="https://oihandover.com/politica-de-privacidade/"
              >
                &nbsp;{this.t('privacy')}&nbsp;
              </a>{" "}
              |
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#1b301b" }}
                href="https://oihandover.com/politica-de-cookies/"
              >
                &nbsp;{this.t('cookies')}&nbsp;
              </a>
            </p>
          </Footer>
        </Container>
      </Backgournd>
    );
  }
}

export default withStyles(styles)(withTranslation('signup')(SignUp));
