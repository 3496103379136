import React, { Component } from "react";

// Material
import { TextField, MenuItem, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";

// Global Components
import Container from "../../components/Container";
//import Snackbar from "../../components/Snackbar";
import TextMaskCustomCNPJ from "../../components/TextMaskCustomCNPJ";
import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as SubTeamActions } from "../../store/ducks/subTeam";

import getOnlyNumbers from "../../services/getOnlyNumbers";
import history from "../../routes/history";
import { checkEmail } from "../../services";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

class BusinessEdit extends Component {
  state = {
    currentSubTeam: {
      name: "",
      email: "",
      cnpj: "",
      idSubTeamStatus: "",
      mobile: ""
    },
    edit: false,
    emailValidation: false
  };

  componentWillMount() {
    const { getSubTeamStatusRequest } = this.props;
    getSubTeamStatusRequest("");
  }

  handleChange = key => event => {
    let value = event.target.value;

    if (key === "cnpj" || key === "mobile") {
      value = getOnlyNumbers(event.target.value);
    }

    // validate email
    if (key === "email") {
      if (!checkEmail(event.target.value)) {
        this.setState({ emailValidation: true });
      } else {
        this.setState({ emailValidation: false });
      }
    }

    this.setState({
      currentSubTeam: {
        ...this.state.currentSubTeam,
        [key]: value
      }
    });
  };

  handleSave = () => {
    const { createSubTeamRequest } = this.props;
    const { currentSubTeam } = this.state;

    createSubTeamRequest(currentSubTeam, this.props.history.push);
  };

  render() {
    const { subTeams, classes } = this.props;
    const { currentSubTeam, emailValidation } = this.state;

    return (
      <Container title="">
        <ThemeTextFildGreen>
          <h3
            style={{
              fontFamily: "'Livvic', sans-serif",
              textTransform: "lowercase"
            }}
          >
            Cadastrar uma Loja
          </h3>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                id="outlined-name"
                label="Nome"
                className={classes.textField}
                value={currentSubTeam.name}
                onChange={this.handleChange("name")}
                margin="normal"
                variant="outlined"
                disabled={subTeams.loading}
              />
            </Grid>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                id="outlined-name"
                label="Email"
                className={classes.textField}
                value={currentSubTeam.email}
                margin="normal"
                variant="outlined"
                disabled={subTeams.loading}
                onChange={this.handleChange("email")}
                error={!emailValidation}
              />
            </Grid>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                onChange={this.handleChange("cnpj")}
                id="outlined-name"
                label="CNPJ"
                className={classes.textField}
                value={currentSubTeam.cnpj}
                margin="normal"
                variant="outlined"
                disabled={subTeams.loading}
                InputProps={{
                  inputComponent: TextMaskCustomCNPJ
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                id="outlined-name"
                label="Contato"
                className={classes.textField}
                value={currentSubTeam.mobile}
                defaultValue={currentSubTeam.mobile}
                margin="normal"
                variant="outlined"
                disabled={subTeams.loading}
                InputProps={{
                  inputComponent: TextMaskCustomMobile
                }}
                onChange={this.handleChange("mobile")}
              />
            </Grid>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                disabled={subTeams.loading}
                id="custom-css-outlined-input"
                select
                label="Status"
                className={classes.selectType}
                value={currentSubTeam.idSubTeamStatus}
                onChange={this.handleChange("idSubTeamStatus")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                {subTeams.subTeamStatus.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={this.handleSave}
            disabled={
              subTeams.loading ||
              !currentSubTeam.name ||
              !currentSubTeam.email ||
              !currentSubTeam.cnpj ||
              !currentSubTeam.mobile ||
              !currentSubTeam.idSubTeamStatus ||
              !emailValidation ||
              currentSubTeam.mobile.length < 10 ||
              currentSubTeam.cnpj.length !== 14
            }
            className={classes.button}
          >
            <SaveIcon className={classes.leftIcon} />
            Criar
          </Button>
          <Button
            variant="contained"
            onClick={() => history.push("/subTeams")}
            disabled={subTeams.loading}
            className={classes.button}
          >
            <CancelIcon className={classes.leftIcon} />
            Cancelar
          </Button>
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  subTeams: state.subTeams
});

const mapDispathToProps = dispatch =>
  bindActionCreators(
    {
      ...SubTeamActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withStyles(materialStyle)(BusinessEdit));
