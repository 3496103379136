import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import history from "../../routes/history";

import { Actions as LockersActions } from "../ducks/locker";

export function* getAllLockers(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/lockers?${action.payload.data}`
    );

    yield put(LockersActions.lockersSuccess(response.data));
  } catch (err) {
    yield put(LockersActions.lockersError(err));
  }
}

export function* getAllLockersForSelect(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/lockers?${action.payload.data}`
    );

    yield put(LockersActions.lockersSelectSuccess(response.data));
  } catch (err) {
    yield put(LockersActions.lockersSelectError(err));
  }
}

export function* showLocker(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/lockers/${action.payload.id}`
    );

    yield put(LockersActions.showLockerSuccess(response.data));
  } catch (err) {
    yield put(LockersActions.showLockerError(err));
  }
}

export function* getLocationLocker(action) {
  try {
    const response = yield call(api.get, "/v1/adm/location", {
      params: {
        cityName: action.payload.data.cityName
      }
    });

    yield put(
      LockersActions.getLocationLockerSuccess({
        ...response.data,
        zipCode: action.payload.zipCode,
        ...action.payload.data
      })
    );
  } catch (err) {
    yield put(LockersActions.getLocationLockerError(err));
  }
}

export function* getLocation(action) {
  try {
    const response = yield call(api.get, "/v1/adm/location", {
      params: {
        cityName: action.payload.data.cityName
      }
    });

    yield put(
      LockersActions.getLocationSuccess({
        ...response.data,
        ...action.payload.data
      })
    );
  } catch (err) {
    yield put(LockersActions.getLocationError(err));
  }
}

export function* updateLocker(action) {
  try {
    yield call(api.put, `/v1/adm/master/lockers/${action.payload.id}`, {
      ...action.payload.data
    });

    yield put(
      LockersActions.updateLockerSuccess({
        ...action.payload.data
      })
    );

    yield put(
      LockersActions.openSnackbarLockers("Atualizado com sucesso", "success")
    );

    window.scrollTo(0, 0);
  } catch (err) {
    yield put(LockersActions.updateLockerError(err));
  }
}

export function* getLockersInfo(action) {
  try {
    const response = yield call(api.get, "/v1/adm/master/lockers/info");

    yield put(LockersActions.getInfoLockersSuccess(response.data));
  } catch (err) {
    yield put(LockersActions.getInfoLockersError(err));
  }
}

export function* createLocker(action) {
  try {
    const response = yield call(api.post, "/v1/adm/master/lockers/", {
      ...action.payload.data
    });

    yield put(LockersActions.createLockerSuccess(response.data));
    yield put(LockersActions.openSnackbarLockers("Locker Criado!", "success"));

    history.push(`/lockers/edit/${response.data.id}`);
  } catch (err) {
    yield put(LockersActions.createLockerError(err));
  }
}

export function* openLockerAllCompartments(action) {
  try {
    yield call(api.post, "/v1/adm/business/locker/open", {
      idLocker: action.idLocker
    });
    yield put(
      LockersActions.openLockerAllCompartmentsSuccess({
        snackbarVisible: true,
        snackbarMessage: "Locker Aberto!",
        snackbarType: "success"
      })
    );
  } catch (err) {
    yield put(LockersActions.openLockerAllCompartmentsError(err.response));
  }
}

export function* getCompartmentIdentifiers(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/compartment/identifiers?${action.payload.data}`
    );

    yield put(LockersActions.getCompartmentIdentifiersSuccess(response.data));
  } catch (err) {
    yield put(LockersActions.getCompartmentIdentifiersError(err.response));
  }
}

export function* rebootLocker(action) {
  try {
    yield call(api.post, "/v1/internal/locker/reboot", action.payload);

    yield put(LockersActions.rebootLockerSuccess());
  } catch (err) {
    yield put(LockersActions.rebootLockerError(err.response));
  }
}

export function* getSetupLockerPasswords(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/master/setup/locker/${action.payload.idLocker}/passwords`
    );

    yield put(LockersActions.getSetupLockerPasswordsSuccess(response.data));
  } catch (err) {
    yield put(LockersActions.getSetupLockerPasswordsError(err.response));
  }
}
