import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Divider, Grid } from "@material-ui/core";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";

import Container from "../../../../components/Container";
import ThemeTextFildGreen from "../../../../styles/ThemeTextFildGreen";

// Redux
import { Actions as LockerActions } from "../../../../store/ducks/locker";
import ButtonLink from "../../../../components/ButtonLink";
import Spinner from "../../../../components/Spinner";

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3)
  },
  paperDisconnected: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
    backgroundColor: "#df4759"
  },
  paperConnected: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
    backgroundColor: "#42ba96"
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  paper: {
    // maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2)
  }
}));

function LockerConnection() {
  const classes = useStyles();
  const dispath = useDispatch();
  const lockers = useSelector(state => state.locker);

  useEffect(() => {
    dispath(
      LockerActions.lockersRequest(
        queryString.stringify({
          // isActive: 1,
          perPage: 1000
        })
      )
    );
  }, []);

  return (
    <Container title="Conexão dos lockers">
      <ThemeTextFildGreen>
        {lockers.loading ? (
          <Spinner />
        ) : (
          <Grid container spacing={3}>
            {lockers.data &&
              lockers.data.length &&
              lockers.data.map(row => (
                <Grid item xs={3} key={row.id} zeroMinWidth>
                  <Paper
                    className={
                      parseInt(row.available) === 1
                        ? classes.paperConnected
                        : classes.paperDisconnected
                    }
                  >
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item container xs zeroMinWidth direction="column">
                        <Grid item container xs zeroMinWidth>
                          <Typography noWrap gutterBottom variant="subtitle1">
                            {row.team.name}
                          </Typography>
                        </Grid>
                        <Grid item container xs zeroMinWidth>
                          <Typography noWrap gutterBottom variant="body2">
                            {row.address}
                          </Typography>
                        </Grid>
                        <Grid item container xs zeroMinWidth>
                          <Typography
                            noWrap
                            gutterBottom
                            variant="body2"
                            color="textSecondary"
                          >
                            {!row.available
                              ? "Carregando..."
                              : parseInt(row.available) === 1
                                ? "Online"
                                : "Offline"}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <ButtonLink
                          startIcon={<OpenInBrowserIcon />}
                          to={`/lockers/show/${row.id}`}
                          helpText={`Acessar locker: ${row.address}`}
                          content={""}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        )}
      </ThemeTextFildGreen>
    </Container>
  );
}

export default LockerConnection;
