export default function checkObject(obj1, obj2) {

  // obtem as chaves o primeiro objeto
  const local = Object.keys(obj1);
  // const props = Object.keys(obj2);

  let count = 0;
  let newData = {};

  // passa por todos os items
  for (let i = 0; i < local.length; i++) {
    // se houver diferença entre os valores da mesma chave nos dois objetos
    if (obj1[local[i]] !== obj2[local[i]]) {
      count += 1; // soma para cada item modificado
      newData[local[i]] = obj1[local[i]]; // cria novo obj com as chaves mudadas e seus valores novos
    }
  }

  if (!count) {
    return false;
  }

  return newData;
}
