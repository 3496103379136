import React, { Component } from "react";
import queryString from "query-string";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import ptBR from "date-fns/locale/pt-BR";

// Global
import Container from "../../components/Container";
import NeposTicketsTable from "../../components/NeposTicketsTable";
import Spinner from "../../components/Spinner";
import { materialStyle } from "../../styles";

// Material
import { Grid, Button, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as PartnersNeposTickets } from "../../store/ducks/partners/nepos/tickets";

import { ExportExcelNepos } from "../../components/ExportExcel";

class PartnerNeposTickets extends Component {
  state = {
    ticket: "",
    initialDate: new Date(),
    finalDate: new Date()
  };

  componentWillMount() {
    this.setInitialStateAndSearch();
  }

  convertDateToSearch = date => {
    let tmp = new Date(date);
    tmp.setHours(tmp.getHours() + 3);
  };

  setInitialStateAndSearch = () => {
    const { partnersNeposTicketsRequest } = this.props;
    const finalDate = this.getFormatedDate(new Date(), false);
    const initialDate = this.getFormatedDate(new Date(), true);
    this.setState({
      finalDate,
      initialDate
    });

    const data = {
      initialDate,
      finalDate,
      page: 1,
      perPage: 20
    };

    partnersNeposTicketsRequest(queryString.stringify(data));
  };

  getFormatedDate = (date, start = false) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${
      start ? "00:00:00" : "23:59:59"
    }`;
  };

  handleChange = key => event => this.setState({ [key]: event.target.value });

  handleSearchQuery = () => {
    const { initialDate, finalDate, ticket } = this.state;

    const { partnerNeposTickets } = this.props;

    const data = {
      page: 1,
      perPage: partnerNeposTickets.perPage,
      initialDate: this.getFormatedDate(new Date(initialDate)),
      finalDate: this.getFormatedDate(new Date(finalDate)),
      ticket
    };

    return data;
  };

  handleSearch = () => {
    const { partnersNeposTicketsRequest } = this.props;

    partnersNeposTicketsRequest(
      queryString.stringify(this.handleSearchQuery())
    );
  };

  render() {
    const { classes, partnerNeposTickets } = this.props;

    return (
      <Container title="tickets de estacionamento" id="title-sm__h3">
        {partnerNeposTickets.loading ? (
          <Spinner />
        ) : (
          <>
            <div className="helper-spacing-top-mobile" />

            <Grid container spacing={2} className="first-grid">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data inicial"
                    value={this.state.initialDate}
                    onChange={value =>
                      this.setState({
                        initialDate: this.getFormatedDate(value, true)
                      })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    inputVariant="outlined"
                    autoOk
                    className={classes.textField1}
                  />
                </Grid>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data final"
                    value={this.state.finalDate}
                    onChange={value =>
                      this.setState({
                        finalDate: this.getFormatedDate(value, false)
                      })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    inputVariant="outlined"
                    autoOk
                    className={classes.textField1}
                  />
                </Grid>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-field"
                    label="Número do ticket"
                    className={classes.textField1}
                    value={this.state.ticket}
                    onChange={this.handleChange("ticket")}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Button
              onClick={this.handleSearch}
              variant="contained"
              className={classes.button}
              style={{
                // para os Buttons
                backgroundColor: "#006660",
                fontSize: 14,
                color: "#fff",
                textTransform: "lowercase",
                height: 35
              }}
            >
              Pesquisar
            </Button>
            <Button
              onClick={this.setInitialStateAndSearch}
              variant="contained"
              className={classes.button}
              style={{
                // para os Buttons
                backgroundColor: "#006660",
                fontSize: 14,
                color: "#fff",
                textTransform: "lowercase",
                height: 35
              }}
            >
              Limpar
            </Button>
            <ExportExcelNepos
              data={partnerNeposTickets.data}
              classes={classes}
            />
            <NeposTicketsTable history={this.props.history} />
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  partnerNeposTickets: state.partnerNeposTickets
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...PartnersNeposTickets
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(PartnerNeposTickets));
