import React, { Component } from "react";

// Material
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

/**
 * Redux & Saga
 */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as UserActions } from "../../store/ducks/users";

/**
 * Globa Components
 */
import Container from "../../components/Container";
import Snackbar from "../../components/Snackbar";
import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";
import TextMaskCustomLandline from "../../components/TextMaskCustomLandline";
import TextMaskCustomCPF from "../../components/TextMaskCustomCPF";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

const styles = theme => ({
  textField: {
    margin: theme.spacing(1)
  }
});

class UserEdit extends Component {
  state = {
    name: "",
    lastName: "",
    mobile: "",
    landline: "",
    // birthdate: '',
    email: "",
    showPassword: false,
    password: "",
    password_confirmation: "",
    emailValidation: false
  };

  handleChange = key => event => {
    this.setState({
      [key]: event.target.value
    });

    if (key === "email") {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)
      ) {
        this.setState({ emailValidation: true });
      } else {
        this.setState({ emailValidation: false });
      }
    }
  };

  handleSave = () => {
    const { createUserRequest, setSnackbar } = this.props;
    const state = this.state;

    if (
      !state.name ||
      !state.lastName ||
      !state.mobile ||
      !state.landline ||
      !state.email ||
      !state.password ||
      !state.password_confirmation
    ) {
      setSnackbar();
      return;
    }

    if (state.password !== state.password_confirmation) {
      return;
    }

    const { showPassword, ...data } = this.state;

    createUserRequest({
      ...data
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  render() {
    const { classes, loading, setSnackbar, snackbar } = this.props;
    const { showPassword, emailValidation } = this.state;

    return (
      <Container title="Criar usuário">
        <ThemeTextFildGreen>
          <Snackbar
            open={snackbar}
            variant="warning"
            onClose={setSnackbar}
            message="Preencha todos os campos"
          />
          <TextField
            id="outlined-name"
            label="Nome"
            className={classes.textField}
            value={this.state.name}
            onChange={this.handleChange("name")}
            margin="normal"
            variant="outlined"
            disabled={loading}
          />
          <TextField
            id="outlined-name"
            label="Sobrenome"
            className={classes.textField}
            value={this.state.lastName}
            onChange={this.handleChange("lastName")}
            margin="normal"
            variant="outlined"
            disabled={loading}
          />
          <TextField
            error={this.state.emailValidation}
            id="outlined-name"
            label="email"
            className={classes.textField}
            value={this.state.email}
            onChange={this.handleChange("email")}
            margin="normal"
            variant="outlined"
            disabled={loading}
          />
          <TextField
            variant="outlined"
            className={classes.textField}
            label="Celular"
            value={this.state.mobile}
            onChange={this.handleChange("mobile")}
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: TextMaskCustomMobile
            }}
          />
          <TextField
            id="outlined-name"
            label="Telefone"
            className={classes.textField}
            value={this.state.landline}
            onChange={this.handleChange("landline")}
            margin="normal"
            variant="outlined"
            disabled={loading}
            InputProps={{
              inputComponent: TextMaskCustomLandline
            }}
          />
          <TextField
            id="outlined-adornment-password"
            className={classes.textField}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            label="Password"
            value={this.state.password}
            onChange={this.handleChange("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            error={this.state.password !== this.state.password_confirmation}
            id="outlined-adornment-password"
            className={[classes.textField]}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            label="Confirme a senha"
            value={this.state.password_confirmation}
            onChange={this.handleChange("password_confirmation")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            id="outlined-name"
            label="CPF"
            className={classes.textField}
            value={this.state.cpf}
            margin="normal"
            variant="outlined"
            disabled={loading}
            InputProps={{
              inputComponent: TextMaskCustomCPF
            }}
          />
          <div>
            <Button
              variant="contained"
              onClick={this.handleSave}
              disabled={loading || emailValidation}
            >
              Salvar
            </Button>
          </div>
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.users.loading,
  error: state.users.error,
  setSnackbar: state.users.setSnackbar,
  snackbar: state.users.snackbar,
  createUserRequest: state.users.createUserRequest
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserEdit));
