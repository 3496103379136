export const Types = {
  resetCustomerLogAbbreviation: "resetCustomerLogAbbreviation",
  customerLogAbbreviationRequest: "customerLogAbbreviation/Request",
  customerLogAbbreviationSuccess: "customerLogAbbreviation/Success",
  customerLogAbbreviationError: "customerLogAbbreviation/Error"
};

const INITIAL_STATE = {
  data: [],
  page: 0,
  totl: 0,
  perPage: 0,
  loading: false,
  error: null
};

export default function abbrrviation(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.resetCustomerLogAbbreviation:
      return INITIAL_STATE;

    case Types.customerLogAbbreviationRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.customerLogAbbreviationSuccess:
      return {
        ...state,
        ...action.payload.data,
        loading: false
      };

    case Types.customerLogAbbreviationError:
      return {
        ...state,
        error: action.payload.error,
        laoding: false
      };
    default:
      return state;
  }
}

export const Actions = {
  resetCustomerLogAbbreviation: () => ({
    type: Types.resetCustomerLogAbbreviation
  }),
  customerLogAbbreviationRequest: data => ({
    type: Types.customerLogAbbreviationRequest,
    payload: { data }
  }),

  customerLogAbbreviationSuccess: data => ({
    type: Types.customerLogAbbreviationSuccess,
    payload: { data }
  }),

  customerLogAbbreviationError: error => ({
    type: Types.customerLogAbbreviationError,
    payload: { error }
  })
};
