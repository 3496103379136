import styled from "styled-components";

import Background from "../../assets/background_v2.svg";

export const Container = styled.div`
  width: 100%;
  position: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

export const LogoStyle = styled.img`
  height: 35px;
  margin-left: 11%;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  @media (max-width: 970px) {
    height: 30px;
  }
  @media (max-width: 660px) {
    margin-left: 0px;
  }
`;

// export const LogoStyle2 = styled.img`
//   // margin-top: -60px;
//   @media (max-width: 390px) {
//     margin-left : -15%;
//     margin-top: 0px;
//     margin-bottom: 10px;
//     cursor: pointer;
//   }
//   // @media (min-width: 970px) {
//   //   display: none;
//   // }

// `;

export const TitleLogo = styled.small`
  font-family: "Livvic", sans-serif;
  font-size: 10px;
  font-weight: bolder;
  margin-left: 145px;
  color: #000;
`;

export const BannerTitle = styled.h1`
  font-family: "Livvic", sans-serif;
  color: #fff;
  font-size: 40px;
  margin-left: 20px;
  font-weight: 500;
  text-transform: lowercase;
  margin-top: 100px;
  // line-height: 0.8;
`;

export const Footer = styled.div`
  margin: 25px 0 10px;
`;

export const Content = styled.div`
  display: flex;
  margin-top: 6%;
  height: 100%;
`;

export const Backgournd = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px;
  margim: 0px;
  background-image: url(${Background});
  // background-repeat: no-repeat;
  background-position: center;
  // background: #0000ff;
  // background: #FFF;
  overflow-y: auto;
`;
