import styled from "styled-components";

export const Loading = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  display: ${props => (props.loading ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 100
`;

export const TextLoading = styled.h1`
  font-size: 50px;
  color: #fff;
`;

export const ContainerTextField = styled.div`
  width: 100%;
`;

export const ContainerIframe = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const Input = styled.input``;

