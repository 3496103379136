import { Badge } from "@material-ui/core";
import React from "react";
// import { formatDistance, addMinutes } from "date-fns";
// import { ptBR } from "date-fns/locale";

// export default (initialDate, finalDate, increment) =>
//   formatDistance(new Date(), addMinutes(new Date(finalDate), increment), {
//     locale: ptBR
//   });

export default (date, increment, t) => {
  let message = "";
  const countDownDate = new Date(date);
  countDownDate.setMinutes(countDownDate.getMinutes() + increment);

  const now = new Date().getTime();
  const timeleft = countDownDate - now;

  var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
  var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
  // var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

  if (parseInt(days) >= 1) {
    message += ` ${days}d`;
  }

  if (parseInt(hours) >= 1) {
    message += ` ${hours}h`;
  }

  if (parseInt(minutes) >= 1) {
    message += ` ${minutes}m`;
  }

  if (parseInt(days) <= 0 && parseInt(hours) <= 0 && parseInt(minutes) <= 0) {
    message = <Badge badgeContent={
      <p id="table_Cell_Col">
        {t('expired')}
      </p>
      } color={"error"}/>;
  }

  return message;
};
