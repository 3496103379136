import React, { Component } from "react";
import * as XLSX from "xlsx";
import queryString from "query-string";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Global
import Container from "../../components/Container";
import RentalPaginationTable from "../../components/RentalPaginationTable";
import Spinner from "../../components/Spinner";
import { materialStyle } from "../../styles";

// Material
import { Grid, TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerAcions } from "../../store/ducks/locker";
import { Actions as CompartmentAcions } from "../../store/ducks/compartment";
import { Actions as RentalActions } from "../../store/ducks/rental";
import { Actions as ReportActions } from "../../store/ducks/reports";

import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

import constants from "../../config/constants";
import storageKeys from "../../services/keys";
import formatDate, { getUTCDateDatabase } from "../../functions/formatDate";

import Snackbar from "../../components/Snackbar";
import ButtonClick from "../../components/ButtonClick";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import FilterTitle from "../../components/FilterTitle";
import getOnlyNumbers from "../../services/getOnlyNumbers";
import TextMaskCustomCPF from "../../components/TextMaskCustomCPF";
import CustomDialogContent from "../../components/CustomDialogContent";
import api from "../../services/api";

const EXPORT_FILTERS = {
  initialDate: null,
  finalDate: null,
  idLocker: "all",
  idCompartment: "all",
  status: constants.deliveryStatus.all.id,
  email: "",
  cpf: "",
  loading: false
};

class RentalUserOrder extends Component {
  state = {
    initialDate: null,
    finalDate: null,
    idLocker: "all",
    idCompartment: "all",
    status: constants.deliveryStatus.all.id,
    email: "",
    search: "",
    dashboardParams: null,
    perPage: 100,
    idTeam: "",
    cpf: "",
    snackbarMessage: "",
    snackbarVisible: "",
    snackbarType: "",
    exportFilters: EXPORT_FILTERS,
    exportCompartmentFilters: []
  };

  handleInit() {
    console.log("handleInit");
    const {
      getRentalRequest,
      reportsGeneralRequest,
      history: { location },

      lockersRequest
    } = this.props;

    lockersRequest("perPage=1000");

    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    this.setState({
      parameters
    });

    if (parameters.dashboardParams && parameters.dashboardParams.length) {
      this.setState({
        dashboardParams: JSON.parse(parameters.dashboardParams)
      });
    }

    let search = {
      ...queryString.parse(location.search),
      perPage: 100
    };

    if (search.status) {
      const keys = Object.keys(constants.deliveryStatus);

      const statusSearch = keys.find(
        item => constants.deliveryStatus[item].slug === search.status
      );

      if (statusSearch) {
        this.setState({ status: constants.deliveryStatus[statusSearch].id });
        search.status = constants.deliveryStatus[statusSearch].id;
      }
    }

    if (search.idLocker) {
      this.setState({ idLocker: search.idLocker });
    }

    if (search.idSubTeam) {
      console.log("search.subteam");
      this.setState({ idSubTeam: search.idSubTeam });
    }

    const initialDate = search.initialDate || null;
    const finalDate = search.finalDate || null;

    this.setState({
      ...this.state,
      ...queryString.parse(location.search),
      initialDate,
      finalDate,
      search: queryString.stringify(search)
    });
    getRentalRequest(queryString.stringify(search));
    reportsGeneralRequest(`query=total`);
  }

  clearExportFilters = () => {
    this.setState({
      exportFilters: EXPORT_FILTERS,
      exportCompartmentFilters: []
    });
  };

  componentWillMount() {
    console.log("componentWillMount");
    this.handleInit();
  }

  handleChange = key => event => {
    console.log("handleChange");
    const {
      getCompartmentsRequest,
      getSubTeamUnitsRequest, // blocos
      getSubTeamSubUnitsRequest, // apartamentos
      getSubTeamSubUnitsContactRequest // moradores
    } = this.props;
    const value = event.target.value;
    let search = JSON.parse(localStorage.getItem(storageKeys.search));

    if (key === "idLocker") {
      localStorage.setItem(
        storageKeys.search,
        JSON.stringify({
          ...search,
          idLocker: `idLocker=${value}`
        })
      );
      search = JSON.parse(localStorage.getItem(storageKeys.search));
      getCompartmentsRequest(`idLocker=${value}&perPage=100`);
    } else if (key === "idSubTeam") {
      if (value !== "all") {
        // se escolher subTeam (Condominio/Unidade)
        getSubTeamUnitsRequest(`idSubTeam=${value}&perPage=1000`); // busca blocos
      }
    } else if (key === "idSubTeamUnits") {
      if (value !== "all") {
        // se escolher Bloco
        getSubTeamSubUnitsRequest(`idSubTeamUnits=${value}&perPage=1000`); // busca apartamentos
      }
    } else if (key === "idSubTeamSubUnits") {
      if (value !== "all") {
        // se escolher apartamento
        getSubTeamSubUnitsContactRequest(
          `idSubTeamSubUnits=${value}&perPage=1000`
        ); // busca moradores
      }
    }
    this.setState({ [key]: value });
  };

  checkIfSearch = () => {
    const {
      customerReferenceId,
      status,
      idLocker,
      idCompartment,
      createdAt,
      email,
      idSubTeam,
      initialDate,
      finalDate
    } = this.state;

    if (
      !customerReferenceId &&
      !status &&
      !idLocker &&
      !idCompartment &&
      !createdAt &&
      !email &&
      !idSubTeam &&
      !initialDate &&
      !finalDate
    )
      return false;

    return true;
  };

  convertValuesOfObjectKeysToQueryString = object => {
    const keys = Object.keys(object);
    let search = "";

    for (let i = 0; i < keys.length; i++) {
      search += `${object[keys[i]]}&`;
    }

    return search;
  };

  setSnackbar = ({ snackbarMessage, snackbarVisible, snackbarType }) => {
    this.setState({
      snackbarMessage,
      snackbarVisible,
      snackbarType
    });
  };

  handleSearch = (initial = false) => {
    console.log("handleSearch");
    const {
      idLocker,
      idCompartment,
      status,
      email,
      initialDate,
      finalDate,
      perPage,
      cpf
    } = this.state;

    const { getRentalRequest } = this.props;

    if (!this.checkIfSearch()) return;

    const _cpf = getOnlyNumbers(cpf);

    if (_cpf && _cpf.length !== 11) {
      this.setSnackbar({
        snackbarMessage: "CPF incompleto",
        snackbarVisible: true,
        snackbarType: "warning"
      });
      return;
    }

    let stringSearch = {
      email,
      idLocker,
      idCompartment,
      status,
      initialDate: initialDate ? getUTCDateDatabase(initialDate) : "",
      finalDate: finalDate ? getUTCDateDatabase(finalDate) : "",
      perPage: perPage === 100 ? (initial ? 100 : 200) : perPage,
      cpf: _cpf
    };

    const keys = Object.keys(stringSearch);
    for (let i = 0; i < keys.length; i++) {
      if (stringSearch[keys[i]] === "all" || stringSearch[keys[i]] === "") {
        delete stringSearch[keys[i]];
      }
    }

    stringSearch = queryString.stringify(stringSearch);

    this.setState(
      {
        search: stringSearch
      },
      () => getRentalRequest(stringSearch)
    );
    this.props.history.push(`/rental?${stringSearch}`);
  };

  handleSearchToExport = async (initial = false) => {
    try {
      this.setState(state => ({
        exportFilters: {
          ...state.exportFilters,
          loading: true
        }
      }));
      console.log("handleSearch to export");

      const {
        idLocker,
        idCompartment,
        status,
        email,
        initialDate,
        finalDate,
        cpf
      } = this.state.exportFilters;

      const _cpf = getOnlyNumbers(cpf);

      if (_cpf && _cpf.length !== 11) {
        this.setSnackbar({
          snackbarMessage: "CPF incompleto",
          snackbarVisible: true,
          snackbarType: "warning"
        });
        return;
      }

      let stringSearch = {
        email,
        idLocker,
        idCompartment,
        status,
        initialDate: initialDate ? getUTCDateDatabase(initialDate) : "",
        finalDate: finalDate ? getUTCDateDatabase(finalDate) : "",
        cpf: _cpf,
        unlimited: true
      };

      const keys = Object.keys(stringSearch);
      for (let i = 0; i < keys.length; i++) {
        if (stringSearch[keys[i]] === "all" || stringSearch[keys[i]] === "") {
          delete stringSearch[keys[i]];
        }
      }

      stringSearch = queryString.stringify(stringSearch);
      // getRentalRequest(stringSearch);

      const response = await api.get(`/v1/adm/rental?${stringSearch}`);

      // Definindo os cabeçalhos personalizados
      const headers = [
        "NOME DO USUARIO",
        "EMAIL DO USUARIO",
        "CPF",
        "CONTATO",
        "LOCKER",
        "PORTA",
        "DATA",
        "DATA FINALIZADA",
        "STATUS"
      ];

      const sheet = response.data.map(item => {
        const customItem = {
          name: item.user ? item.user.name : "-",
          email: item.user ? item.user.email : "-",
          cpf: item.user ? item.user.cpf : "-",
          phone: item.user ? item.user.phone : "-",
          locker: item.locker ? item.locker.address : "-",
          porta: item.compartment ? item.compartment.compartmentNumber : "-",
          data: formatDate(item.created_at),
          finaldate: item.finishedAt ? formatDate(item.finishedAt) : "-",
          status: item.finishedAt ? "Finalizada" : "Ativa"
        };

        return customItem;
      });

      // Adicionando os cabeçalhos no início dos dados
      const worksheetData = [headers, ...sheet.map(Object.values)];

      // Converte os dados formatados para uma planilha
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Cria um novo workbook
      const workbook = XLSX.utils.book_new();

      // Adiciona a worksheet ao workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Gera o arquivo Excel e o baixa
      XLSX.writeFile(workbook, "Export-guarda-volumes.xlsx");
    } catch (err) {
      console.log(err);
    } finally {
      this.setState(state => ({
        exportFilters: {
          ...state.exportFilters,
          loading: false
        }
      }));
    }
  };

  handleClear = () => {
    const { resetCompartments } = this.props;

    localStorage.removeItem(storageKeys.search);

    this.setState(
      {
        initialDate: null,
        finalDate: null,
        idLocker: "all",
        idCompartment: "all",
        status: constants.deliveryStatus.all.id,
        email: "",
        search: "",
        dashboardParams: null,
        perPage: 100,
        idTeam: "",
        cpf: ""
      },
      () => {
        resetCompartments();
        this.handleSearch(true);
        this.props.history.push("/rental");
      }
    );
  };

  handleChangeExportValues = key => event => {
    console.log("handleChange");
    const {
      getCompartmentsRequest,
      getSubTeamUnitsRequest, // blocos
      getSubTeamSubUnitsRequest, // apartamentos
      getSubTeamSubUnitsContactRequest // moradores
    } = this.props;
    const value = event.target.value;
    let search = JSON.parse(localStorage.getItem(storageKeys.search));

    if (key === "idLocker") {
      localStorage.setItem(
        storageKeys.search,
        JSON.stringify({
          ...search,
          idLocker: `idLocker=${value}`
        })
      );
      search = JSON.parse(localStorage.getItem(storageKeys.search));
      getCompartmentsRequest(`idLocker=${value}&perPage=100`);
    } else if (key === "idSubTeam") {
      if (value !== "all") {
        // se escolher subTeam (Condominio/Unidade)
        getSubTeamUnitsRequest(`idSubTeam=${value}&perPage=1000`); // busca blocos
      }
    } else if (key === "idSubTeamUnits") {
      if (value !== "all") {
        // se escolher Bloco
        getSubTeamSubUnitsRequest(`idSubTeamUnits=${value}&perPage=1000`); // busca apartamentos
      }
    } else if (key === "idSubTeamSubUnits") {
      if (value !== "all") {
        // se escolher apartamento
        getSubTeamSubUnitsContactRequest(
          `idSubTeamSubUnits=${value}&perPage=1000`
        ); // busca moradores
      }
    }
    this.setState(state => ({
      exportFilters: {
        ...state.exportFilters,
        [key]: value
      }
    }));
  };

  handleGetCompartmentToExport = async locker_id => {
    try {
      this.setState(state => ({
        exportFilters: {
          ...state.exportFilters,
          loading: true
        },
        exportCompartmentFilters: []
      }));

      const response = await api.get(
        `/v1/adm/compartments?idLocker=${locker_id}&perPage=1000`
      );
      this.setState(state => ({
        exportFilters: {
          ...state.exportFilters,
          loading: false
        },
        exportCompartmentFilters: response.data.data
      }));
    } catch (err) {
      console.log(err);
    } finally {
      this.setState(state => ({
        exportFilters: {
          ...state.exportFilters,
          loading: false
        }
      }));
    }
  };

  renderExportFilters = () => {
    const { classes, lockers, compartments } = this.props;
    const {
      idLocker,
      email,
      cpf,
      idCompartment,
      status,
      initialDate,
      finalDate,
      loading
    } = this.state.exportFilters;
    const { exportCompartmentFilters } = this.state;

    return (
      <Grid container spacing={2} className="first-grid">
        <Grid item xs={12} md={3}>
          <TextField
            id="custom-css-outlined-input"
            label="E-mail"
            className={classes.textField1}
            value={email}
            onChange={this.handleChangeExportValues("email")}
            margin="normal"
            variant="outlined"
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            id="custom-css-outlined-input"
            label="CPF"
            className={classes.textField1}
            value={cpf}
            onChange={this.handleChangeExportValues("cpf")}
            margin="normal"
            variant="outlined"
            disabled={loading}
            InputProps={{
              inputComponent: TextMaskCustomCPF
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <CustomAutoComplete
            options={lockers.data}
            value={lockers.data.find(item => item.id === idLocker)}
            onChange={newValue => {
              this.handleChangeExportValues("idLocker");
              this.handleGetCompartmentToExport(newValue.id);
            }}
            disabled={lockers.loading || !lockers.data.length || loading}
            loading={lockers.loading}
            getOptionLabelKey="address"
            label="Locker"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <CustomAutoComplete
            options={exportCompartmentFilters}
            value={exportCompartmentFilters.find(
              item => item.id === idCompartment
            )}
            onChange={this.handleChangeExportValues("idCompartment")}
            disabled={
              !exportCompartmentFilters.length ||
              compartments.loading ||
              loading
            }
            loading={loading}
            getOptionLabelKey="compartmentNumber"
            label="Porta"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            id="custom-css-outlined-input"
            select
            label="Status"
            className={classes.textField1}
            value={status}
            onChange={this.handleChangeExportValues("status")}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            margin="normal"
            variant="outlined"
            disabled={loading}
          >
            {Object.keys(constants.rentalStatus).map(
              status =>
                constants.rentalStatus[status].visible && (
                  <MenuItem
                    key={constants.rentalStatus[status].slug}
                    value={constants.rentalStatus[status].id}
                  >
                    {constants.rentalStatus[status].label}
                  </MenuItem>
                )
            )}
          </TextField>
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} md={3}>
            <KeyboardDatePicker
              disabled={loading}
              className={classes.textField1}
              disableToolbar
              format="dd/MM/yyyy"
              margin="normal"
              inputVariant="outlined"
              id="date-picker-inline"
              label="Data inicial"
              value={initialDate}
              onChange={data =>
                this.setState(state => ({
                  exportFilters: {
                    ...state.exportFilters,
                    initialDate: data
                  }
                }))
              }
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              inputProps={{
                margin: "normal"
              }}
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Data inicial"
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <KeyboardDatePicker
              disabled={loading}
              className={classes.textField1}
              disableToolbar
              format="dd/MM/yyyy"
              margin="normal"
              inputVariant="outlined"
              id="date-picker-inline"
              label="Data Final"
              value={finalDate}
              onChange={data =>
                this.setState(state => ({
                  exportFilters: {
                    ...state.exportFilters,
                    finalDate: data
                  }
                }))
              }
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              inputProps={{
                margin: "normal"
              }}
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Data final"
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
    );
  };

  render() {
    const { rental, history, classes, lockers, compartments } = this.props;

    const {
      status,
      idLocker,
      idCompartment,
      email,
      cpf,
      snackbarMessage,
      snackbarVisible,
      snackbarType,
      initialDate,
      finalDate
    } = this.state;

    const { getCompartmentsRequest } = this.props;

    return (
      <Container title="rental orders" id="title-sm__h3">
        {rental.loading ? (
          <Spinner />
        ) : (
          <ThemeTextFildGreen>
            <div className="helper-spacing-top-mobile" />
            <Snackbar
              message={snackbarMessage}
              open={snackbarVisible}
              variant={snackbarType}
              onClose={() =>
                this.setSnackbar({
                  snackbarMessage: "",
                  snackbarType: "warning",
                  snackbarVisible: false
                })
              }
            />
            <FilterTitle />
            <Grid container spacing={2} className="first-grid">
              <Grid item xs={12} md={3}>
                <TextField
                  id="custom-css-outlined-input"
                  label="E-mail"
                  className={classes.textField1}
                  value={email}
                  onChange={this.handleChange("email")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="custom-css-outlined-input"
                  label="CPF"
                  className={classes.textField1}
                  value={cpf}
                  onChange={this.handleChange("cpf")}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskCustomCPF
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomAutoComplete
                  options={lockers.data}
                  value={lockers.data.find(item => item.id === idLocker)}
                  onChange={newValue => {
                    this.setState({
                      idLocker: newValue.id
                    });
                    getCompartmentsRequest(
                      `idLocker=${newValue.id}&perPage=100`
                    );
                  }}
                  disabled={lockers.loading || !lockers.data.length}
                  loading={lockers.loading}
                  getOptionLabelKey="address"
                  label="Locker"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <CustomAutoComplete
                  options={compartments.data}
                  value={compartments.data.find(
                    item => item.id === idCompartment
                  )}
                  onChange={newValue => {
                    this.setState({
                      idCompartment: newValue.id
                    });
                  }}
                  disabled={!compartments.data.length || compartments.loading}
                  loading={compartments.loading}
                  getOptionLabelKey="compartmentNumber"
                  label="Porta"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="custom-css-outlined-input"
                  select
                  label="Status"
                  className={classes.textField1}
                  value={status}
                  onChange={this.handleChange("status")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {Object.keys(constants.rentalStatus).map(
                    status =>
                      constants.rentalStatus[status].visible && (
                        <MenuItem
                          key={constants.rentalStatus[status].slug}
                          value={constants.rentalStatus[status].id}
                        >
                          {constants.rentalStatus[status].label}
                        </MenuItem>
                      )
                  )}
                </TextField>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} md={3}>
                  <KeyboardDatePicker
                    className={classes.textField1}
                    disableToolbar
                    format="dd/MM/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    label="Data inicial"
                    value={initialDate}
                    onChange={data => this.setState({ initialDate: data })}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    inputProps={{
                      margin: "normal"
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder="Data inicial"
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <KeyboardDatePicker
                    className={classes.textField1}
                    disableToolbar
                    format="dd/MM/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    label="Data Final"
                    value={finalDate}
                    onChange={data => this.setState({ finalDate: data })}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    inputProps={{
                      margin: "normal"
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder="Data final"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid container spacing={1}>
              <ButtonClick
                onClick={() => this.handleSearch(false)}
                disabled={!this.checkIfSearch()}
              >
                Buscar
              </ButtonClick>
              <ButtonClick
                onClick={() => this.handleClear()}
                disabled={!this.checkIfSearch()}
              >
                Limpar
              </ButtonClick>

              <CustomDialogContent
                title={"Exportar relatório de uso"}
                subTitle={
                  "Selecione os filtros abaixo para exportar seu relatório no formato excel .xlsx"
                }
                onConfirmLabel="Exportar"
                externalLabel="Exportar"
                onConfirm={() => this.handleSearchToExport()}
                loading={this.state.exportFilters.loading}
                disabled={!rental.data.length}
                classes={classes}
              >
                {this.renderExportFilters()}
              </CustomDialogContent>
            </Grid>

            <RentalPaginationTable
              onClose={() => {
                this.clearExportFilters();
              }}
              history={history}
              perPage={() => this.state.perPage}
              search={() => this.state.search}
              onChangePerPage={value => {
                this.setState({ perPage: value });
              }}
            />
          </ThemeTextFildGreen>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  lockers: state.locker,
  compartments: state.compartment,
  ...state
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...LockerAcions,
      ...CompartmentAcions,
      ...RentalActions,
      ...ReportActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(RentalUserOrder));
