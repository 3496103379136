import React, { Component } from "react";
import TextMaskCustomCPF from "../../components/TextMaskCustomCPF";
import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";

// Global
import Container from "../../components/Container";
import { materialStyle } from "../../styles";
import Spinner from "../../components/Spinner";

// Material
import { TextField, Grid, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as RentalActions } from "../../store/ducks/rental";

import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

import formatDate from "../../functions/formatDate";
import EditorCurrentRegisterContainer from "../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../components/ButtonEditorCurrentRegister";
import { Cancel as CancelIcon, DoneAll } from "@material-ui/icons";
import CompartmentOpeningTable from "../../components/CompartmentOpeningTable";

class RentalUserOrderEdit extends Component {
  state = {
    currentRental: null,
    isChanged: true,
    parameters: null,
    image: "",
    open: false,
    downloadFile: false,
    idDeliveryUpdateInfoSubType: null,
    edit: false
  };

  componentWillMount() {
    const { showRentalRequest } = this.props;
    const { id } = this.props.match.params;

    showRentalRequest(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rental.currentRental !== this.state.currentRental) {
      this.setState({
        currentRental: nextProps.rental.currentRental
      });
    }
  }

  handleChange = key => event => {
    this.setState({
      ...this.state,
      isChanged: false,
      currentRental: {
        ...this.state.currentRental,
        [key]: event.target.value
      }
    });
  };

  handleSave = () => {
    const { updateRentalRequest } = this.props;
    const { id } = this.props.match.params;

    this.setState({ edit: false });

    updateRentalRequest(id);
  };

  render() {
    const { rental, classes } = this.props;
    const { currentRental } = this.state;

    return (
      <Container title="Informações da locação">
        {rental.loading ? (
          <Spinner />
        ) : (
          currentRental && (
            <ThemeTextFildGreen>
              <EditorCurrentRegisterContainer
                disabled={rental.loading || this.state.edit}
                flexDirection="row-reverse"
              >
                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <ButtonEditorCurrentRegister
                    label="Editar"
                    disabled={
                      rental.loading ||
                      this.state.edit ||
                      currentRental.finishedAt
                    }
                    onClick={() =>
                      this.setState({
                        edit: true
                      })
                    }
                  />
                </Grid>
              </EditorCurrentRegisterContainer>
              <h5 className={classes.subTitle}>Usuário</h5>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <TextField
                    id="outlined-name"
                    label="Nome"
                    className={classes.textField}
                    value={currentRental.user.name}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <TextField
                    id="outlined-name"
                    label="Contato"
                    className={classes.textField}
                    value={
                      String(currentRental.user.phone).startsWith("55")
                        ? String(currentRental.user.phone).substring(2)
                        : currentRental.user.phone
                    }
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                    InputProps={{
                      inputComponent: TextMaskCustomMobile
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    id="outlined-name"
                    label="CPF"
                    className={classes.textField}
                    value={currentRental.user.cpf}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                    InputProps={{
                      inputComponent: TextMaskCustomCPF
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="outlined-name"
                    label="E-mail"
                    className={classes.textField}
                    value={currentRental.user.email}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <h5 className={classes.subTitle}>Locker</h5>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label="Locker"
                    className={classes.textField}
                    value={`${currentRental.locker.lockerNumber} - ${
                      currentRental.locker.address
                    }`}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label="Porta"
                    className={classes.textField}
                    value={currentRental.compartment.compartmentNumber}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <h5 className={classes.subTitle}>Status</h5>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label="Iniciada em"
                    className={classes.textField}
                    value={formatDate(currentRental.created_at)}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label="Finalizada em"
                    className={classes.textField}
                    value={
                      currentRental.finishedAt
                        ? formatDate(currentRental.finishedAt)
                        : "EM USO"
                    }
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </Grid>

              {this.state.edit && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => this.handleSave()}
                    disabled={rental.loading}
                    className={classes.button}
                  >
                    <DoneAll className={classes.leftIcon} />
                    finalizar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => this.setState({ edit: false })}
                    disabled={rental.loading}
                    className={classes.button}
                  >
                    <CancelIcon className={classes.leftIcon} />
                    Cancelar
                  </Button>
                </>
              )}

              <h5 className={classes.subTitle}>Aberturas de porta</h5>
              <CompartmentOpeningTable
                source={{
                  data: currentRental.opening
                }}
              />
            </ThemeTextFildGreen>
          )
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  rental: state.rental
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...RentalActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(RentalUserOrderEdit));
