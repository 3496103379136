import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as ThinsActions } from "../ducks/externalProfile";
import { Actions as ConfigActions } from "../ducks/configs";
import { constants } from "../../config";

export function* externalProfileRequest(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/externalProfile?${action.payload.data}`
    );

    yield put(ThinsActions.externalProfileSuccess(response.data));
  } catch (err) {
    yield put(ThinsActions.externalProfileError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}

export function* externalProfileShowRequest(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/externalProfile/${action.payload.id}`
    );

    yield put(ThinsActions.externalProfileShowSuccess(response.data));
  } catch (err) {
    yield put(ThinsActions.externalProfileShowError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}

export function* externalProfileUpdateRequest(action) {
  try {
    const response = yield call(
      api.put,
      `/v1/adm/externalProfile/${action.payload.id}`,
      action.payload.data
    );

    yield put(ThinsActions.externalProfileUpdateSuccess(response.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Perfil atualizado",
        toastVariant: constants.toastVariants.success
      })
    );
  } catch (err) {
    yield put(ThinsActions.externalProfileUpdateError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}
