import styled from "styled-components";

export const Header = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 10px;
  height: 100%;
  width: 100%;
`;

export const Logo = styled.img`
  height: 100px;
  width: 100px;
`;

export const TextSuccess = styled.h3`
  margin-top: 50px;
  text-align: center;
`;
