export const Types = {
  customerLogsRequest: "customerLogs/Request",
  customerLogsSuccess: "customerLogs/Success",
  customerLogsError: "customerLogs/Error"
};

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: [],
  page: 1,
  perPage: 100,
  lastPage: 1,
  queryString: ""
};

export default function customerLogs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.customerLogsRequest:
      return {
        ...INITIAL_STATE,
        queryString: action.payload
      };

    case Types.customerLogsSuccess:
      return {
        ...state,
        ...action.payload,
        loading: false
      };

    case Types.customerLogsError:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}

export const Actions = {
  customerLogsRequest: payload => ({
    type: Types.customerLogsRequest,
    payload
  }),

  customerLogsSuccess: payload => ({
    type: Types.customerLogsSuccess,
    payload
  }),

  customerLogsError: error => ({
    type: Types.customerLogsError,
    error
  })
};
