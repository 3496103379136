import { format } from "date-fns";

export default function formatDate(date) {
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function(p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );
  if (isSafari) {
    return date;
  }

  const test = new Date(date);
  // test.setHours(test.getHours() - 3);

  return format(test, "dd/MM/yyyy, 'às' HH:mm");
}

export function getDate(date) {
  const test = new Date(date);
  // test.setHours(test.getHours() - 3);

  return format(test, "dd/MM/yyyy");
}

export function getDateShort(date) {
  try {
    const test = new Date(date);

    return format(test, "dd/MM");
  } catch (err) {
    return ".";
  }
}

export function getHours(date) {
  const test = new Date(date);
  // test.setHours(test.getHours() - 3);

  return format(test, "HH:mm");
}

export function getUTCDate(date) {
  const test = new Date(date);
  // test.setHours(test.getHours() + 3);

  return format(test, "yyyy-MM-dd HH:mm:ss");
}

export function getUTCDateDatabase(date) {
  const test = new Date(date);
  // test.setHours(test.getHours() - 3);

  return format(test, "yyyy-MM-dd");
}
