import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Edit";
import VideocamIcon from "@material-ui/icons/Videocam";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import FavoriteIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import PasswordIcon from "@material-ui/icons/VpnKey";
import AdmIcon from "@material-ui/icons/Notes";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CustomDialog from "../../../../../../components/CustomDialog";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerActions } from "../../../../../../store/ducks/locker";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  tab: {
    [theme.breakpoints.down("xs")]: {
      width: "92vw"
    }
  },
  containerView: {
    [theme.breakpoints.down("xs")]: {
      "div:nth-child(0)": {
        padding: "0"
      }
    }
  }
}));

function ScrollableTabsButtonForce({
  view1,
  view2,
  view3,
  view4,
  view5,
  openLockerAllCompartmentsRequest,
  idLocker
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          className={classes.tab}
        >
          <Tab label="Editar" icon={<PhoneIcon />} {...a11yProps(0)} />
          <Tab label="Câmera" icon={<VideocamIcon />} {...a11yProps(1)} />
          <Tab label="Porta" icon={<FavoriteIcon />} {...a11yProps(2)} />
          <Tab
            label="Alarme"
            icon={<NotificationsActiveIcon />}
            {...a11yProps(3)}
          />
          <Tab label="Administrar" icon={<AdmIcon />} {...a11yProps(4)} />
          <Tab
            label="Senhas de acesso"
            icon={<PasswordIcon />}
            {...a11yProps(5)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.containerView}>
        {view1}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {view2}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {view3}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {view4}
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CustomDialog
          className={classes.button}
          title="Atenção"
          buttonLabel="Abrir Locker inteiro"
          cancelButtonText="Cancelar"
          confirmButtonText="Abrir"
          message={`Deseja realmente abrir todas as portas deste Locker?`}
          onConfirm={() => openLockerAllCompartmentsRequest(idLocker)}
          disabled={false}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        {view5}
      </TabPanel>
    </div>
  );
}

const mapStateToProps = state => ({
  loading: state.locker.loading,
  error: state.locker.error
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(LockerActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollableTabsButtonForce);
