import styled from "styled-components";

export const ContainerImage = styled.div`
  display: flex;
  min-height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const ImageCamera = styled.img`
  height: 50%;
  width: 50%;
`;
