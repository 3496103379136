import styled from "styled-components";

import { colors } from "../../styles";

export const Header = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContain = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #999;
  padding: 0 20px;
  font-weight: bold;
  color: #fff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 10px;
  height: 100%;
  width: 100%;
`;

export const TitleForm = styled.h2`
  color: #4ac151;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 14px;
`;

export const SubTitleForm = styled.small`
  display: flex;
  color: #666;
  font-size: 16px;
  margin: -2px 0;
`;

export const SubTitleFormResp = styled.strong`
  color: #666;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 5px;

  & button {
    background: #4ac151;
    color: #fff;
    border: 0;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background: #4ac000;
    }

    &:disabled {
      background: #ccc;
    }

    width: 210px;
    height: 40px;
    border-radius: 5px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & label {
    margin-top: 5px;
  }

  & input {
    border-radius: 5px;
    border: 1px solid #ddd;

    padding: 5px 10px;
  }
`;

export const Info = styled.div`
  margin: 20px 0 0;

  line-height: 1.2;
  font-size: 14px;

  font-style: italic;
  text-decoration: justify;

  & a {
    color: #666;
    font-weight: bold;
  }
`;

export const Logo = styled.img`
  height: 100px;
  width: 100px;
`;

export const TextError = styled.h6`
  color: ${colors.dark2};
  margin-top: ${props => (props.margin ? "50px" : "10px")};
  margin-top: ${props => (props.margin ? "50px" : "10px")};
  text-align: ${props => props.align && "center"};
`;

export const TextSuccess = styled.h3`
  margin-top: 50px;
  text-align: center;
`;

export const SubTitleTextSuccess = styled.h6`
  margin-top: 20px;
  text-align: center;
`;

export const Loading = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  align-content: center;
  justify-content: center;
  height: 100%;
`;
