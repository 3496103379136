import React from "react";

import { Typography } from "@material-ui/core";

function MenuTypography({ children }) {
  return (
    <Typography
      variant="body1"
      style={{
        fontSize: "0.75rem",
        fontWeight: "500"
      }}
    >
      {children}
    </Typography>
  );
}

export default MenuTypography;
