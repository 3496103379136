import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Material
import { TextField, Grid, Button, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Autorenew, DeleteForever } from "@material-ui/icons";

// Global Components
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import SubTeamSubUnitsTable from "../../components/SubTeamSubUnitsTable";

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { Actions as SubTeamSubUnitsActions } from "../../store/ducks/subTeamSubUnits";
import { Actions as SubTeamUnits } from "../../store/ducks/subTeamUnits";

// history
import history from "../../routes/history";

import EditorCurrentRegisterContainer from "../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../components/ButtonEditorCurrentRegister";
import ModalEdit from "../../components/ModalEdit";
import AlertDialog from "../../components/AlertDialog";
import { useTranslation } from "react-i18next";

function SubTeamUnitsEdit({
  match: {
    params: { id }
  },
  classes
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const subTeamUnits = useSelector(state => state.subTeamUnits);
  const [editableField, setEditableField] = useState(false);
  const [dashboardParams, setDashboardParams] = useState(null);
  const [_currentSubTeamUnits, setCurrentSubTeamUnits] = useState();
  const [modalVisible, setMdalVisible] = useState(false);
  const subTeamUnitsLabel =
    dashboardParams &&
    Object.keys(dashboardParams).includes("subTeamUnitsLabel")
      ? String(dashboardParams.subTeamUnitsLabel)
      : t('common:block');

  useEffect(() => {
    try {
      const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
        .parameters;

      if (parameters.dashboardParams && parameters.dashboardParams.length) {
        setDashboardParams(JSON.parse(parameters.dashboardParams));
      }
    } catch (err) {}

    dispatch(SubTeamUnits.showSubTeamUnitsRequest(id));
    dispatch(
      SubTeamSubUnitsActions.getSubTeamSubUnitsRequest(
        `perPage=1000&idSubTeamUnits=${id}`
      )
    );
  }, []);

  useEffect(
    () => {
      const { currentSubTeamUnits } = subTeamUnits;

      if (currentSubTeamUnits) {
        setCurrentSubTeamUnits(currentSubTeamUnits);
      }
    },
    [subTeamUnits]
  );

  return (
    <Container title={`${t('common:edit')} ${subTeamUnitsLabel.toLowerCase()}`}>
      {subTeamUnits.loading ? (
        <Spinner />
      ) : (
        <>
          <EditorCurrentRegisterContainer
            disabled={editableField}
            flexDirection="row-reverse"
          >
            <ButtonEditorCurrentRegister
              label={t('common:edit')}
              onClick={() => setEditableField(true)}
            />
          </EditorCurrentRegisterContainer>
          <h3>{t('common:info')}</h3>
          <ModalEdit
            open={editableField}
            onClose={() => {
              setEditableField(false);
              setCurrentSubTeamUnits(subTeamUnits.currentSubTeamUnits);
            }}
            onConfirm={() => {
              dispatch(
                SubTeamUnits.updateSubTeamUnitsRequest({
                  id: _currentSubTeamUnits.id,
                  name: _currentSubTeamUnits.name,
                  idSubTeam: _currentSubTeamUnits.idSubTeam
                })
              );
              setEditableField(false);
            }}
            classes={classes}
            title={`${t('common:edit')} ${subTeamUnitsLabel.toLowerCase()}`}
            subTitle={t('subteamssubunit:editMessage')}
            loading={subTeamUnits.loading}
            disableConfirmButton={
              _currentSubTeamUnits && !parseInt(_currentSubTeamUnits.status)
            }
          >
            <TextField
              label={t('common:name')}
              id="outlined-name"
              className={classes.textField}
              value={_currentSubTeamUnits && _currentSubTeamUnits.name}
              margin="normal"
              variant="outlined"
              disabled={
                subTeamUnits.loading ||
                (_currentSubTeamUnits && !parseInt(_currentSubTeamUnits.status))
              }
              onChange={e => {
                setCurrentSubTeamUnits({
                  ..._currentSubTeamUnits,
                  name: e.target.value
                });
              }}
            />
            <TextField
              select
              label="Status"
              id="outlined-name"
              className={classes.selectType}
              value={_currentSubTeamUnits && _currentSubTeamUnits.status}
              margin="normal"
              variant="outlined"
              disabled={true}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              onChange={e => {
                setCurrentSubTeamUnits({
                  ..._currentSubTeamUnits,
                  status: e.target.value
                });
              }}
            >
              {["1", "0"].map(item => (
                <MenuItem key={item} value={item}>
                  {parseInt(item) ? t('common:enabled'): t('common:disabled')}
                </MenuItem>
              ))}
            </TextField>
            {_currentSubTeamUnits && (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    setEditableField(false);
                    setMdalVisible(true);
                  }}
                  disabled={subTeamUnits.loading}
                  className={classes.button}
                >
                  {parseInt(_currentSubTeamUnits.status) ? (
                    <DeleteForever className={classes.leftIcon} />
                  ) : (
                    <Autorenew className={classes.leftIcon} />
                  )}
                  {parseInt(_currentSubTeamUnits.status)
                    ? t('common:disable')
                    :t('common:enable')}
                </Button>
              </>
            )}
          </ModalEdit>
          {_currentSubTeamUnits && (
            <AlertDialog
              open={modalVisible}
              className={classes.button}
              title={t('common:attention')}
              cancelButtonText={t('common:dismiss')}
              confirmButtonText={t('common:yes')}
              message={`${t('subteamssubunit:changestatusMessage')} ${
                parseInt(_currentSubTeamUnits.status) ? t('common:disable'): t('common:enable')
              } ${subTeamUnitsLabel.toLowerCase()} ${t('subteamssubunit:named')}: ${
                _currentSubTeamUnits.name
              } ?`}
              onConfirm={() => {
                dispatch(
                  SubTeamUnits.changeStatusSubTeamUnitsRequest(
                    id,
                    parseInt(_currentSubTeamUnits.status) === 1 ? 0 : 1
                  )
                );
              }}
              disabled={false}
              handleClose={() => setMdalVisible(false)}
            />
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                id="outlined-name"
                className={classes.textField}
                value={
                  subTeamUnits.currentSubTeamUnits &&
                  subTeamUnits.currentSubTeamUnits.name
                }
                margin="normal"
                variant="outlined"
                disabled={true}
                label={t('common:name')}
              />
            </Grid>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                id="outlined-name"
                className={classes.textField}
                label="Status"
                value={
                  subTeamUnits.currentSubTeamUnits &&
                  parseInt(subTeamUnits.currentSubTeamUnits.status)
                    ? t('common:enabled')
                    : t('common:disabled')
                }
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
          </Grid>

          <SubTeamSubUnitsTable
            history={history}
            dashboardParams={dashboardParams}
          />
        </>
      )}
    </Container>
  );
}

export default withStyles(materialStyle)(SubTeamUnitsEdit);
