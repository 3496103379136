import { call, put } from "redux-saga/effects";
import api from "../../../../services/api";

import { Actions as PartnersNeposTickets } from "../../../ducks/partners/nepos/tickets";

export function* getNeposTickets(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/partners/nepos/tickets?${action.payload.data}`
    );

    yield put(PartnersNeposTickets.partnersNeposTicketsSuccess(response.data));
  } catch (err) {
    yield put(PartnersNeposTickets.partnersNeposTicketsError(err.response));
  }
}
