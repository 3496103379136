import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as CustomerFeedback } from "../ducks/customerFeedback";

export function* getCustomerFeedbacks(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/customer/feedback?${action.payload.data}`
    );

    yield put(CustomerFeedback.customerFeedbackSuccess(response.data));
  } catch (err) {
    yield put(CustomerFeedback.customerFeedbackError(err.response));
  }
}
