export const Types = {
  getLaundryLockerItemOptionsRequest: "getLaundryLockerItemOptions/Request",
  getLaundryLockerItemOptionsSuccess: "getLaundryLockerItemOptions/Success",
  getLaundryLockerItemOptionsError: "getLaundryLockerItemOptions/Error",

  showLaundryLockerItemOptionsRequest: "showLaundryLockerItemOptions/Request",
  showLaundryLockerItemOptionsSuccess: "showLaundryLockerItemOptions/Success",
  showLaundryLockerItemOptionsError: "showLaundryLockerItemOptions/Error",

  createLaundryLockerItemOptionsRequest:
    "createLaundryLockerItemOptions/Request",
  createLaundryLockerItemOptionsSuccess:
    "createLaundryLockerItemOptions/Success",
  createLaundryLockerItemOptionsError: "createLaundryLockerItemOptions/Error",

  getLaundryUsersRequest: "getLaundryUsers/Request",
  getLaundryUsersSuccess: "getLaundryUsers/Success",
  getLaundryUsersError: "getLaundryUsers/Error",

  updateLaundryUsersRequest: "updateLaundryUsers/Request",
  updateLaundryUsersSuccess: "updateLaundryUsers/Success",
  updateLaundryUsersError: "updateLaundryUsers/Error"
};

const INITAL_STATE = {
  data: [],
  loading: false,
  error: null,
  page: null,
  perPage: null,
  total: null,
  lastPage: null,
  currentData: null
};

export default function laundry(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.getLaundryLockerItemOptionsRequest:
      return {
        ...state,
        loading: true,
        error: null,
        data: INITAL_STATE.data
      };

    case Types.getLaundryLockerItemOptionsSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data
      };

    case Types.getLaundryLockerItemOptionsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.showLaundryLockerItemOptionsRequest:
      return {
        ...state,
        loading: true,
        error: null,
        currentData: INITAL_STATE.currentData
      };

    case Types.showLaundryLockerItemOptionsSuccess:
      return {
        ...state,
        loading: false,
        currentData: action.payload.data
      };

    case Types.showLaundryLockerItemOptionsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.createLaundryLockerItemOptionsRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.createLaundryLockerItemOptionsSuccess:
      return {
        ...state,
        loading: false
      };

    case Types.createLaundryLockerItemOptionsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.getLaundryUsersRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.getLaundryUsersSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data
      };

    case Types.getLaundryUsersError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.updateLaundryUsersRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.updateLaundryUsersSuccess:
      return {
        ...state,
        loading: false,
        currentData: {
          ...state.currentData,
          ...action.payload.data
        }
      };

    case Types.updateLaundryUsersError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
}

export const Actions = {
  getLaundryLockerItemOptionsRequest: data => ({
    type: Types.getLaundryLockerItemOptionsRequest,
    payload: { data }
  }),

  getLaundryLockerItemOptionsSuccess: data => ({
    type: Types.getLaundryLockerItemOptionsSuccess,
    payload: { data }
  }),

  getLaundryLockerItemOptionsError: error => ({
    type: Types.getLaundryLockerItemOptionsError,
    payload: { error }
  }),

  showLaundryLockerItemOptionsRequest: id => ({
    type: Types.showLaundryLockerItemOptionsRequest,
    payload: { id }
  }),

  showLaundryLockerItemOptionsSuccess: data => ({
    type: Types.showLaundryLockerItemOptionsSuccess,
    payload: { data }
  }),

  showLaundryLockerItemOptionsError: error => ({
    type: Types.showLaundryLockerItemOptionsError,
    payload: { error }
  }),

  createLaundryLockerItemOptionsRequest: data => ({
    type: Types.createLaundryLockerItemOptionsRequest,
    payload: { data }
  }),

  createLaundryLockerItemOptionsSuccess: data => ({
    type: Types.createLaundryLockerItemOptionsSuccess,
    payload: { data }
  }),

  createLaundryLockerItemOptionsError: error => ({
    type: Types.createLaundryLockerItemOptionsError,
    payload: { error }
  }),

  getLaundryUsersRequest: data => ({
    type: Types.getLaundryUsersRequest,
    payload: { data }
  }),

  getLaundryUsersSuccess: data => ({
    type: Types.getLaundryUsersSuccess,
    payload: { data }
  }),

  getLaundryUsersError: error => ({
    type: Types.getLaundryUsersError,
    payload: { error }
  }),

  updateLaundryUsersRequest: data => ({
    type: Types.updateLaundryUsersRequest,
    payload: { data }
  }),

  updateLaundryUsersSuccess: data => ({
    type: Types.updateLaundryUsersSuccess,
    payload: { data }
  }),

  updateLaundryUsersError: error => ({
    type: Types.updateLaundryUsersError,
    payload: { error }
  })
};
