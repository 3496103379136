import React, { Component } from "react";

// Global Components
import Container from "../../components/Container";

// Material
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, Grid } from "@material-ui/core";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as ThingTypeActions } from "../../store/ducks/thingTypes";
import { Actions as CompartmentActions } from "../../store/ducks/compartment";
import { Actions as LockerActions } from "../../store/ducks/locker";

//configs
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import AnimatedMultiSelector from "../../components/AnimatedMultiSelector";
import Spinner from "../../components/Spinner";

class ThingTypeCreate extends Component {
  state = {
    name: "",
    currentThing: null,
    description: "",
    compartments: []
  };

  // Load Roles and teams
  componentWillMount() {
    const {
      match: { params },
      showThingTypeRequest,
      getCompartmentsOfTeamRequest
    } = this.props;

    getCompartmentsOfTeamRequest("perPage=1000");

    if (Object.keys(params).includes("id")) {
      showThingTypeRequest(params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.thingTypes.currentThingType !== this.state.currentThingType) {
      this.setState({
        ...nextProps.thingTypes.currentThingType
      });
    }
  }

  // Handle Change global
  handleChange = name => event => {
    let value = event.target.value;

    this.setState({ [name]: value });
  };

  // create new team
  handleCreate = () => {
    const {
      updateThingTypeRequest,
      createThingTypeRequest,
      match: { params }
    } = this.props;

    if (Object.keys(params).includes("id")) {
      updateThingTypeRequest(this.state);
    } else {
      createThingTypeRequest(this.state);
    }
  };

  render() {
    const { classes, thingTypes, compartment } = this.props;
    const { name, description } = this.state;

    return (
      <Container
        title={thingTypes.currentThingType ? "Editar" : "Criar tipo de thing"}
      >
        {thingTypes.loading || compartment.loading ? (
          <Spinner />
        ) : (
          <ThemeTextFildGreen>
            <h3 style={{ marginTop: 20 }}>
              {thingTypes.currentThingType
                ? `Dados do thing type ${thingTypes.currentThingType.name}`
                : "Dados do novo Thing type"}
            </h3>
            <Grid className="first-grid" container spacing={2}>
              <Grid
                item
                xs={12}
                sm={3}
                className="form-colaboradore-sm"
                alignItems="flex-start"
              >
                <TextField
                  required
                  id="outlined-name"
                  label="Nome"
                  className={classes.textField}
                  value={name}
                  onChange={this.handleChange("name")}
                  margin="normal"
                  variant="outlined"
                  disabled={thingTypes.loading}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className="form-colaboradore-sm"
                alignItems="flex-start"
              >
                <TextField
                  required
                  id="outlined-name"
                  label="Descrição"
                  className={classes.textField}
                  value={description}
                  onChange={this.handleChange("description")}
                  margin="normal"
                  variant="outlined"
                  disabled={thingTypes.loading}
                />
              </Grid>
            </Grid>

            <h2>Portas</h2>

            <AnimatedMultiSelector
              onChange={compartments =>
                this.setState({ compartments: compartments || [] })
              }
              options={compartment.data}
              labelKey="compartmentNumber"
              defaultValue={this.state.compartments}
            />

            <br />
            <Button
              disabled={thingTypes.loading}
              onClick={this.handleCreate}
              variant="contained"
            >
              <SaveIcon className={classes.leftIcon} />
              {Object.keys(this.props.match.params).includes("id")
                ? "Salvar"
                : "Criar"}
            </Button>
            <Button
              variant="contained"
              onClick={() => this.props.history.push("/thing/types")}
              className={classes.button}
            >
              <CancelIcon className={classes.leftIcon} />
              Cancelar
            </Button>
          </ThemeTextFildGreen>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  things: state.things,
  thingTypes: state.thingTypes,
  groups: state.groups,
  compartment: state.compartment
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ThingTypeActions,
      ...CompartmentActions,
      ...LockerActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(ThingTypeCreate));
