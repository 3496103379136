import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './config/reactotron';
import store from './store';
import Routes from './routes';
import { wsConnect } from './services/websocket';
import './styles/signin.css';
import './styles/global.css';

require('dotenv').config();
class App extends Component {
  render() {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (auth) {
      wsConnect();
    }
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Provider>
    );
  }
}
export default App;
