import React, { Component } from "react";
import AWS from "aws-sdk";

// Global
import Container from "../../../../components/Container";
import { materialStyle } from "../../../../styles";
import Spinner from "../../../../components/Spinner";
import CustomDialog from "../../../../components/CustomDialog";
import { withTranslation } from "react-i18next";
import Snackbar from "../../../../components/Snackbar";
import { Actions as BusinessActions } from "../../../../store/ducks/business";

// Material
import {
  TextField,
  Button,
  MenuItem,
  Grid,
  TextareaAutosize,
  Dialog
} from "@material-ui/core";
import { Save as SaveIcon, OpenInNew, SaveAlt } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as DeliveryActions } from "../../../../store/ducks/delivery";
import { Actions as DeliveryUpdateInfoSubTypeActions } from "../../../../store/ducks/deliveryUpdateInfoSubType";

import ThemeTextFildGreen from "../../../../styles/ThemeTextFildGreen";

import { constants } from "../../../../config";
import ButtonLink from "../../../../components/ButtonLink";
import { getDashboardURL } from "../../../../services/api";
import checkHandoverEmail from "../../../../services/checkHandoverEmail";
import apiPDF from "../../../../services/apiPDF";
import CustomDialogCancelDelivery from "../../../../components/CustomDialogCancelDelivery";
import MultiEmailInput from "../../../../components/MultiEmailInput";
import formatMobile from "../../../../utils/formatMobile";

class DeliveryEdit extends Component {
  t = this.props.t
  state = {
    currentDelivery: null,
    isChanged: true,
    parameters: null,
    image: "",
    open: false,
    downloadFile: false,
    idDeliveryUpdateInfoSubType: null
  };

  componentWillMount() {
    const {
      showDeliveryRequest,
      getBusinessRequest,
      deliveryUpdateInfoSubTypeRequest
    } = this.props;
    const { id } = this.props.match.params;

    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;
    const { email } = JSON.parse(localStorage.getItem("auth")).user;

    this.setState({ parameters, email });

    showDeliveryRequest(id);
    getBusinessRequest("perPage=1000&status=1");
    deliveryUpdateInfoSubTypeRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.delivery.currentDelivery !== this.state.currentDelivery) {
      this.setState({
        currentDelivery: nextProps.delivery.currentDelivery
      });
    }
  }

  handleChange = key => event => {
    this.setState({
      ...this.state,
      isChanged: false,
      currentDelivery: {
        ...this.state.currentDelivery,
        [key]: event.target.value
      }
    });
  };

  handleSave = () => {
    const { updateDeliveryRequest } = this.props;

    const { currentDelivery } = this.state;

    const tmp = currentDelivery;

    if (tmp.idBusiness === "select") {
      tmp.idBusiness = "";
    }

    if (tmp.idBusinessColector === "select") {
      tmp.idBusinessColector = "";
    }

    updateDeliveryRequest(tmp);
  };

  handleDownload = async () => {
    try {
      const { setSnackbar } = this.props;
      this.setState({ downloadFile: true });

      const { currentDelivery, parameters } = this.state;

      const s3 = new AWS.S3({
        region: "us-east-1",
        apiVersion: "2006-03-01",
        credentials: {
          accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
          secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY
        }
      });

      const useSlack = Boolean(parameters.sendSlackToCollect);

      const BUCKET = useSlack
        ? constants.buckets.fileOperations
        : constants.buckets.informacoedevistorias;

      const file = await (await s3
        .getObject({
          Bucket: BUCKET,
          Key: currentDelivery.protocolDocument
        })
        .promise()).Body;

      this.setState({
        image: file
      });

      if (!useSlack) {
        const imageData = Buffer.from(file, "utf-8").toString();
        const body = {
          documentType: "pdf",
          remessa: currentDelivery.customerReferenceId,
          pedido: currentDelivery.customerReferenceId,
          nota: currentDelivery.customerReferenceId,
          nome_cliente: String(currentDelivery.customerName).toUpperCase(),
          documento: currentDelivery.secretCode,
          tipo_recebedor: currentDelivery.collectorType
            ? String(currentDelivery.collectorType).toUpperCase()
            : "Próprio Destinatário",
          nome_recebedor:
            currentDelivery.collectorType === "Próprio Destinatário"
              ? String(currentDelivery.customerName).toUpperCase()
              : String(currentDelivery.collectorName).toUpperCase(),
          documento_recebedor:
            currentDelivery.collectorType === "Próprio Destinatário"
              ? currentDelivery.secretCode
              : currentDelivery.collectorDocument,
          endereco: String(currentDelivery.locker.address).toUpperCase(),
          date: currentDelivery.updated_at,
          imageData
        };

        const data = await apiPDF.post("/", body);

        const _file = new Blob([data.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(_file);
        window.open(fileURL);
      } else {
        var matches = String(file)
          .toString()
          .match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);

        const url = window.URL.createObjectURL(
          new Blob([Buffer.from(matches[2], "base64")], {
            type: "image/png"
          })
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Documento-${currentDelivery.customerReferenceId}.png`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }

      setSnackbar({
        snackbarVisible: true,
        snackbarType: "success",
        snackbarMessage: this.t('receivedDocument')
      });
    } catch (err) {
      console.log(err);
      this.props.setSnackbar({
        snackbarVisible: true,
        snackbarType: "error",
        snackbarMessage: this.t('receivedDocumentError')
      });
    } finally {
      this.setState({ downloadFile: false });
    }
  };

  render() {
    const {
      delivery,
      classes,
      openDeliveryLockerRequest,
      setSnackbarVisibleDelivery,
      sendDeliveryEmailCodominiumRequest,
      business,
      cancelDeliveryRequest
    } = this.props;
    const {
      currentDelivery,
      parameters,
      email,
      idDeliveryUpdateInfoSubType
    } = this.state;

    const labelStatus = Boolean(this.state.parameters.useLaundry)
      ? constants.deliveryStatusLaundry
      : constants.deliveryStatus;

    return (
      <Container title={this.t('editTitle')}>
        <Snackbar
          message={delivery.snackbarMessage}
          open={delivery.snackbarVisible}
          variant={delivery.snackbarType}
          onClose={() => setSnackbarVisibleDelivery(false)}
        />
        {delivery.loading || this.state.downloadFile ? (
          <Spinner />
        ) : (
          currentDelivery && (
            <ThemeTextFildGreen>
              <Dialog
                open={this.state.open}
                onClose={() => this.setState({ open: false })}
                hasCloseButton
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              >
                <img
                  style={{ width: "auto", height: "100%" }}
                  src={this.state.image}
                  alt="Protocolo"
                />
              </Dialog>
              <h5 className={classes.subTitle}>Informações da entrega</h5>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <TextField
                    id="outlined-name"
                    label="ID"
                    className={classes.textField}
                    value={currentDelivery.customerReferenceId}
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange("customerReferenceId")}
                    disabled={true}
                  />
                </Grid>
                {currentDelivery.secretCode && (
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="outlined-name"
                      label={
                        parameters && parameters.textSecretCode
                          ? parameters.textSecretCode
                          : this.t('accessCode')
                      }
                      className={classes.textField}
                      value={currentDelivery.secretCode}
                      margin="normal"
                      variant="outlined"
                      onChange={this.handleChange("secretCode")}
                      disabled={
                        true ||
                        currentDelivery.status === "3" ||
                        currentDelivery.status === 4
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={this.t('common:locker')}
                    className={classes.textField}
                    value={`${currentDelivery.locker.lockerNumber} - ${
                      currentDelivery.locker.address
                    }`}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={this.t('common:door')}
                    className={classes.textField}
                    value={currentDelivery.compartment.compartmentNumber}
                    variant="outlined"
                    margin="normal"
                    disabled={true}
                  />
                </Grid>

                {currentDelivery.customerName && (
                  <Grid item xs={12} md={2} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label={this.t('clientName')}
                      className={classes.textField}
                      value={currentDelivery.customerName}
                      variant="outlined"
                      margin="normal"
                      disabled={true}
                    />
                  </Grid>
                )}

                {currentDelivery.customerLastname && (
                  <Grid item xs={12} md={2} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label={this.t('clientLastName')}
                      className={classes.textField}
                      value={currentDelivery.customerLastname}
                      variant="outlined"
                      margin="normal"
                      disabled={true}
                    />
                  </Grid>
                )}

                {currentDelivery.customerEmail && (
                  <Grid item xs={12} md={2} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label={this.t('clientEmail')}
                      className={classes.textField}
                      value={currentDelivery.customerEmail}
                      variant="outlined"
                      margin="normal"
                      disabled={true}
                    />
                  </Grid>
                )}

                {currentDelivery.customerPhone && (
                  <Grid item xs={12} md={2} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label={this.t('clientNumber')}
                      className={classes.textField}
                      value={currentDelivery.customerPhone}
                      variant="outlined"
                      margin="normal"
                      disabled={true}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    ref={textarea => (this.textArea = textarea)}
                    className={classes.textFieldLocker}
                    value={this.state.currentDelivery.urlEncryption}
                    label={this.t('urlToken')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={this.t('itemsDescription')}
                    className={classes.textField}
                    value={currentDelivery.collectionDescription}
                    variant="outlined"
                    margin="normal"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    ref={textarea => (this.textArea = textarea)}
                    className={classes.textFieldLocker}
                    value={
                      this.state.currentDelivery.protocolDocument
                        ? this.t('thing:yes')
                        : this.t('thing:no')
                    }
                    label="Protocolo registrado"
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={this.t('common:business')}
                    className={classes.textField}
                    value={
                      currentDelivery.team ? currentDelivery.team.name : ""
                    }
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                {currentDelivery.subTeam && (
                  <Grid item xs={12} md={2} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label={this.t('unit')}
                      className={classes.textField}
                      value={
                        currentDelivery.subTeam
                          ? currentDelivery.subTeam.name
                          : ""
                      }
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    select
                    className={classes.textFieldLocker}
                    value={this.state.currentDelivery.status}
                    onChange={this.handleChange("status")}
                    label="Status"
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                    disabled={
                      delivery.loading || Boolean(parameters.useCondominium)
                    }
                  >
                    {Object.keys(labelStatus)
                      .filter(item => item !== "all")
                      .map(
                        status =>
                          labelStatus[status].visible && (
                            <MenuItem
                              key={labelStatus[status].slug}
                              value={labelStatus[status].id}
                            >
                              {labelStatus[status].label}
                            </MenuItem>
                          )
                      )}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={this.t('createdAt')}
                    className={classes.textField}
                    value={currentDelivery.created_at}
                    margin="normal"
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </Grid>

              {currentDelivery.subTeamSubUnitsContact && (
                <>
                  <h5 className={classes.subTitle}>
                    {this.t('receiverInfo')}
                  </h5>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={2} className="form-colaboradore-sm">
                      <TextField
                        id="outlined-name"
                        label="Nome do morador"
                        className={classes.textField}
                        value={currentDelivery.subTeamSubUnitsContact.name}
                        variant="outlined"
                        margin="normal"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} className="form-colaboradore-sm">
                      <TextField
                        id="outlined-name"
                        label="Celular do morador"
                        className={classes.textField}
                        value={currentDelivery.subTeamSubUnitsContact.phone}
                        variant="outlined"
                        margin="normal"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={10} className="form-colaboradore-sm">
                      <MultiEmailInput
                        disabled={true}
                        defaultValue={
                          currentDelivery.subTeamSubUnitsContact.email
                        }
                        helperText=""
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {!Boolean(parameters.useCondominium) &&
                !Boolean(parameters.useLaundry) && (
                  <>
                    <h5 className={classes.subTitle}>
                      Responsáveis pela entrega
                    </h5>
                    <Grid container spacing={2} alignItems="center">
                      {currentDelivery.updateInfo.length ? (
                        <Grid
                          item
                          xs={12}
                          md={2}
                          className="form-colaboradore-sm"
                        >
                          <TextField
                            id="outlined-name"
                            label="Criado por"
                            className={classes.textField}
                            value={
                              parseInt(
                                currentDelivery.updateInfo[0]
                                  .idDeliveryUpdateInfoType
                              ) === 7
                                ? currentDelivery.updateInfo[0].business.name
                                : currentDelivery.business
                                  ? currentDelivery.business.name
                                  : ""
                            }
                            margin="normal"
                            variant="outlined"
                            disabled={true}
                          />
                        </Grid>
                      ) : null}

                      {!Boolean(parameters.useLaundry) && (
                        <>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className="form-colaboradore-sm"
                          >
                            <TextField
                              id="custom-css-outlined-input"
                              select
                              label="Quem faz depósito"
                              className={classes.textFieldLocker}
                              value={this.state.currentDelivery.idBusiness}
                              onChange={this.handleChange("idBusiness")}
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu
                                }
                              }}
                              margin="normal"
                              variant="outlined"
                            >
                              <MenuItem key={"select"} value={"select"}>
                                Selecione
                              </MenuItem>
                              {business.data &&
                                business.data.map(
                                  item =>
                                    (parseInt(item.type) === 1 ||
                                      parseInt(item.type) === 3) && (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    )
                                )}
                            </TextField>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={3}
                            className="form-colaboradore-sm"
                          >
                            <TextField
                              id="custom-css-outlined-input"
                              select
                              label="Quem faz a coleta"
                              className={classes.textFieldLocker}
                              value={
                                this.state.currentDelivery.idBusinessColector
                              }
                              onChange={this.handleChange("idBusinessColector")}
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu
                                }
                              }}
                              margin="normal"
                              variant="outlined"
                            >
                              <MenuItem key={"select"} value={"select"}>
                                Selecione
                              </MenuItem>
                              {business.data &&
                                business.data.map(
                                  item =>
                                    (parseInt(item.type) === 2 ||
                                      parseInt(item.type) === 3) && (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    )
                                )}
                            </TextField>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

              {Boolean(parameters.useLaundry) &&
                currentDelivery.laundryUser && (
                  <>
                    <h5 className={classes.subTitle}>Cliente da lavanderia</h5>
                    <Grid container spacing={2} alignItems="center">
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className="form-colaboradore-sm"
                      >
                        <TextField
                          id="custom-css-outlined-input"
                          label="Nome"
                          className={classes.textFieldLocker}
                          value={currentDelivery.laundryUser.name}
                          margin="normal"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className="form-colaboradore-sm"
                      >
                        <TextField
                          id="custom-css-outlined-input"
                          label="Email"
                          className={classes.textFieldLocker}
                          value={currentDelivery.laundryUser.email}
                          margin="normal"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className="form-colaboradore-sm"
                      >
                        <TextField
                          id="custom-css-outlined-input"
                          label="Contato"
                          className={classes.textFieldLocker}
                          value={formatMobile(
                            currentDelivery.laundryUser.mobile
                          )}
                          margin="normal"
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

              {currentDelivery.initialCompartmentSupply && (
                <>
                  <h5 className={classes.subTitle}>
                    Descrição inicial de itens
                  </h5>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6} className="form-colaboradore-sm">
                      <TextareaAutosize
                        aria-label="minimum height"
                        rowsMin={3}
                        rowsMax={10}
                        margin="normal"
                        variant="outlined"
                        value={
                          currentDelivery.initialCompartmentSupply.description
                        }
                        style={{
                          width: 500,
                          marginLeft: 10
                        }}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {parseInt(delivery.currentDelivery.status) === 3 &&
                delivery.currentDelivery.collectorType && (
                  <>
                    <h5 className={classes.subTitle}>Coleta realizada por</h5>
                    <Grid container spacing={2} alignItems="center">
                      <Grid
                        item
                        xs={12}
                        md={2}
                        className="form-colaboradore-sm"
                      >
                        <TextField
                          id="outlined-name"
                          label=""
                          className={classes.textField}
                          value={delivery.currentDelivery.collectorType}
                          margin="normal"
                          variant="outlined"
                          disabled={true}
                        />
                      </Grid>

                      {delivery.currentDelivery.collectorDocument && (
                        <>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className="form-colaboradore-sm"
                          >
                            <TextField
                              id="outlined-name"
                              label="Nome"
                              className={classes.textField}
                              value={delivery.currentDelivery.collectorName}
                              margin="normal"
                              variant="outlined"
                              disabled={true}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className="form-colaboradore-sm"
                          >
                            <TextField
                              id="outlined-name"
                              label="CPF"
                              className={classes.textField}
                              value={delivery.currentDelivery.collectorDocument}
                              margin="normal"
                              variant="outlined"
                              disabled={true}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

              {!Boolean(parameters.useCondominium) &&
                Boolean(parameters.useUrl) && (
                  <ButtonLink
                    variant="contained"
                    className={classes.button}
                    content="Link web para coleta"
                    to={`${getDashboardURL()}/openForUrl?token=${
                      this.state.currentDelivery.urlEncryption
                    }`}
                    endIcon={<OpenInNew />}
                    helpText="Link para apenas para coleta"
                  />
                )}

              {Boolean(parameters.useCondominium) &&
                parseInt(this.props.delivery.currentDelivery.status) !== 3 &&
                parseInt(this.props.delivery.currentDelivery.status) !== 6 &&
                parseInt(this.props.delivery.currentDelivery.status) !== 7 && (
                  <ButtonLink
                    variant="contained"
                    className={classes.button}
                    content="QRCode"
                    to={`https://empresas.oihandover.com/qrcode/${
                      currentDelivery.urlEncryption
                    }`}
                    endIcon={<OpenInNew />}
                    helpText="QRCode para usar no totem de atendimento do locker"
                    disabled={delivery.loading}
                  />
                )}

              {(Boolean(parameters.sendSlackToCollect) ||
                checkHandoverEmail(email)) && (
                <Button
                  variant="contained"
                  onClick={this.handleDownload}
                  disabled={!currentDelivery.protocolDocument}
                  className={classes.button}
                >
                  <SaveAlt className={classes.leftIcon} />
                  Baixar documento
                </Button>
              )}

              {parseInt(this.props.delivery.currentDelivery.status) !== 3 &&
                parseInt(this.props.delivery.currentDelivery.status) !== 6 &&
                parseInt(this.props.delivery.currentDelivery.status) !== 7 && (
                  <>
                    <CustomDialog
                      className={classes.button}
                      title="Atenção"
                      buttonLabel="Abrir porta"
                      cancelButtonText="Cancelar"
                      confirmButtonText="Abrir"
                      message={`Deseja realmente abrir o porta ${
                        currentDelivery.compartment.compartmentNumber
                      } no endereço ${currentDelivery.locker.address} ?`}
                      onConfirm={() =>
                        openDeliveryLockerRequest({
                          idDelivery: currentDelivery.id
                        })
                      }
                      disabled={
                        parseInt(this.props.delivery.currentDelivery.status) ===
                          3 ||
                        parseInt(this.props.delivery.currentDelivery.status) ===
                          4
                          ? true
                          : false
                      }
                    />

                    {parseInt(this.props.delivery.currentDelivery.status) ===
                      2 &&
                      (Boolean(parameters.useCondominium) ||
                        currentDelivery.customerEmail ||
                        checkHandoverEmail(email)) && (
                        <CustomDialog
                          className={classes.button}
                          title="Atenção"
                          buttonLabel="Enviar notificação para coleta"
                          cancelButtonText="Cancelar"
                          confirmButtonText="Enviar"
                          message={`Enviar novo email para ${
                            currentDelivery.subTeamSubUnitsContact
                              ? currentDelivery.subTeamSubUnitsContact.email
                              : currentDelivery.customerEmail
                          }?`}
                          onConfirm={() => {
                            sendDeliveryEmailCodominiumRequest(
                              currentDelivery.id
                            );
                          }}
                          disabled={
                            parseInt(currentDelivery.status) === 3 ||
                            parseInt(currentDelivery.status) === 4
                              ? true
                              : false
                          }
                        />
                      )}

                    {parseInt(this.props.delivery.currentDelivery.status) !==
                      3 &&
                      parseInt(this.props.delivery.currentDelivery.status) !==
                        6 &&
                      parseInt(this.props.delivery.currentDelivery.status) !==
                        7 && (
                        <CustomDialogCancelDelivery
                          className={classes.button}
                          title="Atenção"
                          buttonLabel="Finalizar entrega"
                          cancelButtonText="Não"
                          confirmButtonText="Sim"
                          message={
                            "Certifique-se de que todos os itens dentro da porta foram removidos."
                          }
                          subMessage={
                            "A notificação de finalização será enviado por email e/ou SMS do destinatário."
                          }
                          finalMessage={"Deseja realmente finalizar a entrega?"}
                          onConfirm={() => {
                            cancelDeliveryRequest(
                              currentDelivery.id,
                              idDeliveryUpdateInfoSubType
                            );
                          }}
                          disabled={
                            parseInt(currentDelivery.status) === 3 ||
                            parseInt(currentDelivery.status) === 4
                              ? true
                              : false
                          }
                          onChangePageDeliveryUpdateInfoSubType={value => {
                            this.setState({
                              idDeliveryUpdateInfoSubType: value
                            });
                          }}
                          onOpenPort={() => {
                            openDeliveryLockerRequest({
                              idDelivery: currentDelivery.id
                            });
                          }}
                        />
                      )}
                  </>
                )}
              <br />

              {(!Boolean(parameters.useCondominium) ||
                checkHandoverEmail()) && (
                <Button
                  variant="contained"
                  onClick={this.handleSave}
                  disabled={delivery.loading}
                  className={classes.button}
                >
                  <SaveIcon className={classes.leftIcon} />
                  Salvar
                </Button>
              )}
            </ThemeTextFildGreen>
          )
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  delivery: state.delivery,
  business: state.business
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...DeliveryActions,
      ...BusinessActions,
      ...DeliveryUpdateInfoSubTypeActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(withTranslation('delivery')(DeliveryEdit)));
