import { call, put } from 'redux-saga/effects';
import api from "../../services/api";
import history from '../../routes/history';

import { Actions as RoleActions } from '../ducks/roles';

export function* getAllRoles(action) {
  try {
    const response = yield call(api.get, '/v1/adm/business/roles');

    yield put(RoleActions.getAllRolesSuccess(response.data));
  } catch (err) {
    yield put(RoleActions.getAllRolesError(err));
  }
}

export function* getRoles(action) {
  try {
    const response = yield call(api.get, `/v1/adm/master/roles?${action.payload.data}`);

    yield put(RoleActions.getRolesSuccess(response.data));
  } catch (err) {
    yield put(RoleActions.getRolesError(err.response));
  }
}

export function* createRole(action) {
  try {
    const response = yield call(api.post, '/v1/adm/master/roles', {
      ...action.payload.data,
    });

    yield put(RoleActions.createRoleSuccess(response.data));
    history.push(`/administrator/roles/edit/${response.data.id}`);
  } catch (err) {
    yield put(RoleActions.createRoleError(err.response));
  }
}

export function* showRole(action) {
  try {
    const response = yield call(api.get, `/v1/adm/master/roles/${action.payload.id}`)

    yield put(RoleActions.showRoleSuccess(response.data));
  } catch (err) {
    yield put(RoleActions.showRoleError(err.response));
  }
}
