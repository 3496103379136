import React, { Component } from "react";
import Container from "../../components/Container";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid
} from "@material-ui/core";

import Spinner from "../../components/Spinner";
import Snackbar from "../../components/Snackbar";

import api from "../../services/api";

export default class updateDeploymentStatus extends Component {
  state = {
    alertOpen: true,
    deploymentStatus: null,
    attachmentNumber: "verificar",
    address: null,
    companyName: null,
    date: "verificar",

    lockerStatus: null,

    loading: true,
    updateLoading: false,
    err: null,

    deploymentStatusData: [],
    locker: null,
    deployStatusNow: null,
    snackbarVisible: false,
    snackbarMessage: "",
    snackbarType: "success"
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const { id } = this.props.match.params;

      const deploymentStatusData = await api.get("/v1/adm/lockerDeployStatus");

      const locker = await api.get(`/v1/adm/lockers/${id}`);

      this.setState({
        deploymentStatusData: deploymentStatusData.data,
        loading: false,
        locker: locker.data,
        deployStatusNow: locker.data.deployStatusNow
      });
    } catch (error) {
      this.setState({
        loading: false
      });
    }
  }

  handleClick = async () => {
    try {
      const { id } = this.props.match.params;
      const { locker, deployStatusNow } = this.state;
      this.setState({ loading: true });

      await api.put(`/v1/adm/lockerDeployStatus/${id}`, {
        idLockerDeployStatus: this.state.deployStatusNow.id
      });

      this.setState({
        loading: false,
        snackbarMessage: "Status atualizado",
        snackbarVisible: true,
        locker: {
          ...locker,
          deployStatusNow
        }
      });
    } catch (err) {
      let snackbarMessage =
        "Problema em atualizar os status, verifique com o administrador";

      if (err.response.data.message) {
        snackbarMessage = err.response.data.message;
      }

      this.setState({
        loading: false,
        snackbarMessage,
        snackbarType: "error",
        snackbarVisible: true
      });
    }
  };

  render() {
    const {
      locker,
      loading,
      deployStatusNow,
      deploymentStatusData,
      snackbarMessage,
      snackbarType,
      snackbarVisible
    } = this.state;
    return (
      <Container title="Atualizar status de implantação">
        <Snackbar
          message={snackbarMessage}
          open={snackbarVisible}
          variant={snackbarType}
          onClose={() => this.setState({ snackbarVisible: false })}
        />
        {this.state.alertOpen && (
          <>
            <Alert severity="info" style={{ width: "100%" }}>
              <AlertTitle>Atenção !!!</AlertTitle>
              <li>
                Esteja atento as alterações nesse campo, só altere se estiver
                certo do status de implantação desse locker.
              </li>
              <li>
                Estes dados são de carater informativos para o proprietario do
                locker.
              </li>
              <li>
                As alterações aqui feitas só terão efeito 10 minutos após a
                confirmação do envio, podendo serem alteradas caso estegem
                erradas.
              </li>
            </Alert>
            <br />
          </>
        )}
        {loading ? (
          <Spinner />
        ) : (
          <>
            {/* <p>Número anexo de contratação: {this.state.attachmentNumber}</p> */}
            <p>Endereço do locker: {locker && locker.address}</p>
            <p>Cliente: {locker && locker.team.name}</p>
            {/* <p>Data do pedido: {this.state.date}</p> */}
            <FormControl size="small" variant="filled" fullWidth>
              <InputLabel id="updateDeploymentStatus">
                Status de implantação
              </InputLabel>
              <Select
                labelId="updateDeploymentStatus"
                id="updateDeploymentStatusselect"
                value={deployStatusNow ? deployStatusNow.id : ""}
                onChange={e =>
                  this.setState({
                    deployStatusNow: deploymentStatusData.find(
                      item => item.id === e.target.value
                    )
                  })
                }
              >
                {deploymentStatusData &&
                  deploymentStatusData.map(ls => (
                    <MenuItem
                      key={ls.id}
                      value={ls.id}
                      disabled={
                        locker.deployStatusNow &&
                        (locker.deployStatusNow.position > ls.position ||
                          ls.position > locker.deployStatusNow.position + 1)
                      }
                    >
                      <em>
                        {ls.position} - {ls.description}
                      </em>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <p style={{ color: "red", fontSize: "14px" }}>
              {this.state.err && this.state.err}
            </p>
            <Grid container fullWidth justify="flex-end">
              <Button
                variant="contained"
                onClick={this.handleClick}
                style={{ // para os Buttons
                  backgroundColor: "#006660",
                  fontSize: 14,
                  color: "#fff",
                  textTransform: "lowercase",
                  height: 35,
                }}
                disabled={
                  loading || deployStatusNow
                    ? deployStatusNow.id === locker.deployStatusNow.id
                    : false
                }
              >
                {this.state.updateLoading ? "Carregando..." : "Atualizar"}
              </Button>
            </Grid>
          </>
        )}
      </Container>
    );
  }
}
