import { all, takeLatest } from "redux-saga/effects";

// Login
import { Types as LoginTypes } from "../ducks/login";
import { login, logout } from "./login";

// Teams
import { Types as TeamsTypes } from "../ducks/teams";
import {
  getAllTeams,
  setCurrentTeam,
  createTeam,
  showTeam,
  updateTeam,
  setLocalTeam
} from "./teams";

// Lockers
import { Types as LockerTypes } from "../ducks/locker";
import {
  getAllLockers,
  getAllLockersForSelect,
  showLocker,
  getLocationLocker,
  updateLocker,
  getLockersInfo,
  getLocation,
  createLocker,
  openLockerAllCompartments,
  getCompartmentIdentifiers,
  rebootLocker,
  getSetupLockerPasswords
} from "./locker";

// Compartments
import {
  searchCompartmentByNumber,
  openCompartment,
  getCompartments,
  showCompartment,
  updateCompartment,
  getCompartmentsInfo,
  createCompartment,
  getCompartmentsOfTeamRequest
} from "./compartment";
import { Types as CompartmentTypes } from "../ducks/compartment";

// Users
import { searchEmailUser, searchUser, updateUser, createUser } from "./users";
import { Types as UserTypes } from "../ducks/users";

// Business
import {
  getBusiness,
  showBusiness,
  updateBusiness,
  createBusiness,
  getMe,
  updateProfile
} from "./business";
import { Types as BusinessTypes } from "../ducks/business";

// Order
import { getOrders, showOrder } from "./orders";
import { Types as OrderTypes } from "../ducks/orders";

// Delivery
import {
  getDeliveries,
  showDelivery,
  createDelivery,
  searchDelivery,
  updateDelivery,
  openDeliveryLocker,
  sendDeliveryEmailCodominiumRequest,
  cancelDeliveryRequest
} from "./delivery";
import { Types as DeliveryTypes } from "../ducks/delivery";

// Reports
import {
  getReportsGeneral,
  getReports,
  getDaysReport,
  reportsRentalRequest
} from "./reports";
import { subTeamReportDaily } from "./dailyReports/subTeam";
import { Types as ReportTypes } from "../ducks/reports";

// Members
import { getMembers } from "./members";
import { Types as MemberTypes } from "../ducks/members";

// Roles
import { getAllRoles, showRole, createRole, getRoles } from "./roles";
import { Types as RoleTypes } from "../ducks/roles";

// Location
import { getLocationInfo } from "./location";
import { Types as LocationTypes } from "../ducks/location";

// SubTeamUnits
import {
  getSubTeamUnits,
  createSubTeamUnits,
  showSubTeamUnits,
  updateSubTeamUnits,
  createSubTeamWizard,
  handleStatusSubTeamUnits
} from "./subTeamUnits";
import { Types as SubTeamUnitsTypes } from "../ducks/subTeamUnits";

// SubTeamSubUnits
import {
  getSubTeamSubUnits,
  createSubTeamSubUnits,
  showSubTeamSubUnits,
  updateSubTeamSubUnits,
  changeStatusSubTeamSubUnitsRequest,
  getSubTeamSubUnitsByCompartmentRequest
} from "./subTeamSubUnits";
import { Types as SubTeamSubUnitsTypes } from "../ducks/subTeamSubUnits";

// SubTeamSubUnitsContact
import {
  getSubTeamSubUnitsContact,
  createSubTeamSubUnitsContact,
  showSubTeamSubUnitsContact,
  updateSubTeamSubUnitsContact,
  deleteSubTeamSubUnitsContact,
  handleStatusSubTeamSubUnitsContact
} from "./subTeamSubUnitsContact";
import { Types as SubTeamSubUnitsContact } from "../ducks/subTeamSubUnitsContact";

// SubTeams
import {
  getSubTeams,
  showSubTeam,
  updateSubTeam,
  getSubTeamStatus,
  createSubTeam
} from "./subTeam";
import { Types as SubTeamActions } from "../ducks/subTeam";

// SurveyForms
import {
  getSurveyForms,
  createSurveyForm,
  showSurveyForms,
  updateSurveyFormStatus
} from "./surveyForm";
import { Types as SurveyFormTypes } from "../ducks/surveyForm";

// SurveyForms
import { getLockerStructureOptions } from "./lockerStructureConditionOptions";
import { Types as LockerStructureTypes } from "../ducks/lockerStructureConditionOptions";

// SurveyForms
import { getPaintConditionOptions } from "./paintConditionOptions";
import { Types as PaintConditionTypes } from "../ducks/paintConditionOptions";

// Customer Feedbacks
import { getCustomerFeedbacks } from "./customerFeedback";
import { Types as CustomerFeedbackTypes } from "../ducks/customerFeedback";

// Partners <> Nepos
import { getNeposTickets } from "./partners/nepos/tickets";
import { Types as PartnersNeposTickets } from "../ducks/partners/nepos/tickets";
// Notification
import {
  createNotification,
  getNotification,
  updateNotification
} from "./notification";
import { Types as NotificationAction } from "../ducks/notification";

// CustomerLogsAbbreviation
import { customerLogAbbreviationRequest } from "./customerLogAbbreviation";
import { Types as CustomerLogAbbreviationTypes } from "../ducks/customerLogAbbreviation";

// CustomerLogs
import { customerLogsRequest } from "./customerLogs";
import { Types as CustomerLogTypes } from "../ducks/customerLogs";

// DeliveryEmails
import { getDeliveryEmails } from "./deliveryEmails";
import { Types as DeliveryEmails } from "../ducks/deliveryEmails";

// Groups
import {
  groupsRequest,
  showGroupRequest,
  updateThingGroupRequest
} from "./groups";
import { Types as GroupTypes } from "../ducks/groups";

// Things
import {
  thingsRequest,
  thingsCreateRequest,
  thingsShowRequest,
  thingsUpdateRequest
} from "./things";
import { Types as ThingTypes } from "../ducks/things";

// Things Types
import {
  createThingTypeRequest,
  showThingTypeRequest,
  thingTypesRequest,
  updateThingTypeRequest
} from "./thingTypes";
import { Types as ThingTypesT } from "../ducks/thingTypes";

// External Profile
import {
  externalProfileRequest,
  externalProfileShowRequest,
  externalProfileUpdateRequest
} from "./externalProfile";
import { Types as ExternalProfileTypes } from "../ducks/externalProfile";

import {
  azureActiveDirectoryRequest,
  createAzureActiveDirectoryRequest,
  updateAzureActiveDirectoryRequest
} from "./azureActiveDirectory";
import { Types as AzureActiveDirectoryActions } from "../ducks/azureActiveDirectory";

import { getDeliveryUpdateInfoSubTypeRequest } from "./deliveryUpdateInfoSubType";
import { Types as DeliveryUpdateInfoSubTypes } from "../ducks/deliveryUpdateInfoSubType";

// User Credit Tickets
import { searchUserCreditTicketRequest } from "./userCreditTicket";
import { Types as UserCreditTicketTypes } from "../ducks/userCreditTicket";

//Rental
import {
  getRentalRequest,
  showRentalUserOrderRequest,
  updateRentalRequest
} from "./rental";
import { Types as RentalTypes } from "../ducks/rental";

// Laundry
import {
  createLaundryLockerItemOptionsRequest,
  getLaundryLockerItemOptionsRequest,
  getLaundryUsersRequest,
  showLaundryLockerItemOptionsRequest,
  updateLaundryUsersRequest
} from "./laundry";
import { Types as LaundryTypes } from "../ducks/laundry";

// Integration
import {
  getIntegrationDataPartnerRequest,
  getIntegrationDataTeamRequest
} from "./integrations";
import { Types as IntegrationTypes } from "../ducks/integrations";

export default function* rootSaga() {
  yield all([
    // login
    takeLatest(LoginTypes.loginRequest, login),
    takeLatest(LoginTypes.logout, logout),

    // teams
    takeLatest(TeamsTypes.teamsRequest, getAllTeams),
    takeLatest(TeamsTypes.setCurrentTeamRequest, setCurrentTeam),
    takeLatest(TeamsTypes.createTeamRequest, createTeam),
    takeLatest(TeamsTypes.showTeamRequest, showTeam),
    takeLatest(TeamsTypes.updateTeamRequest, updateTeam),
    takeLatest(TeamsTypes.setLocalTeam, setLocalTeam),

    // compartment
    takeLatest(
      CompartmentTypes.compartmentSearchRequest,
      searchCompartmentByNumber
    ),
    takeLatest(CompartmentTypes.openCompartmentRequest, openCompartment),
    takeLatest(CompartmentTypes.getCompartmentsRequest, getCompartments),
    takeLatest(
      CompartmentTypes.getCompartmentsOfTeamRequest,
      getCompartmentsOfTeamRequest
    ),
    takeLatest(CompartmentTypes.showCompartmentRequest, showCompartment),
    takeLatest(CompartmentTypes.updateCompartmentRequest, updateCompartment),
    takeLatest(
      CompartmentTypes.getCompartmentsInfoRequest,
      getCompartmentsInfo
    ),
    takeLatest(CompartmentTypes.createCompartmentRequest, createCompartment),

    // locker
    takeLatest(LockerTypes.lockersRequest, getAllLockers),
    takeLatest(LockerTypes.lockersSelectRequest, getAllLockersForSelect),
    takeLatest(LockerTypes.showLockerRequest, showLocker),
    takeLatest(LockerTypes.getLocationLockerRequest, getLocationLocker),
    takeLatest(LockerTypes.updateLockerRequest, updateLocker),
    takeLatest(LockerTypes.getInfoLockersRequest, getLockersInfo),
    takeLatest(LockerTypes.getLocationRequest, getLocation),
    takeLatest(LockerTypes.createLockerRequest, createLocker),
    takeLatest(
      LockerTypes.openLockerAllCompartmentsRequest,
      openLockerAllCompartments
    ),
    takeLatest(
      LockerTypes.getCompartmentIdentifiersRequest,
      getCompartmentIdentifiers
    ),
    takeLatest(LockerTypes.rebootLockerRequest, rebootLocker),
    takeLatest(
      LockerTypes.getSetupLockerPasswordsRequest,
      getSetupLockerPasswords
    ),

    // user
    takeLatest(UserTypes.searchEmailRequest, searchEmailUser),
    takeLatest(UserTypes.searchUserRequest, searchUser),
    takeLatest(UserTypes.updateUserRequest, updateUser),
    takeLatest(UserTypes.createUserRequest, createUser),

    // business
    takeLatest(BusinessTypes.getBusinessRequest, getBusiness),
    takeLatest(BusinessTypes.showBusinessRequest, showBusiness),
    takeLatest(BusinessTypes.updateBusinessRequest, updateBusiness),
    takeLatest(BusinessTypes.createBusinessRequest, createBusiness),
    takeLatest(BusinessTypes.businessMeRequest, getMe),
    takeLatest(BusinessTypes.updateProfileRequest, updateProfile),

    // orders
    takeLatest(OrderTypes.getActiveOrdersRequest, getOrders),
    takeLatest(OrderTypes.showOrderRequest, showOrder),

    // delivery
    takeLatest(DeliveryTypes.getDeliveriesRequest, getDeliveries),
    takeLatest(DeliveryTypes.showDeliveryRequest, showDelivery),
    takeLatest(DeliveryTypes.createDeliveryRequest, createDelivery),
    takeLatest(DeliveryTypes.searchDeliveryRequest, searchDelivery),
    takeLatest(DeliveryTypes.updateDeliveryRequest, updateDelivery),
    takeLatest(DeliveryTypes.openDeliveryLockerRequest, openDeliveryLocker),
    takeLatest(
      DeliveryTypes.sendDeliveryEmailCodominiumRequest,
      sendDeliveryEmailCodominiumRequest
    ),
    takeLatest(DeliveryTypes.cancelDeliveryRequest, cancelDeliveryRequest),

    // reports
    takeLatest(ReportTypes.reportsGeneralRequest, getReportsGeneral),
    takeLatest(ReportTypes.reportsRequest, getReports),
    takeLatest(ReportTypes.reportsGeneralDaysRequest, getDaysReport),
    takeLatest(ReportTypes.subTeamReportsDailyRequest, subTeamReportDaily),
    takeLatest(ReportTypes.reportsRentalRequest, reportsRentalRequest),

    // members
    takeLatest(MemberTypes.getMembersRequest, getMembers),

    // roles
    takeLatest(RoleTypes.getAllRolesRequest, getAllRoles),
    takeLatest(RoleTypes.showRoleRequest, showRole),
    takeLatest(RoleTypes.createRoleRequest, createRole),
    takeLatest(RoleTypes.getRolesRequest, getRoles),

    // Location
    takeLatest(LocationTypes.getLocationRequest, getLocationInfo),

    // Subteams
    takeLatest(SubTeamActions.getSubTeamsRequest, getSubTeams),
    takeLatest(SubTeamActions.showSubTeamRequest, showSubTeam),
    takeLatest(SubTeamActions.updateSubTeamRequest, updateSubTeam),
    takeLatest(SubTeamActions.getSubTeamStatusRequest, getSubTeamStatus),
    takeLatest(SubTeamActions.createSubTeamRequest, createSubTeam),

    // SubTeamUnits
    takeLatest(SubTeamUnitsTypes.getSubTeamUnitsRequest, getSubTeamUnits),
    takeLatest(SubTeamUnitsTypes.createSubTeamUnitsRequest, createSubTeamUnits),
    takeLatest(
      SubTeamUnitsTypes.createSubTeamWizardRequest,
      createSubTeamWizard
    ),
    takeLatest(SubTeamUnitsTypes.showSubTeamUnitsRequest, showSubTeamUnits),
    takeLatest(SubTeamUnitsTypes.updateSubTeamUnitsRequest, updateSubTeamUnits),
    takeLatest(
      SubTeamUnitsTypes.changeStatusSubTeamUnitsRequest,
      handleStatusSubTeamUnits
    ),

    // SubTeamSubUnits
    takeLatest(
      SubTeamSubUnitsTypes.getSubTeamSubUnitsRequest,
      getSubTeamSubUnits
    ),
    takeLatest(
      SubTeamSubUnitsTypes.createSubTeamSubUnitsRequest,
      createSubTeamSubUnits
    ),
    takeLatest(
      SubTeamSubUnitsTypes.showSubTeamSubUnitsRequest,
      showSubTeamSubUnits
    ),
    takeLatest(
      SubTeamSubUnitsTypes.updateSubTeamSubUnitsRequest,
      updateSubTeamSubUnits
    ),
    takeLatest(
      SubTeamSubUnitsTypes.changeStatusSubTeamSubUnitsRequest,
      changeStatusSubTeamSubUnitsRequest
    ),
    takeLatest(
      SubTeamSubUnitsTypes.getSubTeamSubUnitsByCompartmentRequest,
      getSubTeamSubUnitsByCompartmentRequest
    ),

    // SubTeamSubUnitsContact
    takeLatest(
      SubTeamSubUnitsContact.getSubTeamSubUnitsContactRequest,
      getSubTeamSubUnitsContact
    ),
    takeLatest(
      SubTeamSubUnitsContact.createSubTeamSubUnitsContactRequest,
      createSubTeamSubUnitsContact
    ),
    takeLatest(
      SubTeamSubUnitsContact.showSubTeamSubUnitsContactRequest,
      showSubTeamSubUnitsContact
    ),
    takeLatest(
      SubTeamSubUnitsContact.updateSubTeamSubUnitsContactRequest,
      updateSubTeamSubUnitsContact
    ),
    takeLatest(
      SubTeamSubUnitsContact.deleteSubTeamSubUnitsContactRequest,
      deleteSubTeamSubUnitsContact
    ),
    takeLatest(
      SubTeamSubUnitsContact.changeStatusSubTeamSubUnitsContactRequest,
      handleStatusSubTeamSubUnitsContact
    ),

    // SurveyForm
    takeLatest(SurveyFormTypes.surveyFormRequest, getSurveyForms),
    takeLatest(SurveyFormTypes.createSurveyFormRequest, createSurveyForm),
    takeLatest(SurveyFormTypes.showSurveyFormRequest, showSurveyForms),
    takeLatest(
      SurveyFormTypes.updateSurveyFormStatusRequest,
      updateSurveyFormStatus
    ),

    // Locker Structure Options
    takeLatest(
      LockerStructureTypes.getLockerStructureConditionOptionsRequest,
      getLockerStructureOptions
    ),

    // Paint Structure Options
    takeLatest(
      PaintConditionTypes.getPaintConditionOptionsRequest,
      getPaintConditionOptions
    ),

    // Customer Feedbacks
    takeLatest(
      CustomerFeedbackTypes.customerFeedbackRequest,
      getCustomerFeedbacks
    ),

    takeLatest(
      PartnersNeposTickets.partnersNeposTicketsRequest,
      getNeposTickets
    ),
    // Notification
    takeLatest(NotificationAction.getNotificationRequest, getNotification),
    takeLatest(
      NotificationAction.updateNotificationRequest,
      updateNotification
    ),
    takeLatest(
      NotificationAction.createNotificationRequest,
      createNotification
    ),

    // CustomerLogAbbreviation
    takeLatest(
      CustomerLogAbbreviationTypes.customerLogAbbreviationRequest,
      customerLogAbbreviationRequest
    ),

    // CustomerLogs
    takeLatest(CustomerLogTypes.customerLogsRequest, customerLogsRequest),

    // DeliveryEmails
    takeLatest(DeliveryEmails.getDeliveryEmailsRequest, getDeliveryEmails),

    // Groups
    takeLatest(GroupTypes.groupsRequest, groupsRequest),
    takeLatest(GroupTypes.showGroupRequest, showGroupRequest),
    takeLatest(GroupTypes.updateThingGroupRequest, updateThingGroupRequest),

    // Things
    takeLatest(ThingTypes.thingsRequest, thingsRequest),
    takeLatest(ThingTypes.thingsCreateRequest, thingsCreateRequest),
    takeLatest(ThingTypes.thingsShowRequest, thingsShowRequest),
    takeLatest(ThingTypes.thingsUpdateRequest, thingsUpdateRequest),

    // Thing Types
    takeLatest(ThingTypesT.thingTypesRequest, thingTypesRequest),
    takeLatest(ThingTypesT.createThingTypeRequest, createThingTypeRequest),
    takeLatest(ThingTypesT.showThingTypeRequest, showThingTypeRequest),
    takeLatest(ThingTypesT.updateThingTypeRequest, updateThingTypeRequest),

    // External Profile
    takeLatest(
      ExternalProfileTypes.externalProfileRequest,
      externalProfileRequest
    ),
    takeLatest(
      ExternalProfileTypes.externalProfileShowRequest,
      externalProfileShowRequest
    ),
    takeLatest(
      ExternalProfileTypes.externalProfileUpdateRequest,
      externalProfileUpdateRequest
    ),

    // Integrations
    takeLatest(
      AzureActiveDirectoryActions.azureActiveDirectoryRequest,
      azureActiveDirectoryRequest
    ),
    takeLatest(
      AzureActiveDirectoryActions.createAzureActiveDirectoryRequest,
      createAzureActiveDirectoryRequest
    ),
    takeLatest(
      AzureActiveDirectoryActions.updateAzureActiveDirectoryRequest,
      updateAzureActiveDirectoryRequest
    ),

    // Delivery Update Info Sub Types
    takeLatest(
      DeliveryUpdateInfoSubTypes.deliveryUpdateInfoSubTypeRequest,
      getDeliveryUpdateInfoSubTypeRequest
    ),

    // User Credit Tickets
    takeLatest(
      UserCreditTicketTypes.searchUserCreditTicketRequest,
      searchUserCreditTicketRequest
    ),

    // Rental
    takeLatest(RentalTypes.getRentalRequest, getRentalRequest),
    takeLatest(RentalTypes.showRentalRequest, showRentalUserOrderRequest),
    takeLatest(RentalTypes.updateRentalRequest, updateRentalRequest),

    // Laundry
    takeLatest(
      LaundryTypes.getLaundryLockerItemOptionsRequest,
      getLaundryLockerItemOptionsRequest
    ),
    takeLatest(
      LaundryTypes.showLaundryLockerItemOptionsRequest,
      showLaundryLockerItemOptionsRequest
    ),
    takeLatest(
      LaundryTypes.createLaundryLockerItemOptionsRequest,
      createLaundryLockerItemOptionsRequest
    ),
    takeLatest(LaundryTypes.getLaundryUsersRequest, getLaundryUsersRequest),
    takeLatest(
      LaundryTypes.updateLaundryUsersRequest,
      updateLaundryUsersRequest
    ),

    // Integration
    takeLatest(
      IntegrationTypes.getIntegrationDataPartnerRequest,
      getIntegrationDataPartnerRequest
    ),
    takeLatest(
      IntegrationTypes.getIntegrationDataTeamRequest,
      getIntegrationDataTeamRequest
    )
  ]);
}
