import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import { useTranslation } from "react-i18next";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const useStyles2 = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  }
}));

function ReportTeamTable({ dataContext }) {
  const { t } = useTranslation('team')
  const classes = useStyles2();
  const data = useContext(dataContext);

  return (
    <Paper className={classes.root} id="table-locker-sm">
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead className="tableheader">
            <TableRow align="center">
              <TableCell
                className="tablecell-header th-delivery"
                align="center"
              >
                {t('common:client')}
              </TableCell>
              <TableCell
                className="tablecell-header th-delivery"
                align="center"
              >
                {t('deliveries')}
              </TableCell>
              <TableCell
                className="tablecell-header th-delivery"
                align="center"
              >
                {t('doorOpen')}
              </TableCell>
              <TableCell
                className="tablecell-header tablecell-header__initial-table"
                align="center"
              >
                EMAILS
              </TableCell>
              <TableCell
                className="tablecell-header tablecell-header__team-status-lockertable"
                align="center"
              >
                SMS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map(row => (
                <TableRow key={row.id} className={classes.tableContent}>
                  <TableCell className="tablecell  td-delivery" align="center">
                    {row.name}
                  </TableCell>
                  <TableCell className="tablecell  td-delivery" align="center">
                    {row.countDeliveries}
                  </TableCell>
                  <TableCell className="tablecell  td-delivery" align="center">
                    {row.countCompartmentOpening}
                  </TableCell>
                  <TableCell className="tablecell  td-delivery" align="center">
                    {row.countDeliverieEmails}
                  </TableCell>
                  <TableCell className="tablecell  td-delivery" align="center">
                    {row.countDeliverieSMS}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}

export default ReportTeamTable;
