import { CircularProgress, TextField, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { materialStyle } from "../../styles";
import { constants } from "../../config";

const styles = makeStyles(theme => materialStyle(theme));

function CustomAutoComplete({
  options,
  value,
  disabled,
  onChange,
  loading,
  getOptionLabelKey,
  label
}) {
  const classes = styles();
  return (
    <Autocomplete
      style={{
        alignItems: "center",
        justifyContent: "center"
      }}
      options={[constants.itemOptionAll(), ...options]}
      getOptionLabel={option => String(option[getOptionLabelKey])}
      value={value || constants.itemOptionAll()}
      onChange={(event, newValue) => {
        onChange(newValue || constants.itemOptionAll());
      }}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.textField1}
          label={label}
          variant="outlined"
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}

CustomAutoComplete.defaultProps = {
  options: [],
  value: "",
  disabled: false,
  onChange: () => {},
  loading: false,
  getOptionLabelKey: "id",
  label: ""
};

export default CustomAutoComplete;
