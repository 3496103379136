import React, { Component } from "react";
import queryString from "query-string";

// Global Components
import Container from "../../components/Container";
import OrdersPaginationTable from "../../components/OrdersPaginationTable";
import Spinner from "../../components/Spinner";

// Material
import {
  Button,
  TextField,
  MenuItem,
  Grid,
  CircularProgress
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as CompartmentActions } from "../../store/ducks/compartment";
import { Actions as LockerActions } from "../../store/ducks/locker";
import { Actions as UserActions } from "../../store/ducks/users";
import { Actions as OrderActions } from "../../store/ducks/orders";

// Global style
import { materialStyle } from "../../styles";

import ButtonClick from "../../components/ButtonClick";
import CustomAutoComplete from "../../components/CustomAutoComplete";

class Order extends Component {
  state = {
    email: "",
    date: "",
    idLocker: "",
    idCompartment: "",
    finishedAt: "",
    isFinished: 1,
    createdAt: null
  };

  componentWillMount() {
    const { lockersRequest, getActiveOrdersRequest } = this.props;
    const { isFinished } = this.state;

    lockersRequest(`perPage=1000`);
    getActiveOrdersRequest(`isFinished=${isFinished}`);
  }

  handleChange = key => event => {
    this.setState({ [key]: event.target.value });
  };

  handleChangeDate = createdAt => {
    this.setState({ createdAt });
  };

  handleSearch = () => {
    const { getActiveOrdersRequest } = this.props;
    const { email, idLocker, isFinished } = this.state;

    const data = {
      isFinished
    };

    if (email) {
      Object.assign(data, {
        email
      });
    }

    if (idLocker) {
      Object.assign(data, {
        idLocker
      });
    }

    getActiveOrdersRequest(queryString.stringify(data));
  };

  clearState = () => {
    this.setState({
      email: "",
      createdAt: "",
      idLocker: "",
      isFinished: 1
    });

    const { getActiveOrdersRequest } = this.props;

    getActiveOrdersRequest(`isFinished=${this.state.isFinished}`);
  };

  render() {
    const { classes, history, orders } = this.props;
    const { email, isFinished } = this.state;

    return (
      <Container title="Locações">
        {orders.loading ? (
          <Spinner />
        ) : (
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="outlined-name"
                    label="email"
                    className={classes.textField}
                    value={email}
                    onChange={this.handleChange("email")}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomAutoComplete
                    options={
                      this.props.locker.data.length
                        ? this.props.locker.data
                        : []
                    }
                    value={this.props.locker.data.find(
                      item => item.id === this.state.idLocker
                    )}
                    onChange={newValue => {
                      this.setState({
                        idLocker: newValue.id
                      });
                    }}
                    disabled={
                      this.props.locker.loading ||
                      !this.props.locker.data.length
                    }
                    loading={this.props.locker.loading}
                    getOptionLabelKey="address"
                    label="Locker"
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    width={200}
                    id="custom-css-outlined-input"
                    select
                    label="Status"
                    className={classes.textFieldLocker}
                    value={isFinished}
                    onChange={this.handleChange("isFinished")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <MenuItem key={1} value={1}>
                      Ativas
                    </MenuItem>
                    <MenuItem key={0} value={0}>
                      Todas
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>

            <ButtonClick onClick={this.handleSearch}>Buscar</ButtonClick>
            <ButtonClick onClick={this.clearState}>Limpar</ButtonClick>

            {orders.loading ? (
              <CircularProgress />
            ) : (
              <OrdersPaginationTable history={history} />
            )}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  locker: state.locker,
  compartment: state.compartment,
  orders: state.orders
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CompartmentActions,
      ...LockerActions,
      ...UserActions,
      ...OrderActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(Order));
