import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

// Material
import { Button, TextField, CircularProgress, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Global Components
import Container from "../../components/Container";
import Snackbar from "../../components/Snackbar";
import { materialStyle } from "../../styles";
import TextMaskCustomCNPJ from "../../components/TextMaskCustomCNPJ";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LaundryActions } from "../../store/ducks/laundry";
import LaundryLockerItemOptionTable from "../../components/LaundryLockerItemOptionTable";

class LaundryLockerItemOption extends Component {
  static propTypes = {
    getLaundryLockerItemOptionsRequest: PropTypes.func.isRequired
  };

  state = {};

  componentWillMount() {
    this.handleInitSearch();
  }

  handleInitSearch = () => {
    const { getLaundryLockerItemOptionsRequest } = this.props;

    getLaundryLockerItemOptionsRequest();
  };

  handleChange = name => event => {
    let value = event.target.value;

    this.setState({ [name]: value });
  };

  handleSearch = () => {
    const { getLaundryLockerItemOptionsRequest } = this.props;
    const { name, cnpj } = this.state;

    let query = queryString.stringify({
      name,
      cnpj
    });

    getLaundryLockerItemOptionsRequest(`perPage=1000&${query}`);
  };

  render() {
    const { classes, history, laundry, setSnackbarVisible } = this.props;

    return (
      <Container title="condomínio">
        {laundry.loading ? (
          <CircularProgress />
        ) : (
          <>
            <Snackbar
              open={laundry.snackbarVisible}
              variant="warning"
              onClose={setSnackbarVisible}
              message="Usuário não encontrado"
            />

            <Grid container xs={12} spacing={2} alignItems="center">
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  id="outlined-name"
                  label="Nome"
                  className={classes.textField}
                  value={this.state.name}
                  onChange={this.handleChange("name")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  id="outlined-name"
                  label="cnpj"
                  className={classes.textField}
                  value={this.state.cnpj}
                  onChange={this.handleChange("cnpj")}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskCustomCNPJ
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} className="form-colaboradore-sm">
                <Button
                  className={classes.button}
                  onClick={this.handleSearch}
                  variant="contained"
                  style={{
                    backgroundColor: "#006660",
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "lowercase",
                    height: 35
                  }}
                  disabled={
                    laundry.loading || (!this.state.name && !this.state.cnpj)
                  }
                >
                  {laundry.loading ? <CircularProgress /> : "Pesquisar"}
                </Button>
                <Button
                  className={classes.button}
                  onClick={this.handleInitSearch}
                  variant="contained"
                  disabled={!this.state.name && !this.state.cnpj}
                  style={{
                    backgroundColor: "#006660",
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "lowercase",
                    height: 35
                  }}
                >
                  Limpar pesquisa
                </Button>
                <Button
                  className={classes.button}
                  onClick={() => history.push("/laundry/create/locker/item")}
                  variant="contained"
                  style={{
                    backgroundColor: "#006660",
                    fontSize: 14,
                    color: "#fff",
                    textTransform: "lowercase",
                    height: 35
                  }}
                >
                  Criar novo item
                </Button>
              </Grid>
            </Grid>
            <LaundryLockerItemOptionTable history={history} />
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  laundry: state.laundry
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(LaundryActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(LaundryLockerItemOption));
