import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { Actions as subTeamUnitsActions } from "../ducks/subTeamUnits";
import { Actions as ConfigsActions } from "../ducks/configs";
import constants from "../../config/constants";
import history from "../../routes/history";

export function* getSubTeamUnits(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/subTeamUnits?${action.payload.data}`
    );

    yield put(subTeamUnitsActions.getSubTeamUnitsSuccess(response.data));
  } catch (err) {
    yield put(subTeamUnitsActions.getSubTeamUnitsError());
  }
}

export function* createSubTeamUnits(action) {
  try {
    const response = yield call(api.post, "/v1/adm/subTeamUnits", {
      data: action.payload
    });

    yield put(subTeamUnitsActions.createSubTeamUnitsSuccess(response.data));

    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Bloco criado com sucesso!",
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );

    // se redirectToShow=True recarrega pagina
    if (action.redirectToShow) {
      const refreshdata = yield call(
        api.get,
        `/v1/adm/subTeamUnits?idSubTeam=${action.payload[0].idSubTeam}`
      );

      yield put(subTeamUnitsActions.getSubTeamUnitsSuccess(refreshdata.data));
    } else {
      // redireciona para editar o unico criado
      history.push(`/subTeam/condominium/edit/${action.payload[0].idSubTeam}`);
    }

    yield put(subTeamUnitsActions.setDialogCreateVisible(false));
  } catch (err) {
    yield put(subTeamUnitsActions.createSubTeamUnitsError());
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* showSubTeamUnits(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/subTeamUnits/${action.payload.id}`
    );

    yield put(subTeamUnitsActions.showSubTeamUnitsSuccess(response.data));
  } catch (err) {
    yield put(subTeamUnitsActions.showSubTeamUnitsError());
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* updateSubTeamUnits(action) {
  try {
    const { id, name, idSubTeam } = action.payload;
    const idTeam = JSON.parse(localStorage.getItem("auth")).currentTeam.id;

    const response = yield call(api.put, `/v1/adm/subTeamUnits/${id}`, {
      name,
      idSubTeam,
      idTeam
    });

    yield put(subTeamUnitsActions.updateSubTeamUnitsSucess(response.data));
    yield put(subTeamUnitsActions.showSubTeamUnitsRequest(id));
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Atualizado!",
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );
  } catch (err) {
    yield put(subTeamUnitsActions.updateSubTeamUnitsError());
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* createSubTeamWizard(action) {
  try {
    const data = action.payload;

    yield call(api.post, `/v1/adm/subTeams/data/wizard`, {
      data
    });

    yield put(subTeamUnitsActions.createSubTeamWizardSuccess({}));
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Dados criados com sucesso!",
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );

    history.push("/subTeam/condominium");
  } catch (err) {
    yield put(subTeamUnitsActions.createSubTeamWizardError());
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* handleStatusSubTeamUnits(action) {
  try {
    const { status, id } = action.payload;

    yield call(api.put, `/v1/adm/subTeamUnits/${id}/status`, {
      status
    });

    yield put(subTeamUnitsActions.changeStatusSubTeamUnitsSuccess(status));
  } catch (err) {
    yield put(subTeamUnitsActions.changeStatusSubTeamUnitsError(err));
  }
}
