import React from "react";

import { Image, Container } from "./styles";

export default function ImageContainer({ src, isPhone, shaddow }) {
  return (
    <Container>
      <Image src={src} isPhone={isPhone} shaddow={shaddow} />
    </Container>
  );
}

ImageContainer.defaultProps = {
  src: "",
  isPhone: false,
  shaddow: false
};
