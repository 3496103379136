import React, { useState, useCallback, useEffect } from "react";

import { Alert, AlertTitle } from "@material-ui/lab";

import api from "../../services/api";

// Global
import Container from "../../components/Container";
import ImportExcelFileButton from "../../components/ImportExcelFileButton";

// Material
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core/styles";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import Spinner from "../../components/Spinner";
import EmailsInternalWizardTable from "../../components/EmailsInternalWizardTable";
import StepperEmailsInternal from "./components/Stepper";
import MultiEmailInput from "../../components/MultiEmailInput";
import Snackbar from "../../components/Snackbar";
import { materialStyle } from "../../styles";
import constants from "../../config/constants";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { getDate } from "../../functions/formatDate";

function SendEmailsInternal({ classes }) {
  const [dataExcel, setDataExcel] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idTemplate, setIdTemplate] = useState();
  const [email, setEmail] = useState("");
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState(
    constants.toastVariants.success
  );
  const [activeStep, setActiveStep] = useState(0);
  const [templateEmail, setTemplateEmail] = useState();
  const [params, setParams] = useState({});
  const [_currentSubTeamUnits, setCurrentSubTeamUnits] = useState();

  const [dataEmails, setDataEmails] = useState([]);

  const isEmpty = obj => {
    for (var key in obj) {
      if (!obj[key]) return false;
    }
    return true;
  };

  const handleInputEmails = value => {
    setEmail(value);
    const _emails = value.split(",").map(item => {
      let custom = {};

      custom.id = Math.random();

      custom.name = "Cliente";
      custom.email = String(item).trim();

      Object.assign(custom, params);

      return custom;
    });
    setDataEmails(_emails);
  };

  const checkNext = useCallback(
    () => {
      if (!idTemplate) {
        setToastVisible(true);
        setToastMessage("Selecione o modelo do template");
        setToastVariant(constants.toastVariants.warning);
        return;
      }

      if (
        activeStep === 1 &&
        !isEmpty(params) &&
        setCurrentSubTeamUnits.status === "0"
      ) {
        setToastVisible(true);
        setToastMessage("Informe todos os parametros do email");
        setToastVariant(constants.toastVariants.warning);
        return;
      }

      if (activeStep === 1 && !dataExcel.length && !email.length) {
        setToastVisible(true);
        setToastMessage("Informe a lista de emails");
        setToastVariant(constants.toastVariants.warning);
        return false;
      }

      return true;
    },
    [dataExcel, data, idTemplate, activeStep, email, params]
  );

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/v1/adm/master/templates/emails`);

      setData(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  function importFile(_data) {
    try {
      setDataExcel(
        _data.map(item => {
          let custom = {};

          custom.id = Math.random();

          custom.name = String(item[0]).trim();
          custom.email = String(item[1]).trim();

          if (templateEmail.parameters) {
            for (
              let i = 0;
              i < JSON.parse(templateEmail.parameters).length;
              i++
            ) {
              Object.assign(custom, {
                [`param${i}`]: item[i + 2]
              });
            }
          }

          console.log(custom);

          return custom;
        })
      );
    } catch (err) {
      console.log(err);
    }
  }

  const sendEmails = useCallback(
    async () => {
      try {
        setLoading(true);

        const customParams = {};
        // Object.assign(customParams, {
        //   [JSON.parse(templateEmail.parameters).find(
        //     item => item.type === "date"
        //   ).key]: getDate(
        //     params[
        //       JSON.parse(templateEmail.parameters).find(
        //         item => item.type === "date"
        //       ).key
        //     ]
        //   )
        // });

        const body = {
          data:
            _currentSubTeamUnits.status === "1"
              ? dataExcel
              : dataEmails.map(item => {
                  Object.assign(item, customParams);
                  return item;
                }),
          idTemplate
        };

        await api.post("/v1/adm/master/templates/emails/send", body);

        setToastVisible(true);
        setToastMessage("Emails enviados");
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setToastVariant(constants.toastVariants.success);
      } catch (err) {
        console.log(err);
        setToastVisible(true);
        setToastMessage("Erro ao enviar emails");
        setToastVariant(constants.toastVariants.error);
      } finally {
        setLoading(false);
      }
    },
    [
      params,
      dataExcel,
      data,
      idTemplate,
      toastMessage,
      activeStep,
      toastVariant,
      dataEmails,
      _currentSubTeamUnits
    ]
  );

  const setupInitialParams = _template => {
    const parameters = _template.parameters
      ? JSON.parse(_template.parameters)
      : [];
    const keyParameters = {};
    parameters.map(item => {
      Object.assign(keyParameters, {
        [item.key]: ""
      });
      return item;
    });
    setParams(keyParameters);
  };

  const handleChange = event => {
    const _template = data.find(item => item.id === event.target.value);

    setIdTemplate(event.target.value);
    setTemplateEmail(_template);
    setupInitialParams(_template);
  };

  const resetState = () => {
    setDataExcel([]);
    setLoading(false);
    setIdTemplate();
    setEmail("");
    setActiveStep(0);
    setTemplateEmail();
    setParams({});
    setCurrentSubTeamUnits();
    setDataEmails([]);
  };

  const renderTitleHelper = text => (
    <h4 style={{ textAlign: "left", padding: "0", margin: "10px" }}>{text}</h4>
  );

  const renderParameters = ({ disabled = false }) => {
    return (
      <>
        {templateEmail && templateEmail.parameters
          ? renderTitleHelper("Parâmetros do email")
          : null}
        <Grid container spacing={2} className="first-grid">
          {templateEmail ? (
            <>
              {!templateEmail.parameters
                ? null
                : JSON.parse(templateEmail.parameters).map(param => {
                    if (param.type === "date") {
                      return (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid item xs={12} md={3}>
                            <KeyboardDatePicker
                              className={classes.textField1}
                              disableToolbar
                              format="dd/MM/yyyy"
                              margin="normal"
                              inputVariant="outlined"
                              id="date-picker-inline"
                              label={param.name}
                              value={params[param.key] || null}
                              onChange={newDate => {
                                try {
                                  setParams({
                                    ...params,
                                    [param.key]: newDate
                                  });
                                } catch (err) {
                                  console.log(err);
                                }
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                              inputProps={{
                                margin: "normal"
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              placeholder="Data inicial das entregas"
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      );
                    } else if (param.type === "hour") {
                      return (
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="custom-css-outlined-input"
                            select
                            label={param.name}
                            className={classes.textField1}
                            value={params[param.key]}
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu
                              }
                            }}
                            margin="normal"
                            variant="outlined"
                            onChange={e => {
                              try {
                                setParams({
                                  ...params,
                                  [param.key]: e.target.value
                                });
                              } catch (err) {
                                console.log(err);
                              }
                            }}
                          >
                            {["Período da manhã", "Período da tarde"].map(
                              item => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                      );
                    }
                    return (
                      <Grid key={param.key} item xs={12} md={3}>
                        <TextField
                          disabled={disabled}
                          required={true}
                          label={param.name}
                          margin="normal"
                          variant="outlined"
                          className={classes.textField1}
                          value={
                            Object.keys(params).includes(param.key)
                              ? params[param.key]
                              : ""
                          }
                          onChange={e => {
                            try {
                              setParams({
                                ...params,
                                [param.key]: e.target.value
                              });
                            } catch (err) {
                              console.log(err);
                            }
                          }}
                        />
                      </Grid>
                    );
                  })}
            </>
          ) : null}
        </Grid>
      </>
    );
  };

  const renderConfirm = () => {
    return !_currentSubTeamUnits ? null : (
      <>
        {renderTitleHelper("Confirme os dados")}
        <Grid container spacing={2} className="first-grid">
          <Grid item xs={12} md={6}>
            <TextField
              label="Modelo do template"
              className={classes.textField1}
              value={templateEmail ? templateEmail.name : "-"}
              disabled={true}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="custom-css-outlined-input"
              label="Título do email"
              className={classes.textField1}
              value={templateEmail ? templateEmail.subject : "-"}
              disabled={true}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              id="custom-css-outlined-input"
              label="Descrição"
              className={classes.textField1}
              value={templateEmail ? templateEmail.description : "-"}
              disabled={true}
              margin="normal"
              variant="outlined"
            />
          </Grid>
        </Grid>
        {_currentSubTeamUnits.status === "1" ? (
          <EmailsInternalWizardTable
            data={dataExcel}
            extraColumns={
              templateEmail && templateEmail.parameters
                ? JSON.parse(templateEmail.parameters)
                : []
            }
          />
        ) : (
          <>
            {renderParameters({
              disabled: true
            })}
            {renderMultiEmailInput({ disabled: true })}
          </>
        )}
      </>
    );
  };

  const renderIframe = url => {
    return (
      <>
        {renderTitleHelper("Preview do template (sem dados)")}
        <iframe
          title="Conteúdo Externo"
          src={url}
          width="800"
          height="600"
          frameBorder="0"
          allowFullScreen
        />
      </>
    );
  };

  const renderMultiEmailInput = ({ disabled = false }) => {
    return (
      <>
        {renderTitleHelper("Emails de destino")}
        <Grid container spacing={2} className="first-grid">
          <Grid item xs={12} md={12}>
            <MultiEmailInput
              extractEmails={e => handleInputEmails(e)}
              defaultValue={email}
              disabled={
                disabled ||
                loading ||
                (templateEmail.parameters &&
                  Object.keys(params).find(item => !params[item]))
              }
              placeholder={
                templateEmail.parameters &&
                Object.keys(params).find(item => !params[item]) &&
                "Informe os parâmetros para liberar a caixa"
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Container title={`Wizard`}>
      {!loading ? (
        <>
          <Alert severity="info" style={{ width: "100%", marginBottom: 20 }}>
            <AlertTitle>Funcionalidade:</AlertTitle>
            <p style={{ fontSize: "14px" }}>
              Esta ferramenta auxilia o envio de emails do time Handover para
              clientes. Siga abaixo os passos: 1. Insira a lista de emails 2.
              Selecione o template de email 3. Envie o email
            </p>
          </Alert>
          <Snackbar
            open={toastVisible}
            onClose={() => setToastVisible(false)}
            message={toastMessage}
            variant={toastVariant}
          />

          <StepperEmailsInternal
            loading={loading}
            checkNext={checkNext}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            resetState={() => resetState()}
            sendEmails={() => sendEmails()}
            step1={
              <>
                <Grid container spacing={2} className="first-grid">
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="custom-css-outlined-input"
                      select
                      label={idTemplate ? "Modelo do template" : "Selecione"}
                      className={classes.textField1}
                      value={idTemplate}
                      onChange={handleChange}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                    >
                      {/* <MenuItem key={"all"} value={"all"}>
                        Todos
                      </MenuItem> */}
                      {data.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="custom-css-outlined-input"
                      label="Título do email"
                      className={classes.textField1}
                      value={templateEmail ? templateEmail.subject : "-"}
                      disabled={true}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="custom-css-outlined-input"
                      label="Descrição"
                      className={classes.textField1}
                      value={templateEmail ? templateEmail.description : "-"}
                      disabled={true}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {templateEmail ? renderIframe(templateEmail.url) : null}
              </>
            }
            step2={
              <>
                <Grid container spacing={2} className="first-grid">
                  <TextField
                    select
                    label={
                      _currentSubTeamUnits ? "Método de Envio" : "Selecione"
                    }
                    id="outlined-name"
                    className={classes.selectType}
                    value={_currentSubTeamUnits && _currentSubTeamUnits.status}
                    margin="normal"
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    onChange={e => {
                      setCurrentSubTeamUnits({
                        status: e.target.value
                      });
                    }}
                  >
                    {["1", "0"].map(item => (
                      <MenuItem key={item} value={item}>
                        {parseInt(item) ? "Envio em lote" : "Envio unitário"}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {_currentSubTeamUnits && _currentSubTeamUnits.status === "1" ? (
                  <>
                    <ImportExcelFileButton setData={importFile} />
                    <EmailsInternalWizardTable
                      data={dataExcel}
                      extraColumns={
                        templateEmail && templateEmail.parameters
                          ? JSON.parse(templateEmail.parameters)
                          : []
                      }
                    />
                  </>
                ) : null}
                {_currentSubTeamUnits && _currentSubTeamUnits.status === "0" ? (
                  <>
                    {renderParameters({
                      disabled: false
                    })}
                    {renderMultiEmailInput({
                      disabled: false
                    })}
                  </>
                ) : null}
              </>
            }
            step3={renderConfirm()}
          />
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              flexDirection: "column"
            }}
          >
            <Spinner />
          </div>
        </>
      )}
    </Container>
  );
}

export default withStyles(materialStyle)(SendEmailsInternal);
