import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Container from "../../../../components/Container";
import Image from "../Image";

function Tutorials() {
  return (
    <Container title="">
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography
            variant="h4"
            align="center"
            style={{ textTransform: "lowercase" }}
          >
            Criando uma entrega pelo painel de gestão
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            Veremos como acessar o aplicativo handover Drop
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            1. Ao entrarmos no app, teremos as opções de E-mail e CPF. Selecione
            a opção conforme seu cadastro.
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-app.png"
            alt="dashboard-list"
            isPhone={true}
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            2. Após selecionar a opção de login conforme acima, insira seu
            e-mail ou cpf juntamente com sua senha e clique no botão{" "}
            <em>Entrar</em>.
          </Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-email.png"
            alt="drop-email"
            isPhone={true}
            shaddow={true}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-cpf.png"
            alt="drop-cpf"
            isPhone={true}
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            3. Após acessar sua conta, será redirecionado para a home.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-home.png"
            alt="drop-cpf"
            isPhone={true}
            shaddow={true}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Tutorials;
