import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { constants } from "../../config";
import { materialStyle } from "../../styles";

const useStyles = makeStyles(materialStyle);

export default function ButtonClick({ onClick, children, ...props }) {
  const classes = useStyles(materialStyle);

  return (
    <Button
      onClick={() => onClick()}
      variant="contained"
      className={classes.button}
      style={
        props.disabled
          ? constants.buttonDefaultDisabledStyle
          : constants.buttonDefaultStyle
      }
      {...props}
    >
      {children}
    </Button>
  );
}

ButtonClick.defaultProps = {
  onClick: () => {}
};
