import React, { Component } from "react";
import PropTypes from "prop-types";

// Material
import {
  Button,
  TextField,
  CircularProgress,
  Grid,
  Typography,
  Box
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Global Components
import ThingTypesTable from "../../components/ThingTypesTable";
import Container from "../../components/Container";
import Snackbar from "../../components/Snackbar";
import TextMaskCustomCPF from "../../components/TextMaskCustomCPF";
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as ThingTypesActions } from "../../store/ducks/thingTypes";

import history from "../../routes/history";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3} className="box-tab-teams">
        {children}
      </Box>
    </Typography>
  );
}

class ThingTypes extends Component {
  static propTypes = {
    thingTypesRequest: PropTypes.func.isRequired
  };

  async componentWillMount() {
    const { thingTypesRequest } = this.props;

    thingTypesRequest();
  }

  state = {
    email: "",
    value: 0,
    cpf: "",
    idTeam: ""
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleChangeValue = (event, newValue) => {
    this.setState({ value: newValue });
  };

  clearSearchUser = () => {
    const { getBusinessRequest } = this.props;
    const { idTeam } = this.state;

    getBusinessRequest(`idTeam=${idTeam}`);
    this.setState({ email: "", cpf: "" });
  };

  render() {
    const { classes, thingTypes, setSnackbarVisible } = this.props;
    const { email, cpf } = this.state;

    return (
      <Container title="Tipos de things">
        <ThemeTextFildGreen>
          {thingTypes.loading ? (
            <CircularProgress />
          ) : (
            <>
              <TabPanel
                value={this.state.value}
                index={0}
                style={{ padding: 0, margin: 0 }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label="email"
                      className={classes.textField}
                      value={this.state.email}
                      onChange={this.handleChange("email")}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label="CPF"
                      className={classes.textField}
                      value={this.state.cpf}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        inputComponent: TextMaskCustomCPF
                      }}
                      onChange={this.handleChange("cpf")}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      className={classes.button}
                      onClick={this.handleSearchUser}
                      variant="contained"
                      disabled={!email && !cpf}
                    >
                      {thingTypes.loading ? <CircularProgress /> : "Pesquisar"}
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={this.clearSearchUser}
                      variant="contained"
                      disabled={!email && !cpf}
                    >
                      {thingTypes.loading ? (
                        <CircularProgress />
                      ) : (
                        "Limpar pesquisa"
                      )}
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={() => history.push("/thing/types/create")}
                      variant="contained"
                      style={{ background: "#4AC151", color: "#fff" }}
                    >
                      {thingTypes.loading ? (
                        <CircularProgress />
                      ) : (
                        "Criar thing type"
                      )}
                    </Button>
                  </Grid>
                </Grid>
                <ThingTypesTable history={history} />
              </TabPanel>

              <Snackbar
                open={thingTypes.snackbarVisible}
                variant="warning"
                onClose={setSnackbarVisible}
                message="Usuário não encontrado"
              />
            </>
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  thingTypes: state.thingTypes
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ThingTypesActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(ThingTypes));
