import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import SimpleActionButton from '../../../../components/SimpleActionButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions as UserActions } from '../../../../store/ducks/users';

class SimpleTable extends Component {
  static propTypes = {
  }

  setHistory = (pathname) => {
    const { history: { push } } = this.props;

    push({
      pathname,
    });
  }

  useStyles = () => makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
  }));


  render () {
    const classes = this.useStyles();
    const { searchData, loading } = this.props;

    return(
      <Paper style={{
        marginTop: 10,
        marginBottom: 20,
        minHeight: 100,
      }}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center">NOME</TableCell>
            <TableCell align="center">SOBRENOME</TableCell>
            <TableCell align="center">EMAIL</TableCell>
            <TableCell align="center">CEULAR</TableCell>
            <TableCell align="center">CPF</TableCell>
            <TableCell align="center">AÇOES</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { loading && <CircularProgress className={classes.progress} /> }
          {
            searchData && (
              <TableRow>
                <TableCell align="center">{searchData.name}</TableCell>
                <TableCell align="center">{searchData.lastName}</TableCell>
                <TableCell align="center">{searchData.email}</TableCell>
                <TableCell align="center">{searchData.mobile}</TableCell>
                <TableCell align="center">{searchData.cpf}</TableCell>
                <TableCell align="center">
                  <SimpleActionButton
                    id={searchData.id}
                    actions={[
                      () => {},
                      () => this.setHistory(`/users/edit/${searchData.id}`)
                    ]}
                    options={['Tickets', 'Editar']}
                  />
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </Paper>
    );
  }
}

const mapStateToProps = state => ({
  searchData: state.users.searchData,
  loading: state.users.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SimpleTable);
