import React, { Component } from "react";
import axios from "axios";

// Global
import Container from "../../../../components/Container";
import TextMaskCustomCEP from "../../../../components/TextMaskCustomCEP";
import Snackbar from "../../../../components/Snackbar";
import CompartmentsTable from "../../../../components/CompartmentsTable";
import LockerSetupPasswordsTable from "../../../../components/LockerSetupPasswordsTable";
import Spinner from "../../../../components/Spinner";
import EditorCurrentRegisterContainer from "../../../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../../../components/ButtonEditorCurrentRegister";
import SaveButtons from "../../../../components/SaveButtons";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerActions } from "../../../../store/ducks/locker";
import { Actions as CompartmentActions } from "../../../../store/ducks/compartment";

// Material
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// components
import Tabs from "./components/Tab";
import CameraView from "../../../../components/CameraView";
import ThemeTextFildGreen from "../../../../styles/ThemeTextFildGreen";
import AlarmTable from "../../../../components/AlarmTable";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  },
  selectType: {
    margin: theme.spacing(1),
    minWidth: 150
  },
  tabContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      padding: "0"
    }
  }
});

class LockerEdit extends Component {
  state = {
    currentLocker: null,
    errorCEP: false,
    isChanged: true,
    value: 0,
    edit: true,
    open: false
  };

  componentWillMount() {
    const {
      showLockerRequest,
      getCompartmentsRequest,
      getInfoLockersRequest,
      getSetupLockerPasswordsRequest
    } = this.props;
    const { id } = this.props.match.params;

    showLockerRequest(id);
    getCompartmentsRequest(`idLocker=${id}&perPage=1000`);
    getInfoLockersRequest();
    getSetupLockerPasswordsRequest(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lockers.currentLocker !== this.state.currentLocker) {
      this.setState({
        currentLocker: nextProps.lockers.currentLocker
      });
    }
  }

  componentWillUnmount() {
    const { closeSnackbarLockers } = this.props;
    closeSnackbarLockers();
  }

  handleChange = key => event => {
    this.setState({
      ...this.state,
      currentLocker: {
        ...this.state.currentLocker,
        [key]: event.target.value
      }
    });
  };

  getCEP = async () => {
    const { getLocationLockerRequest } = this.props;
    const {
      currentLocker: { zipCode }
    } = this.state;
    this.setState({ errorCEP: false });

    if (!zipCode) return;

    const response = await axios.get(
      `https://viacep.com.br/ws/${zipCode
        .replace(".", "")
        .replace("-", "")}/json/`
    );

    const { erro, ...data } = response.data;

    if (erro) {
      this.setState({ errorCEP: true });
      return;
    }

    getLocationLockerRequest({
      cityName: data.localidade,
      zipCode: this.state.currentLocker.zipCode,
      streetName: data.logradouro
    });
  };

  // Função verifica cada item modificado e requisita a mudança na api
  handleSave = () => {
    const {
      updateLockerRequest,
      match: {
        params: { id }
      }
    } = this.props;
    let countCheck = 0;
    let newData = {};
    const resultLocal = Object.keys(this.state.currentLocker);
    const resultProps = Object.keys(this.props.lockers.currentLocker);

    for (let i = 0; i < resultLocal.length; i++) {
      if (
        this.state.currentLocker[resultLocal[i]] !==
        this.props.lockers.currentLocker[resultProps[i]]
      ) {
        countCheck += 1;

        if (resultLocal[i] === "zipCode") {
          newData[resultLocal[i]] = this.state.currentLocker[resultLocal[i]]
            .replace(".", "")
            .replace("-", "");
        } else {
          newData[resultLocal[i]] = this.state.currentLocker[resultLocal[i]];
        }
      }
    }

    if (!countCheck) return;

    updateLockerRequest(id, newData);
  };

  renderCameraView = () => <CameraView />;

  renderEdit = () => {
    const { currentLocker, errorCEP } = this.state;
    const { classes, lockers, setEditableLockers } = this.props;

    return (
      <div className={classes.tabContainer}>
        <EditorCurrentRegisterContainer
          flexDirection="row-reverse"
          disabled={lockers.setEditable}
        >
          <ButtonEditorCurrentRegister
            onClick={() => setEditableLockers(true)}
            label="Editar"
          />
        </EditorCurrentRegisterContainer>
        <TextField
          id="outlined-name"
          label="ID"
          className={classes.textField}
          value={currentLocker.lockerNumber}
          margin="normal"
          variant="outlined"
          disabled={true}
        />
        {lockers.lockersInfo && (
          <>
            <TextField
              id="custom-css-outlined-input"
              select
              label="Disponibilidade"
              className={classes.selectType}
              value={this.state.currentLocker.idLockerAvailability}
              onChange={this.handleChange("idLockerAvailability")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              variant="outlined"
              disabled={lockers.loading || !lockers.setEditable}
            >
              {lockers.lockersInfo.availability.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.status}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="custom-css-outlined-input"
              select
              label="Status"
              className={classes.selectType}
              value={this.state.currentLocker.idLockerStatus}
              onChange={this.handleChange("idLockerStatus")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              variant="outlined"
              disabled={lockers.loading || !lockers.setEditable}
            >
              {lockers.lockersInfo.status.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              // disabled={business.loading}
              id="custom-css-outlined-input"
              select
              label="Tipo"
              className={classes.selectType}
              value={this.state.currentLocker.idLockerType}
              onChange={this.handleChange("idLockerType")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              variant="outlined"
              disabled={lockers.loading || !lockers.setEditable}
            >
              {lockers.lockersInfo.types.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </TextField>
            <h5 className={classes.textField}>Empresa Cliente</h5>
            <TextField
              id="outlined-name"
              label=""
              className={classes.textField}
              value={currentLocker.team.name}
              margin="normal"
              variant="outlined"
              disabled={true}
            />
          </>
        )}
        <h5 className={classes.textField}>Endereço</h5>
        <TextField
          error={errorCEP}
          id="outlined-name"
          label="CEP"
          className={classes.textField}
          value={currentLocker.zipCode}
          margin="normal"
          variant="outlined"
          onChange={this.handleChange("zipCode")}
          InputProps={{
            inputComponent: TextMaskCustomCEP
          }}
          disabled={lockers.loading || !lockers.setEditable}
        />
        <Button
          disabled={lockers.loading || !lockers.setEditable}
          variant="contained"
          onClick={this.getCEP}
        >
          Buscar
        </Button>
        <br />
        <TextField
          id="outlined-name"
          label="País"
          className={classes.textField}
          value={currentLocker.country.countryName}
          margin="normal"
          variant="outlined"
          disabled={true}
        />
        <TextField
          id="outlined-name"
          label="Estado"
          className={classes.textField}
          value={currentLocker.state.stateName}
          margin="normal"
          variant="outlined"
          disabled={true}
        />
        <TextField
          id="outlined-name"
          label="Cidade"
          className={classes.textField}
          value={currentLocker.city.cityName}
          margin="normal"
          variant="outlined"
          disabled={true}
        />
        <br />
        <TextField
          id="outlined-name"
          label="Endereço"
          className={classes.textField}
          value={currentLocker.address}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("address")}
        />
        <TextField
          id="outlined-name"
          label="Logradouro"
          className={classes.textField}
          value={currentLocker.streetName}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("streetName")}
        />
        <TextField
          id="outlined-name"
          label="Número"
          className={classes.textField}
          value={currentLocker.streetNumber}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("streetNumber")}
        />

        <h5 className={classes.textField}>Informações do sistema do locker</h5>
        <TextField
          id="outlined-name"
          label="URL do Locker"
          className={classes.textField}
          value={currentLocker.url}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("url")}
        />
        <TextField
          id="outlined-name"
          label="Email Locker"
          className={classes.textField}
          value={currentLocker.emailLocker}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("emailLocker")}
        />
        <TextField
          id="outlined-name"
          label="Senha do locker"
          className={classes.textField}
          value={currentLocker.pwdLocker}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("pwdLocker")}
        />
        <h5 className={classes.textField}>Informações da câmera</h5>
        <TextField
          id="outlined-name"
          label="URL da câmera"
          className={classes.textField}
          value={currentLocker.cameraUrl}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("cameraUrl")}
        />
        <TextField
          id="outlined-name"
          label="Senha câmera"
          className={classes.textField}
          value={currentLocker.pwdCamera}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("pwdCamera")}
        />
        <h5 className={classes.textField}>Informações geográficas</h5>
        <TextField
          id="outlined-name"
          label="latitude"
          className={classes.textField}
          value={currentLocker.latitude}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("latitude")}
        />
        <TextField
          id="outlined-name"
          label="longitude"
          className={classes.textField}
          value={currentLocker.longitude}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("longitude")}
        />
        <h5 className={classes.textField}>
          Informações do local de instalação do Locker
        </h5>
        <TextField
          id="outlined-name"
          label="Nome de contato"
          className={classes.textField}
          value={currentLocker.contactName}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("contactName")}
        />
        <TextField
          id="outlined-name"
          label="Telefone de contato"
          className={classes.textField}
          value={currentLocker.contactNumber}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("contactNumber")}
        />
        <TextField
          id="outlined-name"
          label="Email responsável"
          className={classes.textField}
          value={currentLocker.email}
          margin="normal"
          variant="outlined"
          disabled={lockers.loading || !lockers.setEditable}
          onChange={this.handleChange("email")}
        />

        <h5 className={classes.textField}>Data de cadastro</h5>
        <TextField
          id="outlined-name"
          label="Criado em"
          className={classes.textField}
          value={currentLocker.createdAt}
          margin="normal"
          variant="outlined"
          disabled={true}
        />
        <TextField
          id="outlined-name"
          label="Última Atualização"
          className={classes.textField}
          value={currentLocker.updatedAt}
          margin="normal"
          variant="outlined"
          disabled={true}
        />
        {lockers.setEditable && (
          <EditorCurrentRegisterContainer
            justifyContent
            disabled={lockers.loading || !lockers.setEditable || errorCEP}
          >
            <SaveButtons
              onConfirm={this.handleSave}
              onCancel={() => setEditableLockers(false)}
            />
          </EditorCurrentRegisterContainer>
        )}
      </div>
    );
  };

  renderAlarm = () => {
    const { open } = this.state;

    return (
      <>
        {/* <Grid container spacing={2}>
          <Grid item sm={6} md={3} xs={12} justify="center">
            <TextField
              id="hello world"
              select
              label="Tempo do alarme após abertura da porta"
              fullWidth
              disabled={edit}
              name="timeWithAuth"
              value={this.state.timeWithAuth}
              onChange={e => this.handleChange1(e)}
              className={classes.textField}
              SelectProps={{
                native: true
              }}
              margin="normal"
              variant="outlined"
            >
              <option key={1} value={1}>
                60 segundos
              </option>
            </TextField>
          </Grid>
          <Grid item sm={6} md={3} xs={12}>
            <TextField
              id="time-port-without-auth"
              select
              label="Abertura da porta sem autorização"
              name="timeWithoutAuth"
              fullWidth
              disabled
              value={this.state.timeWithoutAuth}
              className={classes.textField}
              SelectProps={{
                native: true
              }}
              margin="normal"
              variant="outlined"
            >
              <option key={1} value={1}>
                1 segundo
              </option>
            </TextField>
          </Grid>
          <Grid item>
            <CustomDialog
              className={classes.button}
              title="Atenção"
              buttonLabel="Desativar alarme"
              style={{
                backgroundColor: "#006660",
                color: "#fff",
                textTransform: "lowercase",
                height: 35
              }}
              cancelButtonText="Cancelar"
              confirmButtonText="Salvar"
              message={`Deseja salvar esta configuração?`}
              onConfirm={() => this.setState({ open: true })}
              disabled={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                if (edit) {
                  this.setState({ edit: false });
                } else {
                  this.setState({ edit: true, open: true });
                }
              }}
              className={classes.button}
            >
              {edit ? "Editar" : "Atualizar"}
            </Button>
          </Grid>
        </Grid> */}

        <AlarmTable id={this.props.match.params.id} />
        <Snackbar
          variant="success"
          open={open}
          onClose={() => this.setState({ open: false })}
          message="Salvo com sucesso!"
        />
      </>
    );
  };

  renderCompartments = () => {
    const { currentLocker } = this.state;

    return (
      <>
        <CompartmentsTable
          source={{
            data: currentLocker.compartments,
            total: currentLocker.compartments.length,
            rowsPerPage: currentLocker.compartments.length,
            page: 0
          }}
        />
      </>
    );
  };

  renderTabs = () => {
    const { currentLocker } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    return currentLocker ? (
      <Tabs
        view1={this.renderEdit()}
        view2={this.renderCameraView()}
        view3={this.renderCompartments()}
        view4={this.renderAlarm()}
        view5={() => <LockerSetupPasswordsTable />}
        idLocker={id}
      />
    ) : (
      <></>
    );
  };

  render() {
    const { errorCEP } = this.state;
    const { lockers, closeSnackbarLockers } = this.props;

    return (
      <Container title="Editar Locker">
        <ThemeTextFildGreen>
          {errorCEP && (
            <Snackbar
              open={errorCEP}
              onClose={() => this.setState({ errorCEP: false })}
              message="CEP não encontrado"
              variant="error"
            />
          )}
          <Snackbar
            open={lockers.snackbarVisible}
            onClose={closeSnackbarLockers}
            variant={lockers.snackbarType}
            message={lockers.snackbarMessage}
          />
          {lockers.loading ? <Spinner /> : this.renderTabs()}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  lockers: state.locker,
  compartments: state.compartment
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...LockerActions,
      ...CompartmentActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LockerEdit));
