import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Container from "../../../../components/Container";
import Image from "../Image";

function Tutorials() {
  return (
    <Container title="">
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography variant="h4" align="center" style={{textTransform: 'lowercase'}}>
            Criando uma entrega pelo painel de gestão
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            Veremos neste página, como criarmos uma entrega pelo{" "}
            <em>Painel de gestão</em>, que reservará uma porta. Quem tem acesso
            a este recurso é somente os colaboradores que possuem permissão para
            acesso a este painel.Esta mesma entrega também pode ser criada pelo
            app <em>handover Drop</em> para quem tem o perfil{" "}
            <em>Conta para depoósito</em>
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            1. Para editar uma entrega, devemos acessar o menu{" "}
            <em>Gerenciar locações</em> onde veremos uma lista de todas as já
            criadas. Após encontrarmos a entrega, em sua linha na tabela haverá
            um botão verde com uma <em>seta</em> seu lado que poderemos ver
            todas as opções de ação. Clique na <em>seta</em> e selecione a opção{" "}
            <em>Editar</em>
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/delivery/delivery-list-edit-button.png"
            alt="dashboard-list"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            2. Acessamos a página de edição da entrega. No botão superior
            direiro <em>Editar</em>, habilitaremos os campos que podem ser
            alterados.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/delivery/delivery-edit.png"
            alt="delivery-edit"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            3. Após alterar, deve-se clicar no botão <em>Salvar</em>, para
            guardar as mudanças
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Tutorials;
