import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1024px;
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
`;

export const ConentAsk = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 24px;
`;

export const ContentImages = styled.div`
  display: flex;
`;

export const WrapperTeamInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;
