import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Material
import { Button, TextField, CircularProgress, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Global Components
import Container from "../../components/Container";
import BusinessSimpleTable from "../../components/BusinessSimpleTable";
import Snackbar from "../../components/Snackbar";
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as BusinessActions } from "../../store/ducks/business";

// Help Functions
import { checkUserRoles } from "../../services/permissions";

// Feature Roles
import featureRoles from "./roles";
import { withTranslation } from "react-i18next";

class Business extends Component {
  static propTypes = {
    getBusinessRequest: PropTypes.func.isRequired
  };
  t = this.props.t

  state = {
    email: ""
  };

  componentWillMount() {
    const { resetBusinessState } = this.props;
    resetBusinessState();
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSearchUser = () => {
    const { email } = this.state;
    const { getBusinessRequest } = this.props;

    if (!email) return;

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      //return;
    }

    getBusinessRequest({
      email
    });
  };

  render() {
    const { classes, history, business, setSnackbarVisible } = this.props;

    return (
      <Container title={this.t('usersTitle')}>
        {business.loading ? (
          <CircularProgress />
        ) : (
          <>
            <Snackbar
              open={business.snackbarVisible}
              variant="warning"
              onClose={setSnackbarVisible}
              message={this.t('errors:userNotFound')}
            />
            {checkUserRoles(featureRoles.createBusinessFeatureRole) && (
              <Fragment>
                <h3>{this.t('createUser')}</h3>
                <Button
                  onClick={() => history.push("/business/create")}
                  variant="contained"
                >
                  {this.t('common:create')}
                </Button>
              </Fragment>
            )}

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  id="outlined-name"
                  label="email"
                  className={classes.textField}
                  value={this.state.email}
                  onChange={this.handleChange("email")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <Button
                  className={classes.button}
                  onClick={this.handleSearchUser}
                  variant="contained"
                >
                  {business.loading ? <CircularProgress /> : this.t('common:search')}
                </Button>
              </Grid>
            </Grid>
            <BusinessSimpleTable history={history} />
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  business: state.business
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(BusinessActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(withTranslation('business')(Business)));
