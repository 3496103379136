import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions } from "../ducks/deliveryUpdateInfoSubType";

export function* getDeliveryUpdateInfoSubTypeRequest(action) {
  try {
    const response = yield call(
      api.get,
      "/v1/adm/delivery/update/info/subTypes"
    );

    yield put(Actions.deliveryUpdateInfoSubTypeSuccess(response.data));
  } catch (err) {
    yield put(Actions.deliveryUpdateInfoSubTypeError(err));
  }
}
