import api from "./api";

export function checkPermission(customPermissions) {
  const userPermissions = JSON.parse(localStorage.getItem("auth"));

  const { permissions } = userPermissions;

  if (!permissions) return false;

  if (!customPermissions.length) return false;

  for (let i = 0; i < customPermissions.length; i++) {
    if (
      customPermissions[i] === "*" ||
      permissions.find(item => item === customPermissions[i])
    ) {
      return true;
    }
  }

  return false;
}

export function checkRoles(customRoles, acceptedRoles, acess) {
  if (!customRoles || !acceptedRoles) return false;
  let _currentTeam = null;

  // get roles
  const userRoles = JSON.parse(localStorage.getItem("auth"));

  if (!userRoles) return false;

  if (!userRoles.currentTeam) {
    let teams = null;
    new Promise(async (resolve, reject) => {
      try {
        const teams = await api.get("/teams");
        return resolve(teams);
      } catch (err) {
        return reject(err);
      }
    }).then(value => {
      teams = value;
    });

    if (!teams) return false;

    _currentTeam = teams.data[0];

    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("auth")),
        currentTeam: teams.data[0]
      })
    );
  } else {
    _currentTeam = userRoles.currentTeam;
  }

  // accepted roles
  const { roles } = userRoles;

  if (!roles || !roles.length) {
    return false;
  }

  if (
    acess &&
    !_currentTeam.parameters.activeTools &&
    !roles.includes("masterAdministrator")
  ) {
    return false;
  }

  for (let i = 0; i < customRoles.length; i++) {
    if (customRoles[i] === "*") return true;

    if (acceptedRoles.indexOf(customRoles[i]) > -1) return true;
  }

  return false;
}

export function checkUserRoles(acceptedRoles) {
  if (!acceptedRoles) return false;

  // get roles
  const userRoles = JSON.parse(localStorage.getItem("auth"));

  if (!userRoles) return false;

  // accepted roles
  const { roles } = userRoles;

  if (!roles || !roles.length) {
    return false;
  }

  for (let i = 0; i < acceptedRoles.length; i++) {
    if (acceptedRoles[i] === "*") return true;

    if (roles.indexOf(acceptedRoles[i]) > -1) return true;
  }

  return false;
}
