import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as LocationActions } from "../ducks/location";

export function* getLocationInfo(action) {
  try {
    const response = yield call(api.get, "/v1/adm/location", {
      params: {
        cityName: action.payload.data.cityName
      },
      headers: {
        public: true
      }
    });

    yield put(
      LocationActions.getLocationSuccess({
        ...response.data,
        streetName: action.payload.data.streetName
      })
    );
  } catch (err) {
    yield put(LocationActions.getLocationError(err));
  }
}
