import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Launch from "@material-ui/icons/Launch";
import Person from "@material-ui/icons/Person";
import Store from "@material-ui/icons/Store";
import CompareArrows from "@material-ui/icons/CompareArrows";
import Business from "@material-ui/icons/Business";
import Edit from "@material-ui/icons/Edit";
import Grow from "@material-ui/core/Grow";
import Container from "../../components/Container";
import { Divider } from "@material-ui/core";
import history from "../../routes/history";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
    // maxWidth: 752
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  container: {
    display: "flex"
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    // backgroundColor: colors.background,
    boxShadow: "3px 3px 15px 1px rgba(0,0,0,0.2)",
    height: "100%"
  },
  svg: {
    width: 100,
    height: 100
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1
  }
}));

function CustomListItem({ label, Labelicon, onClick, secondary }) {
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <Labelicon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={label || "Single-line item"}
          secondary={secondary}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={onClick}>
            <Launch />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}

function Tutorials() {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);

  const [checked, setChecked] = React.useState(false);

  const handleChange = link => {
    history.push(link);
  };

  return (
    <Container title="tutoriais">
      <Grow in={!checked}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.title}>
              aprenda e utilizar o painel de gestão
            </Typography>

            <List dense={dense}>
              <CustomListItem
                label="Criar estabelecimento"
                Labelicon={Business}
                onClick={() => handleChange("/tutorials/subTeam/create")}
                secondary="Unidade, loja, etc."
              />
              <CustomListItem
                label="Criar perfil de acesso"
                Labelicon={Person}
                onClick={() => handleChange("/tutorials/business/create")}
                secondary="Contas para painel e aplicativo."
              />
              <CustomListItem
                label="Criar entrega pelo painel de gestão"
                Labelicon={CompareArrows}
                onClick={() => handleChange("/tutorials/delivery/create")}
              />
              <CustomListItem
                label="Editar uma entrega"
                Labelicon={Edit}
                onClick={() => handleChange("/tutorials/delivery/edit")}
              />
            </List>
          </Grid>
        </Grid>
      </Grow>
      <Grow in={!checked}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.title}>
              aprenda e utilizar o app handover drop
            </Typography>

            <List dense={dense}>
              <CustomListItem
                label="Login e acesso"
                Labelicon={Person}
                onClick={() => handleChange("/tutorials/drop/login")}
              />
              <CustomListItem
                label="Criar entrega"
                Labelicon={Store}
                onClick={() => handleChange("/tutorials/drop/delivery/create")}
              />
              <CustomListItem
                label="Gerenciar entregas"
                Labelicon={CompareArrows}
                onClick={() => handleChange("/tutorials/drop/delivery/manage")}
              />
            </List>
          </Grid>
        </Grid>
      </Grow>

      <Grow in={!checked}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.title}>
              como usar o totem de atendimento
            </Typography>

            <List dense={dense}>
              {/* <CustomListItem
                label="Depositar produtos"
                Labelicon={Person}
                onClick={() => handleChange("/tutorials/drop/login")}
              /> */}
              <CustomListItem
                label="Coletar/Retirar produtos"
                Labelicon={Store}
                onClick={() => handleChange("/tutorials/totem/collect")}
              />
            </List>
          </Grid>
        </Grid>
      </Grow>
    </Container>
  );
}

export default Tutorials;
