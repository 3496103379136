export const Types = {
  getNotificationRequest: "getNotificationRequest",
  getNotificationSuccess: "getNotificationSuccess",
  getNotificationError: "getNotificationError",

  createNotificationRequest: "createNotificationRequest",
  createNotificationSuccess: "createNotificationSuccess",
  createNotificationError: "createNotificationError",

  updateNotificationRequest: "updateNotificationRequest",
  updateNotificationSuccess: "updateNotificationSuccess",
  updateNotificationError: "updateNotificationError"
};

const INITIAL_STATE = {
  data: [],
  error: false,
  loading: false
};

export default function notification(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.getNotificationRequest:
      return {
        ...state,
        loading: true,
        error: false
      };

    case Types.getNotificationSuccess:
      return {
        ...state,
        loading: false,
        data: action.payload
      };

    case Types.getNotificationError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.createNotificationRequest:
      return {
        ...state,
        loading: true,
        error: false
      };

    case Types.createNotificationSuccess:
      return {
        ...state,
        loading: false
      };

    case Types.createNotificationError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.updateNotificationRequest:
      return {
        ...state,
        error: false
      };

    case Types.updateNotificationSuccess:
      return {
        ...state,
        loading: false
      };

    case Types.updateNotificationError:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
}

export const Actions = {
  getNotificationRequest: ({ idTeam }) => ({
    type: Types.getNotificationRequest,
    payload: { idTeam }
  }),

  getNotificationSuccess: data => ({
    type: Types.getNotificationSuccess,
    payload: data
  }),

  getNotificationError: error => ({
    type: Types.getNotificationError,
    payload: { error }
  }),

  createNotificationRequest: ({ title, content }) => ({
    type: Types.createNotificationRequest,
    payload: {
      title,
      content
    }
  }),

  createNotificationSuccess: () => ({
    type: Types.createNotificationSuccess
  }),

  createNotificationError: () => ({
    type: Types.createNotificationError
  }),

  updateNotificationRequest: ({ id }) => ({
    type: Types.updateNotificationRequest,
    payload: { id }
  }),

  updateNotificationSuccess: id => ({
    type: Types.updateNotificationSuccess,
    payload: { id }
  }),

  updateNotificationError: () => ({
    type: Types.updateNotificationError
  })
};
