const time = [
  {
    value: 1,
    label: '1 min'
  },
  {
    value: 2,
    label: '2 min',
  },
  {
    value: 3,
    label: '3 min'
  },
  {
    value: 4,
    label: '4 min',
  },
  {
    value: 5,
    label: '5 min'
  },
  {
    value: 6,
    label: '6 min'
  },
  {
    value: 7,
    label: '7 min'
  },
  {
    value: 8,
    label: '8 min'
  },
  {
    value: 9,
    label: '9 min'
  },
  {
    value: 10,
    label: '10 min'
  },
  {
    value: 11,
    label: '11 min'
  },
  {
    value: 12,
    label: '12 min'
  },
  {
    value: 13,
    label: '13 min'
  },
  {
    value: 14,
    label: '14 min'
  },
  {
    value: 15,
    label: '15 min'
  },
  {
    value: 16,
    label: '16 min'
  },
  {
    value: 17,
    label: '17 min'
  },
  {
    value: 18,
    label: '18 min'
  },
  {
    value: 19,
    label: '19 min'
  },
  {
    value: 20,
    label: '20 min'
  },
  {
    value: 21,
    label: '21 min'
  },
  {
    value: 22,
    label: '22 min'
  },
  {
    value: 23,
    label: '23 min'
  },
  {
    value: 24,
    label: '24 min'
  },
  {
    value: 25,
    label: '25 min'
  },
  {
    value: 26,
    label: '26 min'
  },
  {
    value: 27,
    label: '27 min'
  },
  {
    value: 28,
    label: '28 min'
  },
  {
    value: 29,
    label: '29 min'
  },
  {
    value: 30,
    label: '30 min'
  },
  {
    value: 31,
    label: '31 min'
  },
  {
    value: 32,
    label: '32 min'
  },
  {
    value: 33,
    label: '33 min'
  },
  {
    value: 34,
    label: '34 min'
  },
  {
    value: 35,
    label: '35 min'
  },
  {
    value: 36,
    label: '36 min'
  },
  {
    value: 37,
    label: '37 min'
  },
  {
    value: 38,
    label: '38 min'
  },
  {
    value: 39,
    label: '39 min'
  },
  {
    value: 40,
    label: '40 min'
  },
  {
    value: 41,
    label: '41 min'
  },
  {
    value: 42,
    label: '42 min'
  },
  {
    value: 43,
    label: '43 min'
  },
  {
    value: 44,
    label: '44 min'
  },
  {
    value: 45,
    label: '45 min'
  },
  {
    value: 46,
    label: '46 min'
  },
  {
    value: 47,
    label: '47 min'
  },
  {
    value: 48,
    label: '48 min'
  },
  {
    value: 49,
    label: '49 min'
  },
  {
    value: 50,
    label: '50 min'
  },
  {
    value: 51,
    label: '51 min'
  },
  {
    value: 52,
    label: '52 min'
  },
  {
    value: 53,
    label: '53 min'
  },
  {
    value: 54,
    label: '54 min'
  },
  {
    value: 55,
    label: '55 min'
  },
  {
    value: 56,
    label: '56 min'
  },
  {
    value: 57,
    label: '57 min'
  },
  {
    value: 58,
    label: '58 min'
  },
  {
    value: 59,
    label: '59 min'
  },
  {
    value: 60,
    label: '60 min'
  }
];

export default time;
