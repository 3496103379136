import styled from "styled-components";

export const BackgroundOpacity = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: ${props => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
`;

export const Container = styled.div`
  position: relative;
  border-radius: 10px;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  background: #fff;

  @media (max-width: 450px) {
    width: 80vw;
    padding: 10px 20px;
  }

  & svg {
    color: #000;
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  & h3 {
    font-size: 13px;
    font-weight: bold;
    color: slategray;
    position: absolute;
    top: 55px;
  }
`;

export const HeadModal = styled.div`
  margin: 80px 0 0;
  width: 500px;

  & h1 {
    font-weight: bold;
    font-size: 18px;
    font-family: Montserrat, sans-serif;
  }

  & h2 {
    font-size: 16px;

    @media (max-width: 450px) {
      font-size: 14px;
    }
  }

  @media (max-width: 1025px) {
    width: 420px;
  }

  @media (max-width: 769px) {
    width: 300px;
  }

  @media (max-width: 376px) {
    width: 250px;
  }
`;

export const Image = styled.img`
  position: absolute;
  height: auto;
  top: -60px;
`;

export const ButtonClose = styled.button`
  width: 200px;
  padding: 10px 0;
  color: #ffffff;
  background-color: ${props => (props.loading ? "#aaa" : "#4caf50")};
  border: 0;
  cursor: ${props => (props.loading ? "default" : "pointer")};
  transition: all 0.5s;
  border-radius: 5px;

  &:hover {
    transform: ${props =>
      props.loading ? "translateY(0)" : "translateY(-1px)"};
    background-color: ${props => (props.loading ? "#aaa" : "#4caf30")};
  }

  &:focus {
    outline: 0;
  }

  @media (max-width: 769px) {
    width: 150px;
  }

  @media (max-width: 426px) {
    font-size: 12px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  & input {
    border-radius: 3px;
    margin-bottom: 10px;
    width: 500px;
    padding: 10px 15px;
    border: 0;
    border-bottom: 2px solid #4caf50;
    transition: all 0.5s;
    font-size: 16px;

    &:focus {
      border-bottom: 2px solid #4caf30;
    }

    @media (max-width: 1025px) {
      width: 420px;
    }

    @media (max-width: 769px) {
      width: 300px;
    }

    @media (max-width: 426px) {
      font-size: 12px;
      width: 300px;
      border-bottom: 1px solid #4caf50;

      &:focus {
        border-bottom: 1px solid #4caf30;
      }
    }

    @media (max-width: 376px) {
      width: 250px;
    }
  }

  & p {
    font-size: 15px;
    color: red;
  }

  & button {
    width: 200px;
    padding: 10px 0;
    color: #ffffff;
    background-color: ${props =>
      props.loading || !props.ReCAPTCHAIsValid || !props.email
        ? "#aaa"
        : "#4caf50"};
    border: 0;
    cursor: ${props => (props.loading ? "default" : "pointer")};
    transition: all 0.5s;
    border-radius: 5px;

    &:hover {
      transform: ${props =>
        props.loading ? "translateY(0)" : "translateY(-1px)"};
      background-color: ${props =>
        props.loading || !props.ReCAPTCHAIsValid || !props.email
          ? "#aaa"
          : "#4caf30"};
    }

    &:focus {
      outline: 0;
    }

    @media (max-width: 769px) {
      width: 150px;
    }

    @media (max-width: 426px) {
      font-size: 12px;
    }
  }
`;
