import React, { Component } from "react";

import apiDS from "../../services/apiDS";

// Material
import { Grid, Button, TextField } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import Container from "../../components/Container";
import LocationTable from "./table";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

class ToolsDatascience extends Component {
  state = {
    data: [],
    origin: "",
    destination: "",
    open: true,
    fileName: "",
    file: {},
    mapHtml: null,
    loading: false,
    error: null
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  returnDataString = data => {
    return data.map(d => `${d.logradouro}, ${d.numero}, ${d.estado}`);
  };

  fetchLocationRoute = async () => {
    try {
      this.setState({
        loading: true,
        error: null
      });

      const formData = new FormData();

      const { origin, destination, file, fileName } = this.state;

      formData.append("origin", origin);
      formData.append("destination", destination);
      formData.append("file", file, fileName);

      const data = await apiDS.post(`/delivery_route_optimize/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*"
        }
      });

      const formDataMap = new FormData();
      let tmpData = "[";
      formDataMap.append("origin", `"${data.data.origin}"`);
      formDataMap.append("destination", `"${data.data.destination}"`);

      for (let i = 0; i < data.data.waypoints.length; i++) {
        tmpData += `"${data.data.waypoints[i]}",`;
      }

      tmpData += "]";

      formDataMap.append("data", tmpData);

      const htm = await apiDS.post(
        `/delivery_route_optimize/map_render`,
        formDataMap,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data"
          }
        }
      );

      this.setState({
        mapHtml: htm.data,
        data: data.data.data,
        loading: false,
        open: false
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: err
      });
    }
  };

  handleFile = ({ target }) => {
    if (!target.files.length) {
      this.setState({ fileName: "" });
      return;
    }

    this.setState({ file: target.files[0], fileName: target.files[0].name });
  };

  render() {
    return (
      <Container title="Otimização de rotas para entrega dos produtos.">
        <ThemeTextFildGreen>
          <div className="helper-spacing-top-mobile" />

          <Alert severity="info" style={{ width: "100%" }}>
            <AlertTitle>Funcionalidades:</AlertTitle>
            <p style={{ fontSize: "16px" }}>
              Esta ferramenta possibilita formular os melhores trajetos para a
              entrega dos produtos, considerando possíveis imprevístos no
              trajeto (acidentes, trânsito, etc).
            </p>
            <AlertTitle>Observações: </AlertTitle>

            <span>
              As rotas apresentadas no mapa estão considerando que o entregador
              esta fazendo as entregas a pé ou de bike;
            </span>
            <br />
            <span>
              O tipo de arquivo esperado deve estar no formato{" "}
              <strong>xlsx</strong>;
            </span>
            <br />
            <span>E permitido no máximo 25 endereços no arquivo;</span>
            <br />
            <span>O xlsx deve estar no modelo do exemplo abaixo.</span>
            <ul style={{ marginTop: 10 }}>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Para acessar o modelo{" "}
                <a
                  href="https://drive.google.com/file/d/10nEEujSrl307p_1rA110Dx89NrQgJnNw/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  clique aqui
                </a>
              </li>
            </ul>

            <AlertTitle>Como usar: </AlertTitle>

            <ul>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Em <strong>'Dados básicos'</strong> adicionaremos o início do
                trajeto (<strong>Ponto de partida do entregador</strong>
                ), o final do trajeto (
                <strong>Ponto final do entregador</strong>
                ).
              </li>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Os campos de início e final do trajeto devem estar neste modelo:{" "}
                <strong>
                  logradouro (rua, avenida, viela, etc), número do logradouro,
                  estado do logradouro. Ex:{" "}
                </strong>
                Av. Paulista, 181, São Paulo.{" "}
              </li>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Em <strong>endereço das entregas</strong> faça o upload do
                arquivo xlsx.
              </li>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Após colocar estes dados, clique em <strong>Calcular</strong>.
                Após isso irá carregar o mapa com a melhor rota para a entrega
                dos produtos.
              </li>
            </ul>

            <AlertTitle>Observação</AlertTitle>
            <ul>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                O tempo para análise e cálculo pode levar até 60 segundos,
                dependo da quantidade de endereços, lockers e raio de entrega.
              </li>
            </ul>
          </Alert>

          <h4
            style={{
              marginBottom: -10,
              marginTop: 20,
              textAlign: "left",
              fontSize: "16px"
            }}
          >
            Dados básicos
          </h4>

          <Grid container spacing={2} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={5}>
              <TextField
                id="outlined-field"
                label="Ponto de partida do entregador"
                fullWidth
                name="origin"
                value={this.state.origin}
                onChange={e => this.handleChange(e)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                id="outlined-field"
                label="Ponto final do entregador"
                fullWidth
                name="destination"
                value={this.state.destination}
                onChange={e => this.handleChange(e)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>

          <h4
            style={{
              marginBottom: -10,
              marginTop: 10,
              textAlign: "left",
              fontSize: "16px"
            }}
          >
            Endereço das Entregas
          </h4>
          <Grid
            container
            spacing={1}
            style={{
              marginBottom: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Grid item xs={6} md={4}>
              <TextField
                label="Arquivo xlsx"
                disabled
                fullWidth
                name="origin"
                value={this.state.fileName}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3} md={2}>
              <Button
                variant="contained"
                style={{ marginTop: 10, background: "#4AC151", color: "#fff" }}
                component="label"
              >
                Upload
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={this.handleFile}
                  style={{ display: "none" }}
                />
              </Button>
            </Grid>
          </Grid>
          <Button
            onClick={this.fetchLocationRoute}
            variant="contained"
            style={{ marginTop: 20, background: "#4AC151", color: "#fff" }}
          >
            Calcular
          </Button>
          <br />
          <LocationTable data={this.state.data} loading={this.state.loading} />
          <br />
          {this.state.mapHtml && (
            <iframe
              title="ddd"
              srcdoc={this.state.mapHtml}
              width={"100%"}
              height={"480px"}
            />
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

export default ToolsDatascience;
