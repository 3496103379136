export const Types = {
  getIntegrationDataPartnerRequest: "getIntegrationDataPartner/Request",
  getIntegrationDataPartnerSuccess: "getIntegrationDataPartner/Success",
  getIntegrationDataPartnerError: "getIntegrationDataPartner/Error",

  getIntegrationDataTeamRequest: "getIntegrationDataTeam/Request",
  getIntegrationDataTeamSuccess: "getIntegrationDataTeam/Success",
  getIntegrationDataTeamerError: "getIntegrationDataTeam/Error"
};

const INIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  currentIntegrationDataPartner: null,
  currentIntegrationDataTeam: null
};

export default function integrations(state = INIAL_STATE, action) {
  switch (action.type) {
    case Types.getIntegrationDataPartnerRequest:
      return {
        ...state,
        loading: true,
        error: null,
        currentIntegrationDataPartner: null
      };

    case Types.getIntegrationDataPartnerSuccess:
      return {
        ...state,
        currentIntegrationDataPartner: action.payload.data,
        loading: false
      };

    case Types.getIntegrationDataPartnerError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.getIntegrationDataTeamRequest:
      return {
        ...state,
        loading: true,
        error: null,
        currentIntegrationDataTeam: null
      };

    case Types.getIntegrationDataTeamSuccess:
      return {
        ...state,
        currentIntegrationDataTeam: action.payload.data,
        loading: false
      };

    case Types.getIntegrationDataTeamError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
}

export const Actions = {
  getIntegrationDataPartnerRequest: id => ({
    type: Types.getIntegrationDataPartnerRequest,
    payload: { id }
  }),

  getIntegrationDataPartnerSuccess: data => ({
    type: Types.getIntegrationDataPartnerSuccess,
    payload: { data }
  }),

  getIntegrationDataPartnerError: error => ({
    type: Types.getIntegrationDataPartnerError,
    payload: { error }
  }),

  getIntegrationDataTeamRequest: id => ({
    type: Types.getIntegrationDataTeamRequest,
    payload: { id }
  }),

  getIntegrationDataTeamSuccess: data => ({
    type: Types.getIntegrationDataTeamSuccess,
    payload: { data }
  }),

  getIntegrationDataTeamError: error => ({
    type: Types.getIntegrationDataTeamError,
    payload: { error }
  })
};
