import React, { useState, forwardRef } from "react";

import MaterialTable from "material-table";
import { Check } from "@material-ui/icons";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

// Material
import { withStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField
} from "@material-ui/core";

import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import { materialStyle } from "../../styles";
import constants from "../../config/constants";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function IntegrationDataCreateFormEdit({
  classes,
  defaultData,
  disabled,
  setIntegrationDataTeam
}) {
  const [data, setData] = useState(defaultData);

  const handleChange = key => event => {
    let _value = event.target.value;

    setData(old => ({
      ...old,
      [key]: _value
    }));

    setIntegrationDataTeam([
      {
        ...data,
        [key]: _value
      }
    ]);
  };

  return (
    <ThemeTextFildGreen>
      <>
        <h4 className={classes.subTitle2}>API externa (via webhook)</h4>

        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <TextField
              id="outlined-name"
              label="URL"
              className={classes.textField}
              value={data.url}
              margin="normal"
              variant="outlined"
              onChange={handleChange("url")}
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <TextField
              id="outlined-name"
              label="Método HTTP"
              className={classes.textField}
              value={"POST"}
              margin="normal"
              variant="outlined"
              disabled={true}
            />
          </Grid>
        </Grid>

        <h6 className={classes.subTitle}>
          Funções da API Handover (via webhook)
        </h6>

        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <FormGroup row className={classes.defaultComponent}>
              {constants.whenCallOptions.map(item => (
                <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      checked={
                        data.whenCall === constants.whenCallOptions[1].id
                          ? true
                          : data.whenCall === item.id
                      }
                      onChange={event => {
                        if (event && event.target) {
                          let finalWhen = item.id;

                          if (
                            !event.target.checked &&
                            item.id === constants.whenCallOptions[1].id
                          ) {
                            finalWhen = constants.whenCallOptions[0].id;
                          }

                          setData(old => ({
                            ...old,
                            whenCall: finalWhen
                          }));
                          setIntegrationDataTeam([
                            {
                              ...data,
                              whenCall: finalWhen
                            }
                          ]);
                        } else {
                          console.error("event.target is null or undefined");
                        }
                      }}
                      name={item.id}
                      color="primary"
                      disabled={
                        disabled || item.id === constants.whenCallOptions[0].id
                      }
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>

        <MaterialTable
          title="Headers"
          disabled={true}
          columns={[
            {
              title: "Chave",
              field: "name",
              emptyValue: false
            },
            {
              title: "Valor",
              field: "value",
              emptyValue: false
            }
          ]}
          options={{
            paging: false,
            search: false,
            actionsColumnIndex: -1,
            toolbarButtonAlignment: "left"
          }}
          data={Object.keys(JSON.parse(data.headers)).map(item => {
            const _item = {
              id: Math.random(),
              name: item,
              value: JSON.parse(data.headers)[item]
            };
            return _item;
          })}
          icons={tableIcons}
          localization={{
            body: {
              editRow: {
                deleteText: "Deseja excluir este endereço?",
                cancelTooltip: "Cancelar",
                saveTooltip: "Salvar"
              },
              addTooltip: "Adicionar",
              deleteTooltip: "Deletar",
              editTooltip: "Editar",
              emptyDataSourceMessage: "Nenhum cabeçalho adicionado"
            },
            header: {
              actions: ""
            }
          }}
        />
      </>
      <br />
    </ThemeTextFildGreen>
  );
}

IntegrationDataCreateFormEdit.defaultProps = {
  defaultData: null,
  headers: [],
  editable: true,
  disabled: false
};

export default withStyles(materialStyle)(IntegrationDataCreateFormEdit);
