import styled from "styled-components";

import Background from "../../assets/f2.svg";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${Background});
  position: absolute;
  background-repeat: repeat;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  padding: 15px 35px;
  border-radius: 10px;
  position: relative;

  & h1 {
    font-size: 16px;
    margin: 15px 0 5px;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    width: 300px;
  }

  & h3 {
    font-size: 13px;
    font-weight: bold;
    color: slategray;
  }
`;

export const Informations = styled.div`
  display: flex;
  flex-direction: column;
  background: #eee;
  padding: 12px 10px;
  margin-bottom: 5px;
  width: 300px;

  & h4 {
    align-items: center;
    font-size: 12px;
  }
`;

export const Requeriment = styled.p`
  font-size: 12px;
  margin: 0;
  color: #000;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: ${props => (props.validation ? "#4caf50" : "#f9a602")};
    border-radius: 50%;
    position: relative;
    margin-right: 3px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0 20px;
  position: relative;

  & input {
    border-radius: 3px;
    margin-bottom: 20px;
    width: 300px;
    padding: 10px 15px;
    border: 0;
    border-bottom: 2px solid #4caf50;
    transition: all 0.5s;
  }

  & small {
    width: 300px;
    color: red;
  }

  & button {
    width: 180px;
    padding: 10px 0;
    color: #ffffff;
    background-color: ${props =>
      props.loading || props.disabledButton ? "#aaa" : "#4caf50"};
    border: 0;
    cursor: ${props => (props.loading ? "default" : "pointer")};
    transition: all 0.5s;
    border-radius: 5px;
    margin: 15px 0 10px;

    &:hover {
      transform: ${props =>
        props.loading ? "translateY(0)" : "translateY(-1px)"};
      background-color: ${props => (props.loading ? "#aaa" : "#4caf50")};
    }
  }

  & a {
    color: #757575;
    transition: all 0.2s;
    text-decoration: none;
    font-size: 12px;

    &:hover {
      color: #4caf30;
      outline: none;
    }
  }
`;

export const IconEye = styled.svg`
  position: absolute;
  right: 0;
  top: ${props => (props.top ? "78px" : "13px")};
  width: 20px;
  height: 20px;
  cursor: pointer;

  & svg {
    color: #888;
  }
`;
