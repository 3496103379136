import React, { Component } from "react";
import queryString from "query-string";

// Global Components
import Container from "../../components/Container";
import Snackbar from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import TextMaskCustomCPF from "../../components/TextMaskCustomCPF";
import TextMaskCustomCNPJ from "../../components/TextMaskCustomCNPJ";
import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";

// Material
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, Grid } from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";

import api from "../../services/api";

class BusinessCreate extends Component {
  state = {
    data: null,
    loading: true,
    error: null,
    success: false
  };

  getToken = () => {
    const {
      router: {
        location: { search }
      }
    } = this.props;

    const { token } = queryString.parse(search);

    return token;
  };

  // Load Roles and teams
  componentWillMount() {
    const token = this.getToken();

    if (!token) {
      this.setState({ error: "Token inválido" });
      return;
    }

    api
      .get(`/v1/adm/subTeams/info/token/register?token=${token}`)
      .then(({ data }) => {
        this.setState({ data });
      })
      .catch(err => {
        this.setState({ error: true });
      });

    this.setState({ loading: false });
  }

  handleCreate = () => {
    const token = this.getToken();
    this.setState({ loading: true });

    api
      .post(`/v1/adm/subTeams/token/active?token=${token}`)
      .then(res => {
        const data = this.state.data;
        data.subTeam.idSubTeamStatus = "b156d18b-ae3c-41fb-a02d-b237a76d1034";
        data.business.status = 1;
        this.setState({ data, success: true });
      })
      .catch(err => {
        this.setState({ error: true });
      });

    this.setState({ loading: false });
  };

  renderItem = (value, label, type = "text") => {
    const { classes } = this.props;
    let inputProps = {};

    switch (type) {
      case "mobile":
        inputProps = {
          inputComponent: TextMaskCustomMobile
        };
        break;
      case "cnpj":
        inputProps = {
          inputComponent: TextMaskCustomCNPJ
        };
        break;
      case "cpf":
        inputProps = {
          inputComponent: TextMaskCustomCPF
        };
        break;
      default:
        break;
    }

    return (
      <Grid
        item
        xs={12}
        sm={3}
        className="form-colaboradore-sm"
        alignItems="flex-start"
      >
        <TextField
          id="outlined-name"
          label={label}
          className={classes.textField}
          value={value}
          margin="normal"
          variant="outlined"
          disabled
          InputProps={inputProps}
        />
      </Grid>
    );
  };

  handleItens = () => {
    const { data } = this.state;
    const { classes } = this.props;

    if (!data) return;

    return (
      <>
        <h6>Informações do Estabelecimento</h6>
        <Grid container spacing={2}>
          {this.renderItem(data.subTeam.name, "Nome Fantasia")}
          {this.renderItem(data.subTeam.cnpj, "CNPJ", "cnpj")}
          {this.renderItem(data.subTeam.mobile, "Número de contato", "mobile")}
          {this.renderItem(data.subTeam.email, "Email de contato")}
        </Grid>
        <h6>Credenciais de acesso ao painel</h6>
        <Grid container spacing={2}>
          {this.renderItem(data.business.name, "Nome")}
          {this.renderItem(data.business.email, "email")}
        </Grid>
        <h6>Credenciais de acesso ao Drop</h6>
        <Grid container spacing={2}>
          {this.renderItem(
            data.businessDepositor
              ? data.businessDepositor.name
              : data.business.name,
            "Nome"
          )}
          {this.renderItem(
            data.businessDepositor
              ? data.businessDepositor.email
              : data.business.email,
            "email"
          )}
          {data.businessDepositor &&
            data.businessDepositor.cpf &&
            this.renderItem(data.businessDepositor.cpf, "CPF", "cpf")}
        </Grid>

        {data &&
        parseInt(data.business.status) !== 0 &&
        data.subTeam.idSubTeamStatus !==
          "d0398a67-37c7-4c56-9822-3ee505451bea" ? (
          <strong>Conta ativada com sucesso</strong>
        ) : (
          <Button
            disabled={false}
            onClick={this.handleCreate}
            variant="contained"
          >
            <SaveIcon className={classes.leftIcon} />
            Ativar conta
          </Button>
        )}
      </>
    );
  };

  render() {
    const { loading, error, success } = this.state;

    return (
      <Container title="Confirmar informações do Estabelecimento">
        <Snackbar
          open={error}
          onClose={() => this.setState({ error: null })}
          message="Não foi possível ativar"
          variant="error"
        />
        <Snackbar
          open={success}
          onClose={() => this.setState({ success: false })}
          message="Cadastro ativado!"
          variant="success"
        />

        {loading ? <Spinner /> : this.handleItens()}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps)(
  withStyles(materialStyle)(BusinessCreate)
);
