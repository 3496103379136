import React, { Component } from "react";

// Global
import Container from "../../../../components/Container";
import { materialStyle } from "../../../../styles";
import Snackbar from "../../../../components/Snackbar";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as DeliveryActions } from "../../../../store/ducks/delivery";
import { Actions as LockerActions } from "../../../../store/ducks/locker";
import { Actions as CompartmentActions } from "../../../../store/ducks/compartment";
import { Actions as BusinessActions } from "../../../../store/ducks/business";
import { Actions as SubTeamActions } from "../../../../store/ducks/subTeam";
import { Actions as SubTeamUnitsActions } from "../../../../store/ducks/subTeamUnits";
import { Actions as SubTeamSubUnitsActions } from "../../../../store/ducks/subTeamSubUnits";
import { Actions as SubTeamSubUnitsContactActions } from "../../../../store/ducks/subTeamSubUnitsContact";
import { withTranslation } from "react-i18next";
// Material
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  TextareaAutosize
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ThemeTextFildGreen from "../../../../styles/ThemeTextFildGreen";
import getOnlyNumbers from "../../../../services/getOnlyNumbers";
import TextMaskCustomMobile from "../../../../components/TextMaskCustomMobile";
import constants from "../../../../config/constants";
import validateEmail from "../../../../utils/validateEmail";

class DeliveryCreate extends Component {
  t = this.props.t
  state = {
    idLocker: "",
    secretCode: "",
    customerReferenceId: "",
    email: "",
    idBusiness: "select",
    emptyValidation: false,
    idBusinessColector: "select",
    customerName: "",
    customerLastName: "",
    customerEmail: "",
    customerPhone: "",
    returnActive: false,
    idCompartmentIdentifier: "select",
    roles: [],

    // condominium
    idSubTeam: "",
    idSubTeamUnits: "",
    idSubTeamSubUnits: "",
    idSubTeamSubUnitsContact: "",

    // validation
    showValidation: false,
    validationMessage: ""
  };

  componentWillMount() {
    const {
      lockersRequest,
      resetBusinessState,
      getBusinessRequest,
      compartmentIdentifiersReset,

      // for condominium
      getSubTeamsRequest
    } = this.props;

    resetBusinessState();
    compartmentIdentifiersReset();
    lockersRequest("perPage=1000");
    getBusinessRequest("perPage=1000&status=1");

    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;
    const roles = JSON.parse(localStorage.getItem("auth")).roles;

    if (Boolean(parameters.useCondominium)) {
      getSubTeamsRequest();
    }

    this.setState({ parameters, roles });
  }

  componentWillReceiveProps(nextProps) {
    const { subTeams } = nextProps;

    if (
      subTeams.data.length === 1 &&
      this.state.roles.length &&
      this.state.roles.includes("administratorSubTeam") &&
      this.state.idSubTeam !== subTeams.data[0].id
    ) {
      this.setState({
        idSubTeam: subTeams.data[0].id
      });
    }
  }

  handleChange = key => event => {
    const {
      getCompartmentIdentifiersRequest,
      getSubTeamUnitsRequest,
      getSubTeamSubUnitsRequest,
      getSubTeamSubUnitsContactRequest
    } = this.props;
    let value = event.target.value;

    if (key === "customerPhone") {
      value = getOnlyNumbers(value);
    }

    this.setState({ [key]: value });

    switch (key) {
      case "idLocker":
        getCompartmentIdentifiersRequest(`idLocker=${event.target.value}`);

        break;
      case "idSubTeam":
        getSubTeamUnitsRequest(`idSubTeam=${event.target.value}`);
        break;
      case "idSubTeamUnits":
        getSubTeamSubUnitsRequest(`idSubTeamUnits=${event.target.value}`);
        break;
      case "idSubTeamSubUnits":
        getSubTeamSubUnitsContactRequest(
          `idSubTeamSubUnits=${event.target.value}`
        );
        break;
      default:
        break;
    }
  };

  handleSave = () => {
    const { createDeliveryRequest } = this.props;
    const {
      idLocker,
      customerReferenceId,
      secretCode,
      idBusiness,
      idBusinessColector,
      customerName,
      customerLastName,
      customerEmail,
      customerPhone,
      parameters,
      returnActive,
      idCompartmentIdentifier,
      compartmentSupplyDescription,
      idSubTeam,
      idSubTeamUnits,
      idSubTeamSubUnits,
      idSubTeamSubUnitsContact
    } = this.state;
    this.setState({ emptyValidation: false });

    if (
      !idLocker ||
      (!customerReferenceId && !parameters.generateCustomerReferenceId) ||
      !idBusiness
    ) {
      this.setState({ emptyValidation: true });
      return;
    }

    if (
      parameters.useCustomerPhone &&
      String(getOnlyNumbers(customerPhone)).length !== 11
    ) {
      this.setState({
        showValidation: true,
        validationMessage: this.t('error:invalidPhone')
      });
      return;
    }

    if (
      parameters &&
      parameters.useSecretCode &&
      !secretCode.replace(/[^a-zA-Z0-9 ]/g, "")
    ) {
      this.setState({
        showValidation: true,
        validationMessage: this.t('error:invalidPhone')
      });
      return;
    }

    if (
      parameters &&
      parameters.useCustomerEmail &&
      !validateEmail(customerEmail)
    ) {
      this.setState({
        showValidation: true,
        validationMessage: this.t('error:invalidPhone')
      });
      return;
    }

    const data = {
      idLocker,
      customerReferenceId: customerReferenceId.replace(/[^a-zA-Z0-9 ]/g, ""),
      secretCode: secretCode.replace(/[^a-zA-Z0-9 ]/g, ""),
      idBusiness: idBusiness.replace("select", ""),
      idBusinessColector: idBusinessColector.replace("select", ""),
      customerName,
      customerLastName,
      customerEmail,
      customerPhone: getOnlyNumbers(customerPhone),
      returnActive,
      idCompartmentIdentifier:
        idCompartmentIdentifier === "select" ? "" : idCompartmentIdentifier,
      compartmentSupplyDescription: compartmentSupplyDescription || "",
      idSubTeam,
      idSubTeamUnits,
      idSubTeamSubUnits,
      idSubTeamSubUnitsContact
    };

    Object.keys(data).forEach(
      key => (data[key] === undefined || !data[key] ? delete data[key] : {})
    );

    createDeliveryRequest(data);
  };

  render() {
    const {
      classes,
      lockers,
      loading,
      error,
      setSnackbarVisibleDelivery,
      business,
      subTeams,
      subTeamUnits,
      subTeamSubUnits,
      subTeamSubUnitsContact
    } = this.props;
    const {
      idLocker,
      customerReferenceId,
      idBusiness,
      secretCode,
      idBusinessColector,
      parameters,
      customerName,
      customerPhone,
      customerLastName,
      customerEmail,
      compartmentSupplyDescription,
      idSubTeam,
      idSubTeamUnits,
      idSubTeamSubUnits,
      idSubTeamSubUnitsContact,
      showValidation,
      validationMessage
    } = this.state;

    return (
      <Container title={this.t('createTitle')}>
        <ThemeTextFildGreen>
          <Snackbar
            variant="error"
            open={error}
            message={error && (error.data.message || this.t('error:error'))}
            onClose={() => setSnackbarVisibleDelivery(false)}
          />
          <Snackbar
            variant={constants.toastVariants.warning}
            open={showValidation}
            message={validationMessage}
            onClose={() => this.setState({ showValidation: false })}
          />
          <h5 className={classes.subTitle}>{this.t('deliveryInfo')}</h5>
          <Grid container spacing={2}>
            {!parameters.generateCustomerReferenceId && (
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  disabled={loading}
                  id="outlined-name"
                  label={
                    parameters
                      ? parameters.textInputCustomerReferenceId
                      : this.t('orderNumber')
                  }
                  className={classes.textField}
                  value={customerReferenceId}
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange("customerReferenceId")}
                />
              </Grid>
            )}

            {parameters && parameters.useSecretCode ? (
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  disabled={loading}
                  id="outlined-name"
                  label={
                    parameters
                      ? `${parameters.textSecretCode}*`
                      : `${this.t('accessCode')}*`
                  }
                  className={classes.textField}
                  value={secretCode}
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange("secretCode")}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                disabled={
                  (subTeams.data.length === 1 &&
                    this.state.roles.length &&
                    this.state.roles.includes("administratorSubTeam")) ||
                  subTeams.loading ||
                  !subTeams.data.length
                }
                id="custom-css-outlined-input"
                select
                label={this.t('establishment')}
                className={classes.textFieldLocker}
                value={idSubTeam}
                onChange={this.handleChange("idSubTeam")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                {subTeams.data &&
                  subTeams.data.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            {parameters &&
              Boolean(parameters.useCondominium) && (
                <>
                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      disabled={
                        subTeamUnits.loading || !subTeamUnits.data.length
                      }
                      id="custom-css-outlined-input"
                      select
                      label={this.t('bloc')}
                      className={classes.textFieldLocker}
                      value={idSubTeamUnits}
                      onChange={this.handleChange("idSubTeamUnits")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                    >
                      {subTeamUnits.data &&
                        subTeamUnits.data.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      disabled={
                        subTeamSubUnits.loading || !subTeamSubUnits.data.length
                      }
                      id="custom-css-outlined-input"
                      select
                      label={this.t('apartment')}
                      className={classes.textFieldLocker}
                      value={idSubTeamSubUnits}
                      onChange={this.handleChange("idSubTeamSubUnits")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                    >
                      {subTeamSubUnits.data &&
                        subTeamSubUnits.data.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      disabled={
                        subTeamSubUnitsContact.loading ||
                        !subTeamSubUnitsContact.data.length
                      }
                      id="custom-css-outlined-input"
                      select
                      label={this.t('resident')}
                      className={classes.textFieldLocker}
                      value={idSubTeamSubUnitsContact}
                      onChange={this.handleChange("idSubTeamSubUnitsContact")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                    >
                      {subTeamSubUnitsContact.data &&
                        subTeamSubUnitsContact.data.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                </>
              )}

            {parameters && parameters.useCustomerName ? (
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  disabled={loading}
                  id="outlined-name"
                  label={`${this.t('clientName')}*`}
                  className={classes.textField}
                  value={customerName}
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange("customerName")}
                />
              </Grid>
            ) : null}
            {parameters && parameters.useCustomerPhone ? (
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  disabled={loading}
                  id="outlined-name"
                  label={this.t('clientNumber')}
                  className={classes.textField}
                  value={customerPhone}
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange("customerPhone")}
                  InputProps={{
                    inputComponent: TextMaskCustomMobile
                  }}
                  error={customerPhone && customerPhone.length !== 11}
                />
              </Grid>
            ) : null}
            {parameters && parameters.useCustomerLastName ? (
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  disabled={loading}
                  id="outlined-name"
                  label={`${this.t('clientLastName')}*`}
                  className={classes.textField}
                  value={customerLastName}
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange("customerLastName")}
                />
              </Grid>
            ) : null}

            {parameters && parameters.useCustomerEmail ? (
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  disabled={loading}
                  id="outlined-name"
                  label={`${this.t('clientLastEmail')}*`}
                  className={classes.textField}
                  value={customerEmail}
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange("customerEmail")}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                disabled={loading}
                id="custom-css-outlined-input"
                select
                label={`${this.t('common:locker')}*`}
                className={classes.textFieldLocker}
                value={idLocker}
                onChange={this.handleChange("idLocker")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                {lockers.data &&
                  lockers.data.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.lockerNumber} - {item.address}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                disabled={loading || !lockers.compartmentIdentiers.length}
                id="custom-css-outlined-input"
                select
                label={this.t('doorSize')}
                className={classes.textFieldLocker}
                value={this.state.idCompartmentIdentifier}
                onChange={this.handleChange("idCompartmentIdentifier")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                <MenuItem value="select">Qualquer</MenuItem>
                {lockers.compartmentIdentiers &&
                  lockers.compartmentIdentiers.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            {parameters && parameters.useCondominium === 0 ? (
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  disabled={loading}
                  id="custom-css-outlined-input"
                  select
                  label={this.t('returnQuestion')}
                  className={classes.textFieldLocker}
                  value={this.state.returnActive}
                  onChange={this.handleChange("returnActive")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  <MenuItem value={false}>Não</MenuItem>
                  <MenuItem value={true}>Sim</MenuItem>
                </TextField>
              </Grid>
            ) : null}
          </Grid>
          <br />
          <h5 className={classes.subTitle}>Responsáveis pela entrega</h5>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                disabled={loading}
                id="custom-css-outlined-input"
                select
                label={this.t('depositee')}
                className={classes.textFieldLocker}
                value={idBusiness}
                onChange={this.handleChange("idBusiness")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                <MenuItem key={"Selecione"} value={"select"}>
                  {this.t('common:select')}
                </MenuItem>
                {business.data &&
                  business.data.map(
                    item =>
                      (parseInt(item.type) === 1 ||
                        parseInt(item.type) === 3) && (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      )
                  )}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <TextField
                disabled={loading}
                id="custom-css-outlined-input"
                select
                label={this.t('taker')}
                className={classes.textFieldLocker}
                value={idBusinessColector}
                onChange={this.handleChange("idBusinessColector")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
                error={
                  this.state.returnActive && idBusinessColector === "select"
                }
              >
                <MenuItem key={"Selecione"} value={"select"}>
                {this.t('common:select')}
                </MenuItem>
                {business.data &&
                  business.data.map(
                    item =>
                      (parseInt(item.type) === 2 ||
                        parseInt(item.type) === 3) && (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      )
                  )}
              </TextField>
            </Grid>
          </Grid>
          {Boolean(parameters.useCompartmentSupply) && (
            <>
              <h5 className={classes.subTitle}>{this.t('itemsDescriptionTitle')}</h5>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6} className="form-colaboradore-sm">
                  <TextareaAutosize
                    aria-label="minimum height"
                    rowsMin={3}
                    rowsMax={7}
                    placeholder={this.t('itemsDescription')}
                    onChange={this.handleChange("compartmentSupplyDescription")}
                    margin="normal"
                    variant="outlined"
                    value={compartmentSupplyDescription}
                    style={{
                      width: 500,
                      marginLeft: 10
                    }}
                    disabled={loading}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Button
            variant="contained"
            className={classes.button}
            onClick={this.handleSave}
            disabled={
              loading ||
              !idLocker ||
              (!customerReferenceId &&
                !parameters.generateCustomerReferenceId) ||
              (this.state.returnActive && idBusinessColector === "select")
            }
          >
            {this.t('common:create')}
          </Button>
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.delivery.loading,
  error: state.delivery.error,
  lockers: state.locker,
  ...state
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...DeliveryActions,
      ...LockerActions,
      ...CompartmentActions,
      ...BusinessActions,
      ...SubTeamActions,
      ...SubTeamUnitsActions,
      ...SubTeamSubUnitsActions,
      ...SubTeamSubUnitsContactActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(withTranslation('delivery')(DeliveryCreate)));
