import "date-fns";
import React, { useState, useEffect, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  TextField,
  Grid,
  MenuItem,
  Paper,
  Typography,
  Box
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import queryString from "query-string";
import ptBR from "date-fns/locale/pt-BR";

// Components
import Spinner from "../../components/Spinner";
import TableSpendingHistory from "../../components/TableSpendingHistory";

// Redux
import { Actions as OrderActions } from "../../store/ducks/orders";
import { Actions as LockerActions } from "../../store/ducks/locker";
import { Actions as TeamActions } from "../../store/ducks/teams";
import { Actions as CompartmentActions } from "../../store/ducks/compartment";

import { materialStyle } from "../../styles";
import { withStyles } from "@material-ui/core/styles";

import Container from "../../components/Container";

import { ExportExcelExtract } from "../../components/ExportExcel";
import formatPrice from "../../utils/formatPrice";

import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import ButtonClick from "../../components/ButtonClick";

function usageAndSpendingHistory({
  getActiveOrdersRequest,
  lockersRequest,
  orders,
  lockers,
  classes,
  compartment,
  getCompartmentsRequest,
  teamsRequest
}) {
  const [idLocker, setIdLocker] = useState("");
  const [idCompartment, setIdCompartment] = useState("");
  const [initialDate, setInitialDate] = useState(setupInitialDates().initDate);
  const [finalDate, setFinalDate] = useState(setupInitialDates().initFinalDate);
  const [queryStringData, setQueryStringData] = useState(getQuerySearch());

  useEffect(() => {
    startSearch();
  }, []);

  function startSearch() {
    getActiveOrdersRequest(getQuerySearch());
    lockersRequest(`perPage=1000`);
  }

  function setupInitialDates() {
    const initDate = new Date();
    initDate.toLocaleString("pt-BR", {
      timeZone: "America/Sao_Paulo"
    });
    initDate.setHours(0, 0, 0, 0);
    initDate.setDate(initDate.getDate() - 3);

    const initFinalDate = new Date();
    initFinalDate.toLocaleString("pt-BR", {
      timeZone: "America/Sao_Paulo"
    });
    initFinalDate.setHours(0, 0, 0, 0);
    return { initFinalDate, initDate };
  }

  function search() {
    getActiveOrdersRequest(getQuerySearch());
  }

  function getFormatedDate(date, start = false) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${
      start ? "00:00:00" : "23:59:59"
    }`;
  }

  function getQuerySearch() {
    return queryString.stringify({
      initialDate: `${initialDate.getFullYear()}-${initialDate.getMonth() +
        1}-${initialDate.getDate()} 00:00:00`,
      finalDate: `${finalDate.getFullYear()}-${finalDate.getMonth() +
        1}-${finalDate.getDate()} 23:59:59`,
      idLocker,
      idCompartment,
      perPage: orders.perPage
    });
  }

  function handleChange(key, value) {
    const data = {
      ...queryString.parse(queryStringData),
      [key]: value
    };

    setQueryStringData(queryString.stringify(data));
  }

  const clearSearch = useCallback(() => {
    setIdLocker("");
    setIdCompartment("");
    setInitialDate(setupInitialDates().initDate);
    setFinalDate(setupInitialDates().initFinalDate);

    startSearch();
  }, []);

  return (
    <Container title="extrato de receitas">
      <ThemeTextFildGreen>
        {orders.loading ? (
          <Spinner />
        ) : (
          <>
            <Grid container spacing={2} className="first-grid">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data inicial"
                    className={classes.textField}
                    value={initialDate}
                    onChange={value => {
                      setInitialDate(value);
                      handleChange("initialDate", getFormatedDate(value, true));
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    inputVariant="outlined"
                    autoOk
                  />
                </Grid>
                <Grid item xs={12} md={2} className="form-colaboradore-sm">
                  <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data final"
                    className={classes.textField}
                    value={finalDate}
                    onChange={value => {
                      setFinalDate(value);
                      handleChange("finalDate", getFormatedDate(value, true));
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    inputVariant="outlined"
                    autoOk
                  />
                </Grid>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="custom-css-outlined-input"
                    select
                    label="Locker"
                    className={classes.textFieldLocker}
                    value={idLocker}
                    onChange={({ target: { value } }) => {
                      setIdLocker(value);
                      handleChange("idLocker", value);
                      getCompartmentsRequest(`idLocker=${value}&perPage=1000`);
                    }}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {lockers.data &&
                      lockers.data
                        .filter(item => item.idLockerStatus !== 0)
                        .map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.lockerNumber} - {item.address}
                          </MenuItem>
                        ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="custom-css-outlined-input"
                    select
                    label="Porta"
                    className={classes.textFieldLocker}
                    value={idCompartment}
                    onChange={({ target: { value } }) => {
                      setIdCompartment(value);
                      handleChange("idCompartment", value);
                    }}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                    disabled={!compartment.data.length || compartment.loading}
                  >
                    {compartment.data &&
                      compartment.data.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.compartmentNumber}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <ButtonClick onClick={() => search()}>Pesquisar</ButtonClick>
            <ButtonClick onClick={() => clearSearch()}>
              Limpar filtros
            </ButtonClick>
            <ExportExcelExtract data={orders.data} classes={classes} />
            <Box
              className={classes.box}
              display="flex"
              p={1}
              m={1}
              bgcolor="background.paper"
              id="content-block-report-sm"
              justifyContent="space-between"
            >
              <Paper className={classes.paperSmall} id="block-report-sm">
                <Typography align="center">Total: {orders.total}</Typography>
              </Paper>
              <Paper className={classes.paperSmall} id="block-report-sm">
                <Typography align="center">
                  Período:{" "}
                  {`${initialDate.getDate()}/${initialDate.getMonth() +
                    1}/${initialDate.getFullYear()} à ${finalDate.getDate()}/${finalDate.getMonth() +
                    1}/${finalDate.getFullYear()}`}
                  <br />
                </Typography>
              </Paper>
              <Paper className={classes.paperSmall} id="block-report-sm">
                <Typography align="center">
                  Ganhos no período: {formatPrice(orders.licensed_revenue)}
                </Typography>
              </Paper>
            </Box>
            <TableSpendingHistory
              classes={classes}
              getQuerySearch={() => getQuerySearch()}
            />
          </>
        )}
      </ThemeTextFildGreen>
    </Container>
  );
}

const mapStateToProps = state => ({
  orders: state.orders,
  lockers: {
    data: state.locker.data.filter(l => {
      const teamLicensed = state.teams.data.filter(
        t => parseInt(t.isLicensed) === 1
      );

      const data = teamLicensed.filter(t => {
        if (t.id === l.idTeam) {
          return l;
        }
      });

      if (data.length) {
        return data[0];
      }

      return false;
    })
  },
  compartment: state.compartment
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...OrderActions,
      ...LockerActions,
      ...CompartmentActions,
      ...TeamActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(usageAndSpendingHistory));
