import styled from 'styled-components';
import {
  Link,
} from 'react-router-dom';

const style = styled(Link)`
  &:hover {
    text-decoration: none;
    color: '#ff8200';
  }
  &.active {
    text-decoration: none;
    color: '#ff8200';
  }
  &.visited {
    text-decoration: none;
    color: '#ff8200';
  }
`;

export default style;
