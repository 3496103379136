import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function CustomDialog({
  disabled,
  className,
  buttonLabel,
  title,
  message,
  subMessage,
  finalMessage,
  cancelButtonText,
  confirmButtonText,
  onConfirm,
  noButton,
  customOpen,
  handleCustomOpenCancel,
  useCustomOpen
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (useCustomOpen) {
      handleCustomOpenCancel();
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      {!noButton && (
        <Button
          style={{
            // para os Buttons
            backgroundColor: "#006660",
            fontSize: 14,
            color: "#fff",
            textTransform: "lowercase",
            height: 35
          }}
          disabled={disabled}
          className={className || null}
          variant="contained"
          onClick={handleClickOpen}
        >
          {buttonLabel || "DEFAULT"}
        </Button>
      )}
      <Dialog
        open={useCustomOpen ? customOpen : open}
        onClose={useCustomOpen ? handleCustomOpenCancel : handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message || "DEFAULT"}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {subMessage || ""}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {finalMessage || ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{
              // para os Buttons
              backgroundColor: "#006660",
              fontSize: 14,
              color: "#fff",
              textTransform: "lowercase",
              height: 35
            }}
          >
            {cancelButtonText || "não"}
          </Button>

          <Button
            onClick={() => {
              handleClose();

              if (typeof onConfirm !== "undefined") {
                onConfirm();
              }
            }}
            color="primary"
            style={{
              // para os Buttons
              backgroundColor: "#006660",
              fontSize: 14,
              color: "#fff",
              textTransform: "lowercase",
              height: 35
            }}
          >
            {confirmButtonText || "sim"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
