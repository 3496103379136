import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { DoneAll, Error, HourglassEmpty } from "@material-ui/icons";
import TableHead from "@material-ui/core/TableHead";
import { withTranslation } from "react-i18next";
class EmailsInternalWizardTable extends Component {
  t = this.props.t

  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5,
    parameters: null,
    dashboardParams: null
  };

  componentWillMount() {
    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    const data = { parameters };

    if (parameters.dashboardParams && parameters.dashboardParams.length) {
      Object.assign(data, {
        dashboardParams: JSON.parse(parameters.dashboardParams)
      });
    }

    this.setState(data);
  }

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  renderIcon = status => {
    let Icon = HourglassEmpty;

    if (status === 1) {
      Icon = DoneAll;
    } else if (status === -1) {
      Icon = Error;
    }

    return <Icon />;
  };

  render() {
    const classes = this.useStyles2();
    const { data, extraColumns } = this.props;

    const { emptyRows } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className="tableheader">
              <TableRow align="center">
                <TableCell
                  className="tablecell-header tablecell-header__initial-table"
                  align="center"
                >
                  {this.t('common:name')}
                </TableCell>

                <TableCell
                  className="tablecell-header td-business-simple-table"
                  align="center"
                >
                  {this.t('common:email')}
                </TableCell>

                {extraColumns &&
                  extraColumns.map(item => (
                    <TableCell
                      className="tablecell-header td-business-simple-table"
                      align="center"
                      key={item.id}
                    >
                      {item.name}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody className="tbody-business-simple-table">
              {data &&
                data.map(row => (
                  <TableRow key={row.id}>
                    <TableCell
                      className="tablecell tablecell-header__initial-table"
                      align="center"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      className="tablecell td-business-simple-table"
                      align="center"
                    >
                      {row.email}
                    </TableCell>
                    {extraColumns &&
                      extraColumns.map((item, index) => (
                        <TableCell
                          className="tablecell-header td-business-simple-table"
                          align="center"
                          key={row.id}
                        >
                          {row[`param${index}`]}
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
              {!data.length && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} align="center">
                    {this.t('common:noInfo')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

EmailsInternalWizardTable.defaultPropd = {
  extraColumns: []
};

export default withTranslation()(EmailsInternalWizardTable);
