import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
  &.active {
    text-decoration: none;
  }
  &.visited {
    text-decoration: none;
  }
`;

export const DefaultLink = styled.a`
  &:hover {
    text-decoration: none;
  }
  &.active {
    text-decoration: none;
  }
  &.visited {
    text-decoration: none;
  }
`;
