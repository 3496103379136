import React, { Component } from "react";
import axios from "axios";

// Global
import TextMaskCustomCNPJ from "../TextMaskCustomCNPJ";
import TextMaskCustomCEP from "../TextMaskCustomCEP";
import TextMaskCustomPhone from "../TextMaskCustomPhone";
import Snackbar from "../Snackbar";
import EditorCurrentRegisterContainer from "../EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../ButtonEditorCurrentRegister";

// Styles
import { materialStyle } from "../../styles";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

// Material
import { TextField, Button, MenuItem, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as TeamActions } from "../../store/ducks/teams";
import { Actions as LocationActions } from "../../store/ducks/location";

// services
// import checkObject from "../../services/checkObject";
import { checkUserRoles } from "../../services/permissions";
import checkHandoverEmail from "../../services/checkHandoverEmail";

import getOnlyNumbers from "../../services/getOnlyNumbers";

// Features Role
import featuresRoles from "./roles";
import TextFieldActivationStatus from "../TextFieldActivationStatus";
import constants from "../../config/constants";
import TextFieldTextValue from "../TextFieldTextValue";
import MultiEmailInput from "../MultiEmailInput";
import { withTranslation } from "react-i18next";

class TeamEdit extends Component {
  t = this.props.t
  state = {
    currentTeam: null,
    errorCEP: false,
    editableField: false
  };
  // load current team
  componentWillMount() {
    const { action } = this.props;
    if (action === "edit") {
      const {
        showTeamRequest,
        match: {
          params: { id }
        }
      } = this.props;

      showTeamRequest(id);
    }
  }
  // on receive current team update local state
  componentWillReceiveProps(nextProps) {
    const {
      teams: { currentTeam },
      location: { data }
    } = nextProps;
    if (currentTeam !== this.state.currentTeam) {
      this.setState({
        currentTeam: {
          ...currentTeam
        }
      });
    }
    if (!nextProps.teams.error) {
      window.scrollTo(0, 0);
    }
    if (nextProps.teams.updateSuccess) {
      this.setState({ editableField: false });
    }
    if (data && data.country) {
      this.setState({
        ...this.state,
        currentTeam: {
          ...this.state.currentTeam,
          idCountry: data.country.id,
          idState: data.state.id,
          idCity: data.city.id,
          streetName: data.streetName
        }
      });
    }
  }
  // save changes
  handleSave = () => {
    const { updateTeamRequest, action } = this.props;
    const { currentTeam } = this.state;
    if (action === "edit") {
      console.log(currentTeam);
      updateTeamRequest(currentTeam);
    } else {
      // createTeamRequest(currentTeam);
    }
  };
  // on change current team
  handleChange = key => ({ target: { value } }) => {
    let result = value;

    if (key === "cnpj" || key === "zipCode") {
      result = getOnlyNumbers(value);
    }
    switch (key) {
      case "contactNumber":
        result = value.replace(/[^0-9.]/g, "");
        break;
      default:
        break;
    }
    this.setState({
      currentTeam: {
        ...this.state.currentTeam,
        [key]: result
      }
    });
  };

  handleInputEmails = value => {
    this.setState({
      currentTeam: {
        ...this.state.currentTeam,
        laundryEmailsToNotification: value
      }
    });
  };

  // get CEP
  getCEP = async () => {
    const { getLocationRequest } = this.props;
    const {
      currentTeam: { zipCode }
    } = this.state;
    this.setState({ errorCEP: false });
    if (!zipCode) return;
    const response = await axios.get(
      `https://viacep.com.br/ws/${zipCode
        .replace(".", "")
        .replace("-", "")}/json/`
    );
    const { erro, ...data } = response.data;
    if (erro) {
      this.setState({ errorCEP: true });
      return;
    }
    getLocationRequest({
      cityName: data.localidade,
      zipCode: this.state.currentTeam.zipCode,
      streetName: response.data.logradouro
    });
  };

  // render view
  renderEdit = () => {
    const { classes, teams, location, action } = this.props;
    const { currentTeam, editableField } = this.state;
    return teams.loading ? (
      <Snackbar />
    ) : (
      <ThemeTextFildGreen>
        {action === "edit" && (
          <EditorCurrentRegisterContainer
            disabled={editableField}
            flexDirection="row-reverse"
          >
            <ButtonEditorCurrentRegister
              label="Editar"
              onClick={() => this.setState({ editableField: true })}
            />
          </EditorCurrentRegisterContainer>
        )}
        <br />
        {checkHandoverEmail() && (
          <>
            <h5>{this.t('contract')}</h5>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} className="team-edit-sm__status">
                <TextField
                  onChange={this.handleChange("status")}
                  disabled={
                    teams.loading || (!editableField && action === "edit")
                  }
                  id="custom-css-outlined-input"
                  select
                  label="Status"
                  className={classes.selectType}
                  value={currentTeam ? currentTeam.status : 0}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  <MenuItem key={1} value={1}>
                    {this.t('common:enabled')}
                  </MenuItem>
                  <MenuItem key={0} value={0}>
                    {this.t('common:disabled')}
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <br />
          </>
        )}
        <h5>Informações básicas</h5>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              onChange={this.handleChange("cnpj")}
              id="outlined-name"
              label={this.t('common:company_registry')}
              className={classes.textField}
              value={currentTeam ? currentTeam.cnpj : ""}
              margin="normal"
              variant="outlined"
              disabled={teams.loading || (!editableField && action === "edit")}
              InputProps={{
                inputComponent: TextMaskCustomCNPJ
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              value={currentTeam ? currentTeam.name : ""}
              variant="outlined"
              className={classes.textField}
              disabled={teams.loading || (!editableField && action === "edit")}
              label={this.t('brandName')}
              margin="normal"
              onChange={this.handleChange("name")}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              value={currentTeam ? currentTeam.businessName : ""}
              variant="outlined"
              className={classes.textField}
              disabled={teams.loading || (!editableField && action === "edit")}
              label={this.t('formalName')}
              margin="normal"
              onChange={this.handleChange("businessName")}
            />
          </Grid>
        </Grid>

        <br />
        <h5>{this.t('address')}</h5>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              value={currentTeam ? currentTeam.zipCode : ""}
              variant="outlined"
              className={classes.textField}
              disabled={teams.loading || (!editableField && action === "edit")}
              label={this.t('common:zipcode')}
              margin="normal"
              onChange={this.handleChange("zipCode")}
              InputProps={{
                inputComponent: TextMaskCustomCEP
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            {checkUserRoles(featuresRoles.getCEPFeatureRole) && (
              <Button
                disabled={!editableField && action === "edit"}
                onClick={this.getCEP}
                variant="contained"
                className={classes.button}
                style={{ background: "#4AC151", color: "#fff" }}
              >
                {this.t('searchZip')}
              </Button>
            )}
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              value={currentTeam ? currentTeam.streetName : ""}
              variant="outlined"
              className={classes.textField}
              disabled={teams.loading || (!editableField && action === "edit")}
              label={this.t('addressLine1')}
              margin="normal"
              onChange={this.handleChange("streetName")}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              value={currentTeam ? currentTeam.streetNumber : ""}
              variant="outlined"
              className={classes.textField}
              disabled={teams.loading || (!editableField && action === "edit")}
              label={this.t('number')}
              margin="normal"
              onChange={this.handleChange("streetNumber")}
              type="number"
              inputProps={{ min: "1" }}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              value={currentTeam ? currentTeam.unitNumber : ""}
              variant="outlined"
              className={classes.textField}
              disabled={teams.loading || (!editableField && action === "edit")}
              label={this.t('addressLine2')}
              margin="normal"
              onChange={this.handleChange("unitNumber")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="business-edit-sm__cep">
          <Grid item xs={12} md={3} className="business-edit-sm__cep-input">
            <TextField
              value={
                location.data
                  ? location.data.country.countryName
                  : currentTeam && currentTeam.country
                    ? currentTeam.country.countryName
                    : ""
              }
              variant="outlined"
              className={classes.textField}
              label={this.t('country')}
              margin="normal"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={3} className="business-edit-sm__cep-input">
            <TextField
              value={
                location.data
                  ? location.data.state.stateName
                  : currentTeam && currentTeam.state
                    ? currentTeam.state.stateName
                    : ""
              }
              variant="outlined"
              className={classes.textField}
              disabled={true}
              label={this.t('state')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={3} className="business-edit-sm__cep-input">
            <TextField
              value={
                location.data
                  ? location.data.city.cityName
                  : currentTeam && currentTeam.city
                    ? currentTeam.city.cityName
                    : ""
              }
              variant="outlined"
              className={classes.textField}
              disabled={true}
              label={this.t('city')}
              margin="normal"
            />
          </Grid>
        </Grid>
        <br />

        <h5>{this.t('admin')}</h5>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              value={currentTeam ? currentTeam.contactEmail : ""}
              variant="outlined"
              className={classes.textField}
              disabled={teams.loading || (!editableField && action === "edit")}
              label={this.t('common:email')}
              margin="normal"
              onChange={this.handleChange("contactEmail")}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              value={currentTeam ? currentTeam.contactName : ""}
              variant="outlined"
              className={classes.textField}
              disabled={teams.loading || (!editableField && action === "edit")}
              label={this.t('common:name')}
              margin="normal"
              onChange={this.handleChange("contactName")}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              value={currentTeam ? currentTeam.contactNumber : ""}
              variant="outlined"
              className={classes.textField}
              disabled={teams.loading || (!editableField && action === "edit")}
              label={this.t('common:phone')}
              margin="normal"
              onChange={this.handleChange("contactNumber")}
              InputProps={{
                inputComponent: TextMaskCustomPhone
              }}
            />
          </Grid>
        </Grid>

        <br />

        {checkUserRoles(featuresRoles.getCEPFeatureRole) && (
          <>
            <h5>{this.t('generalParams')}</h5>
            <Grid container spacing={2}>
              {constants.parameters.general.map(item => {
                const Component = item.isText
                  ? TextFieldTextValue
                  : TextFieldActivationStatus;

                return (
                  <Grid item xs={12} md={3} className="team-edit-sm__status">
                    <Component
                      classes={classes}
                      disabled={
                        teams.loading || (!editableField && action === "edit")
                      }
                      value={currentTeam ? currentTeam[item.key] : 0}
                      onChange={this.handleChange}
                      label={item.label}
                      keyParameter={item.key}
                    />
                  </Grid>
                );
              })}

              <Grid item xs={12} md={3} className="team-edit-sm__status">
                <TextField
                  disabled={
                    teams.loading || (!editableField && action === "edit")
                  }
                  id="outlined-name"
                  label={this.t('timeLimitLocation')}
                  className={classes.selectType}
                  value={currentTeam ? currentTeam.deliveryTimeoutOnCreate : 0}
                  onChange={this.handleChange("deliveryTimeoutOnCreate")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                  inputProps={{ min: "1" }}
                  type="number"
                  helperText={this.t('noLimit')}
                />
              </Grid>
            </Grid>

            <br />
            <h5>Notificações</h5>
            <Grid container spacing={2}>
              {constants.parameters.notifications.map(item => {
                const Component = item.isText
                  ? TextFieldTextValue
                  : TextFieldActivationStatus;

                return (
                  <Grid item xs={12} md={3} className="team-edit-sm__status">
                    <Component
                      classes={classes}
                      disabled={
                        teams.loading || (!editableField && action === "edit")
                      }
                      value={currentTeam ? currentTeam[item.key] : 0}
                      onChange={this.handleChange}
                      label={item.label}
                      keyParameter={item.key}
                    />
                  </Grid>
                );
              })}
            </Grid>

            <br />
            <h5>{this.t('integrations')}</h5>

            {constants.integrations.map(item => {
              return (
                <Grid container spacing={2}>
                  <Grid
                    key={item.key}
                    item
                    xs={12}
                    md={3}
                    className="team-edit-sm__status"
                  >
                    <TextFieldActivationStatus
                      classes={classes}
                      disabled={
                        teams.loading || (!editableField && action === "edit")
                      }
                      value={currentTeam ? currentTeam[item.key] : 0}
                      onChange={this.handleChange}
                      label={item.label}
                      keyParameter={item.key}
                    />
                  </Grid>
                  {item.subKeys.map(subKey => (
                    <Grid
                      key={subKey.key}
                      item
                      xs={12}
                      md={3}
                      className="team-edit-sm__status"
                    >
                      <TextFieldTextValue
                        classes={classes}
                        disabled={
                          teams.loading || (!editableField && action === "edit")
                        }
                        value={currentTeam ? currentTeam[subKey.key] : ""}
                        onChange={this.handleChange}
                        label={subKey.label}
                        keyParameter={subKey.key}
                      />
                    </Grid>
                  ))}
                </Grid>
              );
            })}
            <br />
          </>
        )}

        <h5>Sistema de Lavanderia</h5>
        <Grid container spacing={2}>
          {constants.parameters.laundryParams.map(item => {
            return (
              <Grid item xs={12} md={12} className="team-edit-sm__status">
                <MultiEmailInput
                  extractEmails={e => this.handleInputEmails(e)}
                  defaultValue={currentTeam ? currentTeam[item.key] : ""}
                  disabled={
                    teams.loading || (!editableField && action === "edit")
                  }
                />
              </Grid>
            );
          })}
        </Grid>

        {(editableField || action === "new") && (
          <>
            <Button
              variant="contained"
              onClick={this.handleSave}
              disabled={teams.loading}
              className={classes.button}
            >
              <SaveIcon className={classes.leftIcon} />
              {action === "edit" ? this.t('common:save') : this.t('common:create')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (action === "edit") {
                  this.setState({ editableField: false });
                  window.scrollTo(0, 0);
                } else {
                  this.props.history.push("/teams");
                }
              }}
              disabled={teams.loading}
              className={classes.button}
            >
              <CancelIcon className={classes.leftIcon} />
              {this.t('common:dismiss')}
            </Button>
          </>
        )}
      </ThemeTextFildGreen>
    );
  };

  render() {
    const { teams, setSnackbarVisibleTeams } = this.props;
    const { errorCEP } = this.state;

    return (
      <>
        <Snackbar
          open={errorCEP}
          message={this.t('errors:zipcodeNotFound')}
          onClose={() => this.setState({ errorCEP: false })}
          variant="error"
        />
        {this.renderEdit()}
        <Snackbar
          message={this.t('common:updated')}
          variant="success"
          onClose={() => setSnackbarVisibleTeams(false)}
          open={teams.snackbarVisible}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.teams,
  location: state.location
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...TeamActions,
      ...LocationActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('team')(withStyles(materialStyle)(TeamEdit)));
