export const Types = {
  groupsRequest: "groups/Request",
  groupsSuccess: "groups/Success",
  groupsError: "groups/Error",

  showGroupRequest: "showGroup/Request",
  showGroupSuccess: "showGroup/Success",
  showGroupError: "showGroup/Error",

  updateThingGroupRequest: "updateThingGroup/Request",
  updateThingGroupSuccess: "updateThingGroup/Success",
  updateThingGroupError: "updateThingGroup/Error"
};

const INITAL_STATE = {
  loading: false,
  data: [],
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1,
  currentGroup: null
};

export default function groups(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.groupsRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.groupsSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload.data
      };

    case Types.groupsError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.showGroupRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.showGroupSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        currentGroup: action.payload.data
      };

    case Types.showGroupError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.updateThingGroupRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.updateThingGroupSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        currentGroup: action.payload.data
      };

    case Types.updateThingGroupError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
}

export const Actions = {
  groupsRequest: (email, password) => ({
    type: Types.groupsRequest,
    payload: {
      email,
      password
    }
  }),

  groupsSuccess: data => ({
    type: Types.groupsSuccess,
    payload: { data }
  }),

  groupsError: error => ({
    type: Types.groupsError,
    payload: { error }
  }),

  showGroupRequest: id => ({
    type: Types.showGroupRequest,
    payload: {
      id
    }
  }),

  showGroupSuccess: data => ({
    type: Types.showGroupSuccess,
    payload: { data }
  }),

  showGroupError: error => ({
    type: Types.showGroupError,
    payload: { error }
  }),

  updateThingGroupRequest: (id, data) => ({
    type: Types.updateThingGroupRequest,
    payload: {
      id,
      data
    }
  }),

  updateThingGroupSuccess: data => ({
    type: Types.updateThingGroupSuccess,
    payload: { data }
  }),

  updateThingGroupError: error => ({
    type: Types.updateThingGroupError,
    payload: { error }
  })
};
