import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as LockersStructoreActions } from "../ducks/lockerStructureConditionOptions";

export function* getLockerStructureOptions(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/lockerStructureConditionOptions?${action.payload}`
    );

    yield put(
      LockersStructoreActions.getLockerStructureConditionOptionsSuccess(
        response.data
      )
    );
  } catch (err) {
    yield put(
      LockersStructoreActions.getLockerStructureConditionOptionsError(err)
    );
  }
}
