export const Types = {
  getLocationRequest: 'getLocation/Request',
  getLocationSuccess: 'getLocation/Success',
  getLocationError: 'getLocation/Error',
  resetLocation: 'resetLocation'
}

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
}

export default function location(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.getLocationRequest:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case Types.getLocationSuccess:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };

    case Types.getLocationError:
      return {
        ...state,
        error: action.payload.error,
        laoding: false,
      };

    case Types.resetLocation:
      return {
        ...INITIAL_STATE
      }

    default:
      return state
  }
}

export const Actions = {
  getLocationRequest: data => ({
    type: Types.getLocationRequest,
    payload: { data },
  }),

  getLocationSuccess: data => ({
    type: Types.getLocationSuccess,
    payload: { data },
  }),

  getLocationError: error => ({
    type: Types.getLocationError,
    payload: { error },
  }),

  resetLocation: () => ({
    type: Types.resetLocation,
  })
}
