import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SimpleActionButton from "../SimpleActionButton";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerActions } from "../../store/ducks/locker";

// Help Functions
import { constants } from "../../config";
import { checkUserRoles } from "../../services/permissions";

// Roles
import featuresRoles from "./roles";

import formatDate from "../../functions/formatDate";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class LockersPaginationTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5
  };
  t = this.props.t;

  handleChangePage = (event, newPage) => {
    const { lockersRequest } = this.props;
    const { perPage } = this.state;

    lockersRequest({
      page: newPage + 1,
      perPage
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });

    const { lockersRequest } = this.props;

    lockersRequest({
      perPage: parseInt(event.target.value)
    });
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));
  render() {
    const classes = this.useStyles2();
    const { lockers, history } = this.props;

    const { emptyRows } = this.state;

    return (
      <Paper className={classes.root} id="table-locker-sm">
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className={classes.rowTitles}>
              <TableRow align="left">
                <TableCell
                  className="tablecell-header th-delivery"
                  align="left"
                >
                  <p style={{ color: "#006660", fontSize: 18, margin: 0 }}>
                    {this.t("table.address")}
                  </p>
                </TableCell>

                <TableCell
                  className="tablecell-header tablecell-header__initial-table"
                  align="left"
                >
                  <p style={{ color: "#006660", fontSize: 18, margin: 0 }}>
                  {this.t("table.client")}
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header tablecell-header__team-status-lockertable"
                  align="left"
                >
                  <p style={{ color: "#006660", fontSize: 18, margin: 0 }}>
                  {this.t("table.status")}
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header tablecell-header__team-status-lockertable"
                  align="left"
                >
                  <p style={{ color: "#006660", fontSize: 18, margin: 0 }}>
                  {this.t("table.last_login")}
                  </p>
                </TableCell>
                <TableCell className="tablecell-header" align="left">
                  <p style={{ color: "#006660", fontSize: 18, margin: 0 }}>
                  {this.t("table.actions")}
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lockers.data &&
                lockers.data.map(row => (
                  <TableRow key={row.id} className={classes.tableContent}>
                    <TableCell className="tablecell td-delivery" align="left">
                      {row.address}
                    </TableCell>

                    <TableCell
                      className="tablecell tablecell-header__initial-table"
                      align="left"
                    >
                      {row.team.name}
                    </TableCell>
                    <TableCell className="tablecell" align="left">
                      {row.lockerAvailability.status}
                    </TableCell>
                    <TableCell className="tablecell" align="left">
                      {row.connectionPing
                        ? formatDate(row.connectionPing.created_at)
                        : "-"}
                    </TableCell>
                    <TableCell
                      className="tablecell"
                      id="table-sm__svg"
                      align="left"
                    >
                      <SimpleActionButton
                        id={Math.random()}
                        label={this.t("table.manage")}
                        actions={[
                          () =>
                            history.push(
                              `/lockers/${
                                checkUserRoles(featuresRoles.actionColumnRole)
                                  ? "edit"
                                  : "show"
                              }/${row.id}`
                            ),
                          () =>
                            history.push(`/updateDeploymentStatus/${row.id}`)
                        ]}
                        options={[this.t("table.manage"), this.t("table.edit")]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={constants.optionPerPage}
            colSpan={3}
            count={lockers.total}
            rowsPerPage={lockers.perPage}
            page={lockers.page - 1}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true
            }}
            labelRowsPerPage={this.t("common:lines_per_page")}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} ${this.t("common:of")} ${count}`
            }
          />
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  lockers: state.locker
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...LockerActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("lockers")(LockersPaginationTable));
