import React from "react";
import Container from "../../components/Container";

function SandboxSystems() {
  return (
    <Container title="tutoriais">
      <iframe
        title="Conteúdo Externo"
        width="1024"
        height="600"
        src="https://main.d2nz1hxhvatfgl.amplifyapp.com"
        frameBorder="0"
      />
    </Container>
  );
}

export default SandboxSystems;
