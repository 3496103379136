import React, { useState, useEffect } from "react";
import queryString from "query-string";

import {
  Header,
  Container,
  TitleForm,
  SubTitleForm,
  SubTitleFormResp,
  Form,
  FormLabel,
  ContainerButton,
  Info,
  Logo,
  TextError,
  TextSuccess,
  SubTitleTextSuccess,
  Loading
} from "./styles";

import api from "../../services/api";

// assets
import LogoImage from "../../assets/Lockup_Handover.svg";

import Spinner from "../../components/Spinner";

function reducerCost(props) {
  const [data, setData] = useState({
    secretCode: "",
    delivery: null
  });
  const [loading, setLoading] = useState(true);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(null);
  const [searchError, setSearchError] = useState(null);

  useEffect(() => {
    handleSearch();
  }, []);

  const updateField = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const handleKey = e => {
    const { secretCode } = data;
    if (secretCode.length === 14 && e.keyCode === 8) {
      setData({
        ...data,
        [e.target.name]: secretCode.substring(0, secretCode.length - 1)
      });
    }
  };

  const getToken = () => {
    const {
      location: { search },
      match: {
        params: { token }
      }
    } = props;

    const _token = queryString.parse(search);

    if (_token.token) {
      return _token.token;
    }

    return token;
  };

  const handleError = async () => {
    setError("Desculpe, não foi possível prosseguir.");
    setLoading(false);
  };

  const handleSearch = async () => {
    try {
      setError(null);
      setLoading(false);
      setSearchError(false);

      const response = await api.get(
        `/v1/public/search/delivery?token=${getToken()}`,
        {
          headers: {
            public: true
          }
        }
      );

      if (parseInt(response.status) === 200) {
        setData({
          ...data,
          delivery: response.data
        });
        setLoading(false);
      }
    } catch (err) {
      setSearchError(true);
    }
  };

  const handleOpen = async () => {
    try {
      const { secretCode } = data;
      setError(null);

      await api.post(
        "/v1/public/open/delivery",
        {
          token: getToken(),
          secretCode
        },
        {
          headers: {
            public: true
          }
        }
      );

      setLoading(false);
      setFinished(true);
    } catch (err) {
      console.log(err);
      await handleError();
    } finally {
      alert("aqui");
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    console.log("call");

    await handleOpen();
  };

  return (
    <Container>
      <Header>
        <Logo src={LogoImage} alt="logo" />
      </Header>
      {finished && (
        <TextSuccess>
          Pronto! Agora você pode retirar seu produto, lembre-se de fechar a
          porta.
        </TextSuccess>
      )}

      {searchError && (
        <TextError margin align>
          Desculpe, este link não é mais válido. Entre em contato com quem
          compartilhou com você.
        </TextError>
      )}
      {loading && (
        <Loading>
          <Spinner bgcolor="#fff" />
        </Loading>
      )}
      {data.delivery &&
        !finished && (
          <Container>
            {data.delivery && (
              <>
                <TitleForm>SEU PEDIDO</TitleForm>
                <SubTitleForm>
                  Shopping:
                  <SubTitleFormResp>
                    {data.delivery.team && data.delivery.team.name}
                  </SubTitleFormResp>
                </SubTitleForm>
                {data.delivery.subTeam && (
                  <SubTitleForm>
                    Loja:
                    <SubTitleFormResp>
                      {data.delivery.subTeam && data.delivery.subTeam.name}
                    </SubTitleFormResp>
                  </SubTitleForm>
                )}
                <SubTitleForm>
                  Número de Referência:
                  <SubTitleFormResp>
                    {data.delivery.customerReferenceId}
                  </SubTitleFormResp>
                </SubTitleForm>
                <SubTitleForm>
                  Porta:
                  <SubTitleFormResp>
                    {data.delivery.compartment &&
                      data.delivery.compartment.compartmentNumber}
                  </SubTitleFormResp>
                </SubTitleForm>
                <SubTitleForm>
                  Status:
                  <SubTitleFormResp>
                    {data.delivery.status === 1
                      ? "Pronto para depósito"
                      : "Pronto para coleta"}
                  </SubTitleFormResp>
                </SubTitleForm>
              </>
            )}

            <Form>
              <FormLabel>
                <SubTitleForm htmlFor="">Código de acesso</SubTitleForm>
                <input
                  name="secretCode"
                  onChange={updateField}
                  value={data.secretCode}
                />
              </FormLabel>

              {error && <TextError>{error}</TextError>}

              <br />

              <ContainerButton>
                <button
                  type="button"
                  disabled={!data.secretCode.length || loading}
                  onClick={handleSubmit}
                >
                  Abrir Porta
                </button>
              </ContainerButton>
            </Form>

            <Info>
              Ao clicar em "Abrir Porta", você concorda com os{" "}
              <a href="https://oihandover.com/termos-de-uso/">Termos de Uso</a>{" "}
              e a{" "}
              <a href="https://oihandover.com/politica-de-privacidade/">
                Política de Privacidade
              </a>{" "}
              da handover.
            </Info>
          </Container>
        )}
    </Container>
  );
}

export default reducerCost;
