import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

export default function SplitButton({ id, actions, options, label, team }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  function handleClick() {
    if (!actions[0]) {
      handleToggle();
    } else {
      actions[0]();
    }
  }

  function handleMenuItemClick(event, index) {
    setOpen(false);

    actions[index]();
    if (team) {
      localStorage.setItem(
        "auth",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("auth")),
          currentTeam: team
        })
      );
    }
  }

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  const useStyles = makeStyles(theme => ({
    buttonText: {
      [theme.breakpoints.down("xs")]: {
        lineHeight: 1,
        padding: "6px",
        fontSize: "10px"
      }
    },
    buttonIcon: {
      [theme.breakpoints.down("xs")]: {
        padding: "2px"
      }
    }
  }));

  const classes = useStyles();
  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="Split button"
      >
        <Button
          onClick={handleClick}
          className={classes.buttonText}
          style={{
            backgroundColor: "#006660",
            fontSize: 14,
            color: "#fff",
            textTransform: "lowercase",
            height: 35,
          }}
        >
          {label || "visualizar"}
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper id="menu-list-grow" style={{}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
