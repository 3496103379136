export const Types = {
  setSnabkbarVisible: "orders/setSnackbarVisible",

  getActiveOrdersRequest: "getActiveOrders/Request",
  getActiveOrdersSuccess: "getActiveOrders/Success",
  getActiveOrdersError: "getActiveOrders/Error",

  showOrderRequest: "showOrder/Request",
  showOrderSuccess: "showOrder/Success",
  showOrderError: "showOrder/Error"
};

const INITAL_STATE = {
  licensed_revenue: 0,
  data: [],
  loading: false,
  error: null,
  currentOrder: null,
  snackbarVisible: false,
  total: 0,
  perPage: 1000,
  page: 0,
  lastPage: 0
};

export default function orders(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.setSnabkbarVisible:
      return {
        ...state,
        snackbarVisible: action.payload.status
      };

    case Types.getActiveOrdersRequest:
      return {
        ...INITAL_STATE,
        loading: true
      };

    case Types.getActiveOrdersSuccess:
      return {
        ...state,
        ...action.payload.data,
        loading: false
      };

    case Types.getActiveOrdersError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.showOrderRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.showOrderSuccess:
      return {
        ...state,
        loading: false,
        currentOrder: action.payload.data
      };

    case Types.showOrderError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
}

export const Actions = {
  setSnabkbarVisible: status => ({
    type: Types.setSnabkbarVisible,
    payload: { status }
  }),

  getActiveOrdersRequest: data => ({
    type: Types.getActiveOrdersRequest,
    payload: { data }
  }),

  getActiveOrdersSuccess: data => ({
    type: Types.getActiveOrdersSuccess,
    payload: { data }
  }),

  getActiveOrdersError: error => ({
    type: Types.getActiveOrdersError,
    payload: { error }
  }),

  showOrderRequest: id => ({
    type: Types.showOrderRequest,
    payload: { id }
  }),

  showOrderSuccess: data => ({
    type: Types.showOrderSuccess,
    payload: { data }
  }),

  showOrderError: error => ({
    type: Types.showOrderError,
    payload: { error }
  })
};
