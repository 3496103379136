import React, { Component } from "react";

// Global
import Container from "../../components/Container";
// Styles
import { materialStyle } from "../../styles";

// Material
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as TeamActions } from "../../store/ducks/teams";
import { Actions as LocationActions } from "../../store/ducks/location";

import TeamModel from "../../components/TeamModel";

class TeamEdit extends Component {
  render() {
    const { currentTeam } = this.props.teams;

    return (
      <Container
        title={`editando empresa: ${currentTeam ? currentTeam.name : ""}`}
      >
        <TeamModel {...this.props} action="edit" />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.teams,
  location: state.location
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...TeamActions,
      ...LocationActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(TeamEdit));
