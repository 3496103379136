import React, { useState, forwardRef, useCallback } from "react";

import MaterialTable from "material-table";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Check, AddBoxRounded } from "@material-ui/icons";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

// Global
import Container from "../../components/Container";

// Material
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, MenuItem, TextField } from "@material-ui/core";

// Redux
import api from "../../services/api";
import Spinner from "../../components/Spinner";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import { materialStyle } from "../../styles";
import constants from "../../config/constants";
import history from "../../routes/history";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function IntegrationDataCreate({ classes }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [body, setBody] = useState([
    {
      name: "idSubTeamUnits",
      id: Math.random(),
      value: "Bloco",
      isEditable: false,
      isDeletable: false,
      isDefault: true
    },
    {
      name: "idSubTeamSubUnits",
      id: Math.random(),
      value: "Unidade",
      isEditable: false,
      isDeletable: false,
      isDefault: true
    },
    {
      name: "idSubTeamSubUnitsContact",
      id: Math.random(),
      value: "Morador",
      isEditable: false,
      isDeletable: false,
      isDefault: true
    },
    {
      name: "collectionDescription",
      id: Math.random(),
      value: "value",
      isEditable: false,
      isDeletable: false,
      isDefault: true
    },
    {
      name: "secretCode",
      id: Math.random(),
      value: "Descrição",
      isEditable: false,
      isDeletable: false,
      isDefault: true
    },
    {
      name: "locker",
      id: Math.random(),
      value: "Endereço do locker",
      isEditable: false,
      isDeletable: false,
      isDefault: true
    },
    {
      name: "compartmentNumber",
      id: Math.random(),
      value: "Porta",
      isEditable: false,
      isDeletable: false,
      isDefault: true
    },
    {
      name: "status",
      id: Math.random(),
      value: "Status",
      isEditable: false,
      isDeletable: false,
      isDefault: true
    }
  ]);
  const [url, setUrl] = useState();
  const [whenCall, setWhenCall] = useState(constants.whenCallOptions[0].id);

  const fetch = useCallback(
    async () => {
      try {
        setLoading(true);
        const finalHeaders = {};

        data.map(item => {
          Object.assign(finalHeaders, {
            [item.name]: item.value
          });

          return item;
        });

        const _data = {
          headers: JSON.stringify(finalHeaders),
          url,
          whenCall
        };

        const response = await api.post("/v1/adm/integration/teams", _data);

        history.push(`/integrations/data/edit/${response.data.id}`);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [data]
  );

  return (
    <Container title={""}>
      <ThemeTextFildGreen>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Alert severity="info" style={{ width: "100%", marginBottom: 20 }}>
              <AlertTitle>
                Informe abaixo os dados para chamada de api externa
              </AlertTitle>

              <AlertTitle>Como utilizar?</AlertTitle>

              <ol style={{ marginTop: 10 }}>
                <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                  Para inserir manualmente, clique no botão <AddBoxRounded />,
                  insira os valores e depois clique no botão <Check /> na mesma
                  linha
                </li>
                <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                  Após validar seu dados na tabela, clique no botão{" "}
                  <em>Criar integração</em>
                </li>
              </ol>
            </Alert>

            <h5 className={classes.subTitle}>
              Dados para chamada de API externa
            </h5>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <TextField
                  id="outlined-name"
                  label="URL"
                  className={classes.textField}
                  value={url}
                  margin="normal"
                  variant="outlined"
                  onChange={e => setUrl(e.target.value)}
                />
              </Grid>

              <Grid item xs={6} md={6} className="form-colaboradore-sm">
                <TextField
                  select
                  className={classes.textFieldLocker}
                  value={whenCall}
                  onChange={e => setWhenCall(e.target.value)}
                  label="Quando ativada?"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {constants.whenCallOptions.map(status => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  id="outlined-name"
                  label="Método HTTP"
                  className={classes.textField}
                  value={"POST"}
                  margin="normal"
                  variant="outlined"
                  disabled={true}
                  // onChange={e => setUrl(e.target.value)}
                />
              </Grid>
            </Grid>

            <MaterialTable
              icons={tableIcons}
              disabled={loading}
              title="Headers"
              columns={[
                {
                  title: "Chave",
                  field: "name",
                  emptyValue: false
                },
                {
                  title: "Valor",
                  field: "value",
                  emptyValue: false
                }
              ]}
              data={data}
              localization={{
                body: {
                  editRow: {
                    deleteText: "Deseja excluir este endereço?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Salvar"
                  },
                  addTooltip: "Adicionar",
                  deleteTooltip: "Deletar",
                  editTooltip: "Editar",
                  emptyDataSourceMessage: "Nenhum cabeçalho adicionado"
                },
                header: {
                  actions: ""
                }
              }}
              editable={{
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      setData(data.filter(item => item._id !== oldData._id));
                      resolve();
                    }, 1);
                  }),
                onRowAdd: newData =>
                  new Promise(resolve => {
                    newData._id = Math.random();

                    console.log({ newData });

                    setData([...data, newData]);

                    resolve();
                  }, 1),
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      setData(
                        data.filter(
                          item =>
                            item._id === oldData._id
                              ? (item.name = newData.name)
                              : item
                        )
                      );
                      resolve();
                    }, 1);
                  })
              }}
              options={{
                paging: false,
                search: false,
                actionsColumnIndex: -1,
                toolbarButtonAlignment: "left"
              }}
            />

            <br />

            <MaterialTable
              icons={tableIcons}
              disabled={loading}
              title="Corpo"
              columns={[
                {
                  title: "Chave",
                  field: "name",
                  emptyValue: false
                },
                {
                  title: "Valor",
                  field: "value",
                  emptyValue: false
                }
              ]}
              data={body}
              localization={{
                body: {
                  editRow: {
                    deleteText: "Deseja excluir este endereço?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Salvar"
                  },
                  addTooltip: "Adicionar",
                  deleteTooltip: "Deletar",
                  editTooltip: "Editar",
                  emptyDataSourceMessage: "Nenhum dado extra adicionado"
                },
                header: {
                  actions: ""
                }
              }}
              editable={{
                isEditable: rowData => rowData.isEditable, // Condição para edição
                isDeletable: rowData => rowData.isDeletable,
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      setBody(data.filter(item => item._id !== oldData._id));
                      resolve();
                    }, 1);
                  }),
                onRowAdd: newData =>
                  new Promise(resolve => {
                    newData._id = Math.random();
                    newData.isEditable = true; // ou qualquer lógica que defina se é editável
                    newData.isDeletable = true;

                    console.log({ newData });

                    setBody(prev => [...prev, newData]);

                    resolve();
                  }, 1),
                onRowUpdate: (newData, oldData) =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      setBody(
                        data.filter(
                          item =>
                            item._id === oldData._id
                              ? (item.name = newData.name)
                              : item
                        )
                      );
                      resolve();
                    }, 1);
                  })
              }}
              options={{
                paging: false,
                search: false,
                actionsColumnIndex: -1,
                toolbarButtonAlignment: "left"
              }}
            />
            <Button
              variant="contained"
              className={classes.button}
              onClick={fetch}
              disabled={loading || !data.length}
            >
              Criar integração
            </Button>
          </>
        )}
      </ThemeTextFildGreen>
    </Container>
  );
}

export default withStyles(materialStyle)(IntegrationDataCreate);
