import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as ReportActions } from "../ducks/reports";
import { Actions as ConfigsActions } from "../ducks/configs";

import constants from "../../config/constants";
import checkError from "../../functions/checkError";
import checkHandoverEmail from "../../services/checkHandoverEmail";

export function* getReportsGeneral(action) {
  try {
    const user = JSON.parse(localStorage.getItem("auth")).user;
    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    if (user.idSubTeam) {
      const subTeamDaily = yield call(
        api.get,
        "/v1/adm/reports/general/subTeam/daily"
      );

      yield put(ReportActions.subTeamReportsDailySuccess(subTeamDaily.data));
    } else {
      const response = yield call(api.get, "/v1/adm/reports/general");
      let rental;
      let b2c;

      rental = yield call(api.get, "/v1/adm/reports/general/rental/daily");

      yield put(ReportActions.reportsGeneralSuccess(response.data));

      yield put(ReportActions.reportsRentalSuccess(rental.data));

      if (Boolean(parameters.activeOrderFee) || checkHandoverEmail()) {
        b2c = yield call(api.get, "/v1/adm/reports/general/order/daily");

        yield put(ReportActions.reportsOrdersSuccess(b2c.data));
      }
    }
  } catch (err) {
    yield put(ReportActions.reportsGeneralError(err.response));
  }
}

export function* getReports(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/reports/delivery?${action.payload.data}`
    );
    const deliveries = yield call(
      api.get,
      `/v1/adm/reports/deliveries?${action.payload.data}`
    );

    yield put(ReportActions.reportsSuccess(response.data));
    yield put(ReportActions.reportsGetDeliveriesSuccess(deliveries.data));
  } catch (err) {
    yield put(ReportActions.reportsError(err.response));
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: checkError(500),
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* getDaysReport(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/reports/general/days?${action.payload.data}`
    );

    yield put(ReportActions.reportsGeneralDaysSuccess(response.data));
  } catch (err) {
    yield put(ReportActions.reportsGeneralDaysError(err.response));
  }
}

export function* reportsRentalRequest(action) {
  try {
    const response = yield call(
      api.get,
      "/v1/adm/reports/general/rental/daily"
    );

    yield put(ReportActions.reportsRentalSuccess(response.data));
  } catch (err) {
    yield put(ReportActions.reportsRentalError(err.response));
  }
}
