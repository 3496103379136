import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText'
import { green } from '@material-ui/core/colors'
import { Informations, Requeriment } from './style';
import SnackBar from '../Snackbar';
import api from '../../services/api';
import { useTranslation } from "react-i18next";

export default function FormDialog({ handleOpenPage }) {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState('');

  const [min, setMin] = useState(false);
  const [uppercase, setUppercase] = useState(false);
  const [alphaNumeric, setAlphaNumeric] = useState(false);
  const [validationPassword, setValidationPassword] = useState(false);

  const { t } = useTranslation('password')
  const theme = createMuiTheme({
    palette: {
      primary: green
    },
    typography: {
      fontSize: 12
    }
  })

  const clearState = () => {
    setOldPassword('');
    setPassword('');
    setConfirmPassword('');
    setMin(false);
    setUppercase(false);
    setAlphaNumeric(false);
    setValidationPassword(false);
  }

  const handleChange = func => e => {
    func(e.target.value)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    clearState();
  }

  const handleCancel = () => {
    setOpen(false)
    clearState();
  }

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  const handleUpdate = async() => {
    setLoading(true);
    if (password !== confirmPassword) {
      setMessage(t('distinctPwd'));
      setOpenSnack(true);
      setVariant('error');
      setLoading(false)
    } else {
      try {
        await api.put('v1/adm/business/password/change', {
          oldPassword,
          password,
          password_confirmation: confirmPassword
        })

        handleOpenPage()
        handleClose()
        setLoading(false)
      } catch (error) {
        setMessage(t('wrongPwd'));
        setVariant('error');
        setOpenSnack(true);
        setLoading(false)
      }
    }
  }

  function changePassword(e) {
    const reAlpha = new RegExp(/[a-z]/);
    const reNumber = new RegExp(/[0-9]/);
    const reUppercase = new RegExp(/[A-Z]/);

    const validationsPassword = {
      caracterMin: e.target.value.length > 6,
      caracterAlphaNumeric:
        e.target.value.match(reAlpha) && e.target.value.match(reNumber),
      caracterUppercase: e.target.value.match(reUppercase)
    };

    if (validationsPassword.caracterMin) {
      setMin(true);
    } else {
      setMin(false);
    }
    if (validationsPassword.caracterAlphaNumeric) {
      setAlphaNumeric(true);
    } else {
      setAlphaNumeric(false);
    }
    if (validationsPassword.caracterUppercase) {
      setUppercase(true);
    } else {
      setUppercase(false);
    }
    if (
      validationsPassword.caracterMin &&
      validationsPassword.caracterAlphaNumeric &&
      validationsPassword.caracterUppercase
    ) {
      setValidationPassword(true);
    } else {
      setValidationPassword(false);
    }

    setPassword(e.target.value);
  }

  return (
    <>
      <Button
        variant='contained'
        onClick={handleOpen}
        style={{
          backgroundColor: "#006660",
          color: "#fff",
          textTransform: "lowercase",
          height: 35,
        }}
      >
        {t('changePwd')}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('modalTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {t('rulesMsg')}

          </DialogContentText>

        <Informations>
          <Requeriment validation={min}>{t('rule1')}</Requeriment>
          <Requeriment validation={alphaNumeric}>
          {t('rule2')}
          </Requeriment>
          <Requeriment validation={uppercase}>
          {t('rule3')}
          </Requeriment>
        </Informations>
          <FormControl fullWidth margin='dense'>
          <TextField
            autoFocus
            margin="dense"
            label={t('oldPwd')}
            type='password'
            value={oldPassword}
            onChange={handleChange(setOldPassword)}
            fullWidth
          />
          <TextField
            margin="dense"
            label={t('newPwd')}
            type='password'
            onChange={e => changePassword(e)}
            fullWidth
            value={password}
          />
          <TextField
            margin="dense"
            label={t('confirmPwd')}
            type='password'
            onChange={handleChange(setConfirmPassword)}
            fullWidth
            value={confirmPassword}
          />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
          {t('common:dismiss')}
          </Button>
          <MuiThemeProvider theme={theme}>
            <Button onClick={() => handleUpdate()} color="primary" disabled={loading || !validationPassword}>
              {loading ? t('common:loadingMsg') : t('common:update')}
            </Button>
          </MuiThemeProvider>
        </DialogActions>
        <SnackBar message={message} open={openSnack} onClose={handleCloseSnack} variant={variant} />
      </Dialog>
    </>
  );
}
