import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  TableHead
} from "@material-ui/core";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon
} from "@material-ui/icons";
import queryString from "query-string";

import Spinner from "../../components/Spinner";

// services
import formatDate from "../../functions/formatDate";
import { constants } from "../../config";
import defaultConfig from "../../config/default";
import api from "../../services/api";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class DeliveryPaginationTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    perPage: 20,
    total: 0,
    modalVisible: false,
    currentCompartment: null,
    showDialog: false,
    idCompartment: null,
    loading: true,
    data: []
  };

  async componentWillMount() {
    const { id } = this.props;
    try {
      const alarmData = await api.get(`/v1/adm/lockers/alert/${id}`);

      this.setState({ ...alarmData.data });
    } catch (err) {
    } finally {
      this.setState({ loading: false });
    }
  }

  handleChangePage = async (event, newPage) => {
    try {
      this.setState({ loading: true });
      const { id } = this.props;
      const { perPage } = this.state;

      const query = queryString.stringify({
        page: newPage + 1,
        perPage
      });

      const alarmData = await api.get(`/v1/adm/lockers/alert/${id}?${query}`);

      this.setState({ ...alarmData.data });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChangeRowsPerPage = async event => {
    try {
      this.setState({ loading: true });
      const { id } = this.props;
      const rowsPerPage = parseInt(event.target.value);

      const query = queryString.stringify({
        page: 1,
        perPage: rowsPerPage
      });

      const alarmData = await api.get(`/v1/adm/lockers/alert/${id}?${query}`);

      this.setState({ ...alarmData.data });
    } finally {
      this.setState({ loading: false });
    }
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  parseJSON = (data, key) => {
    try {
      return JSON.parse(data)[key];
    } catch (err) {
      return "";
    }
  };

  render() {
    const classes = this.useStyles2();

    const { emptyRows, loading } = this.state;

    if (loading) return <Spinner />;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className="tableheader">
              <TableRow align="center">
                <TableCell
                  className=" th-delivery tablecell-header"
                  align="center"
                >
                  <p style={{color: "#006660", fontSize: "18px", margin: 0}} >
                    {constants.deliveryTableHeaders.createdAt}
                  </p>
                </TableCell>
                <TableCell
                  className="tablecell-header tablecell-header__initial-table"
                  align="center"
                >
                  <p style={{color: "#006660", fontSize: "18px", margin: 0}} >
                    {constants.deliveryTableHeaders.compartmentNumber}
                  </p>
                </TableCell>
                <TableCell
                  className="th-delivery tablecell-header"
                  align="center"
                >
                  <p style={{color: "#006660", fontSize: "18px", margin: 0}} >
                    mensagem
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map(row => (
                <TableRow key={row.id}>
                  <TableCell
                    className="td-max td-delivery tablecell"
                    align="center"
                  >
                    {formatDate(row.created_at)}
                  </TableCell>
                  <TableCell
                    className="td-max tablecell tablecell-header__initial-table"
                    align="center"
                    style={{
                      flexWrap: "wrap"
                    }}
                  >
                    {this.parseJSON(row.payload, "port")}
                  </TableCell>
                  <TableCell
                    className="td-max td-delivery tablecell"
                    align="center"
                  >
                    {row.type.description}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            count={this.state.total}
            rowsPerPage={this.state.perPage}
            page={this.state.page - 1}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true
            }}
            labelRowsPerPage="Linhas por página"
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} de ${count}`
            }
            rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
            component="div"
          />
        </div>
      </Paper>
    );
  }
}

export default DeliveryPaginationTable;
