import "date-fns";
import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { TextField, Grid, Button } from "@material-ui/core";

// Components
import Spinner from "../../components/Spinner";

// Redux
import { Actions as UserCreditTicketActions } from "../../store/ducks/userCreditTicket";

import { materialStyle } from "../../styles";
import { withStyles } from "@material-ui/core/styles";

import Container from "../../components/Container";

import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import TableUserCreditTickets from "../../components/TableUserCreditTickets";

function UserCreditTickets({
  searchUserCreditTicketRequest,
  userCreditTicket,
  classes
}) {
  const [name, setName] = useState("");

  useEffect(() => {
    searchUserCreditTicketRequest({});
  }, []);

  function search() {
    const { perPage } = userCreditTicket;
    searchUserCreditTicketRequest({ name, perPage });
  }

  return (
    <Container title="extrato de receitas">
      <ThemeTextFildGreen>
        {userCreditTicket.loading ? (
          <Spinner />
        ) : (
          <>
            <Grid container spacing={2} className="first-grid">
              <Grid item xs={12} md={3}>
                <TextField
                  id="outlined-field"
                  label={"Cupom"}
                  className={classes.textField1}
                  value={name}
                  onChange={e => setName(e.target.value)}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Button
              onClick={() => search()}
              variant="contained"
              className={classes.button}
              style={{
                backgroundColor: "#006660",
                color: "#fff",
                textTransform: "lowercase",
                fontSize: 14,
                height: 35
              }}
            >
              Pesquisar
            </Button>
            <Button
              onClick={() => {
                setName("");
                searchUserCreditTicketRequest({});
              }}
              variant="contained"
              className={classes.button}
              style={{
                backgroundColor: "#006660",
                color: "#fff",
                textTransform: "lowercase",
                fontSize: 14,
                height: 35
              }}
            >
              Limpar filtros
            </Button>

            <TableUserCreditTickets
              classes={classes}
              getQuerySearch={() => {
                return { name };
              }}
            />
          </>
        )}
      </ThemeTextFildGreen>
    </Container>
  );
}

const mapStateToProps = state => ({
  userCreditTicket: state.userCreditTicket
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...UserCreditTicketActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(UserCreditTickets));
