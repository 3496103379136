export default {
  background: "#fafafa",
  dark0: "#e0e0e0",
  dark1: "#c1c1c1",
  disabled: "#c1c1c1",
  dark2: "#4a4b4a",
  link: "#ff8200",
  primary: "#006660",
  error: "#ff3333",
  white: "#fff",

  NotBlack: "#1B301B",
  DarkGreen: "#006660",
  UnstoppableGreen: "#2FE076",
  LightGreen: "#EFF7EB",
  TechyGreen: "#E1FFB0"
};
