import { call, put } from 'redux-saga/effects';
import api from '../../services/api';

import { Actions as MemberActions } from '../ducks/members';

export function* getMembers(action) {
  try {
    const response = yield call(api.get, `/v1/adm/members?${action.payload.data}`);

    yield put(MemberActions.getMembersSuccess({
      data: response.data.data,
      total: response.data.total,
      perPage: response.data.perPage,
      lastPage: response.data.lastPage,
      page: response.data.page,
    }));
  } catch (err) {
    yield put(MemberActions.getMembersError(err.data));
  }
}
