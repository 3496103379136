import queryString from "query-string";
import React, { Component } from "react";

// Material
import { Grid, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Global
import Container from "../../components/Container";

import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LaundryActions } from "../../store/ducks/laundry";

import { constants } from "../../config";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import { ContainerButtons } from "./styles";
import ButtonClick from "../../components/ButtonClick";
import LaundryUsersTable from "../../components/LaundryUsersTable";
import Spinner from "../../components/Spinner";

class LaundryUsers extends Component {
  state = {
    isActive: constants.lockerStatusOptions[0].value,
    idLocker: "",
    name: "",
    email: "",
    cpf: ""
  };

  componentWillMount() {
    const { getLaundryUsersRequest } = this.props;

    const query = this.handleQueryString({
      perPage: 10
    });

    getLaundryUsersRequest(query);
  }

  handleQueryString(query) {
    return queryString.stringify(query);
  }

  handleChange = key => event => {
    this.setState({ [key]: event.target.value });
  };

  handleClear = () => {
    const { getLaundryUsersRequest } = this.props;

    const query = this.handleQueryString({
      perPage: 10
    });

    getLaundryUsersRequest(query);

    this.setState({
      name: "",
      email: "",
      cpf: ""
    });
  };

  handleSearch = () => {
    const { name, email, cpf } = this.state;
    const { getLaundryUsersRequest } = this.props;

    getLaundryUsersRequest(
      queryString.stringify({
        name,
        email,
        cpf
      })
    );
  };

  render() {
    const { history, laundry, classes } = this.props;
    const { name, email, cpf } = this.state;

    return (
      <Container>
        <ThemeTextFildGreen>
          {laundry.loading ? (
            <Spinner />
          ) : (
            <>
              <Grid container spacing={2} className="first-grid">
                <Grid item xs={12} md={3}>
                  <TextField
                    id="outlined-field"
                    label={"Nome"}
                    className={classes.textField1}
                    value={name}
                    onChange={this.handleChange("name")}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="outlined-field"
                    label={"E-mail"}
                    className={classes.textField1}
                    value={email}
                    onChange={this.handleChange("email")}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="outlined-field"
                    label={"CPF"}
                    className={classes.textField1}
                    value={cpf}
                    onChange={this.handleChange("cpf")}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <CustomAutoComplete
                options={teams.data}
                value={teams.data.find(item => item.id === idTeam) || null}
                onChange={newValue => {
                  this.handleChange("idTeam")({
                    target: { value: newValue ? newValue.id : "" }
                  });
                }}
                disabled={teams.loading}
                loading={teams.loading}
                getOptionLabelKey="name"
                label="Cliente"
              />
            </Grid> */}

                {/* <Grid item xs={12} md={3} className="form-colaboradore-sm">
              <CustomAutoComplete
                options={lockers.dataSelect || []}
                value={
                  lockers.dataSelect.find(item => item.id === idLocker) || null
                }
                onChange={newValue => {
                  this.handleChange("idLocker")({
                    target: { value: newValue ? newValue.id : "" }
                  });
                }}
                disabled={lockers.loading}
                loading={lockers.loading}
                getOptionLabelKey="address"
                label="Locker"
              />
            </Grid> */}
              </Grid>

              <ContainerButtons
                style={{ marginLeft: "-10px" }}
                className="marginButton"
              >
                <ButtonClick onClick={() => this.handleSearch()}>
                  Pesquisar
                </ButtonClick>
                <ButtonClick onClick={() => this.handleClear()}>
                  Limpar
                </ButtonClick>
              </ContainerButtons>
              <LaundryUsersTable history={history} />
            </>
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  laundry: state.laundry
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(LaundryActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(LaundryUsers));
