import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Container from "../../../../components/Container";
import Image from "../Image";

function Tutorials() {
  return (
    <Container title="">
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography
            variant="h4"
            align="center"
            style={{ textTransform: "lowercase" }}
          >
            Criando uma entrega pelo app handover Drop
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            1. Com app logado em uma conta com perfil para criar, clique no
            botão <em>Criar uma entrega</em>.
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-home.png"
            alt="drop-home"
            isPhone={true}
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            2. A seguir, selecione o <em>locker</em> e preencha todos os campos
            disponíveis conforme a sua operação. Verifique se as informações
            inseridas estão corretas e clique no botão <em>Criar</em>.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-delivery-create.png"
            alt="drop-delivery-create"
            isPhone={true}
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            3. Ao criar, será redirecionado para a lista de todas as locações
            criadas e a que acabamos de criar será a primeira da lista. Clique
            na primeira entrega.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-delivery-list.png"
            alt="drop-cpf"
            isPhone={true}
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            4. Agora vemos os deltalhes da entrega e os botões para interagir
            com a abertura de porta e finalização de processo.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-delivery-details.png"
            alt="drop-cpf"
            isPhone={true}
            shaddow={true}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Tutorials;
