import styled from "styled-components";

export const Container2 = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffffff;
  box-shadow: none;
  div {
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: row;
    margin-left: 15%;
    margin-top: 8%;
    h1 {
      font-family: "Livvic", sans-serif;
      font-size: 4vw;
      text-transform: lowercase;
    }
    #logo_not_found {
      margin-top: 0%;
      margin-left: auto;
    }
  }
`;
