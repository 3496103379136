import React, { useCallback, useState } from "react";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { Grid, TextField, withStyles } from "@material-ui/core";

import ButtonClick from "../../components/ButtonClick";
import { materialStyle } from "../../styles";
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import api from "../../services/api";
import TextMaskCustomCPF from "../../components/TextMaskCustomCPF";
import SimpleTable from "../../components/SimpleTable";
import formatMobile from "../../utils/formatMobile";
import formatDate from "../../functions/formatDate";
import { constants } from "../../config";
import { Actions as ConfigActions } from "../../store/ducks/configs";

function RentalUser({ classes }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    total: 1,
    perPage: 10,
    page: 1,
    lastPage: 1,
    data: []
  });
  const [search, setSearch] = useState({
    name: {
      label: "Nome",
      value: "",
      type: "input",
      mask: null
    },
    cpf: {
      label: "CPF",
      value: "",
      type: "number",
      mask: TextMaskCustomCPF
    },
    email: {
      label: "Email",
      value: "",
      type: "input",
      mask: null
    }
  });

  const fetch = useCallback(
    async () => {
      try {
        if (!Object.keys(search).find(item => search[item].value)) {
          dispatch(
            ConfigActions.setToastVisible({
              toastVisible: true,
              toastMessage: "Campos vazios",
              toastVariant: constants.toastVariants.warning
            })
          );
          return;
        }

        setLoading(true);
        let _search = {};

        Object.keys(search).map(item => {
          if (search[item].value) {
            Object.assign(_search, {
              [item]: search[item].value
            });
          }
        });

        const response = await api.get(
          `/v1/adm/rental/users?${queryString.stringify(_search)}`
        );

        setData(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [search]
  );

  return (
    <Container>
      {loading ? (
        <Spinner />
      ) : (
        <ThemeTextFildGreen>
          <Grid container spacing={2} className="first-grid">
            {Object.keys(search).map(key => (
              <Grid item xs={3} md={3}>
                <TextField
                  id="outlined-field"
                  label={search[key].label}
                  className={classes.textField1}
                  value={search[key].value}
                  onChange={e => {
                    e.persist();

                    setSearch(old => ({
                      ...old,
                      [key]: {
                        ...old[key],
                        value: e.target ? e.target.value : old[key].value
                      }
                    }));
                  }}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    type: search[key].type
                  }}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={1}>
            <ButtonClick onClick={() => fetch()} disabled={loading}>
              Buscar
            </ButtonClick>
            <ButtonClick
              onClick={() => {
                setData(old => ({
                  ...old,
                  data: []
                }));
                Object.keys(search).map(item => {
                  setSearch(old => ({
                    ...old,
                    [item]: {
                      ...old[item],
                      value: ""
                    }
                  }));
                });
              }}
              disabled={loading}
            >
              limpar
            </ButtonClick>
          </Grid>
          <br />

          <SimpleTable
            columns={[
              {
                id: Math.random(),
                text: "Nome"
              },
              {
                id: Math.random(),
                text: "Email"
              },
              {
                id: Math.random(),
                text: "CPF"
              },
              {
                id: Math.random(),
                text: "Celular"
              },
              {
                id: Math.random(),
                text: "Status"
              },
              {
                id: Math.random(),
                text: "Data de cadastro"
              }
            ]}
            rowValues={data.data.map(item => {
              const newItem = {};

              Object.assign(newItem, {
                id: item.id,
                data: [
                  {
                    id: Math.random(),
                    value: item.name
                  },
                  {
                    id: Math.random(),
                    value: item.email
                  },
                  {
                    id: Math.random(),
                    value: item.cpf
                  },
                  {
                    id: Math.random(),
                    value: formatMobile(item.phone)
                  },
                  {
                    id: Math.random(),
                    value: item.status ? "Ativo" : "Desativado"
                  },
                  {
                    id: Math.random(),
                    value: formatDate(item.created_at)
                  }
                ]
              });

              return newItem;
            })}
          />
        </ThemeTextFildGreen>
      )}
    </Container>
  );
}

export default withStyles(materialStyle)(RentalUser);
