export const Types = {
  customerFeedbackRequest: "customerFeedback/Request",
  customerFeedbackSuccess: "customerFeedback/Success",
  customerFeedbackError: "customerFeedback/Error"
};

const INIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  total: null,
  perPage: null,
  lastPage: null,
  page: null
};

export default function customerFeedback(state = INIAL_STATE, action) {
  switch (action.type) {
    case Types.customerFeedbackRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.customerFeedbackSuccess:
      return {
        ...state,
        ...action.payload.data,
        loading: false
      };

    case Types.customerFeedbackError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
}

export const Actions = {
  customerFeedbackRequest: data => ({
    type: Types.customerFeedbackRequest,
    payload: { data }
  }),

  customerFeedbackSuccess: data => ({
    type: Types.customerFeedbackSuccess,
    payload: { data }
  }),

  customerFeedbackError: error => ({
    type: Types.customerFeedbackError,
    payload: { error }
  })
};
