import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 30px;
`;

export const Header = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #4ac151;
  font-family: "Open Sans", sans-serif;
  @media (max-width: 600px) {
    height: 60px;
  }
`;

export const TitleContain = styled.div`
  width: 100%;
  margin: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  justify-content: center;
  background: #4ac151;
  padding: 0 20px;
  font-family: "Livvic", sans-serif;
  color: #fff;
  font-size: 25px;
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Livvic", sans-serif;
  padding: 30px 80px;
  @media (max-width: 600px) {
    padding: 30px 30px;
  }
`;

export const ImageLogo = styled.img`
  margin-top: 15px;
  height: 90px;
  width: 90px;
  @media (max-width: 600px) {
    height: 55px;
    width: 55px;
    margin-top: 0;
  }
`;

export const TitleForm = styled.h2`
  font-family: "Livvic", sans-serif;
  color: #4ac151;
  font-size: 20px;
  font-weight: bold;
`;

export const SubTitleForm = styled.h3`
  font-family: "Livvic", sans-serif;
  color: #666;
  font-size: 18px;
  font-weight: bold;
`;

export const Form = styled.form`
  font-family: "Livvic", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormDiv = styled.div`
  font-family: "Livvic", sans-serif;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  font-family: "Livvic", sans-serif;
  flex-direction: column;
  width: 100%;
  @media (max-width: 600px) {
    width: 100%;
  }
  & label {
    margin-bottom: -3px;
    margin-top: 5px;
    font-family: "Livvic", sans-serif;
  }
  & input {
    border-radius: 5px;
    font-family: "Livvic", sans-serif;
    border: ${props => (props.colorInput ? "1px solid #ddd" : "1px solid red")};
    padding: 5px 10px;
    width: 100px;
  }
  & spacer {
    height: 10px;
  }
  & div {
    flex-direction: column;
    font-family: "Livvic", sans-serif;
    justify-content: space-between;
  }
  & line {
    display: flex;
    line-height: 10px;
    padding: 5px;
  }
  & h1 {
    font-size: 15px;
    font-weight: 10;
    font-family: "Livvic", sans-serif;
  }
`;

export const Spacer = styled.div`
  width: 100%;
  display: flex;
  height: 15px;
  font-family: "Livvic", sans-serif;
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Livvic", sans-serif;
  width: 100%;
  & button {
    color: #fff;
    border: 0;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Livvic", sans-serif;
    cursor: pointer;
    font-family: "Livvic", sans-serif;
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background: ${props => (props.colorBackButton ? "#eee" : "#4ac151")};
    &:hover {
      font-family: "Livvic", sans-serif;
      background: ${props => (props.colorBackButton ? "#4ac000" : "#eee")};
    }
  }
`;

export const ContainerResposta = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 80px;
  font-family: "Livvic", sans-serif;
  @media (max-width: 600px) {
    padding: 30px 30px;
  }
  & div {
    width: 100%;
    font-family: "Livvic", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & strong {
    font-weight: 20px;
    font-size: 20spx;
  }
  & spacer {
    height: 5px;
  }
  & h1 {
    color: #4ac151;
    font-family: "Livvic", sans-serif;
    font-size: 22px;
  }
  & h2 {
    /* font-weight: bold; */
    font-size: 20px;
    font-family: "Livvic", sans-serif;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
`;

export const Linha = styled.div`
  display: flex;
  justify-content: space-around;
  font-family: "Livvic", sans-serif;
  flex-direction: row;
  height: 300px;
`;

export const LittleSpacer = styled.div`
  width: 100%;
  display: flex;
  font-family: "Livvic", sans-serif;
  height: 1px;
  width: 3px;
`;

export const ContainerWithLocker = styled.div`
  border-radius: 5px;
  flex-direction: column;
  font-family: "Livvic", sans-serif;
  margin-top: 10px;
  margin-bottom: 40px;
  justify-content: space-between;
  background: #f7f7f7;
  color: black;
  padding: 5px 20px;
  width: 400px;
  line-height: 25px;
  & line {
    display: flex;
    justify-content: space-between;
    font-family: "Livvic", sans-serif;
    flex-direction: row;
  }
  & h2 {
    font-weight: 20px;
    color: #4ac151;
    font-size: 16px;
    padding-bottom: 5px;
    display: flex;
    font-family: "Livvic", sans-serif;
    justify-content: center;
    align-items: center;
  }
`;

export const ContainerWithoutLocker = styled.div`
  border-radius: 10px;
  flex-direction: column;
  font-family: "Livvic", sans-serif;
  margin-top: 10px;
  margin-bottom: 40px;
  justify-content: space-between;
  background: #f7f7f7;
  color: black;
  padding: 5px 20px;
  width: 400px;
  line-height: 25px;
  & line {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-family: "Livvic", sans-serif;
  }

  & h2 {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 5px;
    font-family: "Livvic", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Livvic", sans-serif;
  justify-content: center;
  align-self: center;
  background: #c6f1c9;
  padding: 5px;
  margin: 10px 300px;
  border-radius: 10px;

  & line {
    display: flex;
    justify-content: center;
    font-family: "Livvic", sans-serif;
    color: black;
    border: 0;
    line-height: 25px;
    padding: 5px;
  }

  & strong {
    font-weight: 20px;
    font-family: "Livvic", sans-serif;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Livvic", sans-serif;
  justify-content: center;
  align-items: center;

  /* margin-bottom: 20px; */

  & strong {
    font-weight: 20px;
    font-family: "Livvic", sans-serif;
  }

  & h2 {
    font-weight: 20px;
    color: #4ac151;
    font-size: 18px;
    padding-bottom: 5px;
    font-family: "Livvic", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    margin: 10px 60px;
  }
`;

export const ContainerSchedule = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  & a {
    display: flex;
    font-family: "Livvic", sans-serif;
    justify-content: center;
    align-items: center;
    background: #4ac151;
    color: #fff;
    border: 0;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      background: #4ac000;
      text-decoration: none;
      font-family: "Livvic", sans-serif;
    }
    &:active {
      text-decoration: none;
      font-family: "Livvic", sans-serif;
    }
    width: 410px;
    height: 40px;
    border-radius: 5px;
  }
`;
