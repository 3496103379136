import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import formatDate from "../../functions/formatDate";
import { withTranslation } from "react-i18next";


const walletLogs = [
  "",
  "Locação",
  "Depósito",
  "(extensão aut.)",
  "extensão manual",
  "Cupom aplicado"
];

const walletLogsEn = [
  "",
  "Rent",
  "Deposit",
  "(authorized extension)",
  "manual extension",
  "coupom"
];

class WalletLogsTable extends Component {
  t = this.props.t
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  render() {
    const classes = this.useStyles2();
    const { data } = this.props;

    const { emptyRows } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className="tableheader">
              <TableRow align="center">
                <TableCell
                  className="tablecell-header tablecell-header__initial-table"
                  align="center"
                >
                  <p id="table_Cell">{this.t('common:description')}</p>
                </TableCell>
                <TableCell
                  className="tablecell-header tablecell-header__initial-table"
                  align="center"
                >
                  <p id="table_Cell">{this.t('value')}</p>
                </TableCell>
                <TableCell
                  className="tablecell-header td-business-simple-table"
                  align="center"
                >
                  <p id="table_Cell">{this.t('bonus')}</p>
                </TableCell>
                <TableCell
                  className="tablecell-header td-business-simple-table"
                  align="center"
                >
                  <p id="table_Cell">{this.t('common:date')}</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tbody-business-simple-table">
              {data &&
                data.map(row => (
                  <TableRow key={row.id}>
                    <TableCell
                      className="tablecell tablecell-header__initial-table"
                      align="center"
                    >
                      {this.props.i18n === 'en' ? walletLogsEn[row.type] : walletLogs[row.type]}
                    </TableCell>
                    <TableCell
                      className="tablecell tablecell-header__initial-table"
                      align="center"
                    >
                      {`R$ ${row.message}`}
                    </TableCell>
                    <TableCell
                      className="tablecell td-business-simple-table"
                      align="center"
                    >
                      {`R$ ${row.used_bonus}`}
                    </TableCell>
                    <TableCell
                      className="tablecell td-business-simple-table"
                      align="center"
                    >
                      {formatDate(row.createdAt)}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default withTranslation('spending')(WalletLogsTable);
