import axios from "axios";

const setHeaders = async request => {
  const token = await JSON.parse(localStorage.getItem("auth")).token;

  if (token) {
    request.headers.Authorization = token;
  }

  return request;
};

const setEnv = () => {
  if (process.env.REACT_APP_STAGING) {
    return "https://notification.sandbox.dashboard.oihandover.com";
  }

  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_NOTIFICATIONS
    : "https://azapi.oihandover.com/api-notifications";
};

const api = axios.create({
  baseURL: setEnv(),
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});

api.interceptors.request.use(request => setHeaders(request));

export default api;
