import React, { Component } from "react";

// Global Components
import Container from "../../components/Container";

// Material
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, MenuItem, Grid } from "@material-ui/core";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as ThingTypeActions } from "../../store/ducks/thingTypes";
import { Actions as ThingsActions } from "../../store/ducks/things";

//configs
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

class ThingCreate extends Component {
  state = {
    idThingType: "",
    name: "",
    inUse: 0,
    is_active: 0,
    currentThing: null
  };

  // Load Roles and teams
  componentWillMount() {
    const {
      thingTypesRequest,
      thingsShowRequest,
      match: { params }
    } = this.props;
    thingTypesRequest();

    if (Object.keys(params).includes("id")) {
      thingsShowRequest(params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.things.currentThing !== this.state.currentThing) {
      this.setState({
        ...nextProps.things.currentThing
      });
    }
  }

  // Handle Change global
  handleChange = name => event => {
    let value = event.target.value;

    this.setState({ [name]: value });
  };

  // create new team
  handleCreate = () => {
    const {
      thingsCreateRequest,
      thingsUpdateRequest,
      things,
      match: { params }
    } = this.props;

    if (things.currentThing) {
      thingsUpdateRequest(params.id, this.state);
    } else {
      thingsCreateRequest(this.state);
    }
  };

  render() {
    const { classes, thingTypes, things } = this.props;
    const { name, idThingType, inUse, is_active } = this.state;

    return (
      <Container title={things.currentThing ? "Editar Thing" : "Criar Thing"}>
        <ThemeTextFildGreen>
          <h3 style={{ marginTop: 20 }}>
            {things.currentThing
              ? `Dados do thing ${things.currentThing.name}`
              : "Dados do novo Thing"}
          </h3>
          <Grid className="first-grid" container spacing={2}>
            <Grid
              item
              xs={12}
              sm={3}
              className="form-colaboradore-sm"
              alignItems="flex-start"
            >
              <TextField
                required
                id="outlined-name"
                label="Nome"
                className={classes.textField}
                value={name}
                onChange={this.handleChange("name")}
                margin="normal"
                variant="outlined"
                disabled={things.loading}
              />
            </Grid>

            <Grid item xs={12} sm={3} className="form-colaboradore-sm">
              <TextField
                required
                disabled={thingTypes.loading}
                id="custom-css-outlined-input"
                select
                label="Tipo"
                className={classes.selectType}
                value={idThingType}
                onChange={this.handleChange("idThingType")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                {thingTypes.data.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={3} className="form-colaboradore-sm">
              <TextField
                required
                disabled={thingTypes.loading}
                id="custom-css-outlined-input"
                select
                label="Status"
                className={classes.selectType}
                value={inUse}
                onChange={this.handleChange("inUse")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                <MenuItem key={0} value={0}>
                  Livre
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Em uso
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={3} className="form-colaboradore-sm">
              <TextField
                required
                disabled={thingTypes.loading}
                id="custom-css-outlined-input"
                select
                label="Habilitado"
                className={classes.selectType}
                value={is_active}
                onChange={this.handleChange("is_active")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
              >
                <MenuItem key={0} value={0}>
                  Não
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Sim
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <br />
          <Button
            disabled={things.loading}
            onClick={this.handleCreate}
            variant="contained"
          >
            <SaveIcon className={classes.leftIcon} />
            {things.currentThing ? "Salvar" : "Criar"}
          </Button>
          <Button
            variant="contained"
            onClick={() => this.props.history.push("/things")}
            className={classes.button}
          >
            <CancelIcon className={classes.leftIcon} />
            Cancelar
          </Button>
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  things: state.things,
  thingTypes: state.thingTypes,
  groups: state.groups
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ThingTypeActions,
      ...ThingsActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(ThingCreate));
