import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { colors, materialStyle } from "../../styles";

class ButtonEditorCurrentRegister extends Component {
  static propType = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    icon: PropTypes.string
  };

  static defaultProps = {
    label: "DEFAULT",
    onClick: () => {},
    disabled: false,
    Icon: EditIcon
  };

  render() {
    const { classes, label, onClick, disabled, Icon } = this.props;

    return (
      <Grid item>
        <Button
          variant="contained"
          onClick={onClick}
          disabled={disabled}
          className={classes.button}
          style={{
            // para os Buttons
            backgroundColor: disabled ? colors.dark1 : "#006660",
            fontSize: 14,
            color: "#fff",
            textTransform: "lowercase",
            height: 35
          }}
        >
          <Icon className={classes.leftIcon} />
          {label}
        </Button>
      </Grid>
    );
  }
}

export default withStyles(materialStyle)(ButtonEditorCurrentRegister);
