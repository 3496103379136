import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

/**
 * Redux & Saga
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions as UserActions } from '../../store/ducks/users';

/**
 * Globa Components
 */
import Container from '../../components/Container';
import Snackbar from '../../components/Snackbar';
import TextMaskCustomLandline from '../../components/TextMaskCustomLandline';
import TextMaskCustomLandMobile from '../../components/TextMaskCustomMobile';
import TextMaskCustomLandCPF from '../../components/TextMaskCustomCPF';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

class UserEdit extends Component {
  state = {
    name:'',
    lastName: '',
    mobile: '',
    landline: '',
    birthdate: '',
    notChange: true,
  }

  componentWillMount () {
    const { match: { params: { id }}, searchUserRequest } = this.props;
    searchUserRequest(id);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.searchData !== this.state.searchData) {
      this.setState({ searchData: nextProps.searchData })
    }
  }

  handleChange = key => event => {
    this.setState({
      searchData: {
        ...this.state.searchData,
        [key]: event.target.value
      },
      notChange: false
    });
  };

  handleSave = () => {
    const { match: { params: { id }}, updateUserRequest } = this.props;
    const resultLocal = Object.keys(this.state.searchData);
    const resultProps = Object.keys(this.props.searchData);
    let countCheck = 0;

    for (let i = 0; i < resultLocal.length; i++) {
      if (this.state.searchData[resultLocal[i]] !== this.props.searchData[resultProps[i]]) {
        countCheck += 1
      }
    }

    if (!countCheck) {
      return;
    }

    let newData = {}

    for (let i = 0; i < resultLocal.length; i++) {
      if (this.state.searchData[resultLocal[i]] !== this.props.searchData[resultProps[i]]) {
        if (resultLocal[i] === 'mobile' || resultLocal[i] === 'landline') {
          newData[resultLocal[i]] = (this.state.searchData[resultLocal[i]])
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(' ', '')
            .trim()
        } else {
          newData[resultLocal[i]] = this.state.searchData[resultLocal[i]]
        }
      }
    }

    updateUserRequest(id, {
      ...newData
    });
    this.setState({ notChange: true });
  }

  render() {
    const { classes, loading, setSnackbar, snackbar } = this.props;
    const { searchData, notChange } = this.state;

    return(
      <Container>
        <Snackbar
          open={snackbar}
          variant="success"
          onClose={setSnackbar}
          message="Atualizado"
        />
        {
          searchData && (
            <>
            <TextField
              id="outlined-name"
              label="ID"
              className={classes.textField}
              value={searchData.idSharing}
              margin="normal"
              variant="outlined"
              disabled={true}
            />
          <TextField
            id="outlined-name"
            label="Nome"
            className={classes.textField}
            value={searchData.name}
            onChange={this.handleChange('name')}
            margin="normal"
            variant="outlined"
            disabled={loading}
          />
          <TextField
            id="outlined-name"
            label="Sobrenome"
            className={classes.textField}
            value={searchData.lastName}
            onChange={this.handleChange('lastName')}
            margin="normal"
            variant="outlined"
            disabled={loading}
          />
          <TextField
            id="outlined-name"
            label="email"
            className={classes.textField}
            value={searchData.email}
            margin="normal"
            variant="outlined"
            disabled={true}
          />
          <TextField
            id="outlined-name"
            label="Celular"
            className={classes.textField}
            value={searchData.mobile}
            onChange={this.handleChange('mobile')}
            margin="normal"
            variant="outlined"
            disabled={loading}
            InputProps={{
              inputComponent: TextMaskCustomLandMobile
            }}
          />
          <TextField
            id="outlined-name"
            label="Telefone"
            className={classes.textField}
            value={searchData.landline}
            onChange={this.handleChange('landline')}
            margin="normal"
            variant="outlined"
            disabled={loading}
            InputProps={{
              inputComponent: TextMaskCustomLandline
            }}
          />
          <TextField
            id="outlined-name"
            label="CPF"
            className={classes.textField}
            value={searchData.cpf}
            margin="normal"
            variant="outlined"
            disabled={this.props.searchData.cpf ? true : false}
            InputProps={{
              inputComponent: TextMaskCustomLandCPF
            }}
            onChange={this.handleChange('cpf')}
          />
          <TextField
            id="outlined-name"
            label="Data de nascimento"
            className={classes.textField}
            value={searchData.birthdate}
            onChange={this.handleChange('birthdate')}
            margin="normal"
            variant="outlined"
            disabled={loading}
          />
          <TextField
            id="outlined-name"
            label="Data de cadastro"
            className={classes.textField}
            value={searchData.createdAt}
            margin="normal"
            variant="outlined"
            disabled={true}
          />
          </>
          )
        }
        <div>
        <Button
          variant="contained"
          onClick={this.handleSave}
          disabled={loading || notChange}
        >Salvar</Button></div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.users.loading,
  error: state.users.error,
  searchData: state.users.searchData,
  setSnackbar: state.users.setSnackbar,
  snackbar: state.users.snackbar,
});

const mapDispatchToProps = dispatch => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserEdit));
