import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonClick from "../ButtonClick";
import { useTranslation } from "react-i18next";
export default function CustomDialogContent({
  children,
  onConfirm,
  onConfirmLabel,
  subTitle,
  title,
  onClose,
  loading,
  classes,
  externalLabel,
  CustomIcon
}) {
  const {t} = useTranslation("config");
  const [open, setOpen] = React.useState(false);
  const fullWidth = true;
  const maxWidth = "lg";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonClick onClick={() => handleClickOpen()} disabled={loading}>
        {CustomIcon ? <CustomIcon className={classes.leftIcon} /> : null}
        {externalLabel}
      </ButtonClick>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.subTitle}>
            {subTitle}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <ButtonClick disabled={loading} onClick={() => handleClose()}>
            {t("common:back")}
          </ButtonClick>
          <ButtonClick disabled={loading} onClick={() => onConfirm()}>
            {t("common:next")}
          </ButtonClick>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

CustomDialogContent.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  onConfirmLabel: "Próximo",
  title: "",
  subTitle: "",
  loading: false,
  externalLabel: "Exportar"
};
