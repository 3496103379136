import { call, put } from "redux-saga/effects";
import api from "../../../services/api";

import { Actions as ReportActions } from "../../ducks/reports";

export function* subTeamReportDaily(action) {
  try {
    const response = yield call(
      api.get,
      "/v1/adm/reports/general/subTeam/daily"
    );

    yield put(ReportActions.subTeamReportsDailyRequest(response.data));
  } catch (err) {
    yield put(ReportActions.subTeamReportsDailyError(err.response));
  }
}
