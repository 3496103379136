export const Types = {
  azureActiveDirectoryRequest: "azureActiveDirectory/Request",
  azureActiveDirectorySuccess: "azureActiveDirectory/Success",
  azureActiveDirectoryError: "azureActiveDirectory/Error",

  createAzureActiveDirectoryRequest: "createAzureActiveDirectory/Request",
  createAzureActiveDirectorySuccess: "createAzureActiveDirectory/Success",
  createAzureActiveDirectoryError: "createAzureActiveDirectory/Error",

  updateAzureActiveDirectoryRequest: "updateAzureActiveDirectory/Request",
  updateAzureActiveDirectorySuccess: "updateAzureActiveDirectory/Success",
  updateAzureActiveDirectoryError: "updateAzureActiveDirectory/Error"
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null
};

export default function azureActiveDirectory(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.azureActiveDirectoryRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.createAzureActiveDirectoryRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.updateAzureActiveDirectoryRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.azureActiveDirectorySuccess:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };

    case Types.createAzureActiveDirectorySuccess:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };

    case Types.updateAzureActiveDirectorySuccess:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };

    case Types.azureActiveDirectoryError:
      return {
        ...state,
        error: action.payload.error,
        laoding: false
      };

    case Types.createAzureActiveDirectoryError:
      return {
        ...state,
        error: action.payload.error,
        laoding: false
      };

    case Types.updateAzureActiveDirectoryError:
      return {
        ...state,
        error: action.payload.error,
        laoding: false
      };

    default:
      return state;
  }
}

export const Actions = {
  azureActiveDirectoryRequest: data => ({
    type: Types.azureActiveDirectoryRequest,
    payload: { data }
  }),
  azureActiveDirectorySuccess: data => ({
    type: Types.azureActiveDirectorySuccess,
    payload: { data }
  }),
  azureActiveDirectoryError: error => ({
    type: Types.azureActiveDirectoryError,
    payload: { error }
  }),

  createAzureActiveDirectoryRequest: data => ({
    type: Types.createAzureActiveDirectoryRequest,
    payload: { data }
  }),
  createAzureActiveDirectorySuccess: data => ({
    type: Types.createAzureActiveDirectorySuccess,
    payload: { data }
  }),
  createAzureActiveDirectoryError: error => ({
    type: Types.createAzureActiveDirectoryError,
    payload: { error }
  }),

  updateAzureActiveDirectoryRequest: data => ({
    type: Types.updateAzureActiveDirectoryRequest,
    payload: { data }
  }),
  updateAzureActiveDirectorySuccess: data => ({
    type: Types.updateAzureActiveDirectorySuccess,
    payload: { data }
  }),
  updateAzureActiveDirectoryError: error => ({
    type: Types.updateAzureActiveDirectoryError,
    payload: { error }
  })
};
