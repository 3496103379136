import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as GroupsActions } from "../ducks/groups";
import { Actions as ConfigActions } from "../ducks/configs";
import { constants } from "../../config";

export function* groupsRequest(action) {
  try {
    const response = yield call(api.get, "/v1/adm/groups");

    yield put(GroupsActions.groupsSuccess(response.data));
  } catch (err) {
    yield put(GroupsActions.groupsError(err.data));
  }
}

export function* showGroupRequest(action) {
  try {
    const response = yield call(api.get, `/v1/adm/groups/${action.payload.id}`);

    yield put(GroupsActions.showGroupSuccess(response.data));
  } catch (err) {
    yield put(GroupsActions.showGroupError(err.data));
  }
}

export function* updateThingGroupRequest(action) {
  try {
    const { data, id } = action.payload;

    yield call(api.put, `/v1/adm/groups/things/types/${id}`, { data });
    const response = yield call(api.get, `/v1/adm/groups/${id}`);

    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Atualizado",
        toastVariant: constants.toastVariants.success
      })
    );

    yield put(GroupsActions.updateThingGroupSuccess(response.data));
  } catch (err) {
    yield put(GroupsActions.updateThingGroupError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}
