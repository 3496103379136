import React, { useState, useCallback } from 'react';
import queryString from 'query-string';
import { Alert, AlertTitle } from '@material-ui/lab';

import api from '../../services/api';

// Global
import Container from '../../components/Container';
import ImportExcelFileButton from '../../components/ImportExcelFileButton';
import SubTeamWizardTable from '../../components/SubTeamWizardTable';

// Material
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Spinner from '../../components/Spinner';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1),
  },
  selectType: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  tabContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0',
    },
  },
});

function SubTeamUnitsCreate({ classes, location }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);

  const { idSubTeam } = queryString.parse(location.search);
  const { t } = useTranslation('data_list');

  const fetch = useCallback(
    async () => {
      setLoading(true);
      let onError = false;

      for (let i = 0; i < data.length; i++) {
        try {
          await api.post('/v1/adm/subTeams/unique/data/wizard', data[i]);

          setData(
            data.map(item => {
              if (item._id === data[i]._id) {
                item.status = 1;
              }
              return item;
            })
          );
        } catch (err) {
          onError = true;
          setData(
            data.map(item => {
              if (item._id === data[i]._id) {
                item.status = -1;
              }
              return item;
            })
          );
        }
        setCounter(i + 1);
      }

      if (onError) {
        alert(t('error'));
      }

      setLoading(false);
    },
    [data]
  );

  function importFile(data) {
    try {
      setData(
        data.map(item => {
          let custom = {};

          custom._id = Math.random();

          custom.subTeamUnits = String(item[0]).trim();
          custom.subTeamSubUnits = String(item[1]).trim();
          custom.subTeamSubUnitsContactName =
            String(item[2]).trim() || 'NAO DEFINIDO';
          custom.subTeamSubUnitsContactEmail =
            String(item[3])
              .trim()
              .toLowerCase() || 'NAO DEFINIDO';
          custom.subTeamSubUnitsContactPhone =
            String(item[4]).replace(/\D/g, '') || '';

          custom.idSubTeam = idSubTeam;
          custom.idTeam = JSON.parse(
            localStorage.getItem('auth')
          ).currentTeam.id;

          custom.subTeamSubUnitsContactSlackChannelID = String(item[5]).trim();

          custom.status = 0;

          return custom;
        })
      );
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Container title={`Wizard`}>
      {!loading ? (
        <>
          <Alert severity="info" style={{ width: '100%', marginBottom: 20 }}>
            <AlertTitle>{t('feature')}:</AlertTitle>
            <p style={{ fontSize: '14px' }}>{t('instructions')}</p>
          </Alert>

          <SubTeamWizardTable data={data} />

          <Button
            variant="contained"
            className={classes.button}
            onClick={fetch}
            disabled={loading}
          >
            {t('create')}
          </Button>
          <ImportExcelFileButton setData={importFile} />
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <Spinner />

            <p>
              {t('total')}: {counter}/{data.length}
            </p>
          </div>
        </>
      )}
    </Container>
  );
}

export default withStyles(styles)(SubTeamUnitsCreate);
