import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Badge from "@material-ui/core/Badge";
import { StyledLink } from "./styled";
import MenuTypography from "./components/MenuTypography";

function nestedList({ icon, text, style, list, newItem, ...props }) {
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
  }

  // ------- Recebendo do Local Storage os botões que foram abertos e/ou clicados ------------
  const openBtn = localStorage.getItem("selectedButton");
  const selectedBtn = localStorage.getItem("selectedButton");
  // -----------------------------------------------------------------------------------------

  return (
    <>
      <ListItem
        button
        onClick={() => {
          handleClick();
        }}
      >
        <ListItemIcon style={style}>{icon}</ListItemIcon>
        <ListItemText
          primary={<MenuTypography>{text}</MenuTypography>}
          style={{ ...style, marginLeft: -25 }}
        />
        {newItem && (
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            style={{
              borderWidth: 1,
              height: 15,
              width: 40,
              borderRadius: 30,
              backgroundColor: "#ff5a00",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Work Sans",
              fontWeight: "bold",
              color: "white",
              marginLeft: 122,
              position: "absolute",
              fontSize: 9
            }}
          >
            NOVO
          </Badge>
        )}

        {open ? <ExpandLess style={style} /> : <ExpandMore style={style} />}
      </ListItem>
      <Collapse in={openBtn === text ? !open : open} timeout="auto">
        <List component="div" disablePadding>
          {list.map(l => {
            if (l.label === "sistema" && !props.useCondominium) return;
            return (
              <StyledLink
                key={l.id}
                to={l.to}
                onClick={() => {
                  localStorage.setItem("selectedButton", text);
                  localStorage.setItem("selectedButton", l.to);
                }}
              >
                <ListItem
                  button
                  style={
                    selectedBtn === l.to
                      ? {
                          backgroundColor: "rgba(20,20,20, 0.6)",
                          borderRight: "4px solid #4AC151",
                          marginLeft: 20,
                          width: "calc(100% - 20px)"
                        }
                      : { marginLeft: 20 }
                  }
                >
                  <ListItemIcon
                    style={
                      selectedBtn === l.to
                        ? { color: "#4AC151" }
                        : { color: "white" }
                    }
                  >
                    {l.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={<MenuTypography>{l.label}</MenuTypography>}
                    style={{ ...style, marginLeft: -25 }}
                  />
                </ListItem>
              </StyledLink>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

export default nestedList;
