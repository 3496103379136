import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Container from "../../../../components/Container";
import Image from "../Image";

function Tutorials() {
  return (
    <Container title="">
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography
            variant="h4"
            align="center"
            style={{ textTransform: "lowercase" }}
          >
            Gerenciar uma entrega pelo app handover Drop
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            1. Com app logado em uma conta, clique no botão{" "}
            <em>Minhas locações</em>.
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-home.png"
            alt="drop-home"
            isPhone={true}
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            2. Veremos lista das locações criadas, então selecione a entrega que
            deseja gerenciar.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-delivery-list.png"
            alt="drop-cpf"
            isPhone={true}
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            3. Agora vemos os deltalhes da entrega e os botões para interagir
            com a abertura de porta e finalização de processo.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-delivery-details.png"
            alt="drop-cpf"
            isPhone={true}
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            4. Para abrir a porta da entrega, selecione o botão{" "}
            <em>Abrir porta</em> e em seguida confirme se realmente deseja
            realizar a operação.
          </Typography>

          <Typography variant="h6">
            4.1 Se os parâmetros de sua empresa estiverem configurados para
            atualizar o status da entrega na abertura, lembre-se que ao abrir a
            porta o <e>status</e> atual será atualizado para o próximo, que são:
            <ol>
              <li>
                <b>Pronto para depósito</b>: disponível somente para quem foi
                escolhido para realiza o depósito
              </li>
              <li>
                <b>Pronto para coleta</b>: disponível somente depois do
                depósito. Somente neste status seu cliente/colaborador poderá
                realizar a retirada/coleta
              </li>
              <li>
                <b>Finalizada</b>: fim do acesso a entrega.
              </li>
            </ol>
          </Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-delivery-details.png"
            alt="drop-cpf"
            isPhone={true}
            shaddow={true}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-delivery-confirm-open.png"
            alt="drop-cpf"
            isPhone={true}
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            5. Finalizar processo atual. Ao clicar no botão{" "}
            <em>Finalizar depoósito/coleta</em> a entrega passa para o próximo.
            Para prosseguir com a finalização, confirme na caixa de confirmação.
            status.
          </Typography>
        </Grid>
        <Grid item md={6} xs={6}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-delivery-details.png"
            alt="drop-cpf"
            isPhone={true}
            shaddow={true}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/drop/drop-delivery-confirm-deposit.png"
            alt="drop-cpf"
            isPhone={true}
            shaddow={true}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Tutorials;
