import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Container from "../../../../components/Container";
import Image from "../Image";

function Tutorials() {
  return (
    <Container title="">
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography
            variant="h4"
            align="center"
            style={{ textTransform: "lowercase" }}
          >
            Coleta/Retirar produtos pelo totem de atendimento
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            1. Selecione a opção <em>Retirar seu pedido</em>.
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/totem/totem-home.png"
            alt="totem-home"
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            2. Podemos realizar a retirada dos produtos por QR Code ou informar
            manualmente. Neste tutorial, vamos mostrar como digitar manualmente
            as informações, porque na opçnao de QR Code somente deve-se
            aproximar a imagem e seremos direcionados para a entrega.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/totem/totem-choose.png"
            alt="totem-choose"
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            3. Informe o <em>número da entrega</em> e toque no botão{" "}
            <em>próximo</em>.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/totem/totem-reference.png"
            alt="totem-reference"
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            4. Informe o <em>código de acesso</em> da entrega e toque no botão{" "}
            <em>próximo</em>.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/totem/totem-secret.png"
            alt="totem-secret"
            shaddow={true}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            5. Agora veremos as informações da entrega encontrada com seu número
            no centro. Podemos abrir a porta pelo botão <em>Abrir porta</em> e
            retirar os produtos.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/totem/totem-confirm-open.png"
            alt="totem-confirm-open"
            shaddow={true}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Tutorials;
