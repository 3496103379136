export const Types = {
  thingsRequest: "things/Request",
  thingsSuccess: "things/Success",
  thingsError: "things/Error",

  thingsCreateRequest: "thingsCreate/Request",
  thingsCreateSuccess: "thingsCreate/Success",
  thingsCreateError: "thingsCreate/Error",

  thingsShowRequest: "thingsShow/Request",
  thingsShowSuccess: "thingsShow/Success",
  thingsShowError: "thingsShow/Error",

  thingsUpdateRequest: "thingsUpdate/Request",
  thingsUpdateSuccess: "thingsUpdate/Success",
  thingsUpdateError: "thingsUpdate/Error"
};

const INITAL_STATE = {
  data: [],
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1,
  currentThing: null
};

export default function things(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.thingsRequest:
      return {
        ...state,
        currentThing: null,
        loading: true,
        error: null
      };

    case Types.thingsSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload.data
      };

    case Types.thingsError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.thingsCreateRequest:
      return {
        ...state,
        currentThing: null,
        loading: true,
        error: null
      };

    case Types.thingsCreateSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        currentThing: action.payload.data
      };

    case Types.thingsCreateError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.thingsShowRequest:
      return {
        ...state,
        currentThing: null,
        loading: true,
        error: null
      };

    case Types.thingsShowSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        currentThing: action.payload.data
      };

    case Types.thingsShowError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.thingsUpdateRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.thingsUpdateSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        currentThing: action.payload.data
      };

    case Types.thingsUpdateError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
}

export const Actions = {
  thingsRequest: data => ({
    type: Types.thingsRequest,
    payload: {
      data
    }
  }),

  thingsSuccess: data => ({
    type: Types.thingsSuccess,
    payload: { data }
  }),

  thingsError: error => ({
    type: Types.thingsError,
    payload: { error }
  }),

  thingsCreateRequest: data => ({
    type: Types.thingsCreateRequest,
    payload: {
      data
    }
  }),

  thingsCreateSuccess: data => ({
    type: Types.thingsCreateSuccess,
    payload: { data }
  }),

  thingsCreateError: error => ({
    type: Types.thingsCreateError,
    payload: { error }
  }),

  thingsShowRequest: id => ({
    type: Types.thingsShowRequest,
    payload: {
      id
    }
  }),

  thingsShowSuccess: data => ({
    type: Types.thingsShowSuccess,
    payload: { data }
  }),

  thingsShowError: error => ({
    type: Types.thingsShowError,
    payload: { error }
  }),

  thingsUpdateRequest: (id, data) => ({
    type: Types.thingsUpdateRequest,
    payload: {
      id,
      data
    }
  }),

  thingsUpdateSuccess: data => ({
    type: Types.thingsUpdateSuccess,
    payload: { data }
  }),

  thingsUpdateError: error => ({
    type: Types.thingsUpdateError,
    payload: { error }
  })
};
