import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import queryString from "query-string";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as PartnersNeposTickets } from "../../store/ducks/partners/nepos/tickets";

// services
import formatDate from "../../functions/formatDate";
import { constants } from "../../config";
import defaultConfig from "../../config/default";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

class DeliveryPaginationTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 20
  };

  handleChangePage = (event, newPage) => {
    const { partnersNeposTicketsRequest, partnerNeposTickets } = this.props;

    const parseQuery = queryString.parse(partnerNeposTickets.query);
    Object.assign(parseQuery, {
      page: newPage + 1
    });

    partnersNeposTicketsRequest(queryString.stringify(parseQuery));
  };

  handleChangeRowsPerPage = event => {
    const perPage = parseInt(event.target.value);

    const { partnersNeposTicketsRequest, partnerNeposTickets } = this.props;

    const parseQuery = queryString.parse(partnerNeposTickets.query);
    Object.assign(parseQuery, {
      perPage
    });

    partnersNeposTicketsRequest(queryString.stringify(parseQuery));
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: "100%",
        marginTop: theme.spacing(3)
      },
      table: {
        minWidth: 500
      },
      tableWrapper: {
        overflowX: "auto"
      }
    }));

  render() {
    const classes = this.useStyles2();
    const { partnerNeposTickets } = this.props;

    const { emptyRows } = this.state;

    return (
      <>
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead className="tableheader">
                <TableRow align="center">
                  <TableCell
                    className=" th-delivery tablecell-header"
                    align="center"
                  >
                    TICKET
                  </TableCell>
                  <TableCell
                    className="tablecell-header tablecell-header__initial-table"
                    align="center"
                  >
                    {constants.deliveryTableHeaders.lockerAddress}
                  </TableCell>
                  <TableCell
                    className="tablecell-header tablecell-header__initial-table"
                    align="center"
                  >
                    {constants.deliveryTableHeaders.createdAt} de validação
                  </TableCell>

                  {/* <TableCell className="tablecell-header" align="center">
                  AÇÕES
                </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {partnerNeposTickets.data &&
                  partnerNeposTickets.data.map(row => (
                    <TableRow key={row.id}>
                      <TableCell
                        className="td-max tablecell tablecell-header__initial-table"
                        align="center"
                        style={{
                          flexWrap: "wrap"
                        }}
                      >
                        {row.ticket}
                      </TableCell>
                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="center"
                      >
                        {row.locker.lockerNumber} - {row.locker.address}
                      </TableCell>
                      <TableCell
                        className="td-max td-delivery tablecell"
                        align="center"
                      >
                        {formatDate(row.created_at)}
                      </TableCell>

                      {/* <TableCell
                      align="center"
                      id="table-sm__svg"
                      className="tablecell"
                    >
                      <SingleActionButton
                        id={Math.random()}
                        actions={[
                          () => history.push(`/delivery/view/${row.idDelivery}`)
                        ]}
                        options={["Visualizar"]}
                      />
                    </TableCell> */}
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              count={partnerNeposTickets.total}
              rowsPerPage={partnerNeposTickets.perPage}
              page={partnerNeposTickets.page - 1}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true
              }}
              labelRowsPerPage="Linhas por página"
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to === -1 ? count : to} de ${count}`
              }
              rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
              component="div"
            />
          </div>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = state => ({
  partnerNeposTickets: state.partnerNeposTickets
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...PartnersNeposTickets
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryPaginationTable);
