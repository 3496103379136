import axios from "axios";

const apiPDF = axios.create({
  baseURL: "https://azapi.oihandover.com/api-pdf",
  responseType: "blob", // important
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST"
  }
});

export default apiPDF;
