import { call, put } from 'redux-saga/effects';
import api from '../../services/api';
import { Actions as OrderActions } from '../ducks/orders';

export function* getOrders(action) {
  try {
    const response = yield call(api.get, `/v1/adm/orders?${action.payload.data}`);

    yield put(OrderActions.getActiveOrdersSuccess(response.data))
  } catch (err) {
    yield put(OrderActions.getActiveOrdersError(err));
  }
}

export function* showOrder(action) {
  try {
    const response = yield call(api.get, `/v1/adm/orders/${action.payload.id}`);

    yield put(OrderActions.showOrderSuccess(response.data));
  } catch (err) {
    yield put(OrderActions.showOrderError(err));
  }
}
