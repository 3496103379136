import React from 'react';
import PropTypes from 'prop-types';
import {
  Cancel as CancelIcon,
  Save as SaveIcon
} from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { materialStyle } from '../../styles';

const SaveButtons = ({ classes, disabled, onConfirm, onCancel }) => {
  const { t }  = useTranslation();
  return (
    <>
      <Button
        variant="contained"
        onClick={onConfirm}
        disabled={disabled}
        className={classes.button}
      >
        <SaveIcon className={classes.leftIcon} />
        {t("save")}
      </Button>
      <Button
        variant="contained"
        onClick={onCancel}
        disabled={disabled}
        className={classes.button}
      >
        <CancelIcon className={classes.leftIcon} />
        {t("dismiss")}
      </Button>
    </>
  );
}
SaveButtons.propTypes = {
  classes: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

SaveButtons.defaultProps = {
  disabled: false,
  onConfirm: () => {},
  onCancel: () => {},
};

export default withStyles(materialStyle)(SaveButtons);
