import React from "react";
import { withTranslation } from 'react-i18next';

function FilterTitle(props) {
  const { t } = props;
  return (
    <>
      <h6
        style={{
          marginLeft: 5,
          fontFamily: "'Livvic', sans-serif",
          color: "#1b301b"
        }}
      >
        {t('title')}
      </h6>
      <br />
    </>
  );
}

export default withTranslation("delivery")(FilterTitle);


