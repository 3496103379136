import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

// Material
import {
  Button,
  TextField,
  CircularProgress,
  Grid,
  Typography,
  Box
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Global Components
import ExternalProfileTable from "../../components/ExternalProfileTable";
import Container from "../../components/Container";
import Snackbar from "../../components/Snackbar";
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as ExternalProfileActions } from "../../store/ducks/externalProfile";

import history from "../../routes/history";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3} className="box-tab-teams">
        {children}
      </Box>
    </Typography>
  );
}

class Thing extends Component {
  static propTypes = {
    externalProfileRequest: PropTypes.func.isRequired
  };

  async componentWillMount() {
    const { externalProfileRequest } = this.props;

    externalProfileRequest();
  }

  state = {
    externalProfileId: "",
    value: 0,
    cpf: "",
    idTeam: ""
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleChangeValue = (event, newValue) => {
    this.setState({ value: newValue });
  };

  clearSearchUser = () => {
    const { externalProfileRequest } = this.props;
    externalProfileRequest();
  };

  handleSearch = () => {
    const { externalProfileRequest } = this.props;
    externalProfileRequest(queryString.stringify(this.state));
  };

  render() {
    const { classes, externalProfile, setSnackbarVisible } = this.props;
    const { externalProfileId, cpf } = this.state;

    return (
      <Container title="Things">
        <ThemeTextFildGreen>
          {externalProfile.loading ? (
            <CircularProgress />
          ) : (
            <>
              <TabPanel
                value={this.state.value}
                index={0}
                style={{ padding: 0, margin: 0 }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label="email"
                      className={classes.textField}
                      value={this.state.externalProfileId}
                      onChange={this.handleChange("externalProfileId")}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      className={classes.button}
                      onClick={this.handleSearch}
                      variant="contained"
                      disabled={!externalProfileId && !cpf}
                    >
                      {externalProfile.loading ? (
                        <CircularProgress />
                      ) : (
                        "Pesquisar"
                      )}
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={this.clearSearchUser}
                      variant="contained"
                      disabled={!externalProfileId && !cpf}
                    >
                      {externalProfile.loading ? (
                        <CircularProgress />
                      ) : (
                        "Limpar pesquisa"
                      )}
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={() => history.push("/thing/create")}
                      variant="contained"
                    >
                      {externalProfile.loading ? <CircularProgress /> : "Criar"}
                    </Button>
                  </Grid>
                </Grid>
                <ExternalProfileTable history={history} />
              </TabPanel>

              <Snackbar
                open={externalProfile.snackbarVisible}
                variant="warning"
                onClose={setSnackbarVisible}
                message="Usuário não encontrado"
              />
            </>
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  externalProfile: state.externalProfile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ExternalProfileActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(Thing));
