import React, { Component } from "react";

// Material
import { TextField, Button, Grid, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";

// Global Components
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import EditorCurrentRegisterContainer from "../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../components/ButtonEditorCurrentRegister";

// Global services

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions } from "../../store/ducks/laundry";

// Roles

//configs
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

import getOnlyNumbers from "../../services/getOnlyNumbers";

class LaundryLockerItemOptionEdit extends Component {
  state = {
    name: "",
    email: "",
    notChange: true,
    showMessage: null,
    messageText: null,
    messageVariant: null,
    edit: false,
    currentData: null
  };

  componentWillMount() {
    const { showLaundryLockerItemOptionsRequest } = this.props;
    const { id } = this.props.match.params;

    showLaundryLockerItemOptionsRequest(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.laundry.currentData !== this.state.currentData) {
      this.setState({
        currentData: nextProps.laundry.currentData
      });
    }
  }

  handleChange = key => event => {
    let value = event.target.value;

    if (key === "mobile") {
      value = getOnlyNumbers(value);
    }

    this.setState({
      currentData: {
        ...this.state.currentData,
        [key]: value
      },
      notChange: false
    });
  };

  handleSave = () => {
    const { updateLaundryUsersRequest } = this.props;
    const { currentData } = this.state;

    updateLaundryUsersRequest(currentData);
    this.setState({ edit: false });
  };

  handleSelectValueLabel = (id, data) => {
    const check = data.find(item => item.id === id);

    if (!check) return "?";

    return check.name;
  };

  render() {
    const { classes, laundry } = this.props;
    const { currentData, edit } = this.state;

    return (
      <Container title="">
        <ThemeTextFildGreen>
          {/* <Snackbar
            open={business.error}
            onClose={() => setSnackbarErrorVisible(false)}
            variant="error"
            message={
              business.error && business.error.message
                ? business.error.message
                : "Erro geral"
            }
          /> */}
          {}
          {laundry.loading ? (
            <Spinner />
          ) : (
            currentData && (
              <>
                <EditorCurrentRegisterContainer
                  disabled={laundry.loading || edit}
                  flexDirection="row-reverse"
                >
                  <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <ButtonEditorCurrentRegister
                      label="Editar"
                      onClick={() =>
                        this.setState({
                          edit: true
                        })
                      }
                    />
                  </Grid>
                </EditorCurrentRegisterContainer>

                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label="Nome"
                      className={classes.textField}
                      value={currentData.name}
                      onChange={this.handleChange("name")}
                      margin="normal"
                      variant="outlined"
                      disabled={laundry.loading || !edit}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label="Máximo por lavagem"
                      className={classes.textField}
                      value={currentData.maxPerWash}
                      onChange={this.handleChange("maxPerWash")}
                      margin="normal"
                      variant="outlined"
                      disabled={!edit}
                      type="number"
                      inputProps={{ min: "0" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    className="form-colaboradore-sm"
                    alignItems="flex-start"
                  >
                    <TextField
                      id="outlined-name"
                      label="Locker"
                      className={classes.textField}
                      value={currentData.locker.address}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      id="custom-css-outlined-input"
                      select
                      label="Status"
                      className={classes.selectType}
                      value={currentData.status}
                      onChange={this.handleChange("status")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                      disabled={laundry.loading || !edit}
                    >
                      <MenuItem key={1} value={1}>
                        Ativo
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        Inativo
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>

                {edit && (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => this.handleSave()}
                      disabled={laundry.loading}
                      className={classes.button}
                    >
                      <SaveIcon className={classes.leftIcon} />
                      Salvar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() =>
                        this.setState({
                          edit: false
                        })
                      }
                      disabled={laundry.loading}
                      className={classes.button}
                    >
                      <CancelIcon className={classes.leftIcon} />
                      Cancelar
                    </Button>
                  </>
                )}
              </>
            )
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  laundry: state.laundry
});

const mapDispathToProps = dispatch =>
  bindActionCreators(
    {
      ...Actions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withStyles(materialStyle)(LaundryLockerItemOptionEdit));
