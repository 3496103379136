import styled from "styled-components";

export const Selector = styled.div`
  color: #006660;
  font-size: 12px;
  display: flex;
  gap: 5px;
  justify-content: center;
  align items: center;
`;

export const Label = styled.label`
  margin-bottom: 0px;
`;