import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import history from "../../routes/history";

function ButtonLink({
  content,
  to,
  endIcon,
  helpText,
  targetBlank,
  startIcon,
  className,
  variant,
  disabled
}) {
  return (
    <Tooltip title={helpText} placement="top-start">
      <Button
        disabled={disabled}
        variant={variant}
        className={className}
        endIcon={endIcon}
        startIcon={startIcon}
        onClick={() => {
          if (!to) return;

          if (targetBlank) {
            window.open(to);
          } else {
            history.push(to);
          }
        }}
      >
        {content}
      </Button>
    </Tooltip>
  );
}

ButtonLink.defaultProps = {
  content: "Default",
  to: null,
  startIcon: null,
  endIcon: null,
  helpText: "Default",
  targetBlank: true,
  className: "",
  variant: ""
};

export default ButtonLink;
