import home from "./home";
import forAll from "./forAll";
import orders from "./orders";
import teamsCreateRole from "./teamsCreateRole";
import administratorRolesPage from "./administrator";
import administratorRolesCreatePage from "./administratorRolesCreate";
import administratorRolesEditPage from "./administratorRolesEdit";
import createLockerRolesPage from "./createLocker";
import teamEditRolesPage from "./teamsEditRole";
import lockersEditRolesPage from "./lockersEdit";
import lockerShowRolesPage from "./lockerShow";
import compartmentsEditRolesPage from "./compartmentsEdit";
import compartmentsCreateRolesPage from "./compartmentsCreate";
import configurationRolesPage from "./configurationRolesPage";
import subTeamsRolesPage from "./subTeamsRolesPage";
import apartment from "./apartment";
import surveyFormCreate from "./surveyFormCreate";
import surveyFormView from "./surveyFormView";

export default {
  home,
  forAll,
  orders,
  teamsCreateRole,
  administratorRolesPage,
  administratorRolesCreatePage,
  administratorRolesEditPage,
  createLockerRolesPage,
  teamEditRolesPage,
  lockersEditRolesPage,
  lockerShowRolesPage,
  compartmentsEditRolesPage,
  compartmentsCreateRolesPage,
  configurationRolesPage,
  subTeamsRolesPage,
  apartment,
  surveyFormCreate,
  surveyFormView
};
