import styled from "styled-components";

export const Label = styled.span`
  font-size: 15px;
  color: #4a4b4a;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 900 !important;
  font-family: "Livvic", sans-serif !important;
  text-transform: lowercase;
  text-align: center;
`;
