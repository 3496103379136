export const Types = {
  getSubTeamUnitsRequest: "getSubTeamUnits/Request",
  getSubTeamUnitsSuccess: "getSubTeamUnits/Success",
  getSubTeamUnitsError: "getSubTeamUnits/Error",

  showSubTeamUnitsRequest: "showSubTeamUnits/Request",
  showSubTeamUnitsSuccess: "showSubTeamUnits/Sucess",
  showSubTeamUnitsError: "showSubTeamUnits/Error",

  createSubTeamUnitsRequest: "createSubTeamUnits/Request",
  createSubTeamUnitsSuccess: "createSubTeamUnits/Success",
  createSubTeamUnitsError: "createSubTeamUnits/Error",

  createSubTeamWizardRequest: "createSubTeamWizard/Request",
  createSubTeamWizardSuccess: "createSubTeamWizard/Success",
  createSubTeamWizardError: "createSubTeamUnits/Error",

  updateSubTeamUnitsRequest: "updateSubTeamUnits/Request",
  updateSubTeamUnitsSuccess: "updateSubTeamUnits/Success",
  updateSubTeamUnitsError: "updateSubTeamUnits/Error",

  changeStatusSubTeamUnitsRequest: "changeStatusSubTeamUnits/Request",
  changeStatusSubTeamUnitsSuccess: "changeStatusSubTeamUnits/Success",
  changeStatusSubTeamUnitsError: "changeStatusSubTeamUnits/Error",

  setDialogCreateVisible: "setDialogCreateVisible/SubTeamUnits",

  resetSubTeamUnits: "resetSubTeamUnits"
};

const INITIAL_STATE = {
  data: [],
  error: false,
  loading: false,
  currentSubTeamUnits: null,
  page: 0,
  perPage: 20,
  lastPage: 0,
  total: 0,
  dialogCreateVisible: false
};

export default function apartment(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.resetSubTeamUnits:
      return INITIAL_STATE;

    case Types.setDialogCreateVisible:
      return {
        ...state,
        dialogCreateVisible: action.payload
      };

    case Types.getSubTeamUnitsRequest:
      return {
        ...state,
        loading: true
      };

    case Types.getSubTeamUnitsSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case Types.getSubTeamUnitsError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.createSubTeamUnitsRequest:
      return {
        ...state,
        loading: true
      };

    case Types.createSubTeamUnitsSuccess:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...action.payload],
        error: false
      };

    case Types.createSubTeamUnitsError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.createSubTeamWizardRequest:
      return {
        ...state,
        loading: true
      };

    case Types.createSubTeamWizardSuccess:
      return {
        ...state,
        loading: false,
        error: false
      };

    case Types.createSubTeamWizardError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.showSubTeamUnitsRequest:
      return {
        ...state,
        loading: true
      };

    case Types.showSubTeamUnitsSuccess:
      return {
        ...state,
        loading: false,
        currentSubTeamUnits: action.payload.data,
        error: false
      };

    case Types.showSubTeamUnitsError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.updateSubTeamUnitsRequest:
      return {
        ...state,
        loading: true
      };

    case Types.updateSubTeamUnitsSuccess:
      return {
        ...state,
        loading: false,
        currentSubTeamUnits: action.payload.data,
        error: false
      };

    case Types.updateSubTeamUnitsError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.changeStatusSubTeamUnitsRequest:
      return {
        ...state,
        loading: true
      };

    case Types.changeStatusSubTeamUnitsSuccess:
      return {
        ...state,
        loading: false,
        error: false,
        currentSubTeamUnits: {
          ...state.currentSubTeamUnits,
          ...action.payload
        }
      };

    case Types.changeStatusSubTeamUnitsError:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
}

export const Actions = {
  setDialogCreateVisible: status => ({
    type: Types.setDialogCreateVisible,
    payload: status
  }),

  getSubTeamUnitsRequest: data => ({
    type: Types.getSubTeamUnitsRequest,
    payload: { data }
  }),

  getSubTeamUnitsSuccess: data => ({
    type: Types.getSubTeamUnitsSuccess,
    payload: data
  }),

  getSubTeamUnitsError: () => ({
    type: Types.getSubTeamUnitsError
  }),

  showSubTeamUnitsRequest: id => ({
    type: Types.showSubTeamUnitsRequest,
    payload: { id }
  }),

  showSubTeamUnitsSuccess: data => ({
    type: Types.showSubTeamUnitsSuccess,
    payload: { data }
  }),

  showSubTeamUnitsError: () => ({
    type: Types.showSubTeamUnitsError
  }),

  createSubTeamUnitsRequest: (data, redirectToShow = false) => ({
    type: Types.createSubTeamUnitsRequest,
    payload: data,
    redirectToShow
  }),

  createSubTeamUnitsSuccess: data => ({
    type: Types.createSubTeamUnitsSuccess,
    payload: data
  }),

  createSubTeamUnitsError: () => ({
    type: Types.createSubTeamUnitsError
  }),

  updateSubTeamUnitsRequest: ({ id, name, idSubTeam }) => ({
    type: Types.updateSubTeamUnitsRequest,
    payload: {
      id,
      name,
      idSubTeam
    }
  }),

  updateSubTeamUnitsSucess: data => ({
    type: Types.updateSubTeamUnitsSuccess,
    payload: { data }
  }),

  updateSubTeamUnitsError: () => ({
    type: Types.updateSubTeamUnitsError
  }),

  createSubTeamWizardRequest: (data, redirectToShow = false) => ({
    type: Types.createSubTeamWizardRequest,
    payload: data,
    redirectToShow
  }),

  createSubTeamWizardSuccess: data => ({
    type: Types.createSubTeamWizardSuccess,
    payload: data
  }),

  createSubTeamWizardError: () => ({
    type: Types.createSubTeamWizardError
  }),

  resetSubTeamUnits: () => ({
    type: Types.resetSubTeamUnits
  }),

  changeStatusSubTeamUnitsRequest: (id, status) => ({
    type: Types.changeStatusSubTeamUnitsRequest,
    payload: { id, status }
  }),

  changeStatusSubTeamUnitsSuccess: status => ({
    type: Types.changeStatusSubTeamUnitsSuccess,
    payload: { status }
  }),

  changeStatusSubTeamUnitsError: error => ({
    type: Types.changeStatusSubTeamUnitsError,
    payload: { error }
  })
};
