import React from "react";
import queryString from "query-string";
import "./styles.css";

//Materials
import { Grid, withStyles } from "@material-ui/core";

// Components
import ContainerSteppers from "./components/containerStepper";
import Spinner from "../../../components/Spinner";

// Assets
import Logo from "../../../assets/Lockup_Handover.svg";

import api from "../../../services/api";

// styled
import {
  Container,
  LogoStyle,
  LogoStyle2,
  TitleLogo,
  BannerTitle,
  Footer,
  Content,
  Backgournd
} from "./styled";

const styles = {
  bannerGrid: {
    background: "rgba(255, 255, 255, 0.6)",
    width: 333.33
  },
  gridForm: {
    background: "rgb(255, 255, 255)",
    width: 663.66
  }
};

class SignUp extends React.Component {
  state = {
    loading: false,
    error: null,
    tokenError: null,
    tokenExpired: false
  };

  async componentWillMount() {
    try {
      const {
        location: { search }
      } = this.props;

      const { token } = queryString.parse(search);

      this.setState({ loading: true, token });

      if (!token) {
        this.setState({ tokenError: "Token não passado nos parâmetros!" });
        return;
      }

      api
        .get(`/v1/public/subTeam/info?token=${token}`, {
          headers: {
            public: true
          }
        })
        .then(({ data }) => {
          this.setState({ data });
          this.setState({ loading: false });
        })
        .catch(err => {
          if (err.response.data && err.response.data.errorCode === 18) {
            this.setState({ tokenExpired: true });
          }
          this.setState({ loading: false });
        });
    } catch (err) {
      this.setState({ loading: false });
    }
  }

  render() {
    const { classes, history } = this.props;
    const { loading, data, token, tokenExpired } = this.state;
    return (
      <Backgournd>
        <Container id="container">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Content>
                {tokenExpired ? (
                  <strong>
                    Link expirado. Entre em contato com quem compartilhou para
                    fornecer um link de cadastro novo.
                  </strong>
                ) : (
                  <>
                    <Grid
                      container
                      id="banner"
                      className={classes.bannerGrid}
                      justify="center"
                    >
                      <Grid>
                        <LogoStyle
                          onClick={() => history.push("/")}
                          src={Logo}
                          alt=""
                          width="140"
                          height="140"
                        />
                        <TitleLogo
                          class="font-weight-normal"
                          style={{ fontSize: "16px", marginLeft: "127px" }}
                        >
                          EMPRESAS
                        </TitleLogo>
                        <BannerTitle>
                          bem-vindo
                          <br />a plataforma
                          <br />
                          handover Empresas
                        </BannerTitle>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      id="containeSteppers"
                      className={classes.gridForm}
                    >
                      <Grid
                        item
                        id="gridSteppers"
                        md={10}
                        xs={12}
                        style={{ marginLeft: 40, marginRight: 40 }}
                      >
                        <LogoStyle2
                          id="logoGridForm"
                          src={Logo}
                          alt=""
                          width="140"
                          height="140"
                        />
                        <ContainerSteppers data={data} token={token} />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Content>
              <Footer>
                <p
                  style={{
                    marginBottom: "auto",
                    textAlign: "center",
                    fontSize: 13,
                    fontFamily: "Work Sans, sans-serif",
                    color: "#708792",
                    width: "bold"
                  }}
                >
                  &copy; 2020 handover Tecnologia EIRELI – CNPJ
                  32.157.419/0001-48 |
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#708792" }}
                    href="https://oihandover.com/termos-de-uso/"
                  >
                    &nbsp;Termos de Uso&nbsp;
                  </a>{" "}
                  |
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#708792" }}
                    href="https://oihandover.com/politica-de-privacidade/"
                  >
                    &nbsp;Política de Privacidade&nbsp;
                  </a>{" "}
                  |
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#708792" }}
                    href="https://oihandover.com/politica-de-cookies/"
                  >
                    &nbsp;Política de Cookies&nbsp;
                  </a>
                </p>
              </Footer>
            </>
          )}
        </Container>
      </Backgournd>
    );
  }
}

export default withStyles(styles)(SignUp);
