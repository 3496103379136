import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions } from "../ducks/laundry";

export function* getLaundryLockerItemOptionsRequest(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/laundry/locker/items?${action.payload.data}`
    );

    yield put(Actions.getLaundryLockerItemOptionsSuccess(response.data));
  } catch (err) {
    yield put(Actions.getLaundryLockerItemOptionsError(err));
  }
}

export function* showLaundryLockerItemOptionsRequest(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/laundry/locker/items/${action.payload.id}`
    );

    yield put(Actions.showLaundryLockerItemOptionsSuccess(response.data));
  } catch (err) {
    yield put(Actions.showLaundryLockerItemOptionsError(err));
  }
}

export function* createLaundryLockerItemOptionsRequest(action) {
  try {
    const { pushFunction, ...data } = action.payload.data;

    const response = yield call(api.post, "/v1/adm/laundry/locker/items", data);

    yield put(Actions.createLaundryLockerItemOptionsSuccess(response.data));

    pushFunction(`/laundry/locker/item/${response.data.id}`);
  } catch (err) {
    yield put(Actions.createLaundryLockerItemOptionsError(err));
  }
}

export function* getLaundryUsersRequest(action) {
  try {
    const { data } = action.payload;

    const response = yield call(api.get, `/v1/adm/laundry/users?${data}`);

    yield put(Actions.getLaundryUsersSuccess(response.data));
  } catch (err) {
    yield put(Actions.getLaundryUsersError(err));
  }
}

export function* updateLaundryUsersRequest(action) {
  try {
    const { data } = action.payload;

    const response = yield call(
      api.put,
      `/v1/adm/laundry/locker/items/${data.id}`,
      data
    );

    yield put(Actions.updateLaundryUsersSuccess(response.data));
  } catch (err) {
    yield put(Actions.updateLaundryUsersError(err));
  }
}
