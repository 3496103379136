import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Container from "../../../../components/Container";
import Image from "../Image";

function Tutorials() {
  return (
    <Container title="">
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography variant="h4" align="center" style={{textTransform: 'lowercase'}}>
            Criando um perfil de acesso
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            Veremos como criarmos um perfil de acesso que poderá ser usado por
            um
            <em>colaborador</em> para utilizar os sistemas.
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            1. No menu laterial esquerdo <em>Configurações</em>, e no seu
            subMenu
            <em>Colaboradores</em>, veremos a página com todos os colaboradores
            cadastros e podemos criar um novo clicanco no botão{" "}
            <em>Adicionar colaborador</em>
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/business/business-page.png"
            alt="img"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            2. Para adicionar um perfil, teremos que inserir seguintes
            informações:
          </Typography>
          <ul>
            <li>
              Escolher tipo de credencial de acesso: e-mail ou CPF (para acessar
              painel de gestão, somente e-mail)
            </li>
            <li>Nome do colaborador</li>
            <li>Inserir o e-mail ou CPF</li>
            <li>Deve-se criar uma senha e confirmá-la</li>
            <li>
              Função no aplicativo: se o colaborador, deve acessar o Aplicativo
              handover Drop, escolha o papel que o mesmo terá na sua operação.
              Se for um perfil somente para acessar o painel de gestão, escolha
              o tipo{" "}
              <ol>
                <li>
                  <b>
                    <em>Nenhuma: </em>
                  </b>{" "}
                  apenas utiliza o painel de gestão
                </li>
                <li>
                  <b>
                    <em>Conta para entrega: </em>
                  </b>
                  tem acesso para criar locacoes e realizar depósitos no locker
                </li>
                <li>
                  <b>
                    <em>Conta para coleta: </em>
                  </b>
                  apenas faz a coleta no locker e conta
                </li>
                <li>
                  <b>
                    <em>Mista: </em>
                  </b>{" "}
                  realiza depósito e coleta
                </li>
              </ol>
            </li>
            <li>
              Estabelecimento: se houver anteriormente criado um{" "}
              <em>Estabelebimento</em> e precisa vincular este perfil a uma
              unidade específica, escolha nesta caixa, senão deixe a opção{" "}
              <em>Todos</em> selecionada
            </li>
          </ul>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/business/business-create.png"
            alt="business-create"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            3. Após preenchermos todos os campos, devemos clicar no botão{" "}
            <em>Criar</em>
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            4. Após criarmos, somos redirecionados para página que mostra o
            registro criado e onde podemos também editá-lo.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/business/business-show.png"
            alt="business-show"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            5. Ao voltar para página com a lista dos <em>Estabelecimentos</em>{" "}
            criados, veremos o que acabamos de criar. Esta
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Tutorials;
