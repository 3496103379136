import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import checkHandoverEmail from "../services/checkHandoverEmail";

import history from "./history";
import api from "../services/api";

// Pages
import Login from "../pages/login/index";
import Home from "../pages/home";
import NotFound from "../pages/NotFound";
import Orders from "../pages/Orders";
import Users from "../pages/users";
import UserEdit from "../pages/userEdit";
import CreateUser from "../pages/userCreate";
import Reports from "../pages/reports";
import UsersB2B from "../pages/business";
import BusinessEdit from "../pages/businessEdit";
import BusinessCreate from "../pages/businessCreate";
import OrderEdit from "../pages/Orders/components/orderEdit";
import Lockers from "../pages/smartLocker";

import EstablishmentEdit from "../pages/EstablishmentEdit";
import LockerEdit from "../pages/smartLocker/components/lockerEdit";
import LockerShow from "../pages/smartLocker/components/lockerShow";
import LockerCreate from "../pages/smartLocker/components/lockerCreate";
import Delivery from "../pages/delivery";
import DeliveryEdit from "../pages/delivery/components/DeliveryEdit";
import DeliveryView from "../pages/delivery/components/DeliveryView";
import DeliveryCreate from "../pages/delivery/components/DeliveryCreate";
import Tools from "../pages/tools/index";
import ToolsDatascience from "../pages/toolsDatascience";
import ToolsCalcRoute from "../pages/toolsCalcRoute";
import ToolsDeliveryOptimizer from "../pages/toolsDeliveryOptimizer";
import Compartments from "../pages/compartments";
import CompartmentEdit from "../pages/compartments/components/CompartmentEdit";
import CompartmentCreate from "../pages/compartments/components/CompartmentCreate";
import CompartmentCreateMaster from "../pages/master/compartmentCreate";
import Teams from "../pages/teams";
import TeamEdit from "../pages/teamEdit";
import Configuration from "../pages/configuration";
import ResetPassword from "../pages/resetPassword";
import Profile from "../pages/profile";
import BusinessList from "../pages/businessList";
import ResetedPassword from "../pages/resetedPassword";

import HeatMap from "../pages/heatmap";

import SurveyForm from "../pages/surveyForm";
import ListSurveyForm from "../pages/listSurveyForm";

import SignUp from "../pages/signUp";
import ReduceCost from "../pages/reduceCost";
import OpenForUrl from "../pages/openForUrl";
import QRCode from "../pages/qrcode";
import CameraLocker from "../pages/cameraLocker";
import DeploymentStatus from "../pages/deploymentStatus";
import UpdateDeploymentStatus from "../pages/updateDeploymentStatus";
import Spendinghistory from "../pages/usageAndSpendingHistory";
import CustomerFeedback from "../pages/customerFeedback";
import NeposTickets from "../pages/neposTickets";
import Notifications from "../pages/notifications";
import Administrator from "../pages/administrator";

/** INIT: SubTeam (Unidade ou Condominio)*/
import SubTeams from "../pages/subTeams";
import SubTeamEdit from "../pages/subTeamEdit";
import SubTeamCreate from "../pages/subTeamCreate";
import PublicSubTeamCreate from "../pages/public/subTeamCreate";

import SubTeamCondominiumEdit from "../pages/subTeamCondominiumEdit";

import SubTeamsCondonimim from "../pages/subTeamsCondominium";
/** END: SubTeam (Unidade ou Condominio)*/

/** INIT: SubTeamUnits (Blocos do condominio)*/
import SubTeamUnitsCreateByList from "../pages/subTeamUnitsCreateByList";
import SubTeamUnitsPage from "../pages/subTeamUnits";
import SubTeamWizard from "../pages/subTeamDataWizard";

import SubTeamsCondCreate from "../pages/subTeamApCreate";
import SubTeamUnitsEdit from "../pages/subTeamUnitsEdit";
import SubTeamSubUnitsEdit from "../pages/subTeamSubUnitsEdit";
import SubTeamSubUnitsContactCreate from "../pages/subTeamSubUnitsContactCreate";
import SubTeamSubUnitsContactEdit from "../pages/subTeamSubUnitsContactEdit";
import SubTeamSubUnitsCreateByList from "../pages/subTeamSubUnitsCreateByList";
import SubTeamSubUnitsContactCreateByList from "../pages/subTeamSubUnitsContactCreateByList";

// Tuts
import Tutorials from "../pages/tutorials";
import TutorialPageSubTeamCreate from "../pages/tutorials/components/TutorialPageSubTeamCreate";
import TutorialPageBusinessCreate from "../pages/tutorials/components/TutorialPageBusinessCreate";
import TutorialPageDeliveryCreate from "../pages/tutorials/components/TutorialPageDeliveryCreate";
import TutorialPageDeliveryEdit from "../pages/tutorials/components/TutorialPageDeliveryEdit";
import TutorialDropLogin from "../pages/tutorials/components/TutorialDropLogin";
import TutorialDropDeliveryCreate from "../pages/tutorials/components/TutorialDropDeliveryCreate";
import TutorialDropDeliveryManage from "../pages/tutorials/components/TutorialDropDeliveryManage";
import TutorialTotemCollect from "../pages/tutorials/components/TutorialTotemCollect";

// Condominium
// import SubTeamUnitsCreate from "../pages/subTeamUnitsCreate";

// Roles Page
import Roles from "../pages/administrator/roles";
import Permission from "../pages/administrator/permissions";
import CreateRole from "../pages/administrator/roles/pages/createRole";
import EditRole from "../pages/administrator/roles/pages/editRole";

// Locker and compartments
import CompatmentCreate from "../pages/master/compartmentCreate";

import Spinner from "../components/Spinner";

// Internal HandOver
import LockerConnection from "../pages/internal/support/lockerConnection";
import ReportsTeams from "../pages/internal/administrator/report/team";
import InternalSupportLocker from "../pages/internal/support/locker";

// Configuration System
import ConfigurationSystem from "../pages/systemConfiguration";

// Function check Role permission
import { checkRoles } from "../services/permissions";

// Permitted Roles
import roles from "../config/roles";

// Thing Management
import Groups from "../pages/groups";
import Things from "../pages/thing";
import ThingTypes from "../pages/thingTypes";
import groupEdit from "../pages/groupEdit";
import thingTypeCreate from "../pages/thingTypeCreate";
import thingCreate from "../pages/thingCreate";
import ExternalProfile from "../pages/externalProfile";
import ExternalProfileEdit from "../pages/externalProfileEdit";

// Integrations
import Integrations from "../pages/integrations";
import AzureActiveDirectory from "../pages/azure/activeDirectory";
import UserCreditTickets from "../pages/userCreditTickets";
import rentalUserOrder from "../pages/rentalUserOrder";
import deliveryUsage from "../pages/deliveryUsage";
import rentalUserOrderEdit from "../pages/rentalUserOrderEdit";
import SandboxSystems from "../pages/sandbox-systems";
import sendEmailsInternal from "../pages/sendEmailsInternal";
import laundryLockerItemOption from "../pages/laundryLockerItemOption";
import LaundryLockerItemOptionEdit from "../pages/LaundryLockerItemOptionEdit";
import CreateLaundryLockerItemOption from "../pages/createLaundryLockerItemOption";
import laundryUsers from "../pages/laundryUsers";
import integrationCreate from "../pages/integrationCreate";
import integrationDataCreate from "../pages/integrationDataCreate";
import integrationDataTeamEdit from "../pages/integrationDataTeamEdit";
import integrationTokenEdit from "../pages/integrationTokenEdit";
import rentalUser from "../pages/rentalUser";

export function auth() {
  if (
    !Cookies.get("auth") ||
    !JSON.parse(localStorage.getItem("auth")) ||
    new Date(Cookies.get("auth")) < new Date()
  ) {
    return false;
  }

  return true;
}

const checkParameterPermission = parameter => {
  const {
    currentTeam: { parameters }
  } = JSON.parse(localStorage.getItem("auth"));

  return parseInt(parameters[parameter]);
};

// Componente para usuário logado
const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  // se estiver logado
  if (auth()) {
    if (checkHandoverEmail()) {
      return <Route {...rest} render={props => <Component {...props} />} />;
    }
    const user = JSON.parse(localStorage.getItem("auth"));

    // verifica regras de acesso da página
    const permitted = checkRoles(roles, user.roles);

    // verifica permissions
    switch (rest.path) {
      case "/spendinghistory":
      case "/userCreditTickets":
        if (
          !(
            !!checkParameterPermission("activeOrderFee") || checkHandoverEmail()
          )
        ) {
          return <NotFound />;
        }
        break;
      case "/customer/feedback":
        if (
          !(
            !!checkParameterPermission("activeCustomerFeedback") ||
            checkHandoverEmail()
          )
        ) {
          return <NotFound />;
        }
        break;
      default:
        // se NÃO possuir permissão
        if (!permitted) {
          return <NotFound />;
        }
    }

    // verificar se isPrivate

    if (!user.isPrivate && rest.isPrivate) {
      return <NotFound />;
    }

    if (rest.keyParameters && !checkParameterPermission(rest.keyParameters)) {
      return <NotFound />;
    }

    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  // se NÃO estiver logado redireciona para login
  return (
    <Route
      {...rest}
      render={props => (
        <Redirect
          to={{
            pathname: "/",
            state: {
              from: props.location
            }
          }}
        />
      )}
    />
  );
};

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth() === false ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/home",
            state: {
              from: props.location
            }
          }}
        />
      )
    }
  />
);

function Routes() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      async function fetch() {
        const auth = JSON.parse(localStorage.getItem("auth"));

        if (auth && !auth.currentTeam) {
          setLoading(true);
          const teams = await api.get("/teams");
          localStorage.setItem(
            "auth",
            JSON.stringify({
              ...JSON.parse(localStorage.getItem("auth")),
              currentTeam: teams.data[0]
            })
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
        setLoading(false);
      }

      fetch();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/cadastro" component={SignUp} />
        <Route exact path="/simuladordecustos" component={ReduceCost} />
        <Route exact path="/openforurl" component={OpenForUrl} />
        <Route exact path="/openforurl/:token" component={OpenForUrl} />
        <Route exact path="/qr/:token" component={QRCode} />
        <Route exact path="/qrcode/:token" component={QRCode} />
        <Route exact path="/reseted_password" component={ResetedPassword} />
        <Route
          exact
          path="/public/subTeam/register"
          component={PublicSubTeamCreate}
        />
        <LoginRoute exact path="/" component={Login} roles={roles.forAll} />
        <PrivateRoute
          exact
          path="/home"
          component={Home}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/orders"
          component={Orders}
          roles={roles.orders}
        />
        <PrivateRoute
          exact
          path="/users"
          component={Users}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/users/edit/:id"
          component={UserEdit}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/users/create"
          component={CreateUser}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/reports"
          component={Reports}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/business"
          component={UsersB2B}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/business/edit/:id"
          component={BusinessEdit}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/notifications"
          component={Notifications}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/business/create"
          component={BusinessCreate}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/orders/edit/:id"
          component={OrderEdit}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/lockers"
          component={Lockers}
          roles={roles.lockerShowRolesPage}
        />

        <PrivateRoute
          exact
          path="/lockers/edit/:id"
          component={LockerEdit}
          roles={roles.lockersEditRolesPage}
        />
        <PrivateRoute
          exact
          path="/lockers/show/:id"
          component={LockerShow}
          roles={roles.lockerShowRolesPage}
        />

        <PrivateRoute
          exact
          path="/delivery"
          component={Delivery}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tools"
          component={Tools}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tools/calcroutes"
          component={ToolsCalcRoute}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tools/truckRouteOptimizer"
          component={ToolsDatascience}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tools/deliveryRouteOptimizer"
          component={ToolsDeliveryOptimizer}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tools/heatMap"
          component={HeatMap}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/delivery/edit/:id"
          component={DeliveryEdit}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/delivery/view/:id"
          component={DeliveryView}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/delivery/create"
          component={DeliveryCreate}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/compartments"
          component={Compartments}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/compartments/create"
          component={CompartmentCreate}
          roles={roles.compartmentsCreateRolesPage}
        />
        <PrivateRoute
          exact
          path="/compartments/create/master"
          component={CompartmentCreateMaster}
          roles={roles.compartmentsCreateRolesPage}
        />

        <PrivateRoute
          exact
          path="/teams"
          component={Teams}
          roles={roles.orders}
        />

        <PrivateRoute
          exact
          path="/teams/edit/:id"
          component={TeamEdit}
          roles={roles.orders}
        />
        <PrivateRoute
          exact
          path="/configuration"
          component={Configuration}
          roles={roles.configurationRolesPage}
        />
        <PrivateRoute
          exact
          path="/administrator"
          component={Administrator}
          roles={roles.administratorRolesPage}
        />
        <PrivateRoute
          exact
          path="/administrator/roles"
          component={Roles}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/administrator/permissions"
          component={Permission}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/administrator/roles/create"
          component={CreateRole}
          roles={roles.administratorRolesCreatePage}
        />
        <PrivateRoute
          exact
          path="/administrator/roles/edit/:id"
          component={EditRole}
          roles={roles.administratorRolesEditPage}
        />
        <PrivateRoute
          exact
          path="/camera"
          component={CameraLocker}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/profile"
          component={Profile}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/contributors"
          component={BusinessList}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/subTeams"
          component={SubTeams}
          roles={roles.subTeamsRolesPage}
        />
        <PrivateRoute
          exact
          path="/subTeam/edit/:id"
          component={SubTeamEdit}
          roles={roles.subTeamsRolesPage}
        />
        <PrivateRoute
          exact
          path="/subTeam/create"
          component={SubTeamCreate}
          roles={roles.subTeamsRolesPage}
        />

        <PrivateRoute
          exact
          path="/home"
          component={Home}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/orders"
          component={Orders}
          roles={roles.orders}
        />
        <PrivateRoute
          exact
          path="/users"
          component={Users}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/users/edit/:id"
          component={UserEdit}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/users/create"
          component={CreateUser}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/reports"
          component={Reports}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/business"
          component={UsersB2B}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/business/edit/:id"
          component={BusinessEdit}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/business/create"
          component={BusinessCreate}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/orders/edit/:id"
          component={OrderEdit}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/lockers/edit/:id"
          component={LockerEdit}
          roles={roles.lockersEditRolesPage}
        />

        <PrivateRoute
          exact
          path="/lockers/create"
          component={LockerCreate}
          roles={roles.createLockerRolesPage}
        />
        <PrivateRoute
          exact
          path="/spendinghistory"
          component={Spendinghistory}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/userCreditTickets"
          component={UserCreditTickets}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/delivery"
          component={Delivery}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tools"
          component={Tools}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tools/calcroutes"
          component={ToolsCalcRoute}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tools/truckRouteOptimizer"
          component={ToolsDatascience}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tools/deliveryRouteOptimizer"
          component={ToolsDeliveryOptimizer}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tools/heatMap"
          component={HeatMap}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/delivery/edit/:id"
          component={DeliveryEdit}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/delivery/create"
          component={DeliveryCreate}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/compartments"
          component={Compartments}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/compartments/edit/:id"
          component={CompartmentEdit}
          roles={roles.compartmentsEditRolesPage}
        />
        <PrivateRoute
          exact
          path="/compartments/create"
          component={CompartmentCreate}
          roles={roles.compartmentsCreateRolesPage}
        />

        <PrivateRoute
          exact
          path="/configuration"
          component={Configuration}
          roles={roles.configurationRolesPage}
        />
        <PrivateRoute
          exact
          path="/administrator"
          component={Administrator}
          roles={roles.administratorRolesPage}
        />
        <PrivateRoute
          exact
          path="/administrator/roles"
          component={Roles}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/administrator/permissions"
          component={Permission}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/administrator/roles/create"
          component={CreateRole}
          roles={roles.administratorRolesCreatePage}
        />
        <PrivateRoute
          exact
          path="/administrator/roles/edit/:id"
          component={EditRole}
          roles={roles.administratorRolesEditPage}
        />
        <PrivateRoute
          exact
          path="/profile"
          component={Profile}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/subTeams"
          component={SubTeams}
          roles={roles.subTeamsRolesPage}
        />
        <PrivateRoute
          exact
          path="/subTeam/edit/:id"
          component={SubTeamEdit}
          roles={roles.subTeamsRolesPage}
        />
        <PrivateRoute
          exact
          path="/subTeam/create"
          component={SubTeamCreate}
          roles={roles.subTeamsRolesPage}
        />
        <PrivateRoute
          exact
          path="/surveyform"
          component={SurveyForm}
          roles={roles.surveyFormCreate}
        />
        <PrivateRoute
          exact
          path="/surveyform/view/:id"
          component={SurveyForm}
          roles={roles.surveyFormView}
        />
        <PrivateRoute
          exact
          path="/listform"
          component={ListSurveyForm}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/surveyForm/edit/:id"
          component={SurveyForm}
          roles={roles.forAll}
        />
        <LoginRoute
          exact
          path="/resetpassword"
          component={ResetPassword}
          roles={roles.forAll}
        />

        {/** INIT: SubTeam (Unidade ou Condominio)*/}
        <PrivateRoute
          exact
          path="/subTeam/condominium"
          component={SubTeamsCondonimim}
          roles={roles.apartment}
          keyParameters="useCondominium"
        />
        <PrivateRoute
          exact
          path="/subTeam/condominium/edit/:id"
          component={SubTeamCondominiumEdit}
          roles={roles.apartment}
          keyParameters="useCondominium"
        />

        <PrivateRoute
          exact
          path="/subTeam/wizard"
          component={SubTeamWizard}
          roles={roles.apartment}
          keyParameters="useCondominium"
        />

        <PrivateRoute
          exact
          path="/subTeam/condominium/create"
          component={SubTeamsCondCreate}
          roles={roles.apartment}
          keyParameters="useCondominium"
        />
        {/** END: SubTeam (Unidade ou Condominio)*/}

        {/* /** INIT: SubTeamUnits (Blocos do condominio)*/}
        <PrivateRoute
          exact
          path="/subTeamUnits/edit/:id"
          component={SubTeamUnitsEdit}
          roles={roles.apartment}
          keyParameters="useCondominium"
        />
        <PrivateRoute
          exact
          path="/subTeamUnits/create/list"
          component={SubTeamUnitsCreateByList}
          roles={roles.apartment}
          keyParameters="useCondominium"
        />
        <PrivateRoute
          exact
          path="/subTeamUnits/:idSubTeam"
          component={SubTeamUnitsPage}
          roles={roles.apartment}
          keyParameters="useCondominium"
        />
        {/* /** END: SubTeamUnits (Blocos do condominio)*/}

        {/* /** INIT: SubTeamSubUnits (Apartamentos do condominio)*/}
        <PrivateRoute
          exact
          path="/subTeamSubUnits/create/list"
          component={SubTeamSubUnitsCreateByList}
          roles={roles.apartment}
          keyParameters="useCondominium"
        />
        {/* /** END: SubTeamSubUnits ((Apartamentos do condominio)*/}

        <PrivateRoute
          exact
          path="/subTeamSubUnits/edit/:id"
          component={SubTeamSubUnitsEdit}
          roles={roles.apartment}
        />
        <PrivateRoute
          exact
          path="/subTeamSubUnitsContact/create"
          component={SubTeamSubUnitsContactCreate}
          roles={roles.apartment}
        />
        <PrivateRoute
          exact
          path="/subTeamSubUnitsContact/create/list"
          component={SubTeamSubUnitsContactCreateByList}
          roles={roles.apartment}
        />
        <PrivateRoute
          exact
          path="/subTeamSubUnitsContact/edit/:id"
          component={SubTeamSubUnitsContactEdit}
          roles={roles.apartment}
        />

        <PrivateRoute
          exact
          path="/customer/feedback"
          component={CustomerFeedback}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/partners/nepos/tickets"
          component={NeposTickets}
          roles={roles.forAll}
        />

        <LoginRoute
          exact
          path="/resetpassword"
          component={ResetPassword}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/deploymentStatus"
          component={DeploymentStatus}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/updateDeploymentStatus/:id"
          component={UpdateDeploymentStatus}
          roles={roles.administratorRolesPage}
        />
        <PrivateRoute
          exact
          path="/establishment/edit"
          component={EstablishmentEdit}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/tutorials"
          component={Tutorials}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/tutorials/subTeam/create"
          component={TutorialPageSubTeamCreate}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/tutorials/business/create"
          component={TutorialPageBusinessCreate}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tutorials/delivery/create"
          component={TutorialPageDeliveryCreate}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tutorials/delivery/edit"
          component={TutorialPageDeliveryEdit}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tutorials/drop/login"
          component={TutorialDropLogin}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tutorials/drop/delivery/create"
          component={TutorialDropDeliveryCreate}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tutorials/drop/delivery/manage"
          component={TutorialDropDeliveryManage}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/tutorials/totem/collect"
          component={TutorialTotemCollect}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/master/compartment/create"
          component={CompatmentCreate}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/internal/lockers/connection"
          component={LockerConnection}
          roles={roles.forAll}
          isPrivate
        />

        <PrivateRoute
          exact
          path="/internal/reports/teams"
          component={ReportsTeams}
          roles={roles.forAll}
          isPrivate
        />

        <PrivateRoute
          exact
          path="/internal/support/lockers"
          component={InternalSupportLocker}
          roles={roles.forAll}
          isPrivate
        />

        <PrivateRoute
          exact
          path="/system/configuration"
          component={ConfigurationSystem}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/groups"
          component={Groups}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/groups/edit/:id"
          component={groupEdit}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/externalProfile"
          component={ExternalProfile}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/externalProfile/edit/:id"
          component={ExternalProfileEdit}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/things"
          component={Things}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/thing/create"
          component={thingCreate}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/thing/edit/:id"
          component={thingCreate}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/thing/types"
          component={ThingTypes}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/thing/types/edit/:id"
          component={thingTypeCreate}
          roles={roles.forAll}
        />
        <PrivateRoute
          exact
          path="/thing/types/create"
          component={thingTypeCreate}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/integrations"
          component={Integrations}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/integrations/create"
          component={integrationCreate}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/integrations/token/:id"
          component={integrationTokenEdit}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/integrations/data/create"
          component={integrationDataCreate}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/integrations/data/edit/:id"
          component={integrationDataTeamEdit}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/azure/ad"
          component={AzureActiveDirectory}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/rental"
          component={rentalUserOrder}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/rental/edit/:id"
          component={rentalUserOrderEdit}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/rental/users"
          component={rentalUser}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/internal/delivery/usage"
          component={deliveryUsage}
          roles={roles.compartmentsCreateRolesPage}
        />

        <PrivateRoute
          exact
          path="/internal/sandbox/systems/rental"
          component={SandboxSystems}
          roles={roles.compartmentsCreateRolesPage}
        />

        <PrivateRoute
          exact
          path="/internal/emails"
          component={sendEmailsInternal}
          roles={roles.compartmentsCreateRolesPage}
        />

        <PrivateRoute
          exact
          path="/laundry/locker/items"
          component={laundryLockerItemOption}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/laundry/locker/item/:id"
          component={LaundryLockerItemOptionEdit}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/laundry/create/locker/item"
          component={CreateLaundryLockerItemOption}
          roles={roles.forAll}
        />

        <PrivateRoute
          exact
          path="/laundry/users"
          component={laundryUsers}
          roles={roles.forAll}
        />

        <PrivateRoute component={NotFound} />
      </Switch>
    </ConnectedRouter>
  );
}

export default Routes;
