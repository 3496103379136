import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { materialStyle } from "../../styles";

class EditorCurrentRegisterContainer extends Component {
  static propTypes = {
    flexDirection: PropTypes.string,
    justifyContent: PropTypes.string,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    flexDirection: "row",
    justifyContent: "space-between"
  };

  render() {
    const { children, disabled, flexDirection, justifyContent } = this.props;
    const elements = React.Children.toArray(children);

    return (
      <Box
        style={{ margin: 0, padding: 0 }}
        display="flex"
        flexDirection={flexDirection}
        justifyContent={justifyContent}
        p={1}
        m={1}
        bgcolor="#fafafa"
      >
        {elements.map(item => React.cloneElement(item, { disabled }))}
      </Box>
    );
  }
}

export default withStyles(materialStyle)(EditorCurrentRegisterContainer);
