import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

function TextMaskCustomCPF(props) {
  const { inputRef, option, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/
      ]}
      placeholderChar={"\u2000"}
    />
  );
}

TextMaskCustomCPF.propTypes = {
  inputRef: PropTypes.func.isRequired
};

export default TextMaskCustomCPF;
