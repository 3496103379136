import styled from "styled-components";

export const Informations = styled.div`
  display: flex;
  flex-direction: column;
  background: #eee;
  padding: 12px 10px;
  margin-bottom: 5px;
`;

export const Requeriment = styled.p`
  font-size: 12px;
  margin: 0;
  color: #000;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: ${props => (props.validation ? "#4caf50" : "#f9a602")};
    border-radius: 50%;
    position: relative;
    margin-right: 3px;
  }
`;
