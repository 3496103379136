import styled from "styled-components";
import colors from "../../../../styles/colors";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Image = styled.img`
  max-width: 1000px;
  max-height: ${props => (props.isPhone ? "500px" : "1000px")};
  margin: 20px;
  box-shadow: ${props =>
    props.shaddow && `inset 0 0 1em ${colors.dark1}, 0 0 1em ${colors.dark2}`};
  border-radius: 5px;
`;
