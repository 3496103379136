import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import queryString from "query-string";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Check, AddBoxRounded } from "@material-ui/icons";

// Global
import Container from "../../components/Container";
import ImportExcelFileButton from "../../components/ImportExcelFileButton";

// Material
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

// Redux
import { Actions } from "../../store/ducks/subTeamSubUnits";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  },
  selectType: {
    margin: theme.spacing(1),
    minWidth: 150
  },
  tabContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      padding: "0"
    }
  }
});

function SubTeamUnitsCreate({ classes, history, match }) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const subTeamUnits = useSelector(state => state.subTeamUnits);
  const { idSubTeam, idSubTeamUnits } = queryString.parse(
    history.location.search
  );

  function importFile(data) {
    try {
      setData(
        data.map(item => {
          let custom = {};

          custom._id = Math.random();
          custom.name = item[0];
          custom.idSubTeam = idSubTeam;

          custom.idTeam = JSON.parse(
            localStorage.getItem("auth")
          ).currentTeam.id;

          custom.idSubTeamUnits = idSubTeamUnits;

          return custom;
        })
      );
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Container title="Criar unidades em lista">
      <Alert severity="info" style={{ width: "100%", marginBottom: 20 }}>
        <AlertTitle>Funcionalidade:</AlertTitle>
        <p style={{ fontSize: "14px" }}>
          Esta ferramenta auxilia a criação de várias unidades no formato de
          lista. Pode-se usar também um arquivo excel .xlsx para carregar vários
          registros de uma vez.
        </p>
        <AlertTitle>Como utilizar?</AlertTitle>

        <ol style={{ marginTop: 10 }}>
          <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
            Para inserir manualmente, clique no botão <AddBoxRounded />, insira
            os valores e depois clique no botão <Check /> na mesma linha
          </li>
          <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
            Após validar seu dados na tabela, clique no botão <em>Criar</em>
          </li>
        </ol>
      </Alert>

      <MaterialTable
        title="Lista das unidades"
        columns={[{ title: "Identificador da unidade", field: "name" }]}
        data={data}
        localization={{
          body: {
            editRow: {
              deleteText: "Deseja excluir este endereço?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Salvar"
            },
            addTooltip: "Adicionar",
            deleteTooltip: "Deletar",
            editTooltip: "Editar",
            emptyDataSourceMessage: "Nenhuma unidade adicionada"
          },
          header: {
            actions: ""
          }
        }}
        editable={{
          onRowAdd: newData =>
            new Promise(resolve => {
              newData._id = Math.random();
              newData.idSubTeam = idSubTeam;

              newData.idTeam = JSON.parse(
                localStorage.getItem("auth")
              ).currentTeam.id;

              newData.idSubTeamUnits = idSubTeamUnits;

              setData([...data, newData]);
              resolve();
            }, 1),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                setData(
                  data.filter(
                    item =>
                      item._id === oldData._id
                        ? (item.name = newData.name)
                        : item
                  )
                );
                resolve();
              }, 1);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                setData(data.filter(item => item._id !== oldData._id));
                resolve();
              }, 1);
            })
        }}
        options={{
          paging: false,
          search: false,
          actionsColumnIndex: -1,
          toolbarButtonAlignment: "left"
        }}
      />
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => {
          dispatch(Actions.createSubTeamSubUnitsRequest(data, false));
        }}
        disabled={subTeamUnits.loading || !data.length}
      >
        Criar
      </Button>
      <ImportExcelFileButton setData={importFile} />
    </Container>
  );
}

export default withStyles(styles)(SubTeamUnitsCreate);
