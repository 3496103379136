import Intl from "intl";
import pt from "intl/locale-data/jsonp/pt-BR";

export default function(price) {
  const format = new Intl.NumberFormat(pt, {
    style: "currency",
    currency: "BRL"
  });

  const splited = format.format(price).split("R$");

  return `R$ ${splited[1]}`;
}

export const { format: a } = new Intl.NumberFormat(pt, {
  style: "currency",
  currency: "BRL"
});
