import React, { Component } from "react";
import MaskedInput from "react-text-mask";

import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { TextField, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Actions as BusinessActions } from "../../store/ducks/business";

import Spinner from "../../components/Spinner";
import Dialog from "../../components/DialogPassword";
import Container from "../../components/Container";
import SnackBar from "../../components/Snackbar";
import { materialStyle } from "../../styles";
import { withTranslation } from "react-i18next";
class profile extends Component {
  t = this.props.t
  state = {
    user: {},
    name: "",
    disabled: true,
    email: "",
    cpf: "",
    cpfFinished: true,
    open: false,
    message: "",
    variant: ""
  };

  componentWillMount() {
    try {
      const { businessMeRequest } = this.props;

      businessMeRequest();

      const { user, currentTeam } = JSON.parse(localStorage.getItem("auth"));
      this.setState({
        user,
        team: currentTeam,
        name: user.name,
        cpf: user.cpf,
        email: user.email
      });
    } catch (err) {}
  }

  handleChange = key => e => {
    this.setState({ [key]: e.target.value });
  };

  handleEdit = () => {
    const { cpf } = this.state.user;

    this.setState({ disabled: false });

    if (cpf === "" || cpf === undefined) {
      this.setState({ cpfFinished: false });
    }
  };

  handleUpdate = data => {
    const { updateProfileRequest } = this.props;

    const cpf = JSON.parse(localStorage.getItem("auth")).user.cpf;

    if (!data.cpf) {
      this.setState({
        open: true,
        message: "CPF não cadastrado! Verifique os seus dados.",
        variant: "error",
        cpfFinished: false,
        disabled: false
      });
      return;
    }

    const cpfFormatted = data.cpf.replace(/[^\d]+/g, "");

    if (cpfFormatted.length < 11) {
      this.setState({
        open: true,
        message: "CPF inválido! Verifique os seus dados.",
        variant: "error",
        cpfFinished: false,
        disabled: false
      });
      return;
    }

    if (cpf === cpfFormatted) {
      delete data.cpf;
    } else {
      data.cpf = cpfFormatted;
    }

    updateProfileRequest(data);
    this.setState({ disabled: true, cpfFinished: true });
    this.setState({ cpf: cpfFormatted });
  };

  handleOpenPage = () => {
    this.setState({
      open: true,
      message: "Senha alterada com sucesso!",
      variant: "success"
    });
  };

  handleClose = () => {
    const { resetUpdateProfileSucess } = this.props;
    this.setState({ open: false });
    resetUpdateProfileSucess();
  };

  handleCloseError = () => {
    const { resetUpdateProfileError } = this.props;

    resetUpdateProfileError();
  };

  TextMaskCustom = props => {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/
        ]}
        placeholderChar={"\u2000"}
        showMask
        guide={false}
      />
    );
  };

  render() {
    const { loading, me, openSuccess, openError, classes } = this.props;

    const {
      name,
      disabled,
      email,
      cpf,
      cpfFinished,
      open,
      message,
      variant,
      user
    } = this.state;

    return (
      <Container title="Meu Perfil">
        {loading || !me ? (
          <Spinner />
        ) : (
          <>
            <Grid container direction="column">
              <Paper className={classes.paper2}>
                <Grid container alignItems="center">
                  <Typography variant="h5" style={{ marginLeft: 10 }}>
                    {this.t('title')}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={12}
                    lg={4}
                    className="form-colaboradore-sm__cpf"
                  >
                    <TextField
                      label={this.t('common:name')}
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      onChange={this.handleChange("name")}
                      value={name}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    sm={12}
                    md={6}
                    xs={12}
                    lg={4}
                    className="form-colaboradore-sm__cpf"
                  >
                    <TextField
                      label={this.t('common:email')}
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      onChange={this.handleChange("email")}
                      disabled
                      value={email}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    xs={12}
                    lg={4}
                    className="form-colaboradore-sm__cpf"
                  >
                    <TextField
                      label={this.t('common:document')}
                      className={classes.textField}
                      margin="normal"
                      onChange={this.handleChange("cpf")}
                      variant="outlined"
                      InputProps={{
                        inputComponent: this.TextMaskCustom
                      }}
                      disabled={cpfFinished}
                      value={cpf}
                    />
                  </Grid>
                </Grid> */}
                <Grid container>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() =>
                        disabled
                          ? this.handleEdit()
                          : this.handleUpdate({ name, cpf, id: user.id })
                      }
                      className={classes.button}
                      style={{
                        backgroundColor: "#006660",
                        color: "#fff",
                        textTransform: "lowercase",
                        height: 35,
                      }}
                    >
                      {disabled ? this.t('common:edit') : this.t('common:update')}
                    </Button>
                    {disabled ? (
                      <Dialog handleOpenPage={() => this.handleOpenPage()} />
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() =>
                          this.setState({ disabled: true, cpfFinished: true })
                        }
                        className={classes.button}
                        style={{
                          backgroundColor: "#006660",
                          color: "#fff",
                          textTransform: "lowercase",
                          height: 35,
                        }}
                      >
                        {this.t('common:dismiss')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
        <SnackBar
          variant={variant}
          open={open}
          onClose={this.handleClose}
          message={message}
        />
        <SnackBar
          variant="success"
          open={openSuccess}
          onClose={this.handleClose}
          message="Usuário alterado com sucesso!"
        />
        <SnackBar
          variant="error"
          open={openError}
          onClose={this.handleCloseError}
          message="Erro na requisição! Verifique os seus dados"
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  me: state.business.me,
  loading: state.business.loading,
  openError: state.business.openError,
  openSuccess: state.business.openSuccess,
  error: state.business.error
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(BusinessActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(withTranslation('profile')(profile)));
