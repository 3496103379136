import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as CustomerLogAbbreviationActions } from "../ducks/customerLogAbbreviation";

export function* customerLogAbbreviationRequest(action) {
  try {
    const response = yield call(api.get, "/v1/adm/logs/abbreviation");
    yield put(
      CustomerLogAbbreviationActions.customerLogAbbreviationSuccess(
        response.data
      )
    );
  } catch (err) {
    yield put(CustomerLogAbbreviationActions.customerLogAbbreviationError(err));
  }
}
