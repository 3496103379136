import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";

import { Loading, TextLoading } from "./styles";

const ListLetter = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "X",
  "Z",
  "AA",
  "AB",
  "AC",
  "AD",
  "AE",
  "AF"
];

function LocationTable(props) {
  const { data, loading } = props;
  return (
    <>
      <Loading loading={loading}>
        <TextLoading>Carregando...</TextLoading>
      </Loading>
      <Paper>
        <div>
          <Table>
            <TableHead className="tableheader">
              <TableRow align="center">
                <TableCell
                  className=" th-delivery tablecell-header"
                  align="center"
                >
                  Jornada
                </TableCell>
                <TableCell
                  className=" th-delivery tablecell-header"
                  align="center"
                >
                  Inicio
                </TableCell>
                <TableCell
                  className="tablecell-header tablecell-header__initial-table"
                  align="center"
                >
                  Fim
                </TableCell>
                <TableCell
                  className="th-delivery tablecell-header"
                  align="center"
                >
                  Tempo
                </TableCell>
                <TableCell
                  className="th-delivery tablecell-header"
                  align="center"
                >
                  Distancia
                </TableCell>
                <TableCell
                  className="th-delivery tablecell-header"
                  align="center"
                >
                  Tempo de espera
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row, key) => (
                  <TableRow key={row.distance}>
                    <TableCell
                      className="td-max td-delivery tablecell"
                      align="center"
                    >
                      {ListLetter[key]} {"->"} {ListLetter[key + 1]}
                    </TableCell>
                    <TableCell
                      className="td-max td-delivery tablecell"
                      align="center"
                    >
                      {row.start_address}
                    </TableCell>
                    <TableCell
                      className="td-max tablecell tablecell-header__initial-table"
                      align="center"
                    >
                      {row.end_address}
                    </TableCell>
                    <TableCell
                      className="td-max td-delivery tablecell"
                      align="center"
                    >
                      {row.journey_time}
                    </TableCell>
                    <TableCell
                      className="td-max td-delivery tablecell"
                      align="center"
                    >
                      {row.distance}
                    </TableCell>
                    <TableCell
                      className="td-max td-delivery tablecell"
                      align="center"
                    >
                      {row.downtime} min
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell
                  className="td-max td-delivery tablecell"
                  align="center"
                >
                  Sua rota otimizada aparecerá aqui!
                </TableCell>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </>
  );
}

export default LocationTable;
