import React, { Component } from "react";

// Global Components
import Container from "../../components/Container";
import { materialStyle } from "../../styles";

// Material
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
//  Redux

// Services
import history from "../../routes/history";

class Administrator extends Component {
  t = this.props.t
  render() {
    const { classes } = this.props;

    return (
      <Container title={this.t('admin')}>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => history.push("/administrator/roles")}
        >
          {this.t('rules')}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => history.push("/administrator/permissions")}
        >
          {this.t('common:permissions')}
        </Button>
      </Container>
    );
  }
}

export default withStyles(materialStyle)(withTranslation('role')(Administrator));
