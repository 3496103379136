import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
} from '@material-ui/icons';
import queryString from 'query-string';

import SimpleActionButton from '../SimpleActionButton';
import { Actions as subTeamSubUnitsContact } from '../../store/ducks/subTeamSubUnitsContact';
import { constants } from '../../config';
import countEmailsAndFirstEmails from '../../utils/countEmailsAndFirstEmails';
import defaultConfig from '../../config/default';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tablecell: {
    textAlign: 'left',
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function SubTeamsPaginationTable({
  subTeamSubUnitsContact,
  getSubTeamSubUnitsContactRequest,
  history,
}) {
  const classes = useStyles();
  const [emptyRows, setEmptyRows] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [parameters, setParameters] = useState(null);
  const [dashboardParams, setDashboardParams] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const storedParameters = JSON.parse(localStorage.getItem('auth'))
      .currentTeam.parameters;

    const data = { parameters: storedParameters };

    if (
      storedParameters.dashboardParams &&
      storedParameters.dashboardParams.length
    ) {
      Object.assign(data, {
        dashboardParams: JSON.parse(storedParameters.dashboardParams),
      });
    }

    setParameters(data.parameters);
    setDashboardParams(data.dashboardParams);
  }, []);

  useEffect(
    () => {
      const { total, perPage, page: currentPage } = subTeamSubUnitsContact;
      setEmptyRows(perPage - Math.min(perPage, total - currentPage * perPage));
    },
    [subTeamSubUnitsContact]
  );

  const handleChangePage = (event, newPage) => {
    const parsed = queryString.parse(subTeamSubUnitsContact.query);
    const query = queryString.stringify({
      ...parsed,
      page: newPage + 1,
    });

    getSubTeamSubUnitsContactRequest(query);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const parsed = queryString.parse(subTeamSubUnitsContact.query);
    const query = queryString.stringify({
      ...parsed,
      page: 1,
      perPage: parseInt(event.target.value),
    });

    setRowsPerPage(parseInt(event.target.value));
    getSubTeamSubUnitsContactRequest(query);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead className="tableheader">
            <TableRow align="left">
              <TableCell
                className={`${
                  classes.tablecell
                } tablecell-header__initial-table tablecell-header`}
                align="left"
              >
                {dashboardParams &&
                Object.keys(dashboardParams).includes('subTeamUnitsLabel')
                  ? dashboardParams.subTeamUnitsLabel
                  : t('common:block')}
              </TableCell>
              {[
                String(
                  dashboardParams &&
                  Object.keys(dashboardParams).includes('subTeamSubUnitsLabel')
                    ? dashboardParams.subTeamSubUnitsLabel
                    : t('common:apartment')
                ),
                // 'NOME',
                // 'EMAIL',
                // 'TELEFONE',
                // 'STATUS',
                t('common:name'),
                t('common:email'),
                t('common:phone'),
                t('common:status'),
              ].map(column => (
                <TableCell
                  key={column}
                  className={`${
                    classes.tablecell
                  } td-business-simple-table tablecell-header`}
                  align="left"
                >
                  {column}
                </TableCell>
              ))}
              <TableCell
                align="left"
                className={`${classes.tablecell} tablecell-header`}
              >
                {t('common:actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tbody-business-simple-table">
            {subTeamSubUnitsContact.data &&
              subTeamSubUnitsContact.data.map(row => (
                <TableRow key={row.id}>
                  <TableCell
                    className={`${
                      classes.tablecell
                    } tablecell tablecell-header__initial-table`}
                    align="left"
                  >
                    {row.subTeamUnits ? row.subTeamUnits.name : ''}
                  </TableCell>

                  <TableCell align="left">
                    {row.subTeamSubUnits ? row.subTeamSubUnits.name : ''}
                  </TableCell>

                  <TableCell align="left">{row.name}</TableCell>

                  <TableCell align="left">
                    {`${countEmailsAndFirstEmails(row.email).firstEmail} ${
                      countEmailsAndFirstEmails(row.email).rest
                        ? `+${countEmailsAndFirstEmails(row.email).rest}`
                        : ''
                    }`}
                  </TableCell>

                  <TableCell align="left">{row.phone}</TableCell>
                  <TableCell align="left">
                    {row.status ? t('common:enabled') : t('common:disabled')}
                  </TableCell>

                  <TableCell
                    id="table-sm__svg"
                    align="left"
                    className={classes.tablecell}
                  >
                    <SimpleActionButton
                      id={Math.random()}
                      actions={[
                        () =>
                          history.push(
                            `/subTeamSubUnitsContact/edit/${row.id}`
                          ),
                      ]}
                      options={[t('common:edit')]}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          count={subTeamSubUnitsContact.total}
          rowsPerPage={subTeamSubUnitsContact.perPage}
          page={subTeamSubUnitsContact.page - 1}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          labelRowsPerPage={t('common:lines_per_page')}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} ${t('common:of')} ${count}`
          }
          rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
          component="div"
        />
      </div>
    </Paper>
  );
}

SubTeamsPaginationTable.propTypes = {
  subTeamSubUnitsContact: PropTypes.object.isRequired,
  getSubTeamSubUnitsContactRequest: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  subTeamSubUnitsContact: state.subTeamSubUnitsContact,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...subTeamSubUnitsContact,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubTeamsPaginationTable);
