import React from 'react';

import {
  Container
} from  './styles';

const NotAuthorized = () => (
  <Container>
    <h3>Seu perfil não possiu permissão para acessar esta página</h3>
  </Container>
);

export default NotAuthorized;
