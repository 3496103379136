import React, { useState, forwardRef, useCallback, useEffect } from "react";

import MaterialTable from "material-table";
import { Check } from "@material-ui/icons";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useTranslation } from "react-i18next";
// Global

// Material
import { withStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField
} from "@material-ui/core";

// Redux

import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import { materialStyle } from "../../styles";
import constants from "../../config/constants";
import Spinner from "../Spinner";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function IntegrationDataCreateForm({ classes, extractData }) {
  const {t} = useTranslation("config");
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState();
  const [whenCall, setWhenCall] = useState(constants.whenCallOptions);
  const [newData, setNewData] = useState({
    url: "",
    whenCall: constants.whenCallOptions[0].id,
    headers: []
  });

  useEffect(
    () => {
      const headers = newData.headers.map(i => ({
        [i.name]: i.value
      }));

      const obj = {};

      for (let i = 0; i < headers.length; i++) {
        Object.assign(obj, headers[i]);
      }

      extractData({
        ...newData,
        headers: obj,
        whenCall
      });
    },
    [newData]
  );

  const handleChange = key => event => {
    let _value = event.target.value;

    setNewData(old => ({
      ...old,
      [key]: _value
    }));
  };

  return (
    <ThemeTextFildGreen>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <h6 className={classes.subTitle}>API externa (via webhook)</h6>

          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <TextField
                id="outlined-name"
                label={t("integrations.webhook_url")}
                className={classes.textField}
                value={url}
                margin="normal"
                variant="outlined"
                onChange={handleChange("url")}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <TextField
                id="outlined-name"
                label={t("integrations.webhook_method_label")}
                className={classes.textField}
                value={"POST"}
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
          </Grid>
          <br />
          <h6 className={classes.subTitle}>
          {t("integrations.webhook_api_functions")}
          </h6>

          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <FormGroup row className={classes.defaultComponent}>
                {whenCall.map(item => (
                  <FormControlLabel
                    key={item.id}
                    control={
                      <Checkbox
                        checked={item.checked}
                        onChange={event => {
                          if (event && event.target) {
                            setWhenCall(old =>
                              old.map(i => {
                                if (i.id === item.id) {
                                  i.checked = event.target.checked;
                                }

                                if (
                                  i.id === constants.whenCallOptions[0].id &&
                                  event.target.checked === true
                                ) {
                                  i.checked = true;
                                }

                                if (
                                  i.id === constants.whenCallOptions[1].id &&
                                  event.target.checked === false
                                ) {
                                  i.checked = false;
                                }

                                return i;
                              })
                            );

                            setNewData(old => ({
                              ...old,
                              whenCall
                            }));
                          } else {
                            console.error("event.target is null or undefined");
                          }
                        }}
                        name={item.id}
                        color="primary"
                      />
                    }
                    label={item.label}
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>

          <MaterialTable
            icons={tableIcons}
            disabled={loading}
            title="Headers"
            columns={[
              {
                title: t("integrations.headers_key"),
                field: "name",
                emptyValue: false
              },
              {
                title: t("integrations.headers_value"),
                field: "value",
                emptyValue: false
              }
            ]}
            data={newData.headers}
            localization={{
              body: {
                editRow: {
                  deleteText: t("integrations.remove_header_message"),
                  cancelTooltip: t("common:dismiss"),
                  saveTooltip: t("common:save")
                },
                addTooltip: t("common:add"),
                deleteTooltip: t("common:remove"),
                editTooltip: t("common:edit"),
                emptyDataSourceMessage: t("integrations.empty_headers_message")
              },
              header: {
                actions: ""
              }
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    setNewData(old => ({
                      ...old,
                      headers: old.headers.filter(
                        item => item._id !== oldData._id
                      )
                    }));

                    resolve();
                  }, 1);
                }),
              onRowAdd: newData =>
                new Promise(resolve => {
                  newData._id = Math.random();

                  setNewData(old => ({
                    ...old,
                    headers: [...old.headers, newData]
                  }));

                  resolve();
                }, 1),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    setNewData(old => ({
                      ...old,
                      headers: old.headers.filter(
                        item =>
                          item._id === oldData._id
                            ? (item.value = newData.value)
                            : item
                      )
                    }));

                    resolve();
                  }, 1);
                })
            }}
            options={{
              paging: false,
              search: false,
              actionsColumnIndex: -1,
              toolbarButtonAlignment: "left"
            }}
          />
        </>
      )}
    </ThemeTextFildGreen>
  );
}

export default withStyles(materialStyle)(IntegrationDataCreateForm);
