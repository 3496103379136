export default function(dataset = []) {
  let _dataset = dataset;
  try {
    _dataset = dataset.sort(
      (d1, d2) => new Date(d1.date).getTime() - new Date(d2.date).getTime()
    );
  } catch (err) {
    console.log(err);
  } finally {
    return _dataset;
  }
}
