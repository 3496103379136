export const Types = {
  setSnackbarVisible: 'setSnackbarVisible',

  getAllRolesRequest: 'getAllRoles/Request',
  getAllRolesSuccess: 'getAllRoles/Success',
  getAllRolesError: 'getAllRoles/Error',

  getRolesRequest: 'getRoles/Request',
  getRolesSuccess: 'getRoles/Success',
  getRolesError: 'getRoles/Error',

  showRoleRequest: 'showRole/Request',
  showRoleSuccess: 'showRole/Success',
  showRoleError: 'showRole/Error',

  createRoleRequest: 'createRole/Request',
  createRoleSuccess: 'createRole/Success',
  createRoleError: 'createRole/Error',

  createPermissionRequest: 'createPermission/Request',
  createPermissionSuccess: 'createPermission/Success',
  createPermissionError: 'createPermission/Error',
};

const INITAL_STATE = {
  data: [],
  loading: false,
  error: null,
  currentRole: null,
  perPage: null,
  page: null,
  total: null,
  lastPage: null,

  snackbarVisible: false,
  snackbarMessage: null,
  snackbarType: null,
};

export default function roles(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.setSnackbarVisible:
      return {
        ...state,
        snackbarVisible: action.payload.status,
      };

    case Types.getAllRolesRequest:
      return {
        ...state,
        loading: true,
        error: null,
        data: [],
      };

    case Types.getAllRolesSuccess:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };

    case Types.getAllRolesError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case Types.getRolesRequest:
      return {
        ...state,
        loading: true,
        error: INITAL_STATE.error,
        snackbarMessage: INITAL_STATE.snackbarMessage,
        snackbarType: INITAL_STATE.snackbarType,
        snackbarVisible: INITAL_STATE.snackbarVisible,
        data: INITAL_STATE.data,
      };

    case Types.getRolesSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data,
      };

    case Types.getRolesError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarMessage: 'Um erro aconteceu',
        snackbarType: 'error',
        snackbarVisible: true,
      };

    case Types.showRoleRequest:
      return {
        ...state,
        loading: true,
        error: INITAL_STATE.error,
        snackbarMessage: INITAL_STATE.snackbarMessage,
        snackbarType: INITAL_STATE.snackbarType,
        snackbarVisible: INITAL_STATE.snackbarVisible,
        currentRole: INITAL_STATE.currentRole,
      };

    case Types.showRoleSuccess:
      return {
        ...state,
        loading: false,
        currentRole: action.payload.data,
      };

    case Types.showRoleError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarMessage: 'Um erro aconteceu',
        snackbarType: 'error',
        snackbarVisible: true,
      };

    case Types.createRoleRequest:
      return {
        ...state,
        loading: true,
        currentRole: INITAL_STATE.currentRole,
        error: INITAL_STATE.error,
        snackbarVisible: INITAL_STATE.snackbarVisible,
      };

    case Types.createRoleSuccess:
      return {
        ...state,
        loading: INITAL_STATE.loading,
        currentRole: action.payload.data,
        snackbarMessage: 'Criado com sucesso',
        snackbarType: 'success',
        snackbarVisible: true,
      };

    case Types.createRoleError:
      return {
        ...state,
        loading: INITAL_STATE.loading,
        error: action.payload.error,
        snackbarVisible: true,
        snackbarType: 'error',
        snackbarMessage: 'Um erro aconteceu'
      };

    case Types.createPermissionRequest:
      return {
        ...state,
        loading: true,
        error: INITAL_STATE.error,
      };

    case Types.createPermissionSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data,
      };

    case Types.createPermissionError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export const Actions = {
  getAllRolesRequest: () => ({
    type: Types.getAllRolesRequest,
  }),

  getAllRolesSuccess: data => ({
    type: Types.getAllRolesSuccess,
    payload: { data },
  }),

  getAllRolesError: error => ({
    type: Types.getAllRolesError,
    payload: { error },
  }),

  getRolesRequest: data => ({
    type: Types.getRolesRequest,
    payload: { data },
  }),

  getRolesSuccess: data => ({
    type: Types.getRolesSuccess,
    payload: { data },
  }),

  getRolesError: error => ({
    type: Types.getRolesError,
    payload: { error },
  }),

  showRoleRequest: id => ({
    type: Types.showRoleRequest,
    payload: { id },
  }),

  showRoleSuccess: data => ({
    type: Types.showRoleSuccess,
    payload: { data },
  }),

  showRoleError: error => ({
    type: Types.showRoleError,
    payload: { error },
  }),

  setSnackbarVisible: status => ({
    type: Types.setSnackbarVisible,
    payload: { status },
  }),

  createRoleRequest: data => ({
    type: Types.createRoleRequest,
    payload: { data },
  }),

  createRoleSuccess: data => ({
    type: Types.createRoleSuccess,
    payload: { data },
  }),

  createRoleError: data => ({
    type: Types.createRoleError,
    payload: { data },
  }),

  createPermissionRequest: data => ({
    type: Types.createPermissionRequest,
    payload: { data },
  }),

  createPermissionSuccess: data => ({
    type: Types.createPermissionSuccess,
    payload: { data },
  }),

  createPermissionError: error => ({
    type: Types.createPermissionError,
    payload: { error },
  }),
};
