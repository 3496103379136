export const Types = {
  resetCompartments: "resetCompartments",
  setSnackbarVisibleCompartment: "setSnackbarVisible/Compartment",

  compartmentSearchRequest: "compartmentSearch/Request",
  compartmentSearchSuccess: "compartmentSearch/Success",
  compartmentSearchError: "compartmentSearch/Error",

  openCompartmentRequest: "openCompartment/Request",
  openCompartmentSuccess: "openCompartment/Success",
  openCompartmentError: "openCompartment/Error",

  getCompartmentsRequest: "getCompartments/Request",
  getCompartmentsSuccess: "getCompartments/Success",
  getCompartmentsError: "getCompartments/Error",

  getOpeningRequest: "getOpening/Request",
  getOpeningSucess: "getOpening/Sucess",
  getOpeningError: "getOpening/Error",

  showCompartmentRequest: "showCompartment/Request",
  showCompartmentSuccess: "showCompartment/Success",
  showCompartmentError: "showCompartment/Error",

  getCompartmentsInfoRequest: "getCompartmentsInfo/Request",
  getCompartmentsInfoSuccess: "getCompartmentsInfo/Success",
  getCompartmentsInfoError: "getCompartmentsInfo/Error",

  updateCompartmentRequest: "updateCompartment/Request",
  updateCompartmentSuccess: "updateCompartment/Success",
  updateCompartmentError: "updateCompartment/Error",

  createCompartmentRequest: "createCompartment/Request",
  createCompartmentSuccess: "createCompartment/Success",
  createCompartmentError: "createCompartment/Error",

  setEditableCompartment: "setEditable/Compartment",
  setModalChangeStatusVisible: "setModalChangeStatusVisible/Compartment",
  setCompartment: "setCompartment",

  getCompartmentsOfTeamRequest: "getCompartmentsOfTeam/Request",
  getCompartmentsOfTeamSuccess: "getCompartmentsOfTeam/Success",
  getCompartmentsOfTeamError: "getCompartmentsOfTeam/Error"
};

const INIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  total: null,
  perPage: null,
  lastPage: null,
  page: null,
  openingData: [],
  info: null,
  snackbarVisible: false,
  snackbarMessage: "",
  snackbarType: "success",
  setEditable: false,
  modalChangeStatusVisible: false,
  currentCompartment: null
};

export default function compartment(state = INIAL_STATE, action) {
  switch (action.type) {
    case Types.resetCompartments:
      return INIAL_STATE;

    case Types.setSnackbarVisibleCompartment:
      return {
        ...state,
        snackbarVisible: action.payload.status,
        error: null
      };

    case Types.setModalChangeStatusVisible:
      return {
        ...state,
        modalChangeStatusVisible: action.payload
      };

    case Types.setCompartment:
      return {
        ...state,
        currentCompartment: action.payload
      };

    case Types.setEditableCompartment:
      return {
        ...state,
        setEditable: action.payload.status
      };

    case Types.compartmentSearchRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.compartmentSearchSuccess:
      return {
        ...state,
        data: action.payload.data,
        error: null,
        loading: false
      };

    case Types.compartmentSearchError:
      return {
        ...state,
        error: true,
        loading: false,
        data: []
      };

    case Types.openCompartmentRequest:
      return {
        ...state,
        error: null,
        loading: true
      };

    case Types.openCompartmentSuccess:
      return {
        ...state,
        loading: false,
        snackbarVisible: true,
        snackbarMessage: "Porta aberta com sucesso!",
        snackbarType: "success"
      };

    case Types.openCompartmentError:
      return {
        ...state,
        loading: false,
        error: true
      };

    case Types.getCompartmentsRequest:
    case Types.getCompartmentsOfTeamRequest:
      return {
        ...state,
        loading: true,
        error: null,
        data: []
      };

    case Types.getCompartmentsSuccess:
    case Types.getCompartmentsOfTeamSuccess:
      const { lastPage, total, perPage, page } = action.payload.data;

      return {
        ...state,
        data: action.payload.data.data,
        total,
        perPage,
        lastPage,
        page,
        loading: false
      };

    case Types.getCompartmentsError:
    case Types.getCompartmentsOfTeamError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.getOpeningRequest:
      return {
        ...state,
        loading: false,
        error: null
      };

    case Types.getOpeningSucess:
      return {
        ...state,
        loading: false,
        openingData: action.payload.data
      };

    case Types.getOpeningError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.showCompartmentRequest:
      return {
        ...state,
        loading: true,
        error: null,
        currentCompartment: null
      };

    case Types.showCompartmentSuccess:
      return {
        ...state,
        loading: false,
        currentCompartment: action.payload.data
      };

    case Types.showCompartmentError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.getCompartmentsInfoRequest:
      return {
        ...state,
        loading: true,
        error: null,
        info: null
      };

    case Types.getCompartmentsInfoSuccess:
      return {
        ...state,
        loading: false,
        info: action.payload.data
      };

    case Types.getCompartmentsInfoError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.updateCompartmentRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.updateCompartmentSuccess:
      return {
        ...state,
        loading: false,
        snackbarVisible: true,
        snackbarMessage: "Atualizado",
        currentCompartment: {
          ...state.currentCompartment,
          ...action.payload.data
        },
        setEditable: INIAL_STATE.setEditable
      };

    case Types.updateCompartmentError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true,
        snackbarMessage: "Não foi possível atualizar",
        snackbarType: "error"
      };

    case Types.createCompartmentRequest:
      return {
        ...state,
        loading: true,
        error: null,
        snackbarVisible: false
      };

    case Types.createCompartmentSuccess:
      return {
        ...state,
        loading: false
      };

    case Types.createCompartmentError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
}

export const Actions = {
  compartmentSearchRequest: data => ({
    type: Types.compartmentSearchRequest,
    payload: { data }
  }),

  compartmentSearchSuccess: data => ({
    type: Types.compartmentSearchSuccess,
    payload: { data }
  }),

  compartmentSearchError: error => ({
    type: Types.compartmentSearchError,
    payload: { error }
  }),

  openCompartmentRequest: id => ({
    type: Types.openCompartmentRequest,
    payload: { id }
  }),

  openCompartmentSuccess: data => ({
    type: Types.openCompartmentSuccess,
    payload: { data }
  }),

  openCompartmentError: error => ({
    type: Types.openCompartmentError,
    payload: { error }
  }),

  getCompartmentsRequest: data => ({
    type: Types.getCompartmentsRequest,
    payload: { data }
  }),

  getCompartmentsSuccess: data => ({
    type: Types.getCompartmentsSuccess,
    payload: { data }
  }),

  getCompartmentsError: error => ({
    type: Types.getCompartmentsError,
    payload: { error }
  }),

  getOpeningRequest: data => ({
    type: Types.getOpeningRequest,
    payload: { data }
  }),

  getOpeningSucess: data => ({
    type: Types.getOpeningSucess,
    payload: { data }
  }),

  getOpeningError: error => ({
    type: Types.getOpeningError,
    payload: { error }
  }),

  showCompartmentRequest: id => ({
    type: Types.showCompartmentRequest,
    payload: { id }
  }),

  showCompartmentSuccess: data => ({
    type: Types.showCompartmentSuccess,
    payload: { data }
  }),

  showCompartmentError: error => ({
    type: Types.showCompartmentError,
    payload: { error }
  }),

  getCompartmentsInfoRequest: () => ({
    type: Types.getCompartmentsInfoRequest
  }),

  getCompartmentsInfoSuccess: data => ({
    type: Types.getCompartmentsInfoSuccess,
    payload: { data }
  }),

  getCompartmentsInfoError: error => ({
    type: Types.getCompartmentsInfoError,
    payload: { error }
  }),

  updateCompartmentRequest: (id, data) => ({
    type: Types.updateCompartmentRequest,
    payload: { id, data }
  }),

  updateCompartmentSuccess: data => ({
    type: Types.updateCompartmentSuccess,
    payload: { data }
  }),

  updateCompartmentError: error => ({
    type: Types.updateCompartmentError,
    payload: { error }
  }),

  setSnackbarVisibleCompartment: status => ({
    type: Types.setSnackbarVisibleCompartment,
    payload: { status }
  }),

  createCompartmentRequest: data => ({
    type: Types.createCompartmentRequest,
    payload: { data }
  }),

  createCompartmentSuccess: data => ({
    type: Types.createCompartmentSuccess,
    payload: { data }
  }),

  createCompartmentError: error => ({
    type: Types.createCompartmentError,
    payload: { error }
  }),

  setEditableCompartment: status => ({
    type: Types.setEditableCompartment,
    payload: { status }
  }),

  setModalChangeStatusVisible: status => ({
    type: Types.setModalChangeStatusVisible,
    payload: status
  }),

  setCompartment: data => ({
    type: Types.setCompartment,
    payload: data
  }),

  getCompartmentsOfTeamRequest: data => ({
    type: Types.getCompartmentsOfTeamRequest,
    payload: { data }
  }),

  getCompartmentsOfTeamSuccess: data => ({
    type: Types.getCompartmentsOfTeamSuccess,
    payload: { data }
  }),

  getCompartmentsOfTeamError: error => ({
    type: Types.getCompartmentsOfTeamError,
    payload: { error }
  }),

  resetCompartments: () => ({
    type: Types.resetCompartments
  })
};
