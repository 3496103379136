import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

// Material
import { Button, TextField, CircularProgress, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Global Components
import Container from "../../components/Container";
import SubTeamsSimpleTable from "../../components/SubTeamsSimpleTable";
import Snackbar from "../../components/Snackbar";
import { materialStyle } from "../../styles";
import TextMaskCustomCNPJ from "../../components/TextMaskCustomCNPJ";
import getOnlyNumbers from "../../services/getOnlyNumbers";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as SubTeamActions } from "../../store/ducks/subTeam";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

import api from "../../services/api";
import FilterTitle from "../../components/FilterTitle";

class SubTeams extends Component {
  static propTypes = {
    getSubTeamsRequest: PropTypes.func.isRequired
  };

  state = {
    name: "",
    cnpj: "",
    open: false,
    token: null,
    loading: false
  };

  componentWillMount() {
    this.handleInitSearch();
  }

  handleInitSearch = () => {
    const { getSubTeamsRequest } = this.props;
    getSubTeamsRequest("perPage=20");
  };

  handleChange = name => event => {
    let value = event.target.value;

    if (name === "cnpj") {
      value = getOnlyNumbers(value);
    }

    this.setState({ [name]: value });
  };

  handleSearch = () => {
    const { getSubTeamsRequest } = this.props;
    const { name, cnpj } = this.state;

    let query = queryString.stringify({
      name,
      cnpj
    });

    getSubTeamsRequest(`perPage=20&${query}`);
  };

  onClose = () => {
    this.setState({ open: false });
  };

  handleGetToken = async () => {
    try {
      this.setState({ loading: true });
      api
        .post("/v1/adm/subTeams/url/creator")
        .then(({ data: { token } }) => {
          this.setState({ token, open: true, loading: false });
        })
        .catch(err => {});
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { classes, history, subTeams, setSnackbarVisible } = this.props;

    return (
      <Container title="estabelecimento">
        <ThemeTextFildGreen>
          {subTeams.loading ? (
            <CircularProgress />
          ) : (
            <>
              <Snackbar
                open={subTeams.snackbarVisible}
                variant="warning"
                onClose={setSnackbarVisible}
                message="usuário não encontrado"
              />

              <FilterTitle />
              <Grid container xs={12} spacing={2} alignItems="center">
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label="Nome da empresa"
                    className={classes.textField}
                    value={this.state.name}
                    onChange={this.handleChange("name")}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label="CNPJ"
                    className={classes.textField}
                    value={this.state.cnpj}
                    onChange={this.handleChange("cnpj")}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      inputComponent: TextMaskCustomCNPJ
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className="form-colaboradore-sm">
                  <Button
                    className={classes.button}
                    onClick={this.handleSearch}
                    variant="contained"
                    style={{
                      // para os Buttons
                      backgroundColor: "#006660",
                      fontSize: 14,
                      color: "#fff",
                      textTransform: "lowercase",
                      height: 35
                    }}
                    disabled={
                      subTeams.loading || (!this.state.name && !this.state.cnpj)
                    }
                  >
                    {subTeams.loading ? <CircularProgress /> : "pesquisar"}
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={this.handleInitSearch}
                    variant="contained"
                    disabled={!this.state.name && !this.state.cnpj}
                    style={{
                      // para os Buttons
                      backgroundColor: "#006660",
                      fontSize: 14,
                      color: "#fff",
                      textTransform: "lowercase",
                      height: 35
                    }}
                  >
                    limpar pesquisa
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={() => history.push("/subTeam/create")}
                    variant="contained"
                    style={{
                      // para os Buttons
                      backgroundColor: "#006660",
                      fontSize: 14,
                      color: "#fff",
                      textTransform: "lowercase",
                      height: 35
                    }}
                  >
                    criar estabelecimento
                  </Button>
                </Grid>
              </Grid>
              <SubTeamsSimpleTable history={history} />
            </>
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  subTeams: state.subTeams
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(SubTeamActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(SubTeams));
