export const Types = {
  teamsRequest: "teams/Request",
  teamsSuccess: "teams/Success",
  teamsError: "teams/Error",

  setCurrentTeamRequest: "setCurrentTeamRequest",
  setCurrentTeamSuccess: "setCurrentTeamSuccess",
  setCurrentTeamError: "setCurrentTeamError",

  createTeamRequest: "createTeam/Request",
  createTeamSuccess: "createTeam/Success",
  createTeamError: "createTeam/Error",

  showTeamRequest: "showTeam/Request",
  showTeamSuccess: "showTeam/Success",
  showTeamError: "showTeam/Error",

  setSnackbarVisibleTeams: "setSnackbarVisible/Teams",

  updateTeamRequest: "updateTeam/Request",
  updateTeamSuccess: "updateTeam/Success",
  updateTeamError: "updateTeam/Error",

  resetErrorTeams: "resetError/Teams",

  setLocalTeam: "setLocalTeam"
};

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  page: 0,
  perPage: 0,
  lastPage: 0,
  currentTeam: null,
  total: 0,
  snackbarVisible: false,
  snackbarVisibleError: false,
  updateSuccess: false
};

export default function teams(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.resetErrorTeams:
      return {
        ...state,
        snackbarVisibleError: false
      };

    case Types.teamsRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.teamsSuccess:
      return {
        ...state,
        error: null,
        loading: false,
        ...action.payload.data
      };

    case Types.teamsError:
      return {
        ...state,
        error: true
      };

    case Types.setCurrentTeamRequest:
      return {
        ...state
      };

    case Types.createTeamRequest:
      return {
        ...state,
        loading: true,
        error: null,
        data: INITIAL_STATE.data
      };

    case Types.createTeamSuccess:
      return {
        ...state,
        loading: false,
        snackbarVisibleError: false
      };

    case Types.createTeamError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisibleError: true
      };

    case Types.showTeamRequest:
      return {
        ...state,
        loading: true,
        currentTeam: null,
        error: null,
        snackbarVisible: false,
        data: INITIAL_STATE.data
      };

    case Types.showTeamSuccess:
      return {
        ...state,
        loading: false,
        currentTeam: action.payload.data
      };

    case Types.showTeamError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.setSnackbarVisibleTeams:
      return {
        ...state,
        snackbarVisible: action.payload.status
      };

    case Types.updateTeamRequest:
      return {
        ...state,
        loading: true,
        error: null,
        snackbarVisible: false,
        currentTeam: {
          ...state.currentTeam,
          ...action.payload.data
        },
        updateSuccess: false
      };

    case Types.updateTeamSuccess:
      return {
        ...state,
        loading: false,
        snackbarVisible: true,
        updateSuccess: true
      };

    case Types.updateTeamError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
}

export const Actions = {
  teamsRequest: data => ({
    type: Types.teamsRequest,
    payload: { data }
  }),

  teamsSuccess: data => ({
    type: Types.teamsSuccess,
    payload: { data }
  }),

  teamsError: error => ({
    type: Types.teamsError,
    payload: { error }
  }),

  setCurrentTeamRequest: id => ({
    type: Types.setCurrentTeamRequest,
    payload: { id }
  }),

  setCurrentTeamSuccess: data => ({
    type: Types.setCurrentTeamSuccess,
    payload: { data }
  }),

  setCurrentTeamError: error => ({
    type: Types.setCurrentTeamError,
    payload: { error }
  }),

  createTeamRequest: data => ({
    type: Types.createTeamRequest,
    payload: { data }
  }),

  createTeamSuccess: data => ({
    type: Types.createTeamSuccess,
    payload: { data }
  }),

  createTeamError: error => ({
    type: Types.createTeamError,
    payload: { error }
  }),

  setSnackbarVisibleTeams: status => ({
    type: Types.setSnackbarVisibleTeams,
    payload: { status }
  }),

  showTeamRequest: id => ({
    type: Types.showTeamRequest,
    payload: { id }
  }),

  showTeamSuccess: data => ({
    type: Types.showTeamSuccess,
    payload: { data }
  }),

  showTeamError: error => ({
    type: Types.showTeamError,
    payload: { error }
  }),

  updateTeamRequest: data => ({
    type: Types.updateTeamRequest,
    payload: { data }
  }),

  updateTeamSuccess: data => ({
    type: Types.updateTeamSuccess,
    payload: { data }
  }),

  updateTeamError: error => ({
    type: Types.updateTeamError,
    payload: { error }
  }),

  resetErrorTeams: () => ({
    type: Types.resetErrorTeams
  }),

  setLocalTeam: () => ({
    type: Types.setLocalTeam
  })
};
