import React from "react";
import Container from "../../components/Container";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Actions as SurveyFormActions } from "../../store/ducks/surveyForm";
import { Actions as LockerActions } from "../../store/ducks/locker";
import { Actions as LockerStructureConditionOptions } from "../../store/ducks/lockerStructureConditionOptions";
import { Actions as PaintConditionOptions } from "../../store/ducks/paintConditionOptions";

//Materiais
import { Grid, TextField, Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

// Components
import FormProblemObs from "../../components/FormProblemObs";
import FormContainerImages from "../../components/FormContainerImages";
import Snackbar from "../../components/Snackbar";
import Spinner from "../../components/Spinner";

import { materialStyle } from "../../styles";
import checkHandOverEmail from "../../services/checkHandoverEmail";

import { Content, ConentAsk, ContentImages, WrapperTeamInfo } from "./styles";
import { checkHandoverEmail } from "../../services";
import { maskCNPJ } from "../../utils/masks";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

const chooseOptions = ["false", "true"];

class SurveyForm extends React.Component {
  state = {
    attachmentNumber: "",
    lockerNumberAttachment: "",
    protocol: null,
    startDate: null,
    idLockerStructureCondition: "select",
    idPaintCondition: "select",

    existsPaintProblem: "false",
    paintProblemDescription: "",

    lockerWasStickyByHandover: "false",
    lockerWasStickyByHandoverDescription: "",

    existsPortsProblem: "false",
    portsProblemDescription: "",

    existsPortsOperationProblem: "false",
    portsOperationProblemDescription: "",

    existsKeyProblem: "false",
    keyProblemDescription: "",

    existsUpperDoorProblem: "false",
    upperDoorProblemDescription: "",

    existsLockerInternProblem: "false",
    internLockerProblemDescription: "",

    existsScrewsProblem: "false",
    screwsProblemDescription: "",

    existsCameraProblems: "false",
    cameraProblemDescription: "",

    existsTotemProblems: "false",
    existsTotemProblemsDescription: "",

    existsTotemStructureProblems: "false",
    totemStructureProblemsDescription: "",

    existsDentsInLockerStructure: "false",
    dentsInLockerStructureDescription: "",

    existsAnotherProblems: "false",
    anotherProblemsDescription: "",

    idLockerSurveyFormStatus: "1",

    existsTotem: "false",

    companyName: "",
    technicianName: "",
    cpf: "",
    surveyDate: "",
    idLocker: "select",
    currentSurveyForm: null,

    onlyRead: false,
    error: {}
  };

  editCurrent = () => {
    const {
      match: { params }
    } = this.props;
    if ("id" in params) {
      return true;
    }

    return false;
  };

  componentWillMount() {
    const {
      match: { params },
      lockersRequest,
      getLockerStructureConditionOptionsRequest,
      getPaintConditionOptionsRequest,
      showSurveyFormRequest,
      setSnabkbarVisibleSurveyForm
    } = this.props;

    setSnabkbarVisibleSurveyForm(false);

    if (this.editCurrent()) {
      this.setState({ onlyRead: true });
      showSurveyFormRequest(params.id);
    }

    lockersRequest("perPage=1000");
    getLockerStructureConditionOptionsRequest();
    getPaintConditionOptionsRequest();
  }

  componentWillReceiveProps({ surveyForm }) {
    try {
      if (surveyForm.error !== this.state.error) {
        this.setState({
          error: surveyForm.error,
          snackbarVisible: surveyForm.snackbarVisible,
          snackbarMessage: surveyForm.snackbarMessage,
          snackbarType: surveyForm.snackbarType
        });
      }

      if (surveyForm.currentSurveyForm !== this.state.currentSurveyForm) {
        this.setState({ currentSurveyForm: surveyForm.currentSurveyForm });
      }

      if (surveyForm.snackbarVisible !== this.state.snackbarVisible) {
        this.setState({
          snackbarMessage: surveyForm.snackbarMessage,
          snackbarVisible: surveyForm.snackbarVisible,
          snackbarType: surveyForm.snackbarType
        });
      }
    } catch (err) {}
  }

  handleChange = e => {
    if (this.editCurrent()) {
      this.setState({
        currentSurveyForm: {
          ...this.state.currentSurveyForm,
          [e.target.name]: e.target.value
        }
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  validateForm = () => {
    const tmp = Object.assign(this.state);
    const keys = Object.keys(this.state);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].startsWith("id") && tmp[keys[i]] === "select") {
        return false;
      }

      if (tmp[keys[i]] === "true") {
        tmp[keys[i]] = true;
      } else if (tmp[keys[i]] === "false") {
        tmp[keys[i]] = false;
      }
    }

    return tmp;
  };

  async handleSubmit(e) {
    e.preventDefault();
    const { createSurveyFormRequest } = this.props;

    const result = this.validateForm();
    if (!result) return;

    createSurveyFormRequest(result);
  }

  async handleSubmitUpdate(e) {
    e.preventDefault();
  }

  handleUpdate = () => {
    const { updateSurveyFormStatusRequest } = this.props;

    const {
      match: {
        params: { id }
      }
    } = this.props;

    updateSurveyFormStatusRequest(id);
  };

  render() {
    const {
      locker,
      lockerStructureConditionOptions,
      paintConditionOptions,
      surveyForm,
      classes
    } = this.props;
    const { currentSurveyForm } = this.state;

    return (
      <Container title="formulario de vistoria">
        <Snackbar
          open={this.state.snackbarVisible}
          onClose={() => this.setState({ snackbarVisible: false })}
          variant={this.state.snackbarType}
          message={this.state.snackbarMessage}
        />
        {surveyForm.loading ? (
          <Spinner />
        ) : (
          <Content>
            <ThemeTextFildGreen>
              <form
                onSubmit={e => {
                  this.handleSubmit(e);
                }}
              >
                {currentSurveyForm ? (
                  <WrapperTeamInfo>
                    <h6>
                      <b>Cliente:</b> {currentSurveyForm.team.businessName} -{" "}
                      {maskCNPJ(currentSurveyForm.team.cnpj)}
                    </h6>
                    <h6>
                      <b>Protocolo de vistoria:</b> {currentSurveyForm.protocol}
                    </h6>
                    <h6>
                      <b>Anexo de contratação:</b>{" "}
                      {currentSurveyForm.protocol.split(".")[2]}
                    </h6>
                    <h6>
                      <b>Locker no anexo:</b>{" "}
                      {currentSurveyForm.protocol.split(".")[3]}
                    </h6>
                    <h6>
                      <b>Status de implantação:</b>{" "}
                      {currentSurveyForm.status.description}
                    </h6>
                  </WrapperTeamInfo>
                ) : (
                  <Grid spacing={2} container style={{ marginBottom: 10 }}>
                    <Grid item xs={12} md={4}>
                      <h6>Número do anexo de contratação</h6>
                      <TextField
                        variant="outlined"
                        className={classes.textFieldLocker}
                        id="custom-css-outlined-input"
                        name="attachmentNumber"
                        value={
                          currentSurveyForm
                            ? currentSurveyForm.attachmentNumber
                            : this.state.attachmentNumber
                        }
                        onChange={e => this.handleChange(e)}
                        disabled={currentSurveyForm ? true : locker.loading}
                        // type="number"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <h6>Número do locker no contrato</h6>
                      <TextField
                        variant="outlined"
                        className={classes.textFieldLocker}
                        id="custom-css-outlined-input"
                        name="lockerNumberAttachment"
                        value={
                          currentSurveyForm
                            ? currentSurveyForm.lockerNumberAttachment
                            : this.state.lockerNumberAttachment
                        }
                        onChange={e => this.handleChange(e)}
                        disabled={currentSurveyForm ? true : locker.loading}
                        // type="number"
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid spacing={2} container style={{ marginBottom: 10 }}>
                  <Grid item xs={12} md={4}>
                    <h6>1. Escolha o locker</h6>
                    <TextField
                      variant="outlined"
                      select
                      className={classes.textFieldLocker}
                      id="custom-css-outlined-input"
                      name="idLocker"
                      value={
                        currentSurveyForm
                          ? currentSurveyForm.idLocker
                          : this.state.idLocker
                      }
                      onChange={e => this.handleChange(e)}
                      disabled={currentSurveyForm ? true : locker.loading}
                    >
                      <MenuItem value={"select"}>Selecione</MenuItem>
                      {locker.data.map(item => {
                        if (item.surveyForms.length && !currentSurveyForm)
                          return null;

                        if (currentSurveyForm) {
                          return (
                            <MenuItem value={currentSurveyForm.locker.id}>
                              {currentSurveyForm.locker.lockerNumber} -{" "}
                              {currentSurveyForm.locker.address}
                            </MenuItem>
                          );
                        }

                        return (
                          <MenuItem value={item.id}>
                            {item.lockerNumber} - {item.address}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: 10 }}>
                  <Grid item xs={12} md={4}>
                    <h6>2. Estado da estrutura do locker</h6>
                    <TextField
                      select
                      variant="outlined"
                      className={classes.textFieldLocker}
                      id="demo-simple-select-helper"
                      name="idLockerStructureCondition"
                      value={
                        currentSurveyForm
                          ? currentSurveyForm.idLockerStructureCondition
                          : this.state.idLockerStructureCondition
                      }
                      onChange={e => this.handleChange(e)}
                      disabled={
                        currentSurveyForm
                          ? true
                          : lockerStructureConditionOptions.loading
                      }
                    >
                      <MenuItem value={"select"}>Selecione</MenuItem>
                      {lockerStructureConditionOptions.data.map(item => (
                        <MenuItem value={item.id}>{item.description}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <ConentAsk>
                  <FormProblemObs
                    disabled={currentSurveyForm ? true : locker.loading}
                    title="3. Existem amassados na estrutura?"
                    optionKeyName="existsDentsInLockerStructure"
                    descriptionKeyName="dentsInLockerStructureDescription"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[
                            currentSurveyForm.existsDentsInLockerStructure
                          ]
                        : this.state.existsDentsInLockerStructure
                    }
                    descriptionValue={
                      currentSurveyForm
                        ? currentSurveyForm.dentsInLockerStructureDescription
                        : this.state.dentsInLockerStructureDescription
                    }
                  />
                </ConentAsk>
                <Grid container spacing={2} style={{ marginBottom: 10 }}>
                  <Grid item xs={12} md={4}>
                    <h6>3. Estado da pintura do locker</h6>
                    <TextField
                      select
                      variant="outlined"
                      className={classes.textFieldLocker}
                      id="demo-simple-select-helper"
                      name="idPaintCondition"
                      value={
                        currentSurveyForm
                          ? currentSurveyForm.idPaintCondition
                          : this.state.idPaintCondition
                      }
                      onChange={e => this.handleChange(e)}
                      disabled={
                        currentSurveyForm ? true : paintConditionOptions.loading
                      }
                    >
                      <MenuItem value={"select"}>Selecione</MenuItem>
                      {paintConditionOptions.data.map(item => (
                        <MenuItem value={item.id}>{item.description}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <ConentAsk>
                  <FormProblemObs
                    title="4. Pintura, existem problemas?"
                    optionKeyName="existsPaintProblem"
                    descriptionKeyName="paintProblemDescription"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[currentSurveyForm.existsPaintProblem]
                        : this.state.existsPaintProblem
                    }
                    descriptionValue={
                      currentSurveyForm
                        ? currentSurveyForm.paintProblemDescription
                        : this.state.paintProblemDescription
                    }
                    disabled={currentSurveyForm ? true : locker.loading}
                  />
                </ConentAsk>
                <ConentAsk>
                  <FormProblemObs
                    title="5. Locker foi adesivado pela handover?"
                    optionKeyName="lockerWasStickyByHandover"
                    descriptionKeyName="lockerWasStickyByHandoverDescription"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[
                            currentSurveyForm.lockerWasStickyByHandover
                          ]
                        : this.state.lockerWasStickyByHandover
                    }
                    descriptionValue={
                      currentSurveyForm
                        ? currentSurveyForm.lockerWasStickyByHandoverDescription
                        : this.state.lockerWasStickyByHandoverDescription
                    }
                    disabled={currentSurveyForm ? true : locker.loading}
                    negativeText="Não."
                  />
                </ConentAsk>
                <ConentAsk>
                  <FormProblemObs
                    disabled={currentSurveyForm ? true : locker.loading}
                    title="6. Portas, existem problemas na estrutura?"
                    optionKeyName="existsPortsProblem"
                    descriptionKeyName="portsProblemDescription"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[currentSurveyForm.existsPortsProblem]
                        : this.state.existsPortsProblem
                    }
                    descriptionValue={
                      currentSurveyForm
                        ? currentSurveyForm.portsProblemDescription
                        : this.state.portsProblemDescription
                    }
                  />
                </ConentAsk>

                <ConentAsk>
                  <FormProblemObs
                    disabled={currentSurveyForm ? true : locker.loading}
                    title="7. Funcionamento das portas, existem problemas?"
                    optionKeyName="existsPortsOperationProblem"
                    descriptionKeyName="portsOperationProblemDescription"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[
                            currentSurveyForm.existsPortsOperationProblem
                          ]
                        : this.state.existsPortsOperationProblem
                    }
                    descriptionValue={
                      currentSurveyForm
                        ? currentSurveyForm.portsOperationProblemDescription
                        : this.state.portsOperationProblemDescription
                    }
                  />
                </ConentAsk>
                <ConentAsk>
                  <FormProblemObs
                    disabled={currentSurveyForm ? true : locker.loading}
                    title="8. Fechaduras, existem problemas?"
                    optionKeyName="existsKeyProblem"
                    descriptionKeyName="keyProblemDescription"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[currentSurveyForm.existsKeyProblem]
                        : this.state.existsKeyProblem
                    }
                    descriptionValue={
                      currentSurveyForm
                        ? currentSurveyForm.keyProblemDescription
                        : this.state.keyProblemDescription
                    }
                  />
                </ConentAsk>
                <ConentAsk>
                  <FormProblemObs
                    disabled={currentSurveyForm ? true : locker.loading}
                    title="9. Porta superior, existem problemas?"
                    optionKeyName="existsUpperDoorProblem"
                    descriptionKeyName="upperDoorProblemDescription"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[
                            currentSurveyForm.existsUpperDoorProblem
                          ]
                        : this.state.existsUpperDoorProblem
                    }
                    descriptionValue={
                      currentSurveyForm
                        ? currentSurveyForm.upperDoorProblemDescription
                        : this.state.upperDoorProblemDescription
                    }
                  />
                </ConentAsk>
                <ConentAsk>
                  <FormProblemObs
                    disabled={currentSurveyForm ? true : locker.loading}
                    title="10. Interior do armário, existem problemas?"
                    optionKeyName="existsLockerInternProblem"
                    descriptionKeyName="internLockerProblemDescription"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[
                            currentSurveyForm.existsLockerInternProblem
                          ]
                        : this.state.existsLockerInternProblem
                    }
                    descriptionValue={
                      currentSurveyForm
                        ? currentSurveyForm.internLockerProblemDescription
                        : this.state.internLockerProblemDescription
                    }
                  />
                </ConentAsk>
                <ConentAsk>
                  <FormProblemObs
                    disabled={currentSurveyForm ? true : locker.loading}
                    title="11. Parafusos, existem problemas?"
                    optionKeyName="existsScrewsProblem"
                    descriptionKeyName="screwsProblemDescription"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[currentSurveyForm.existsScrewsProblem]
                        : this.state.existsScrewsProblem
                    }
                    descriptionValue={
                      currentSurveyForm
                        ? currentSurveyForm.screwsProblemDescription
                        : this.state.screwsProblemDescription
                    }
                  />
                </ConentAsk>
                <ConentAsk>
                  <FormProblemObs
                    disabled={currentSurveyForm ? true : locker.loading}
                    title="12. Câmera de segurança, existem problemas?"
                    optionKeyName="existsCameraProblems"
                    descriptionKeyName="cameraProblemDescription"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[currentSurveyForm.existsCameraProblems]
                        : this.state.existsCameraProblems
                    }
                    descriptionValue={
                      currentSurveyForm
                        ? currentSurveyForm.cameraProblemDescription
                        : this.state.cameraProblemDescription
                    }
                  />
                </ConentAsk>
                <ConentAsk>
                  <FormProblemObs
                    disabled={currentSurveyForm ? true : locker.loading}
                    title="13. Possui tela de atendimento?"
                    optionKeyName="existsTotem"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[currentSurveyForm.existsTotem]
                        : this.state.existsTotem
                    }
                    onlySelect
                  />
                </ConentAsk>
                {(this.state.existsTotem !== "false" || currentSurveyForm) && (
                  <>
                    <ConentAsk>
                      <FormProblemObs
                        disabled={currentSurveyForm ? true : locker.loading}
                        title="13.1 Suporte de fixação da tela de atendimento, existem problemas?"
                        optionKeyName="existsTotemStructureProblems"
                        descriptionKeyName="totemStructureProblemsDescription"
                        change={e => this.handleChange(e)}
                        value={
                          currentSurveyForm
                            ? chooseOptions[
                                currentSurveyForm.existsTotemStructureProblems
                              ]
                            : this.state.existsTotemStructureProblems
                        }
                        descriptionValue={
                          currentSurveyForm
                            ? currentSurveyForm.totemStructureProblemsDescription
                            : this.state.totemStructureProblemsDescription
                        }
                      />
                    </ConentAsk>
                    <ConentAsk>
                      <FormProblemObs
                        disabled={currentSurveyForm ? true : locker.loading}
                        title="13.2 Tela de atendimento, existem problemas?"
                        optionKeyName="existsTotemProblems"
                        descriptionKeyName="existsTotemProblemsDescription"
                        change={e => this.handleChange(e)}
                        value={
                          currentSurveyForm
                            ? chooseOptions[
                                currentSurveyForm.existsTotemProblems
                              ]
                            : this.state.existsTotemProblems
                        }
                        descriptionValue={
                          currentSurveyForm
                            ? currentSurveyForm.existsTotemProblemsDescription
                            : this.state.existsTotemProblemsDescription
                        }
                      />
                    </ConentAsk>
                  </>
                )}

                <ConentAsk>
                  <FormProblemObs
                    disabled={currentSurveyForm ? true : locker.loading}
                    title="14. Existem outros tipos de danos ou problemas?"
                    optionKeyName="existsAnotherProblems"
                    descriptionKeyName="anotherProblemsDescription"
                    change={e => this.handleChange(e)}
                    value={
                      currentSurveyForm
                        ? chooseOptions[currentSurveyForm.existsAnotherProblems]
                        : this.state.existsAnotherProblems
                    }
                    descriptionValue={
                      currentSurveyForm
                        ? currentSurveyForm.anotherProblemsDescription
                        : this.state.anotherProblemsDescription
                    }
                  />
                </ConentAsk>

                {/* <h4>Dados do responsável</h4>
            <ContentInputs marginBotton="20px">
              <TextField
                name="companyName"
                label="EMPRESARIAL - CONTRATANTE:"
                variant="outlined"
                value={this.state.companyName}
                onChange={e => this.handleChange(e)}
                placeholder="NOME EMPRESARIAL - CONTRATANTE"
                fullWidth
              />
            </ContentInputs>
            <ContentInputs marginBotton="20px">
              <TextField
                name="technicianName"
                label="Responsável pela vistoria:"
                variant="outlined"
                value={this.state.technicianName}
                onChange={e => this.handleChange(e)}
                placeholder="Nome"
                fullWidth
              />
            </ContentInputs>
            <ContentInputs marginBotton="20px">
              <TextField
                name="cpf"
                label="CPF do responsável:"
                variant="outlined"
                value={this.state.cpf}
                onChange={e => this.handleChange(e)}
                placeholder="CPF"
                fullWidth
              />
            </ContentInputs>
            <ContentDateV>
              <h4>Data da vistoria:</h4>
              <TextField
                name="surveyDate"
                type="date"
                value={this.state.surveyDate}
                onChange={e => this.handleChange(e)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </ContentDateV> */}
                {!this.editCurrent() && (
                  <Button type="submit" variant="contained">
                    {this.state.protocol ? "Atualizar Formulario" : "Avançar"}
                  </Button>
                )}
              </form>

              {currentSurveyForm && (
                <ContentImages>
                  <FormContainerImages protocol={currentSurveyForm.protocol} />
                </ContentImages>
              )}

              {currentSurveyForm &&
                parseInt(currentSurveyForm.idLockerSurveyFormStatus) === 2 &&
                !checkHandoverEmail() && (
                  <Button variant="contained" onClick={this.handleUpdate}>
                    <p style={{textTransform: 'lowercase', padding: 0, margin: 0}}>Aceitar vistoria</p>
                  </Button>
                )}

              {currentSurveyForm &&
                parseInt(currentSurveyForm.idLockerSurveyFormStatus) === 1 &&
                checkHandOverEmail() && (
                  <Button
                    variant="contained"
                    onClick={this.handleUpdate}
                    style={{ background: "#4AC151", color: "#fff" }}
                  >
                    Finalizar vistoria
                  </Button>
                )}

              {!checkHandoverEmail() &&
                currentSurveyForm &&
                parseInt(currentSurveyForm.idLockerSurveyFormStatus) === 2 && (
                  <Grid item xs={6} md={12}>
                    <p style={{ marginTop: 20, fontSize: 15 }}>
                      Ao clicar em aceitar a vistoria, você cofirma que o Locker
                      foi entregue conforme informado acima.
                    </p>
                  </Grid>
                )}
            </ThemeTextFildGreen>
          </Content>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatcToProps = dispatch =>
  bindActionCreators(
    {
      ...SurveyFormActions,
      ...LockerActions,
      ...LockerStructureConditionOptions,
      ...PaintConditionOptions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatcToProps
)(withStyles(materialStyle)(SurveyForm));
