import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { Actions as LockerActions } from "../../../../../../store/ducks/locker";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  tab: {
    [theme.breakpoints.down("xs")]: {
      width: "92vw"
    }
  },
  tabLabel: {
    fontFamily: '"Livvic", sans-serif',
    fontWeight: 500,
    textTransform: "lowercase",
    fontSize: "14px"
  }
}));

export default function ScrollableTabsButtonForce({
  view1,
  view2,
  view3,
  view4,
  view5,
  idLocker,
  data
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const [activeLockerFullOpen, setActiveLockerFullOpen] = useState(false);

  useEffect(() => {
    const parameters = JSON.parse(localStorage.getItem("auth")).currentTeam
      .parameters;

    if (parameters.dashboardParams && parameters.dashboardParams.length) {
      const parsed = JSON.parse(parameters.dashboardParams);
      if (Object.keys(parsed).includes("activeLockerFullOpen")) {
        setActiveLockerFullOpen(parsed.activeLockerFullOpen);
      }
    }
  }, []);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          className={classes.tab}
        >
          {data.filter(item => item.show).map((item, index) => (
            <Tab
              label={<p className={classes.tabLabel}>{item.label}</p>}
              icon={item.icon}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {data.filter(item => item.show).map((item, index) => (
        <TabPanel value={value} index={index} className={classes.containerView}>
          {item.component}
        </TabPanel>
      ))}
    </div>
  );
}
