import React from "react";

const TiltedYAxisTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x - 5},${y - 15})`}>
      <text
        style={{ fontSize: 10 }}
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
      >
        {payload.value}
      </text>
    </g>
  );
};

export default TiltedYAxisTick;
