export const Types = {
  getMembersRequest: 'getMembers/Request',
  getMembersSuccess: 'getMembers/Success',
  getMembersError: 'getMembers/Error',
};

const INITAL_STATE = {
  data: [],
  loading: false,
  error: null,
  page: null,
  perPage: null,
  total: null,
  lastPage: null,
};

export default function members(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.getMembersRequest:
      return {
        ...state,
        loading: true,
        error: null,
        data: INITAL_STATE.data,
      };

    case Types.getMembersSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data,
      };

    case Types.getMembersError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

export const Actions = {
  getMembersRequest: data => ({
    type: Types.getMembersRequest,
    payload: { data },
  }),

  getMembersSuccess: data => ({
    type: Types.getMembersSuccess,
    payload: { data },
  }),

  getMembersError: error => ({
    type: Types.getMembersError,
    payload: { error },
  }),
};
