import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Container from "../../../../components/Container";
import Image from "../Image";

function Tutorials() {
  return (
    <Container title="">
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography
            variant="h4"
            align="center"
            style={{ textTransform: "lowercase" }}
          >
            Criando uma entrega pelo painel de gestão
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            Veremos neste página, como criarmos uma entrega pelo{" "}
            <em>Painel de gestão</em>, que reservará uma porta. Quem tem acesso
            a este recurso é somente os colaboradores que possuem permissão para
            acesso a este painel.Esta mesma entrega também pode ser criada pelo
            app <em>handover Drop</em> para quem tem o perfil{" "}
            <em>Conta para depoósito</em>
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            1. Para criar uma entrega, temos dois caminhos. Através do botão
            verde no menu <em>Criar entrega</em>, ou pelo menu{" "}
            <em>Gerenciar entrega</em> e em sseguida clicar no botão{" "}
            <em>Criar uma entrega</em>, entraremos na página para criarmos.
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/delivery/delivery-list.png"
            alt="dashboard-list"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            2. Para cada tipo de operação, haverá campos diferentes conforme
            parametrização da empresa. Mas basicamente deve-se preencher todos
            os campos conforme necessidade.
          </Typography>
          <ul>
            <li>
              Locker: Escolhar o armário onde entrega estará criada (Campo
              obrigatório)
            </li>
            <li>
              Há outros campos:
              <ol>
                <li>
                  <b>
                    <em>Tamanho da porta: </em>
                  </b>{" "}
                  se o locker escolhido tiver portas com tamanhos diferentes,
                  poderá escolher o tipo de porta
                </li>
                <li>
                  <b>
                    <em>Quem faz depósito: </em>
                  </b>
                  escolha o perfl de acesso que será usando para realizar o
                  depósito no armário
                </li>
                <li>
                  <b>
                    <em>Quem faz coleta: </em>
                  </b>
                  escolha o perfl de acesso que será usando para realizar a
                  coleta no armário
                </li>
                <li>
                  <b>
                    <em>Descrição de itens: </em>
                  </b>{" "}
                  Se sua operação utilizar o recurso de registro de movimentação
                  no locker, pode-se usar este para registrar os itens do
                  primeiro depósito
                </li>
              </ol>
            </li>
          </ul>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/delivery/delivery-create.png"
            alt="delivery-create"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            3. Após preenchermos todos os campos, devemos clicar no botão{" "}
            <em>Criar</em>
          </Typography>
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            4. Após criarmos, somos redirecionados para página que mostra o
            registro criado e onde podemos também editá-lo.
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Image
            src="https://handover-static-files.s3.amazonaws.com/tutoriais/dashboard/delivery/delivery-show.png"
            alt="delivery-show"
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Typography variant="h6">
            5. Ao voltar para o menu <em>Gerenciar entregas</em> veremos na
            tabela a entrega que acabamos de criar
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Tutorials;
