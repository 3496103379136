import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as CustomerLogActions } from "../ducks/customerLogs";

export function* customerLogsRequest(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/logs/customer?${action.payload}`
    );

    yield put(CustomerLogActions.customerLogsSuccess(response.data));
  } catch (err) {
    yield put(CustomerLogActions.customerLogsError(err));
  }
}
