import React, { Component } from "react";

import apiDS from "../../services/apiDS";

// Material
import { Grid, Button, TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Container from "../../components/Container";
import { Loading, TextLoading } from "./styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

const type = [
  {
    value: "address",
    label: "Endereço"
  },
  {
    value: "LatLog",
    label: "Coordenadas"
  }
];
class ToolsDatascience extends Component {
  state = {
    data: [],
    open: true,
    kilometros: 0,
    type: "address",
    radius: false,
    fileName: "",
    file: {},
    mapHtml: null,
    gmap_coord_waypoints: [],
    loading: false,
    error: null,
    columnsEnd: [
      { title: "Logradouro", field: "logradouro" },
      { title: "Número", field: "numero" },
      {
        title: "Estado",
        field: "estado",
        lookup: {
          Acre: "Acre",
          Alagoas: "Alagoas",
          Amapá: "Amapá",
          Amazonas: "Amazonas",
          Bahia: "Bahia",
          Ceará: "Ceará",
          "Espírito Santo": "Espírito Santo",
          Goias: "Goias",
          Maranhão: "Maranhão",
          "Mato Grosso": "Mato Grosso",
          "Mato Grosso do Sul": "Mato Grosso do Sul",
          "Minas Gerais": "Minas Gerais",
          Pará: "Pará",
          Paraíba: "Paraiba",
          Paraná: "Paraná",
          Pernambuco: "Pernambuco",
          Piauí: "Piauí",
          "Rio de Janeiro": "Rio de Janeiro",
          "Rio Grande do Norte": "Rio Grande do Norte",
          "Rio Grande do Sul": "Rio Grande do Sul",
          Rondônia: "Rondônia",
          Roraima: "Roraima",
          "Santa Catarina": "Santa Catarina",
          "São Paulo": "São Paulo",
          Sergipe: "Sergipe",
          Tocantins: "Tocantins",
          "Distrito Federal": "Distrito Federal"
        }
      },
      {
        title: "Raio para cada locker",
        field: "radius",
        lookup: {
          500: "0,5 KM",
          1000: "1 KM",
          1500: "1,5 KM",
          2000: "2 KM",
          2500: "2,5 KM",
          3000: "3 KM",
          3500: "3,5 KM",
          4000: "4 KM",
          4500: "4,5 KM",
          5000: "5 KM"
        }
      }
    ],
    columnsLatLog: [
      {
        title: "Latitude",
        field: "lat"
      },
      {
        title: "Longitude",
        field: "lng"
      },
      {
        title: "Raio para cada locker",
        field: "radius",
        lookup: {
          500: "0,5 KM",
          1000: "1 KM",
          1500: "1,5 KM",
          2000: "2 KM",
          2500: "2,5 KM",
          3000: "3 KM",
          3500: "3,5 KM",
          4000: "4 KM",
          4500: "4,5 KM",
          5000: "5 KM"
        }
      }
    ]
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChangeChecked = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  returnDataString = data => {
    return data.map(d => {
      return {
        address: d.logradouro + ", " + d.numero + ", " + d.estado,
        radius: d.radius
      };
    });
  };

  fetchLocationRoute = async () => {
    try {
      const formData = new FormData();

      this.setState({
        loading: true,
        error: null
      });

      if (this.state.data.length > 0) {
        const data = this.returnDataString(this.state.data);
        formData.append("point_locker", JSON.stringify(data));
      } else {
        formData.append("point_locker", JSON.stringify(this.state.data));
      }

      formData.append("radius", this.state.kilometros);
      // formData.append("point_locker", JSON.stringify(this.state.data));
      formData.append("file", this.state.file, this.state.fileName);

      const data = await apiDS.post(`/heatmap/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*"
        }
      });

      this.setState({
        mapHtml: data.data,
        loading: false
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: err
      });
    }
  };

  handleFile = ({ target }) => {
    if (!target.files.length) {
      this.setState({ fileName: "" });
      return;
    }

    this.setState({ file: target.files[0], fileName: target.files[0].name });
  };

  render() {
    return (
      <Container title="mapa de calor">
        <ThemeTextFildGreen>
          <div className="helper-spacing-top-mobile" />

          {this.state.open && (
            <Alert
              severity="info"
              style={{ width: "100%" }}
              onClose={() => this.setState({ open: false })}
            >
              <AlertTitle>Funcionalidades:</AlertTitle>
              <p style={{ fontSize: "16px" }}>
                Esta ferramenta possibilita criar um mapa de calor a partir dos
                dados fornecidos pelo cliente. A partir destes dados o cliente
                pode ver todos os pontos onde houve entregas e traçar rotas
                estratégicas para futuras vendas.
              </p>
              <AlertTitle>Observações: </AlertTitle>

              <span>
                O tipo de arquivo esperado deve estar no formato{" "}
                <strong>xlsx</strong>;
              </span>
              <br />
              <span>
                Para modelo de endereço, é permitido no máximo 100 endereços no
                arquivo xlsx;
              </span>
              <br />
              <span>
                O xlsx deve estar em um deste modelos: <strong>endereço</strong>
              </span>
              <ul style={{ marginTop: 10 }}>
                <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                  Para acessar o modelo de <strong>endereço</strong>{" "}
                  <a
                    href="https://drive.google.com/file/d/10nEEujSrl307p_1rA110Dx89NrQgJnNw/view"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    clique aqui
                  </a>
                </li>
                {/* <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                  Para acessar o modelo de <strong>coordenadas</strong>{" "}
                  <a
                    href="https://drive.google.com/file/d/1OZAJtd_41YjjMtVE3EFYaP5kLsA-FNT8/view"
                    target="_blank"
                  >
                    clique aqui
                  </a>
                </li> */}
              </ul>
            </Alert>
          )}

          <Loading loading={this.state.loading}>
            <TextLoading>Carregando...</TextLoading>
          </Loading>

          <h4
            style={{
              marginBottom: -10,
              marginTop: 20,
              textAlign: "left",
              fontSize: "16px"
            }}
          >
            Adicione seu arquivo
          </h4>

          <Grid
            container
            spacing={2}
            style={{
              marginBottom: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Grid item xs={6} md={4}>
              <TextField
                select
                fullWidth
                margin="normal"
                name="type"
                label="Formato de dados para inserir"
                value={this.state.type}
                onChange={e => this.handleChange(e)}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
              >
                {type.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={5} md={2} style={{ paddingTop: 25 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.radius}
                    onChange={this.handleChangeChecked}
                    name="radius"
                    color="primary"
                  />
                }
                label="Mostrar raio a partir do locker"
              />
            </Grid>
          </Grid>

          {this.state.radius && (
            <>
              <h4 style={{ marginLeft: 10, marginBottom: 15 }}>
                Endereço dos lockers
              </h4>

              <MaterialTable
                title=""
                columns={
                  this.state.type === "address"
                    ? this.state.columnsEnd
                    : this.state.columnsLatLog
                }
                data={this.state.data}
                localization={{
                  body: {
                    editRow: {
                      deleteText: "Deseja excluir este endereço?",
                      cancelTooltip: "Cancelar",
                      saveTooltip: "Salvar"
                    },
                    addTooltip: "Adicionar",
                    deleteTooltip: "Deletar",
                    editTooltip: "Editar",
                    emptyDataSourceMessage: "Nenhum endereço adicionado"
                  },
                  header: {
                    actions: ""
                  }
                }}
                editable={{
                  onRowAdd: newData =>
                    new Promise(resolve => {
                      setTimeout(() => {
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data.push(newData);
                          return { ...prevState, data };
                        });

                        resolve();
                      }, 1000);
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                      setTimeout(() => {
                        resolve();
                        if (oldData) {
                          this.setState(prevState => {
                            const data = [...prevState.data];
                            data[data.indexOf(oldData)] = newData;
                            return { ...prevState, data };
                          });
                        }
                      }, 1000);
                    }),
                  onRowDelete: oldData =>
                    new Promise(resolve => {
                      setTimeout(() => {
                        resolve();
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          return { ...prevState, data };
                        });
                      }, 1000);
                    })
                }}
                options={{
                  paging: false,
                  search: false,
                  actionsColumnIndex: -1,
                  toolbarButtonAlignment: "left"
                }}
              />
            </>
          )}
          <Grid
            container
            spacing={2}
            style={{
              marginBottom: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Grid item xs={6} md={4}>
              <TextField
                label="Arquivo xlsx"
                disabled
                fullWidth
                name="origin"
                value={this.state.fileName}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3} md={2}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#006660",
                  fontSize: 14,
                  color: "#fff",
                  textTransform: "lowercase",
                  height: 35,
                }}
                component="label"
              >
                Upload
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={this.handleFile}
                  style={{ display: "none" }}
                />
              </Button>
            </Grid>
          </Grid>

          <Button
            onClick={this.fetchLocationRoute}
            variant="contained"
            style={{
              backgroundColor: "#006660",
              fontSize: 14,
              color: "#fff",
              textTransform: "lowercase",
              height: 35,
            }}
          >
            Calcular
          </Button>
          <br />
          {this.state.mapHtml && (
            <iframe
              title="ddd"
              srcdoc={this.state.mapHtml}
              width={"100%"}
              height={"480px"}
            />
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

export default ToolsDatascience;
