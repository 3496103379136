import React, { Component } from "react";

// Material
import { TextField, MenuItem, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";

// Global Components
import Container from "../../components/Container";
//import Snackbar from "../../components/Snackbar";
import Spinner from "../../components/Spinner";
import TextMaskCustomCNPJ from "../../components/TextMaskCustomCNPJ";
import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";
import EditorCurrentRegisterContainer from "../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../components/ButtonEditorCurrentRegister";

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as SubTeamActions } from "../../store/ducks/subTeam";
import { Actions as ConfigsActions } from "../../store/ducks/configs";

import getOnlyNumbers from "../../services/getOnlyNumbers";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

class BusinessEdit extends Component {
  state = {
    name: "",
    email: "",
    currentSubTeam: null,
    notChange: true,
    roles: [],
    teams: [],
    edit: false
  };

  componentWillMount() {
    const { showSubTeamRequest, setToastVisible } = this.props;
    const { id } = this.props.match.params;

    setToastVisible({ toastVisible: false });
    showSubTeamRequest(id);
  }

  componentWillReceiveProps(nextProps) {
    const { subTeams } = nextProps;
    if (subTeams.currentSubTeam !== this.state.currentSubTeam) {
      this.setState({
        currentSubTeam: subTeams.currentSubTeam,
        edit: subTeams.error ? true : false,
        snackbarVisible: subTeams.snackbarVisible
      });
    } else {
      this.setState({ edit: false });
    }
  }

  componentWillUnmount() {
    /*const { setSnackbarErrorVisible, setSnackbarVisibleBusiness } = this.props;
    setSnackbarErrorVisible(false);
    setSnackbarVisibleBusiness(false);*/
  }

  handleChange = key => event => {
    let value = event.target.value;

    if (key === "cnpj" || key === "mobile") {
      value = getOnlyNumbers(event.target.value);
    }

    this.setState({
      currentSubTeam: {
        ...this.state.currentSubTeam,
        [key]: value
      }
    });
  };

  handleChangeRoles = key => event => {
    this.setState({ [key]: event.target.value });
  };

  handleSave = () => {
    const { updateSubTeamRequest } = this.props;
    const { currentSubTeam } = this.state;

    updateSubTeamRequest(currentSubTeam);
  };

  handleSelectValueLabel = (id, data) => {
    const check = data.find(item => item.id === id);

    if (!check) return "?";

    return check.name;
  };

  renderContent = () => {
    const { classes, subTeams } = this.props;
    const { currentSubTeam, edit } = this.state;

    return (
      <ThemeTextFildGreen>
        <h3>Editar cadastro da loja</h3>
        <EditorCurrentRegisterContainer
          disabled={subTeams.loading || edit}
          flexDirection="row-reverse"
        >
          <ButtonEditorCurrentRegister
            label="Editar"
            onClick={() => this.setState({ edit: true })}
          />
        </EditorCurrentRegisterContainer>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label="Nome"
              className={classes.textField}
              value={currentSubTeam.name}
              onChange={this.handleChange("name")}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading || !edit}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label="Email"
              className={classes.textField}
              value={currentSubTeam.email}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading || !edit}
              defaultValue={currentSubTeam.email}
              onChange={this.handleChange("email")}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              onChange={this.handleChange("cnpj")}
              id="outlined-name"
              label="CNPJ"
              className={classes.textField}
              value={currentSubTeam.cnpj}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading || !edit}
              InputProps={{
                inputComponent: TextMaskCustomCNPJ
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label="Contato"
              className={classes.textField}
              value={currentSubTeam.mobile}
              defaultValue={currentSubTeam.mobile}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading || !edit}
              InputProps={{
                inputComponent: TextMaskCustomMobile
              }}
              onChange={this.handleChange("mobile")}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              disabled={subTeams.loading || !edit}
              id="custom-css-outlined-input"
              select
              label="Status"
              className={classes.selectType}
              value={currentSubTeam.idSubTeamStatus}
              onChange={this.handleChange("idSubTeamStatus")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              variant="outlined"
            >
              {subTeams.subTeamStatus.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {edit && (
          <>
            <Button
              variant="contained"
              onClick={this.handleSave}
              disabled={subTeams.loading}
              className={classes.button}
              style={{ // para os Buttons
                backgroundColor: "#006660",
                fontSize: 14,
                color: "#fff",
                textTransform: "lowercase",
                height: 35,
              }}
            >
              <SaveIcon className={classes.leftIcon} />
              Salvar
            </Button>
            <Button
              variant="contained"
              onClick={() => this.setState({ edit: false })}
              disabled={subTeams.loading}
              className={classes.button}
              style={{ // para os Buttons
                backgroundColor: "#006660",
                fontSize: 14,
                color: "#fff",
                textTransform: "lowercase",
                height: 35,
              }}
            >
              <CancelIcon className={classes.leftIcon} />
              Cancelar
            </Button>
          </>
        )}
      </ThemeTextFildGreen>
    );
  };

  render() {
    const { subTeams } = this.props;
    const { currentSubTeam } = this.state;

    return (
      <Container title="">
        {subTeams.loading ? (
          <Spinner />
        ) : (
          <>{currentSubTeam && this.renderContent()}</>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispathToProps = dispatch =>
  bindActionCreators(
    {
      ...SubTeamActions,
      ...ConfigsActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withStyles(materialStyle)(BusinessEdit));
