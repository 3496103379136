import * as React from "react";
import Select from "react-select";

export default function MultipleSelectChip({
  onChange,
  value,
  options,
  disabled,
  getOptionLabel,
  getOptionValue,
  className
}) {
  return (
    <div>
      <Select
        isMulti
        options={options}
        value={value}
        getOptionLabel={item => getOptionLabel(item)}
        getOptionValue={item => getOptionValue(item)}
        onChange={item => {
          onChange(item);
        }}
        isDisabled={disabled}
        className={className}
      />
    </div>
  );
}

MultipleSelectChip.defaultProps = {
  onChange: () => {},
  value: [],
  options: [],
  disabled: false,
  getOptionLabel: item => item.name,
  getOptionValue: item => item.id
};
