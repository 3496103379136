export default function formatCPF(cpf, masked = false) {
  // Remove todos os caracteres não numéricos
  const numericCPF = cpf.replace(/\D/g, "");

  // Aplica a formatação para os dígitos já inseridos
  return numericCPF.replace(
    /(\d{1,3})(\d{1,3})?(\d{1,3})?(\d{1,2})?/,
    (_, p1, p2, p3, p4) => {
      let formatted = p1;

      if (masked) {
        p2 = p2.substring().replace(/./g, "*");
        p3 = p3.substring().replace(/./g, "*");
      }

      if (p2) formatted += `.${p2}`;
      if (p3) formatted += `.${p3}`;
      if (p4) formatted += `-${p4}`;

      return formatted;
    }
  );
}
