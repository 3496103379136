export const Types = {
  lockersRequest: "lockers/Request",
  lockersSuccess: "lockers/Success",
  lockersError: "lockers/Error",

  lockersSelectRequest: "lockersSelect/Request",
  lockersSelectSuccess: "lockersSelect/Success",
  lockersSelectError: "lockersSelect/Error",

  showLockerRequest: "showLocker/Request",
  showLockerSuccess: "showLocker/Success",
  showLockerError: "showLocker/Error",

  getLocationLockerRequest: "getLocationLocker/Request",
  getLocationLockerSuccess: "getLocationLocker/Success",
  getLocationLockerError: "getLocationLocker/Error",

  getLocationRequest: "getLocation/Request",
  getLocationSuccess: "getLocation/Success",
  getLocationError: "getLocation/Error",

  updateLockerRequest: "updateLocker/Request",
  updateLockerSuccess: "updateLocker/Success",
  updateLockerError: "updateLocker/Error",

  getInfoLockersRequest: "getInfoLockers/Request",
  getInfoLockersSuccess: "getInfoLockers/Success",
  getInfoLockersError: "getInfoLockers/Error",

  createLockerRequest: "createLocker/Request",
  createLockerSuccess: "createLocker/Success",
  createLockerError: "createLocker/Error",

  openSnackbarLockers: "setSnackbarVisible/Lockers",
  closeSnackbarLockers: "closeSnackbar/Lockers",

  openLockerAllCompartmentsRequest: "openLockerAllCompartments/Request",
  openLockerAllCompartmentsSuccess: "openLockerAllCompartments/Success",
  openLockerAllCompartmentsError: "openLockerAllCompartments/Error",

  setEditableLockers: "setEditable/Lockers",

  compartmentIdentifiersReset: "compartmentIdentifiersReset",
  getCompartmentIdentifiersRequest: "getCompartmentIdentifiers/Request",
  getCompartmentIdentifiersSuccess: "getCompartmentIdentifiers/Success",
  getCompartmentIdentifiersError: "getCompartmentIdentifiers/Error",

  setLockerAvailable: "setLockerAvailable",
  // internal locker adm
  rebootLockerRequest: "rebootLocker/Request",
  rebootLockerSuccess: "rebootLocker/Success",
  rebootLockerError: "rebootLocker/Error",

  getSetupLockerPasswordsRequest: "getSetupLockerPasswords/Request",
  getSetupLockerPasswordsSuccess: "getSetupLockerPasswords/Success",
  getSetupLockerPasswordsError: "getSetupLockerPasswords/Error",

  resetLockers: "resetLockers"
};

const INITIAL_STATE = {
  data: [],
  dataSelect: [],
  loading: false,
  error: null,
  total: null,
  perPage: null,
  lastPage: null,
  page: null,
  currentLocker: null,
  lockersInfo: null,
  snackbarVisible: false,
  snackbarMessage: "",
  snackbarType: "",
  locationInfo: null,
  setEditable: false,
  compartmentIdentiers: [],
  setupPasswords: []
};

export default function lockers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.resetLockers:
      return INITIAL_STATE;

    case Types.openSnackbarLockers:
      return {
        ...state,
        ...action.payload,
        snackbarVisible: true
      };

    case Types.compartmentIdentifiersReset:
      return {
        ...state,
        compartmentIdentiers: INITIAL_STATE.compartmentIdentiers
      };

    case Types.setEditableLockers:
      return {
        ...state,
        setEditable: action.payload.status
      };

    case Types.closeSnackbarLockers:
      return {
        ...state,
        snackbarVisible: false
      };

    case Types.lockersRequest:
      return {
        ...state,
        loading: true,
        error: null,
        data: []
      };

    case Types.lockersSuccess:
      const { lastPage, total, perPage, page, data } = action.payload.data;

      return {
        ...state,
        data,
        total,
        perPage,
        lastPage,
        page,
        loading: false
      };

    case Types.lockersError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.lockersSelectRequest:
      return {
        ...state,
        loading: true,
        error: null,
        dataSelect: []
      };

    case Types.lockersSelectSuccess:
      return {
        ...state,
        dataSelect: action.payload.data.data,
        loading: false
      };

    case Types.lockersSelectError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.showLockerRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.showLockerSuccess:
      return {
        ...state,
        currentLocker: action.payload.data,
        loading: false
      };

    case Types.showLockerError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.getLocationLockerSuccess:
      return {
        ...state,
        currentLocker: {
          ...state.currentLocker,
          ...action.payload.data,
          idCountry: action.payload.data.country.id,
          idState: action.payload.data.state.id,
          idCity: action.payload.data.city.id
        }
      };

    case Types.getLocationLockerError:
      return {
        ...state
      };

    case Types.updateLockerError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.updateLockerRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.updateLockerSuccess:
      return {
        ...state,
        currentLocker: {
          ...state.currentLocker,
          ...action.payload.data
        },
        loading: false,
        setEditable: INITIAL_STATE.setEditable
      };

    case Types.getInfoLockersRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.getInfoLockersSuccess:
      return {
        ...state,
        lockersInfo: action.payload.data,
        loading: false
      };

    case Types.getInfoLockersError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.getLocationRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.getLocationSuccess:
      return {
        ...state,
        locationInfo: action.payload.data,
        loading: false
      };

    case Types.getLocationError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.createLockerRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.createLockerSuccess:
      return {
        ...state,
        loading: false
      };

    case Types.createLockerError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.openLockerAllCompartmentsRequest:
      return {
        ...state,
        loading: true,
        error: INITIAL_STATE.error
      };

    case Types.openLockerAllCompartmentsSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data
      };

    case Types.openLockerAllCompartmentsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.getCompartmentIdentifiersRequest:
      return {
        ...state,
        loading: true,
        error: INITIAL_STATE.error,
        compartmentIdentiers: INITIAL_STATE.compartmentIdentiers
      };

    case Types.getCompartmentIdentifiersSuccess:
      return {
        ...state,
        loading: false,
        compartmentIdentiers: action.payload.data
      };

    case Types.getCompartmentIdentifiersError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        compartmentIdentiers: INITIAL_STATE.compartmentIdentiers
      };

    case Types.setLockerAvailable:
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id === action.payload.id) {
            item.available = action.payload.available || 0;
          }
          return item;
        })
      };

    case Types.rebootLockerRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.rebootLockerSuccess:
      return {
        ...state,
        loading: false,
        snackbarVisible: true,
        snackbarType: "success",
        snackbarMessage: "Solicitação enviada"
      };

    case Types.rebootLockerError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true,
        snackbarType: "error",
        snackbarMessage: "Erro geral"
      };

    case Types.getSetupLockerPasswordsRequest:
      return {
        ...state,
        loading: true,
        error: null,
        setupPasswords: INITIAL_STATE.setupPasswords
      };

    case Types.getSetupLockerPasswordsSuccess:
      return {
        ...state,
        loading: false,
        setupPasswords: action.payload.data
      };

    case Types.getSetupLockerPasswordsError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
}

export const Actions = {
  lockersRequest: data => ({
    type: Types.lockersRequest,
    payload: { data }
  }),

  lockersSuccess: data => ({
    type: Types.lockersSuccess,
    payload: { data }
  }),

  lockersError: error => ({
    type: Types.lockersError,
    payload: { error }
  }),

  lockersSelectRequest: data => ({
    type: Types.lockersSelectRequest,
    payload: { data }
  }),

  lockersSelectSuccess: data => ({
    type: Types.lockersSelectSuccess,
    payload: { data }
  }),

  lockersSelectError: error => ({
    type: Types.lockersSelectError,
    payload: { error }
  }),

  showLockerRequest: id => ({
    type: Types.showLockerRequest,
    payload: { id }
  }),

  showLockerSuccess: data => ({
    type: Types.showLockerSuccess,
    payload: { data }
  }),

  showLockerError: error => ({
    type: Types.showLockerError,
    payload: { error }
  }),

  getLocationLockerRequest: data => ({
    type: Types.getLocationLockerRequest,
    payload: { data }
  }),

  getLocationLockerSuccess: data => ({
    type: Types.getLocationLockerSuccess,
    payload: { data }
  }),

  getLocationLockerError: error => ({
    type: Types.getLocationLockerError,
    payload: { error }
  }),

  updateLockerRequest: (id, data) => ({
    type: Types.updateLockerRequest,
    payload: { id, data }
  }),

  updateLockerSuccess: data => ({
    type: Types.updateLockerSuccess,
    payload: { data }
  }),

  updateLockerError: error => ({
    type: Types.updateLockerError,
    payload: { error }
  }),

  getInfoLockersRequest: () => ({
    type: Types.getInfoLockersRequest
  }),

  getInfoLockersSuccess: data => ({
    type: Types.getInfoLockersSuccess,
    payload: { data }
  }),

  getInfoLockersError: error => ({
    type: Types.getInfoLockersError,
    payload: { error }
  }),

  openSnackbarLockers: (snackbarMessage = "", snackbarType = "") => ({
    type: Types.openSnackbarLockers,
    payload: { snackbarMessage, snackbarType }
  }),

  getLocationRequest: data => ({
    type: Types.getLocationRequest,
    payload: { data }
  }),

  getLocationSuccess: data => ({
    type: Types.getLocationSuccess,
    payload: { data }
  }),

  getLocationError: error => ({
    type: Types.getLocationError,
    payload: { error }
  }),

  createLockerRequest: data => ({
    type: Types.createLockerRequest,
    payload: { data }
  }),

  createLockerSuccess: data => ({
    type: Types.createLockerSuccess,
    payload: { data }
  }),

  createLockerError: error => ({
    type: Types.createLockerError,
    payload: { error }
  }),

  closeSnackbarLockers: () => ({
    type: Types.closeSnackbarLockers
  }),

  openLockerAllCompartmentsRequest: idLocker => ({
    type: Types.openLockerAllCompartmentsRequest,
    idLocker
  }),

  openLockerAllCompartmentsSuccess: data => ({
    type: Types.openLockerAllCompartmentsSuccess,
    payload: { data }
  }),

  openLockerAllCompartmentsError: error => ({
    type: Types.openLockerAllCompartmentsError,
    payload: { error }
  }),

  setEditableLockers: status => ({
    type: Types.setEditableLockers,
    payload: { status }
  }),

  getCompartmentIdentifiersRequest: data => ({
    type: Types.getCompartmentIdentifiersRequest,
    payload: { data }
  }),

  getCompartmentIdentifiersSuccess: data => ({
    type: Types.getCompartmentIdentifiersSuccess,
    payload: { data }
  }),

  getCompartmentIdentifiersError: error => ({
    type: Types.getCompartmentIdentifiersError,
    payload: { error }
  }),

  compartmentIdentifiersReset: () => ({
    type: Types.compartmentIdentifiersReset
  }),

  setLockerAvailable: (id, available) => ({
    type: Types.setLockerAvailable,
    payload: { id, available }
  }),

  rebootLockerRequest: idLocker => ({
    type: Types.rebootLockerRequest,
    payload: { idLocker }
  }),

  rebootLockerSuccess: () => ({
    type: Types.rebootLockerSuccess
  }),

  rebootLockerError: error => ({
    type: Types.rebootLockerError,
    payload: { error }
  }),

  getSetupLockerPasswordsRequest: idLocker => ({
    type: Types.getSetupLockerPasswordsRequest,
    payload: { idLocker }
  }),

  getSetupLockerPasswordsSuccess: data => ({
    type: Types.getSetupLockerPasswordsSuccess,
    payload: { data }
  }),

  getSetupLockerPasswordsError: error => ({
    type: Types.getSetupLockerPasswordsError,
    payload: { error }
  }),

  resetLockers: () => ({
    type: Types.resetLockers
  })
};
