import React, { Component } from "react";

// Material
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

// Global Components
import Container from "../../../../components/Container";

// Global Styles
import { materialStyle } from "../../../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as OrderActions } from "../../../../store/ducks/orders";
import ThemeTextFildGreen from "../../../../styles/ThemeTextFildGreen";
import { Grid } from "@material-ui/core";
import WalletLogsTable from "../../../../components/WalletLogsTable";
import TextMaskCustomMobile from "../../../../components/TextMaskCustomMobile";
import TextMaskCustomCPF from "../../../../components/TextMaskCustomCPF";
import SimpleTable from "../../../../components/SimpleTable";
import formatDate from "../../../../functions/formatDate";

class OrderEdit extends Component {
  state = {
    currentOrder: null
  };

  componentWillMount() {
    const { showOrderRequest } = this.props;
    const { id } = this.props.match.params;

    showOrderRequest(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentOrder !== this.state.currentOrder) {
      this.setState({
        currentOrder: nextProps.currentOrder
      });
    }
  }

  handleChange = key => event => {
    this.setState({
      ...this.state,
      currentOrder: {
        ...this.state.currentOrder,
        [key]: event.target.value
      }
    });
  };

  render() {
    const { classes, loading } = this.props;
    const { currentOrder } = this.state;

    return (
      <Container title="Detalhes da locação">
        {loading ? (
          <CircularProgress />
        ) : (
          <div>
            {currentOrder && (
              <>
                <ThemeTextFildGreen>
                  <h5 className={classes.subTitle}>Dados do cliente</h5>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                      <TextField
                        id="outlined-name"
                        label="Locador"
                        className={classes.textField}
                        value={currentOrder.toUser.name}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        id="outlined-name"
                        label="Email"
                        className={classes.textField}
                        value={currentOrder.toUser.email}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <TextField
                        id="outlined-name"
                        label="Contato"
                        className={classes.textField}
                        value={
                          String(currentOrder.toUser.mobile).startsWith("55")
                            ? String(currentOrder.toUser.mobile).substring(2)
                            : currentOrder.toUser.mobile
                        }
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          inputComponent: TextMaskCustomMobile
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <TextField
                        id="outlined-name"
                        label="CPF"
                        className={classes.textField}
                        value={currentOrder.toUser.cpf}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          inputComponent: TextMaskCustomCPF
                        }}
                      />
                    </Grid>
                  </Grid>

                  <h5 className={classes.subTitle}>Detalhes da locação</h5>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={1}>
                      <TextField
                        id="outlined-name"
                        label="Tempo alugado"
                        className={classes.textField}
                        value={`${currentOrder.boughtHours} hrs`}
                        onChange={this.handleChange("boughtHours")}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={1}>
                      <TextField
                        id="outlined-name"
                        label="Custo total"
                        className={classes.textField}
                        value={`R$ ${currentOrder.totalCost}`}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        id="outlined-name"
                        label="Locker"
                        className={classes.textField}
                        value={currentOrder.locker.address}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <TextField
                        id="outlined-name"
                        label="Porta"
                        className={classes.textField}
                        value={currentOrder.compartment.compartmentNumber}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <TextField
                        id="outlined-name"
                        label="Status"
                        className={classes.textField}
                        value={currentOrder.finishedAt ? "Finalizada" : "Ativa"}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <TextField
                        id="outlined-name"
                        label="Data"
                        className={classes.textField}
                        value={currentOrder.createdAt}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <TextField
                        id="outlined-name"
                        label="Finalizada em"
                        className={classes.textField}
                        value={currentOrder.finishedAt || "Ainda ativa"}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>
                  </Grid>

                  <h5 className={classes.subTitle}>
                    Carteira do cliente agora
                  </h5>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                      <TextField
                        id="outlined-name"
                        label="Saldo"
                        className={classes.textField}
                        value={`R$ ${currentOrder.toUser.wallet.amount}`}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        id="outlined-name"
                        label="Bonus"
                        className={classes.textField}
                        value={`R$ ${currentOrder.toUser.wallet.bonus}`}
                        margin="normal"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>
                  </Grid>

                  <h5 className={classes.subTitle}>Uso da carteira</h5>
                  <WalletLogsTable data={currentOrder.walletLogs} />

                  <br />

                  <h5 className={classes.subTitle}>
                    Aberturas de porta geral do usuário
                  </h5>
                  <SimpleTable
                    columns={[
                      {
                        id: Math.random(),
                        text: "Locker"
                      },
                      {
                        id: Math.random(),
                        text: "Porta"
                      },
                      {
                        id: Math.random(),
                        text: "Data"
                      },
                      {
                        id: Math.random(),
                        text: "Status"
                      }
                    ]}
                    rowValues={currentOrder.compartmentOpening.map(item => {
                      const newItem = {};

                      Object.assign(newItem, {
                        id: item.id,
                        data: [
                          {
                            id: Math.random(),
                            value: item.compartment.locker
                              ? item.compartment.locker.address
                              : "?"
                          },
                          {
                            id: Math.random(),
                            value: item.compartment
                              ? item.compartment.compartmentNumber
                              : "?"
                          },
                          {
                            id: Math.random(),
                            value: formatDate(item.createdAt)
                          },
                          {
                            id: Math.random(),
                            value: item.status ? "Enviado" : "Não enviado"
                          }
                        ]
                      });

                      return newItem;
                    })}
                  />
                </ThemeTextFildGreen>
              </>
            )}
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.orders.loading,
  currentOrder: state.orders.currentOrder
});

const mapDispathToProps = dispatch =>
  bindActionCreators(OrderActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withStyles(materialStyle)(OrderEdit));
