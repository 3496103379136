import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

function AlertDialog({
  open,
  handleClose,
  title,
  message,
  cancelButtonText,
  confirmButtonText,
  onConfirm
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {cancelButtonText}
          </Button>
          <Button
            onClick={() => {
              handleClose();

              if (typeof onConfirm !== "undefined") {
                onConfirm();
              }
            }}
            color="primary"
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.func
};

AlertDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  title: "Título",
  message: "Corpo da mensagem",
  cancelButtonText: "Cancelar",
  confirmButtonText: "Sim",
  onConfirm: () => {}
};

export default AlertDialog;
