export const Types = {
  thingTypesRequest: "thingTypes/Request",
  thingTypesSuccess: "thingTypes/Success",
  thingTypesError: "thingTypes/Error",

  showThingTypeRequest: "showThingType/Request",
  showThingTypeSuccess: "showThingType/Success",
  showThingTypeError: "showThingType/Error",

  updateThingTypeRequest: "updateThingType/Request",
  updateThingTypeSuccess: "updateThingType/Success",
  updateThingTypeError: "updateThingType/Error",

  createThingTypeRequest: "createThingType/Request",
  createThingTypeSuccess: "createThingType/Success",
  createThingTypeError: "createThingType/Error"
};

const INITAL_STATE = {
  data: [],
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1,
  currentThingType: null
};

export default function thingTypes(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.thingTypesRequest:
      return {
        ...INITAL_STATE,
        loading: true,
        error: null
      };

    case Types.thingTypesSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload.data
      };

    case Types.thingTypesError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.showThingTypeRequest:
      return {
        ...INITAL_STATE,
        loading: true,
        error: null,
        currentThingType: null
      };

    case Types.showThingTypeSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        currentThingType: action.payload.data
      };

    case Types.showThingTypeError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.updateThingTypeRequest:
      return {
        ...INITAL_STATE,
        loading: true,
        error: null
      };

    case Types.updateThingTypeSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        currentThingType: action.payload.data
      };

    case Types.updateThingTypeError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.createThingTypeRequest:
      return {
        ...INITAL_STATE,
        loading: true,
        error: null
      };

    case Types.createThingTypeSuccess:
      return {
        ...state,
        loading: false,
        error: null
      };

    case Types.createThingTypeError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
}

export const Actions = {
  thingTypesRequest: data => ({
    type: Types.thingTypesRequest,
    payload: {
      data
    }
  }),

  thingTypesSuccess: data => ({
    type: Types.thingTypesSuccess,
    payload: { data }
  }),

  thingTypesError: error => ({
    type: Types.thingTypesError,
    payload: { error }
  }),

  showThingTypeRequest: id => ({
    type: Types.showThingTypeRequest,
    payload: {
      id
    }
  }),

  showThingTypeSuccess: data => ({
    type: Types.showThingTypeSuccess,
    payload: { data }
  }),

  showThingTypeError: error => ({
    type: Types.showThingTypeError,
    payload: { error }
  }),

  updateThingTypeRequest: data => ({
    type: Types.updateThingTypeRequest,
    payload: {
      data
    }
  }),

  updateThingTypeSuccess: data => ({
    type: Types.updateThingTypeSuccess,
    payload: { data }
  }),

  updateThingTypeError: error => ({
    type: Types.updateThingTypeError,
    payload: { error }
  }),

  createThingTypeRequest: data => ({
    type: Types.createThingTypeRequest,
    payload: {
      data
    }
  }),

  createThingTypeSuccess: data => ({
    type: Types.createThingTypeSuccess,
    payload: { data }
  }),

  createThingTypeError: error => ({
    type: Types.createThingTypeError,
    payload: { error }
  })
};
