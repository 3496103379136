import { call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import api from "../../services/api";

import { Actions as TeamsActions } from "../ducks/teams";
import { Actions as ConfigsActions } from "../ducks/configs";
import { Actions as SubTeamActions } from "../ducks/subTeam";

import constants from "../../config/constants";

export function* getAllTeams(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/teams?${action.payload.data}`
    );

    yield put(TeamsActions.teamsSuccess(response.data));

    // if (response.data.data.length === 1) {
    //   const subTeams = yield call(api.get, `/v1/adm/subTeams`);

    //   yield put(SubTeamActions.getSubTeamsSuccess(subTeams.data));
    // }
  } catch (err) {
    yield put(TeamsActions.teamsError(err));
  }
}

export function* setCurrentTeam(action) {
  try {
    const auth = yield JSON.parse(localStorage.getItem("auth"));

    const teams = yield select(state => state.teams.data);
    let team;

    if (teams && teams.length) {
      team = teams.filter(item => item.id === action.payload.id);
    }

    if (!team.length) {
      throw new Error("Undefined teams");
    }

    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...auth,
        currentTeam: team[0]
      })
    );
  } catch (err) {
    yield put(TeamsActions.setCurrentTeamError(err));
  }
}

export function* createTeam(action) {
  try {
    const response = yield call(api.post, "/v1/adm/teams", {
      ...action.payload.data
    });

    yield put(TeamsActions.createTeamSuccess(response.data));
    yield put(push(`/teams/edit/${response.data.id}`));
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Empresa cadastrada com sucesso!",
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );
  } catch (err) {
    yield put(TeamsActions.createTeamError(err.data));
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* showTeam(action) {
  try {
    const response = yield call(api.get, `/v1/adm/teams/${action.payload.id}`);

    yield put(TeamsActions.showTeamSuccess(response.data));
  } catch (err) {
    yield put(TeamsActions.showTeamError(err.data));
  }
}

export function* updateTeam(action) {
  try {
    const response = yield call(
      api.put,
      `/v1/adm/teams/${action.payload.data.id}`,
      {
        ...action.payload.data
      }
    );

    yield put(TeamsActions.updateTeamSuccess(response.data));
  } catch (err) {
    yield put(TeamsActions.updateTeamError(err.response));
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Erro ao atualizar dados.",
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* setLocalTeam(action) {
  try {
    const teams = yield call(api.get, "/teams");

    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("auth")),
        currentTeam: teams.data[0]
      })
    );
  } catch (err) {
    console.log("error set currentTeam");
  }
}
