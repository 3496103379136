import React from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import api from "../../services/api";
import { Actions as SurveyFormActions } from "../../store/ducks/surveyForm";

import { Container, ContainerImage, Image, WrapperImage } from "./styles";

import NotFound from "../../assets/images/notfound.png";
import { checkRoles } from "../../services/permissions";
import Spinner from "../../components/Spinner";
import { checkHandoverEmail } from "../../services";

const renderItemKeys = [
  {
    title: "Frente do armário",
    stateRefName: "img_frontLocker",
    stateKey: "frontLocker"
  },
  {
    title: "Atrás do armário",
    stateRefName: "img_backLocker",
    stateKey: "backLocker"
  },
  {
    title: "Lateral esquerda",
    stateRefName: "img_leftSideLocker",
    stateKey: "leftSideLocker"
  },
  {
    title: "Lateral direita",
    stateRefName: "img_rightSideLocker",
    stateKey: "rightSideLocker"
  },
  {
    title: "Parte superior com tampa",
    stateRefName: "img_topLocker",
    stateKey: "topLocker"
  },
  {
    title: "Compartimento da automação",
    stateRefName: "img_automationLocker",
    stateKey: "automationLocker"
  },
  {
    title: "Conteúdo de porta com acessórios",
    stateRefName: "img_contentLocker",
    stateKey: "contentLocker"
  },
  {
    title: "Foto do locker no Caminhão",
    stateRefName: "img_truckLocker",
    stateKey: "truckLocker"
  }
];

class ConteinerImagens extends React.Component {
  state = {
    frontLocker: "",
    backLocker: "",
    rightSideLocker: "",
    leftSideLocker: "",
    topLocker: "",
    automationLocker: "",
    contentLocker: "",
    truckLocker: "",

    frontLockerFile: "",
    backLockerFile: "",
    rightSideLockerFile: "",
    leftSideLockerFile: "",
    topLockerFile: "",
    automationLockerFile: "",
    contentLockerFile: "",
    truckLockerFile: "",
    permitted: false
  };

  frontLockerRef = React.createRef();
  backLockerRef = React.createRef();
  rightSideLockerRef = React.createRef();
  leftSideLockerRef = React.createRef();
  topLockerRef = React.createRef();
  automationLockerRef = React.createRef();
  contentLockerRef = React.createRef();
  truckLockerRef = React.createRef();

  componentWillMount() {
    const user = localStorage.getItem("auth");
    const userRoles = JSON.parse(user).roles;
    const permitted = checkRoles(["masterAdministrator"], userRoles);

    this.setState({ permitted });
  }

  async handleChangeImg(e) {
    const { files, name } = e.target;
    const key = "img_" + name;

    try {
      const { protocol, surveyForm } = this.props;

      this.setState({ [key]: true });

      const data = new FormData();
      data.append("type", name);
      data.append("protocol", protocol);

      data.append(name, files[0]);
      await api.post(
        `/v1/adm/surveyform/upload/${surveyForm.currentSurveyForm.id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      this.setState({ [`${name}File`]: files[0] });
      const fileRead = new FileReader();
      fileRead.readAsDataURL(files[0]);
      fileRead.onloadend = () => {
        this.setState({ [name]: fileRead.result });
      };
      this.setState({ [key]: false });
    } catch (error) {
      alert("Não foi possivel enviar uma imagem");
      this.setState({ [key]: false });
    }
  }

  async removeImage(e, ref) {
    const { name } = e.target;
    const key = "img_" + name;

    try {
      const { surveyForm } = this.props;

      this.setState({
        [key]: true
      });

      const data = new FormData();
      data.append("type", name);

      await api.put(
        `/v1/adm/surveyform/upload/${surveyForm.currentSurveyForm.id}`,
        data
      );

      this.setState({ [`${name}File`]: "" });
      this.setState({ [name]: "" });

      this.setState({
        [key]: false
      });
    } catch (error) {
      this.setState({ [key]: false });
      alert("Erro ao deletar imagem");
    }
  }

  renderItemState = (key, init = false) => {
    const isHandOver = checkHandoverEmail();
    const {
      surveyForm: { currentSurveyForm }
    } = this.props;

    if (!isHandOver) {
      if (currentSurveyForm) {
        if (parseInt(currentSurveyForm.idLockerSurveyFormStatus) === 1)
          return false;

        if (init) return true;

        if (
          parseInt(currentSurveyForm.idLockerSurveyFormStatus) !== 1 &&
          !currentSurveyForm[key]
        ) {
          return false;
        }
      }
    }

    return true;
  };

  renderItem = (title, stateRefName, key) => {
    const {
      surveyForm: { currentSurveyForm }
    } = this.props;
    const { permitted } = this.state;
    const inputRefName = key + "Ref";

    if (!this.renderItemState(key)) return null;

    return (
      <ContainerImage>
        <>
          <p>{title}</p>

          <WrapperImage>
            {this.state[stateRefName] ? (
              <Spinner />
            ) : (
              <Image
                src={this.state[key] || currentSurveyForm[key] || NotFound}
              />
            )}
          </WrapperImage>
        </>

        {parseInt(currentSurveyForm.idLockerSurveyFormStatus) === 1 &&
          permitted && (
            <>
              <input
                type="file"
                accept=".jpg, .jpeg"
                name={key}
                id={inputRefName}
                onChange={e => this.handleChangeImg(e)}
                ref={this[inputRefName]}
                disabled={this.state[stateRefName]}
              />
              <label id="label_choose_image" for={inputRefName}>
                Escolher foto
              </label>
              <button
                name={key}
                onClick={e => this.removeImage(e, this[inputRefName])}
                disabled={this.state[stateRefName]}
              >
                Remover
              </button>
            </>
          )}
      </ContainerImage>
    );
  };

  render() {
    return (
      <Grid container xs={6} md={12}>
        {/* {this.renderItemState(null, true) && (
          <h4 style={{ marginTop: "40px" }}>Fotos do Armário</h4>
        )} */}

        <Container>
          {renderItemKeys.map(item =>
            this.renderItem(item.title, item.stateRefName, item.stateKey)
          )}
        </Container>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...SurveyFormActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConteinerImagens);
