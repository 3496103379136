import React from "react";

// Material
import { Grid, TextField } from "@material-ui/core";
import MaterialTable from "material-table";

const lockerQtd = [
  {
    value: 1,
    label: "1 locker"
  },
  {
    value: 2,
    label: "2 lockers"
  },
  {
    value: 3,
    label: "3 lockers"
  }
  // {
  //   value: 4,
  //   label: "4 Lockers"
  // },
  // {
  //   value: 5,
  //   label: "5 Lockers"
  // }
  // {
  //   value: 6,
  //   label: "6 Lockers"
  // },
  // {
  //   value: 7,
  //   label: "7 Lockers"
  // },
  // {
  //   value: 8,
  //   label: "8 Lockers"
  // },
  // {
  //   value: 9,
  //   label: "9 Lockers"
  // },
  // {
  //   value: 10,
  //   label: "10 Lockers"
  // }
];

const radiusForLocker = [
  // {
  //   value: 0,
  //   label: "0 KM"
  // },
  // {
  //   value: 1000,
  //   label: "1 KM"
  // },
  {
    value: 2000,
    label: "2 KMs"
  },
  {
    value: 3000,
    label: "3 KMs"
  },
  {
    value: 4000,
    label: "4 KMs"
  },
  {
    value: 5000,
    label: "5 KMs"
  },
  {
    value: 6000,
    label: "6 KMs"
  },
  {
    value: 7000,
    label: "7 KMs"
  }
  // {
  //   value: 8000,
  //   label: "8 KMs"
  // },
  // {
  //   value: 9000,
  //   label: "9 KMs"
  // },
  // {
  //   value: 10000,
  //   label: "10 KMs"
  // }
];

class LockerAddres extends React.Component {
  state = {
    data: [],
    type: "address",
    checkIA: "ia",
    lockerQtd: 0,
    radiusForLocker: 2000,
    columnsEnd: [
      { title: "Logradouro", field: "logradouro" },
      { title: "Número", field: "numero" },
      {
        title: "Estado",
        field: "estado",
        lookup: {
          Acre: "Acre",
          Alagoas: "Alagoas",
          Amapá: "Amapá",
          Amazonas: "Amazonas",
          Bahia: "Bahia",
          Ceará: "Ceará",
          "Espírito Santo": "Espírito Santo",
          Goias: "Goias",
          Maranhão: "Maranhão",
          "Mato Grosso": "Mato Grosso",
          "Mato Grosso do Sul": "Mato Grosso do Sul",
          "Minas Gerais": "Minas Gerais",
          Pará: "Pará",
          Paraíba: "Paraiba",
          Paraná: "Paraná",
          Pernambuco: "Pernambuco",
          Piauí: "Piauí",
          "Rio de Janeiro": "Rio de Janeiro",
          "Rio Grande do Norte": "Rio Grande do Norte",
          "Rio Grande do Sul": "Rio Grande do Sul",
          Rondônia: "Rondônia",
          Roraima: "Roraima",
          "Santa Catarina": "Santa Catarina",
          "São Paulo": "São Paulo",
          Sergipe: "Sergipe",
          Tocantins: "Tocantins",
          "Distrito Federal": "Distrito Federal"
        }
      },
      {
        title: "Raio",
        field: "radius",
        lookup: {
          0: "0 KM",
          1000: "1 KM",
          2000: "2 KM",
          3000: "3 KM",
          4000: "4 KM",
          5000: "5 KM",
          6000: "6 KM",
          7000: "7 KM",
          8000: "8 KM",
          9000: "9 KM",
          10000: "10 KM"
        }
      },
      {
        title: "Custo operacional",
        field: "value",
        type: "numeric"
      }
    ],
    columnsLatLog: [
      {
        title: "Latitude",
        field: "lat"
      },
      {
        title: "Longitude",
        field: "lng"
      },
      {
        title: "Raio",
        field: "radius",
        lookup: {
          0: "0 KM",
          1000: "1 KM",
          2000: "2 KM",
          3000: "3 KM",
          4000: "4 KM",
          5000: "5 KM",
          6000: "6 KM",
          7000: "7 KM",
          8000: "8 KM",
          9000: "9 KM",
          10000: "10 KM"
        }
      },
      {
        title: "Custo operacional",
        field: "value",
        type: "numeric"
      }
    ]
  };

  RenderIa = ({ lockerQtdChange, radiusForLockerChange }) => {
    return (
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: 10,
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <Grid item xs={6} md={4}>
          <TextField
            select
            fullWidth
            margin="normal"
            name="type"
            label="Quantidade de lockers"
            value={this.state.lockerQtd}
            onChange={e =>
              this.setState(
                { lockerQtd: e.target.value },
                lockerQtdChange(e.target.value)
              )
            }
            SelectProps={{
              native: true
            }}
            variant="outlined"
          >
            {lockerQtd.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            select
            fullWidth
            margin="normal"
            name="type"
            label="Raio de entrega a partir do locker"
            value={this.state.radiusForLocker}
            onChange={e =>
              this.setState(
                { radiusForLocker: e.target.value },
                radiusForLockerChange(e.target.value)
              )
            }
            SelectProps={{
              native: true
            }}
            variant="outlined"
          >
            {radiusForLocker.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { type, change, lockerQtdChange, radiusForLockerChange } = this.props;
    return (
      <>
        <br />
        <h4
          style={{
            marginBottom: -10,
            marginTop: 10,
            textAlign: "left",
            fontSize: "16px"
          }}
        >
          Distribuição de lockers
        </h4>

        {this.state.checkIA === "ia" && (
          <this.RenderIa
            lockerQtdChange={lockerQtdChange}
            radiusForLockerChange={radiusForLockerChange}
          />
        )}

        {this.state.checkIA === "normal" && (
          <MaterialTable
            title=""
            columns={
              type === "address"
                ? this.state.columnsEnd
                : this.state.columnsLatLog
            }
            data={this.state.data}
            localization={{
              body: {
                editRow: {
                  deleteText: "Deseja excluir este endereço?",
                  cancelTooltip: "Cancelar",
                  saveTooltip: "Salvar"
                },
                addTooltip: "Adicionar",
                deleteTooltip: "Deletar",
                editTooltip: "Editar",
                emptyDataSourceMessage: "Nenhum endereço adicionado"
              },
              header: {
                actions: ""
              }
            }}
            editable={{
              onRowAdd: newData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      data.push(newData);
                      return { ...prevState, data };
                    });

                    resolve(change(this.state.data));
                  }, 1000);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                      });
                      resolve(change(this.state.data));
                    }
                  }, 1000);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data };
                    });
                    resolve(change(this.state.data));
                  }, 1000);
                })
            }}
            options={{
              paging: false,
              search: false,
              actionsColumnIndex: -1,
              toolbarButtonAlignment: "left"
            }}
          />
        )}
      </>
    );
  }
}

export default LockerAddres;
