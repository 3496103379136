import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as SurveyFormActions } from "../../store/ducks/surveyForm";

import Container from "../../components/Container";

import FormComponetTable from "../../components/FormCompoentTable";
import Spinner from "../../components/Spinner";

import { materialStyle } from "../../styles";

class ListSurveyForm extends React.Component {
  state = {
    userRoles: []
  };

  componentDidMount() {
    const { surveyFormRequest } = this.props;
    surveyFormRequest();

    this.setState({
      userRoles: JSON.parse(localStorage.getItem("auth")).roles
    });
  }

  render() {
    const { surveyForm, classes } = this.props;

    return (
      <Container title="vistoria dos lockers">
        {surveyForm.loading ? (
          <Spinner />
        ) : (
          <>
            {this.state.userRoles.indexOf("masterAdministrator") >= 0 && (
              <Button
                onClick={() => this.props.history.push("/surveyform")}
                variant="contained"
                className={classes.button}
                style={{ background: "#006660", color: "#fff" }}
              >
                criar vistoria
              </Button>
            )}

            <FormComponetTable history={this.props.history} />
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatcToProps = dispatch =>
  bindActionCreators(
    {
      ...SurveyFormActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatcToProps
)(withStyles(materialStyle)(ListSurveyForm));
