import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as SubTeamActions } from "../ducks/subTeam";
import { Actions as ConfigsActions } from "../ducks/configs";

import constants from "../../config/constants";
import checkError from "../../functions/checkError";

export function* getSubTeams(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/subTeams?${action.payload.data}`
    );

    yield put(SubTeamActions.getSubTeamsSuccess(response.data));
  } catch (err) {
    yield put(SubTeamActions.getSubTeamsError(err.response));
  }
}

export function* showSubTeam(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/subTeams/${action.payload.id}`
    );

    const status = yield call(api.get, "/v1/adm/subTeam/status");

    yield put(SubTeamActions.showSubTeamSuccess(response.data));
    yield put(SubTeamActions.getSubTeamStatusSuccess(status.data));
  } catch (err) {
    yield put(SubTeamActions.showSubTeamError(err.response));
  }
}

export function* updateSubTeam(action) {
  try {
    yield call(api.put, `/v1/adm/subTeams/${action.payload.data.id}`, {
      ...action.payload.data
    });

    yield put(SubTeamActions.updateSubTeamSuccess(action.payload.data));

    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Informação alterada com sucesso!",
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );
  } catch (err) {
    yield put(SubTeamActions.updateSubTeamError(err.response));
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: checkError(err.response),
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}

export function* getSubTeamStatus() {
  try {
    const status = yield call(api.get, "/v1/adm/subTeam/status");

    yield put(SubTeamActions.getSubTeamStatusSuccess(status.data));
  } catch (err) {
    yield put(SubTeamActions.getSubTeamStatusError(err.response));
  }
}

export function* createSubTeam(action) {
  try {
    const response = yield call(
      api.post,
      "/v1/adm/subTeams",
      action.payload.data
    );

    yield put(SubTeamActions.createSubTeamSuccess(response.data));

    action.payload.pushFunction(`/subTeam/edit/${response.data.id}`);

    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: "Criado com sucesso!",
        toastVariant: constants.toastVariants.success,
        toastVisible: true
      })
    );
  } catch (err) {
    yield put(SubTeamActions.createSubTeamError(err.response));
    yield put(
      ConfigsActions.setToastVisible({
        toastMessage: checkError(err.response),
        toastVariant: constants.toastVariants.error,
        toastVisible: true
      })
    );
  }
}
