import React, { useState, useEffect } from "react";
import {
  Header,
  TitleContain,
  Container,
  ImageLogo,
  TitleForm,
  SubTitleForm,
  Form,
  FormLabel,
  Spacer,
  DivButton,
  ContainerResposta,
  Linha,
  LittleSpacer,
  ContainerWithLocker,
  ContainerWithoutLocker,
  Wrapper,
  ContainerSchedule,
  Infos,
  Info
} from "./styles";
import Logo from "../../assets/Lockup_Handover.svg";

function reducerCost() {
  const [data, setData] = useState({
    distanceCD: "",
    timeCD: "",
    entregaPorEntregador: "",
    rendimento: "",
    timeDelivery: "",
    qtyEntregadores: "",
    width: "",
    height: "",
    length: "",
    precoCombustivel: "",
    salario: ""
  });
  const [loading, setLoading] = useState(false);

  // const [volumePacotes, setVolumePacotes] = useState("");
  const [qtyLocker, setQtyLocker] = useState("");
  // const [entregaPorEntregadorLocker, setEntregaPorEntregadorLocker] = useState(
  //   ""
  // );
  const [demandaEntregadoresLocker, setDemandaEntregadoresLocker] = useState(
    ""
  );
  const [demanda, setDemanda] = useState("");
  // const [entregaMinutoTotalLocker, setEntregaMinutoTotalLocker] = useState("");
  // const [tempoProdutivoLocker, setTempoProdutivoLocker] = useState("");
  // const [entregaMinutoProdLocker, setEntregaMinutoProdLocker] = useState("");
  // const [combustivelLitroLocker, setCombustivelLitroLocker] = useState("");
  // const [combustivelDiaLocker, setCombustivelDiaLocker] = useState("");
  const [costCombustivelLocker, setCostCombustivelLocker] = useState("");
  const [costEntregadoresLocker, setcostEntregadoresLocker] = useState("");
  const [costLocker, setCostLocker] = useState("");
  const [custoPorEntregaLocker, setCustoPorEntregaLocker] = useState("");
  // const [costTotalLocker, setCostTotalLocker] = useState("");
  // const [demandaEntregadores, setDemandaEntregadores] = useState("");
  // const [entregaMinutoTotal, setEntregaMinutoTotal] = useState("");
  // const [tempoDeslocamento, setTempoDeslocamento] = useState("");
  // const [tempoProdutivo, setTempoProdutivo] = useState("");
  // const [entregaMinutoProd, setEntregaMinutoProd] = useState("");
  // const [combustivelLitro, setCombustivelLitro] = useState("");
  // const [combustivelDia, setCombustivelDia] = useState("");
  const [costCombustivel, setCostCombustivel] = useState("");
  const [costEntregadores, setcostEntregadores] = useState("");
  const [costTotal, setCostTotal] = useState("");
  const [custoPorEntrega, setCustoPorEntrega] = useState("");
  const [percTotal, setPercTotal] = useState("");
  const [economyWithEmployer, setEconomyWithEmployer] = useState("");
  const [economyWithDelivery, setEconomyWithDelivery] = useState("");
  const [timeDeliveryLocker, setTimeDeliveryLocker] = useState("");
  const [produtividade, setProdutividade] = useState("");
  const [radio, setRadio] = useState("22");
  // const [combustivelLitroentrega, setCombustivelLitroentrega] = useState("");
  // const [
  //   combustivelLitroentregaLocker,
  //   setCombustivelLitroentregaLocker
  // ] = useState("");
  const [outrosCustosLocker, setOutrosCustosLocker] = useState("");
  const [outrosCustos, setOutrosCustos] = useState("");

  const [validate, setValidate] = useState(false);

  useEffect(
    () => {
      if (
        data.distanceCD &&
        data.timeCD &&
        data.entregaPorEntregador &&
        data.rendimento &&
        data.qtyEntregadores &&
        data.width &&
        data.height &&
        data.length &&
        data.precoCombustivel &&
        data.salario
      ) {
        return setValidate(true);
      }
    },
    [data, validate]
  );

  const updateField = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  function aroundNumber(num) {
    const getNumber = num.toFixed(2).toString();
    const [integer, number] = getNumber.split(".");

    return Number(number) >= 1 && Number(integer) + 1;
  }

  function sleep(ms) {
    return new Promise(resolve =>
      setTimeout(() => {
        setLoading(false);
        return resolve();
      }, ms)
    );
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    //contas com locker

    //volume dos pacotes
    const volumePacotes =
      parseFloat(data.width) *
      parseFloat(data.height) *
      parseFloat(data.length) *
      0.001;

    const demanda =
      parseInt(data.qtyEntregadores) * parseInt(data.entregaPorEntregador);
    const qtyLocker = aroundNumber(
      (parseFloat(volumePacotes) * parseInt(demanda)) / (6 * 241)
    );
    const tempoDeslocamento = parseInt(data.timeCD) * 2;
    const tempoProdutivo = 480 - parseInt(tempoDeslocamento);
    const entregaMinutoProd =
      parseInt(data.entregaPorEntregador) / parseInt(tempoProdutivo);
    const tempoTrabalhoLocker = 480;
    const tempoDeslocamentoLocker = 10;
    const tempoProdutivoLocker =
      parseInt(tempoTrabalhoLocker) - parseInt(tempoDeslocamentoLocker);
    const entregaPorEntregadorLocker =
      parseFloat(tempoProdutivoLocker) * parseFloat(entregaMinutoProd);
    // const entregaMinutoProdLocker =
    //   parseInt(entregaPorEntregadorLocker) / parseInt(tempoProdutivoLocker);
    const demandaEntregadoresLocker =
      parseInt(demanda) / parseInt(entregaPorEntregadorLocker);
    // const entregaMinutoTotalLocker =
    //   parseInt(entregaPorEntregadorLocker) / parseInt(tempoTrabalhoLocker);
    const combustivelLitroLocker =
      (2 / parseInt(data.rendimento)) * parseInt(demandaEntregadoresLocker) * 2;
    const combustivelDiaLocker =
      parseFloat(combustivelLitroLocker) * parseFloat(data.precoCombustivel);
    const combustivelLitroentregaLocker =
      parseFloat(demanda) * (2.67 / parseFloat(data.rendimento));
    const costCombustivelLocker =
      parseInt(radio) * parseFloat(combustivelDiaLocker) +
      parseInt(radio) *
        parseInt(combustivelLitroentregaLocker) *
        parseInt(data.precoCombustivel);
    const costEntregadoresLocker =
      parseInt(data.salario) * parseFloat(demandaEntregadoresLocker);

    const costLocker = parseInt(qtyLocker) * 1400;
    const costTotalLocker =
      parseInt(costCombustivelLocker) +
      parseInt(costEntregadoresLocker) +
      parseInt(costLocker) +
      parseInt(demandaEntregadoresLocker) * 228.73;
    const custoPorEntregaLocker =
      parseInt(costTotalLocker) / (parseInt(demanda) * parseInt(radio));

    const outrosCustosLocker = parseInt(demandaEntregadoresLocker) * 228.73;

    //contas sem locker

    const demandaEntregadores =
      parseInt(demanda) / parseInt(data.entregaPorEntregador);
    // const entregaMinutoTotal = parseInt(data.entregaPorEntregador) * 480;
    const combustivelLitroentrega =
      parseFloat(demanda) * (4.12 / parseFloat(data.rendimento));
    const combustivelLitro =
      parseFloat(demandaEntregadores) *
      (parseFloat(data.distanceCD) / parseFloat(data.rendimento)) *
      2;
    const combustivelDia =
      parseFloat(data.precoCombustivel) * parseFloat(combustivelLitro);
    const costCombustivel =
      parseFloat(combustivelDia) * parseFloat(radio) +
      parseInt(radio) *
        parseInt(combustivelLitroentrega) *
        parseInt(data.precoCombustivel);
    const costEntregadores =
      parseFloat(data.salario) * parseFloat(demandaEntregadores);

    const costTotal =
      parseFloat(costEntregadores) +
      parseFloat(costCombustivel) +
      parseInt(data.qtyEntregadores) * 228.73;

    const custoPorEntrega =
      parseFloat(costTotal) / (parseFloat(radio) * parseFloat(demanda));

    const outrosCustos = parseInt(data.qtyEntregadores) * 228.73;

    //final
    const percTotal =
      100 *
      (1 - parseFloat(custoPorEntregaLocker) / parseFloat(custoPorEntrega));
    const timeDeliveryLocker = parseInt(data.timeDelivery) * 0.4;
    const economyWithDelivery =
      parseInt(costCombustivel) - parseInt(costCombustivelLocker);
    const economyWithEmployer =
      parseInt(costEntregadores) - parseInt(costEntregadoresLocker);
    const produtividade =
      100 * (parseInt(tempoProdutivoLocker) / parseInt(tempoProdutivo) - 1);

    setProdutividade(produtividade.toFixed(2));
    setEconomyWithDelivery(economyWithDelivery);
    setEconomyWithEmployer(economyWithEmployer);
    setTimeDeliveryLocker(timeDeliveryLocker);
    setQtyLocker(qtyLocker);
    setDemandaEntregadoresLocker(demandaEntregadoresLocker);
    // setVolumePacotes(volumePacotes);
    // setEntregaPorEntregadorLocker(entregaPorEntregadorLocker);
    // setEntregaMinutoTotalLocker(entregaMinutoTotalLocker);
    // setTempoProdutivoLocker(tempoProdutivoLocker);
    // setEntregaMinutoProdLocker(entregaMinutoProdLocker);
    // setCombustivelLitroLocker(combustivelLitroLocker);
    // setCombustivelDiaLocker(combustivelDiaLocker);
    // setCostTotalLocker(costTotalLocker);
    // setDemandaEntregadores(demandaEntregadores);
    // setEntregaMinutoTotal(entregaMinutoTotal);
    // setTempoDeslocamento(tempoDeslocamento);
    // setTempoProdutivo(tempoProdutivo);
    // setEntregaMinutoProd(entregaMinutoProd);
    // setCombustivelLitro(combustivelLitro);
    // setCombustivelDia(combustivelDia);
    setCostCombustivelLocker(costCombustivelLocker);
    setcostEntregadoresLocker(costEntregadoresLocker);
    setCostLocker(costLocker);
    setCustoPorEntregaLocker(custoPorEntregaLocker);
    setCostCombustivel(costCombustivel);
    setcostEntregadores(costEntregadores);
    setCostTotal(costTotal);
    setCustoPorEntrega(custoPorEntrega);
    setPercTotal(percTotal.toFixed(2));
    setDemanda(demanda);
    setOutrosCustosLocker(outrosCustosLocker);
    setOutrosCustos(outrosCustos);
    await sleep(500);
    window.location.href = "#result";
  };

  return (
    <Wrapper>
      <Header>
        <ImageLogo src={Logo} />
        <TitleContain>
          Simule a sua redução de custos ao implementar lockers inteligentes na
          sua logística.
        </TitleContain>
      </Header>
      <Container>
        <div>
          <TitleForm>CUSTOS ATUAIS DA SUA OPERAÇÃO LAST-MILE</TitleForm>
          <SubTitleForm>
            Preencha os campos abaixo com os custos mensais e demais informações
            da sua operação:
          </SubTitleForm>
        </div>
        <Form onSubmit={handleSubmit}>
          <FormLabel colorInput>
            <label htmlFor="">
              <strong>1.</strong> Qual a distância da base até sua região de
              entrega? (km)
            </label>
            <spacer />
            <input
              type="number"
              name="distanceCD"
              placeholder="km"
              onChange={updateField}
              value={data.distanceCD}
            />
          </FormLabel>
          <FormLabel colorInput>
            <label htmlFor="">
              <strong>2.</strong> Qual o tempo da base até sua região de
              entrega? (min)
            </label>
            <spacer />
            <input
              type="number"
              name="timeCD"
              placeholder="minutos"
              onChange={updateField}
              value={data.timeCD}
            />
          </FormLabel>

          <Spacer />

          <FormLabel colorInput>
            <label htmlFor="">
              <strong>3.</strong> Qual o salário médio dos seus entregadores?
            </label>
            <spacer />
            <input
              type="number"
              name="salario"
              placeholder="R$"
              onChange={updateField}
              value={data.salario}
            />
          </FormLabel>
          <FormLabel colorInput>
            <label htmlFor="">
              <strong>4.</strong> Qual o preço do combustível que você usa? (por
              litro)
            </label>
            <spacer />
            <input
              type="number"
              name="precoCombustivel"
              placeholder="por litro"
              onChange={updateField}
              value={data.precoCombustivel}
            />
          </FormLabel>

          <Spacer />

          <FormLabel colorInput>
            <label htmlFor="">
              <strong>5.</strong> Qual a quantidade de entregadores que
              trabalham para essa base de distribuição?
            </label>
            <spacer />
            <input
              type="number"
              name="qtyEntregadores"
              onChange={updateField}
              value={data.qtyEntregadores}
            />
          </FormLabel>
          <FormLabel colorInput>
            <label htmlFor="">
              <strong>6.</strong> Qual o prazo que você oferece para o seu
              cliente para fazer entrega na sua região? (em dias)
            </label>
            <spacer />
            <input
              type="number"
              name="timeDelivery"
              onChange={updateField}
              placeholder="em dias"
              value={data.timeDelivery}
            />
          </FormLabel>

          <Spacer />

          <FormLabel colorInput>
            <label htmlFor="">
              <strong>7.</strong> Quantos Km/L faz o seu veiculo de entrega?
            </label>
            <spacer />
            <input
              type="number"
              name="rendimento"
              onChange={updateField}
              value={data.rendimento}
              placeholder="km/L"
            />
          </FormLabel>
          <FormLabel colorInput>
            <label htmlFor="">
              <strong>8.</strong> Qual a média de entregas por entregador/dia?
            </label>
            <spacer />
            <input
              type="number"
              name="entregaPorEntregador"
              onChange={updateField}
              value={data.entregaPorEntregador}
              placeholder="n/dia"
            />
          </FormLabel>

          <Spacer />

          <FormLabel>
            <label htmlFor="">
              <strong>9.</strong> Quantos dias você entrega por mês?
            </label>
            <spacer />
            <div>
              <input
                type="radio"
                checked={radio === "22"}
                value="22"
                onChange={e => {
                  setRadio(e.target.value);
                }}
              />
              <label>Segunda a sexta (22 dias)</label>
            </div>
            <spacer />
            <div>
              <input
                type="radio"
                checked={radio === "26"}
                value="26"
                onChange={e => {
                  setRadio(e.target.value);
                }}
              />
              <label>Segunda a sábado (26 dias)</label>
            </div>
            <spacer />
            <div>
              <input
                type="radio"
                checked={radio === "30"}
                value="30"
                onChange={e => {
                  setRadio(e.target.value);
                }}
              />
              <label>Domingo a domingo (30 dias)</label>
            </div>
          </FormLabel>

          <FormLabel colorInput>
            <label htmlFor="">
              <strong>10.</strong> Qual tamanho médio de seus pacotes? (cm)
            </label>
            <spacer />
            <line>
              <input
                type="number"
                name="length"
                placeholder="cm"
                onChange={updateField}
                value={data.length}
              />
              <h1> (Comprimento) </h1>
              <spacer />
            </line>
            <line>
              <input
                type="number"
                name="height"
                placeholder="cm"
                onChange={updateField}
                value={data.height}
              />
              <spacer />
              <h1> (Altura) </h1>
            </line>
            <line>
              <input
                type="number"
                name="width"
                placeholder="cm"
                onChange={updateField}
                value={data.width}
              />
              <spacer />
              <h1> (Largura) </h1>
            </line>
          </FormLabel>

          <Spacer />

          <DivButton colorBackButton={!validate || loading}>
            <button disabled={!validate || loading} type="submit">
              {loading ? "AGUARDE" : "SIMULAR"}
            </button>
          </DivButton>
          <Spacer />
        </Form>
      </Container>

      {costTotal && (
        <ContainerResposta id="result">
          <h1>RESULTADO RESUMIDO</h1>
          <span>
            {" "}
            <strong> Foram utilizados os seguinte dados: </strong>{" "}
          </span>
          <spacer />
          <spacer />
          <span>
            Valor do combustível: R$
            {data.precoCombustivel}
          </span>
          <spacer />
          <span>
            Salário dos entregadores: R$
            {data.salario}
          </span>
          <spacer />
          <span>Mês de 30 dias, considerando {radio} dias úteis</span>
          <spacer />
          <span>Demanda diária: {parseInt(demanda)} </span>
        </ContainerResposta>
      )}
      <Linha>
        {costTotal && (
          <ContainerWithoutLocker>
            <h2>SEM ARMÁRIOS INTELIGENTES</h2>
            <line>
              Custo com entregadores:
              <LittleSpacer />
              {costEntregadores.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL"
              })}
              /mensais
            </line>

            <line>
              Custo com combustível:
              <LittleSpacer />
              {costCombustivel.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL"
              })}
              /mensais
            </line>

            <line>
              Outros custos (manutenção, ipva, seguro):
              <LittleSpacer />
              {outrosCustos.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL"
              })}
              /mensais
            </line>

            <line>
              Custo por entrega:
              <LittleSpacer />
              {custoPorEntrega.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL"
              })}
            </line>
          </ContainerWithoutLocker>
        )}

        {percTotal && (
          <ContainerWithLocker>
            <h2>COM ARMÁRIOS INTELIGENTES</h2>

            <line>
              Custo com entregadores:
              <LittleSpacer />
              {costEntregadoresLocker.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL"
              })}
              /mensais
            </line>

            <line>
              Custo com combustível:
              <LittleSpacer />
              {costCombustivelLocker.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL"
              })}
              /mensais
            </line>

            <line>
              Outros custos (manutenção, ipva, seguro):
              <LittleSpacer />
              {outrosCustosLocker.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL"
              })}
              /mensais
            </line>

            <line>
              Custo por entrega:
              <LittleSpacer />
              {custoPorEntregaLocker.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL"
              })}
            </line>
          </ContainerWithLocker>
        )}
      </Linha>

      {costTotal && (
        <div>
          <Info>
            <div>
              <line>
                Economia com combustível:
                <LittleSpacer />
                {economyWithDelivery.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                })}
                /mensais
              </line>

              <line>
                Economia os entregadores:
                <LittleSpacer />
                {economyWithEmployer.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                })}
                /mensais
              </line>

              <line>
                Aumento de produtividade dos funcionários:
                <LittleSpacer />
                {produtividade} %
              </line>

              <line>
                Custo com o Locker:
                <LittleSpacer />
                {costLocker.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                })}
                /mensais
              </line>
              <line>
                Quantidade de entregadores:
                <LittleSpacer />
                {parseInt(demandaEntregadoresLocker)}
              </line>

              <line>
                Seu prazo de entrega com a handover vai de{" "}
                {parseInt(data.timeDelivery)} dias para
                <LittleSpacer />
                {parseInt(timeDeliveryLocker)} dia(s)
              </line>
              <line>
                Redução aproximada de {percTotal}% utilizando {qtyLocker}{" "}
                armários inteligentes
              </line>
            </div>
          </Info>
          <Infos>
            <h2>
              *A simulação de custos apresentada acima se baseia apenas nas
              informações concedidas, a realidade pode apresentar divergências.
              Para uma simulação mais assertiva, converse com um especialista da
              handover.
            </h2>
            <Spacer />
            <ContainerSchedule>
              <a
                href="https://materiais.oihandover.com/conversa-com-especialista"
                target="_blank"
                rel="noopener noreferrer"
              >
                Quero agendar uma conversa!
              </a>
            </ContainerSchedule>
          </Infos>
        </div>
      )}
    </Wrapper>
  );
}
export default reducerCost;
