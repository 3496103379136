import React, { Component } from "react";

// Material
import { TextField, MenuItem, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as CompartmentActions } from "../../../../store/ducks/compartment";
import { Actions as SubTeamSubUnitsActions } from "../../../../store/ducks/subTeamSubUnits";

// Components
import Container from "../../../../components/Container";
import { materialStyle } from "../../../../styles";
import Snackbar from "../../../../components/Snackbar";
import Spinner from "../../../../components/Spinner";
import CustomDialog from "../../../../components/CustomDialog";
import EditorCurrentRegisterContainer from "../../../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../../../components/ButtonEditorCurrentRegister";
import SaveButtons from "../../../../components/SaveButtons";

// services
// import checkObject from "../../../../services/checkObject";
import ThemeTextFildGreen from "../../../../styles/ThemeTextFildGreen";
import checkHandoverEmail from "../../../../services/checkHandoverEmail";
import MultiOptionSelect from "../../../../components/MultiOptionSelect";
import { constants } from "../../../../config";
class CompartmentEdit extends Component {
  state = {
    currentCompartment: null,
    info: null,
    subTeamSubUnits: [],
    currentSubTeamSubUnitsCompartment: [],
    useSubTeamSubUnitsCompartment: false,
    dashboardParams: null
  };

  t = this.props.t;

  componentWillMount() {
    const {
      match: {
        params: { id }
      },
      showCompartmentRequest,
      getSubTeamSubUnitsByCompartmentRequest
    } = this.props;

    const { useSubTeamSubUnitsCompartment, ...storedParameters } = JSON.parse(
      localStorage.getItem("auth")
    ).currentTeam.parameters;

    showCompartmentRequest(id);
    getSubTeamSubUnitsByCompartmentRequest(id);

    const data = { parameters: storedParameters };

    if (
      storedParameters.dashboardParams &&
      storedParameters.dashboardParams.length
    ) {
      Object.assign(data, {
        dashboardParams: JSON.parse(storedParameters.dashboardParams)
      });
    }

    this.setState({
      useSubTeamSubUnitsCompartment,
      dashboardParams: data.dashboardParams
    });
  }

  componentWillReceiveProps(nextProps) {
    const data = {};
    if (
      this.state.currentCompartment !==
      nextProps.compartments.currentCompartment
    ) {
      Object.assign(data, {
        currentCompartment: {
          ...nextProps.compartments.currentCompartment,
          idCompartmentAvailability:
            Boolean(this.state.useSubTeamSubUnitsCompartment) &&
            nextProps.subTeamSubUnits.currentSubTeamSubUnitsCompartment.length
              ? 4
              : nextProps.compartments.currentCompartment
                  .idCompartmentAvailability
        }
      });
    }

    if (this.state.info !== nextProps.compartments.info) {
      Object.assign(data, {
        info: nextProps.compartments.info
      });
    }

    if (
      this.state.currentSubTeamSubUnitsCompartment !==
      nextProps.subTeamSubUnits.currentSubTeamSubUnitsCompartment
    ) {
      Object.assign(data, {
        currentSubTeamSubUnitsCompartment: nextProps.subTeamSubUnits.currentSubTeamSubUnitsCompartment.map(
          item => item.subTeamSubUnits
        )
      });
    }

    this.setState(data);
  }

  handleChange = key => event => {
    this.setState({
      ...this.state,
      currentCompartment: {
        ...this.state.currentCompartment,
        [key]: event.target.value
      }
    });
  };

  handleSave = () => {
    const {
      currentCompartment,
      currentSubTeamSubUnitsCompartment,
      useSubTeamSubUnitsCompartment
    } = this.state;
    // const currentProps = this.props.compartments.currentCompartment;
    const {
      updateCompartmentRequest,
      match: {
        params: { id }
      }
    } = this.props;

    // const result = checkObject(currentCompartment, currentProps);

    // if (!result) return;

    updateCompartmentRequest(id, {
      ...currentCompartment,
      currentSubTeamSubUnitsCompartment: Boolean(useSubTeamSubUnitsCompartment)
        ? currentSubTeamSubUnitsCompartment
        : null
    });
  };

  handleOpenCompartment = () => {
    const {
      match: {
        params: { id }
      },
      openCompartmentRequest
    } = this.props;
    openCompartmentRequest(id);
  };

  render() {
    const {
      compartments,
      subTeamSubUnits,
      classes,
      setSnackbarVisibleCompartment,
      setEditableCompartment
    } = this.props;
    const { currentCompartment, info, dashboardParams } = this.state;

    return (
      <Container title={this.t('compartment:editTitle')}>
        <ThemeTextFildGreen>
          <EditorCurrentRegisterContainer
            flexDirection="row-reverse"
            disabled={compartments.setEditable}
          >
            <ButtonEditorCurrentRegister
              onClick={() => setEditableCompartment(true)}
              label={this.t("edit.edit")}
            />
          </EditorCurrentRegisterContainer>
          <Snackbar
            message={compartments.snackbarMessage}
            variant={compartments.snackbarType}
            open={compartments.snackbarVisible}
            onClose={() => setSnackbarVisibleCompartment(false)}
          />

          {compartments.loading ? (
            <Spinner />
          ) : (
            currentCompartment && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="outlined-name"
                      label={this.t("edit.door_number")}
                      className={classes.textField}
                      value={currentCompartment.portNumber}
                      onChange={this.handleChange("portNumber")}
                      margin="normal"
                      variant="outlined"
                      type="number"
                      inputProps={{ min: "1" }}
                      disabled={
                        !compartments.setEditable || !checkHandoverEmail()
                      }
                    />
                  </Grid>
                  {checkHandoverEmail() && (
                    <>
                      <Grid item xs={12} md={2}>
                        <TextField
                          id="outlined-name"
                          label={this.t("edit.door_number")}
                          className={classes.textField}
                          value={currentCompartment.compartmentNumber}
                          onChange={this.handleChange("compartmentNumber")}
                          margin="normal"
                          variant="outlined"
                          type="number"
                          inputProps={{ min: "1" }}
                          disabled={!compartments.setEditable}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="custom-css-outlined-input"
                      select
                      label={this.t("edit.door_size")}
                      className={classes.textFieldLocker}
                      value={currentCompartment.idCompartmentDimensions}
                      onChange={this.handleChange("idCompartmentDimensions")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                      disabled={
                        !compartments.setEditable || !checkHandoverEmail()
                      }
                    >
                      {info &&
                        info.dimensions.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.description}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      id="custom-css-outlined-input"
                      select
                      label={this.t("edit.door_availability")}
                      className={classes.textFieldLocker}
                      value={currentCompartment.idCompartmentAvailability}
                      onChange={this.handleChange("idCompartmentAvailability")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                      disabled={
                        !compartments.setEditable ||
                        Boolean(this.state.useSubTeamSubUnitsCompartment)
                      }
                    >
                      {info &&
                        info.availability.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.description}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                </Grid>

                {Boolean(this.state.useSubTeamSubUnitsCompartment) ? (
                  <>
                    <h5 className={classes.subTitle}>
                      {this.t("edit.attach_door")}{" "}
                      {dashboardParams &&
                      Object.keys(dashboardParams).includes(
                        "subTeamSubUnitsLabel"
                      )
                        ? String(
                            dashboardParams.subTeamSubUnitsLabel
                          ).toLowerCase()
                        : String(
                            constants.deliveryTableHeaders
                              .subTeamSubUnitsCondominium
                          ).toLowerCase()}
                    </h5>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <MultiOptionSelect
                          maxItems={3}
                          className={classes.textFieldLocker}
                          onChange={result => {
                            this.setState({
                              currentSubTeamSubUnitsCompartment: result
                            });
                          }}
                          options={subTeamSubUnits.data}
                          value={this.state.currentSubTeamSubUnitsCompartment}
                          disabled={
                            compartments.loading ||
                            !compartments.setEditable ||
                            subTeamSubUnits.loading
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null}

                <Grid container spacing={2}>
                  {compartments.setEditable && (
                    <EditorCurrentRegisterContainer
                      justifyContent
                      disabled={
                        compartments.loading || !compartments.setEditable
                      }
                    >
                      <SaveButtons
                        onConfirm={this.handleSave}
                        onCancel={() => setEditableCompartment(false)}
                      />
                      <CustomDialog
                        className={classes.button}
                        title={this.t("doors_table.attention")}
                        buttonLabel={this.t("doors_table.open_action")}
                        cancelButtonText={this.t("doors_table.cancel")}
                        confirmButtonText={this.t("doors_table.confirm")}
                        message={this.t("doors_table.confirm_message")}
                        onConfirm={this.handleOpenCompartment}
                        disabled={!compartments.setEditable}
                      />
                    </EditorCurrentRegisterContainer>
                  )}
                </Grid>
              </>
            )
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  compartments: state.compartment,
  subTeamSubUnits: state.subTeamSubUnits
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CompartmentActions,
      ...SubTeamSubUnitsActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("lockers")(withStyles(materialStyle)(CompartmentEdit)));
