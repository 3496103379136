export const Types = {
  partnersNeposTicketsRequest: "partnersNeposTickets/Request",
  partnersNeposTicketsSuccess: "partnersNeposTickets/Success",
  partnersNeposTicketsError: "partnersNeposTickets/Error"
};

const INITIAL_STATE = {
  loading: false,
  data: [],
  error: null,
  page: 1,
  perPage: 20,
  total: 0,
  query: ""
};

export default function nepos(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.partnersNeposTicketsRequest:
      return {
        ...state,
        loading: true,
        error: INITIAL_STATE.error,
        query: action.payload.data
      };

    case Types.partnersNeposTicketsSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data
      };

    case Types.partnersNeposTicketsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
}

export const Actions = {
  partnersNeposTicketsRequest: data => ({
    type: Types.partnersNeposTicketsRequest,
    payload: { data }
  }),

  partnersNeposTicketsSuccess: data => ({
    type: Types.partnersNeposTicketsSuccess,
    payload: { data }
  }),

  partnersNeposTicketsError: error => ({
    type: Types.partnersNeposTicketsError,
    payload: { error }
  })
};
