import React from "react";
import { MenuItem, TextField } from "@material-ui/core";
import { useTranslation } from 'react-i18next'

function TextFieldActivationStatus({
  classes,
  disabled,
  value,
  label,
  onChange,
  keyParameter
}) {
  const { t } = useTranslation()
  return (
    <TextField
      disabled={disabled}
      id="custom-css-outlined-input"
      select
      label={label}
      className={classes.selectType}
      value={value}
      onChange={onChange(keyParameter)}
      SelectProps={{
        MenuProps: {
          className: classes.menu
        }
      }}
      margin="normal"
      variant="outlined"
    >
      <MenuItem key={1} value={1}>
        {t('common:enabled')}
      </MenuItem>
      <MenuItem key={0} value={0}>
        {t('common:disabled')}
      </MenuItem>
    </TextField>
  );
}

TextFieldActivationStatus.defaultProps = {
  disabled: false,
  value: "",
  label: "Default label",
  onChange: () => {},
  keyParameter: ""
};

export default TextFieldActivationStatus;
