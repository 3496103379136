export default function(error) {
  let message = "Erro geral";

  // eslint-disable-next-line default-case
  switch (error.statusCode) {
    case 401:
      message = "Não autorizado";
      break;
    case 400:
      message = "Dados inválidos";
      break;
  }

  return message;
}
