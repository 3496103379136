import React, { Component } from "react";
import axios from 'axios'

// Material
import { TextField, MenuItem, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";

// Global Components
import Container from "../../components/Container";
//import Snackbar from "../../components/Snackbar";
import TextMaskCustomCNPJ from "../../components/TextMaskCustomCNPJ";
import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as SubTeamActions } from "../../store/ducks/subTeam";
import { Actions as LocationActions } from "../../store/ducks/location";


import getOnlyNumbers from "../../services/getOnlyNumbers";
import history from "../../routes/history";
import TextMaskCustomCEP from "../../components/TextMaskCustomCEP";

class BusinessEdit extends Component {
  state = {
    currentSubTeam: {
      name: "",
      email: "",
      cnpj: "",
      idSubTeamStatus: "",
      mobile: "",
      zipCode: "",
      streetName: "",
      streetNumber: "",
      unitNumber: "",
      idCountry: "",
      idState: "",
      idCity: "",
    },
    edit: false
  };

  componentWillMount() {
    const { getSubTeamStatusRequest } = this.props;
    getSubTeamStatusRequest("");
  }

  componentWillReceiveProps(nextProps) {
    const { location } = nextProps;

    if (location.data && location.data.country) {
      this.setState({
        currentSubTeam: {
          ...this.state.currentSubTeam,
          idCountry: location.data.country.id,
          idState: location.data.state.id,
          idCity: location.data.city.id,
          streetName: location.data.streetName
        }
      });
    }
  }

  getCEP = async () => {
    const { getLocationRequest } = this.props;
    const { zipCode } = this.state.currentSubTeam;
    this.setState({ errorCEP: false });

    if (!zipCode) return;

    const response = await axios.get(
      `https://viacep.com.br/ws/${zipCode
        .replace(".", "")
        .replace("-", "")}/json/`
    );

    const { erro, ...data } = response.data;

    if (erro) {
      this.setState({ errorCEP: true });
      return;
    }

    getLocationRequest({
      cityName: data.localidade,
      zipCode: this.state.zipCode,
      streetName: response.data.logradouro
    });
  };

  handleChange = key => event => {
    let value = event.target.value;

    if (key === "cnpj" || key === "mobile" || key === "contactNumber" || key === "zipCode" || key === "cnpj") {
      value = getOnlyNumbers(event.target.value);
    }

    this.setState({
      currentSubTeam: {
        ...this.state.currentSubTeam,
        [key]: value
      }
    });
  };

  handleSave = () => {
    const { createSubTeamRequest } = this.props;
    const { currentSubTeam } = this.state;

    createSubTeamRequest(currentSubTeam);
  };

  render() {
    const { subTeams, classes, location } = this.props;
    const { currentSubTeam } = this.state;

    return (
      <Container title="">
        <h3>Cadastrar um Condomínio</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label="Nome do condomínio"
              className={classes.textField}
              value={currentSubTeam.name}
              onChange={this.handleChange("name")}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label="Email"
              className={classes.textField}
              value={currentSubTeam.email}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading}
              onChange={this.handleChange("email")}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              onChange={this.handleChange("cnpj")}
              id="outlined-name"
              label="CNPJ"
              className={classes.textField}
              value={currentSubTeam.cnpj}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading}
              InputProps={{
                inputComponent: TextMaskCustomCNPJ
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              id="outlined-name"
              label="Tel de contato"
              className={classes.textField}
              value={currentSubTeam.mobile}
              defaultValue={currentSubTeam.mobile}
              margin="normal"
              variant="outlined"
              disabled={subTeams.loading}
              InputProps={{
                inputComponent: TextMaskCustomMobile
              }}
              onChange={this.handleChange("mobile")}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              disabled={subTeams.loading}
              id="custom-css-outlined-input"
              select
              label="Status"
              className={classes.selectType}
              value={currentSubTeam.idSubTeamStatus}
              onChange={this.handleChange("idSubTeamStatus")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
              variant="outlined"
            >
              {subTeams.subTeamStatus.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <br/>
        <h5>Endereço</h5>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              required
              value={this.state.zipCode}
              variant="outlined"
              className={classes.textField}
              disabled={subTeams.loading}
              label="CEP"
              margin="normal"
              onChange={this.handleChange("zipCode")}
              InputProps={{
                inputComponent: TextMaskCustomCEP
              }}
            />
          </Grid>
          <Button
            onClick={this.getCEP}
            variant="contained"
            className={classes.button}
          >
            Buscar CEP
          </Button>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              required
              value={this.state.currentSubTeam.streetName}
              variant="outlined"
              className={classes.textField}
              disabled={subTeams.loading}
              label="Endereço"
              margin="normal"
              onChange={this.handleChange("streetName")}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              required
              value={this.state.currentSubTeam.streetNumber}
              variant="outlined"
              className={classes.textField}
              disabled={subTeams.loading}
              label="Número"
              margin="normal"
              onChange={this.handleChange("streetNumber")}
              type="number"
              inputProps={{ min: "1" }}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              value={this.state.currentSubTeam.unitNumber}
              variant="outlined"
              className={classes.textField}
              disabled={subTeams.loading}
              label="Complemento"
              margin="normal"
              onChange={this.handleChange("unitNumber")}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              required
              value={location.data ? location.data.country.countryName : ""}
              variant="outlined"
              className={classes.textField}
              label="País"
              margin="normal"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              required
              value={location.data ? location.data.state.stateName : ""}
              variant="outlined"
              className={classes.textField}
              disabled={true}
              label="Estado"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={3} className="form-colaboradore-sm">
            <TextField
              required
              value={location.data ? location.data.city.cityName : ""}
              variant="outlined"
              className={classes.textField}
              disabled={true}
              label="Cidade"
              margin="normal"
            />
          </Grid>
        </Grid>
        <br/>
        <Button
          variant="contained"
          onClick={this.handleSave}
          disabled={subTeams.loading}
          className={classes.button}
        >
          <SaveIcon className={classes.leftIcon} />
          Criar
        </Button>
        <Button
          variant="contained"
          onClick={() => history.push("/subTeam/condominium")}
          disabled={subTeams.loading}
          className={classes.button}
        >
          <CancelIcon className={classes.leftIcon} />
          Cancelar
        </Button>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  subTeams: state.subTeams,
  location: state.location,
});

const mapDispathToProps = dispatch =>
  bindActionCreators(
    {
      ...SubTeamActions,
      ...LocationActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withStyles(materialStyle)(BusinessEdit));
