import React, { useState, useEffect, useCallback } from "react";

import {
  Save as SaveIcon,
  FileCopy,
  Visibility,
  VisibilityOff
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
// Global
import Container from "../../components/Container";

// Material
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography
} from "@material-ui/core";

// Redux
import api from "../../services/api";
import Spinner from "../../components/Spinner";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import { materialStyle } from "../../styles";
import Snackbar from "../../components/Snackbar";
import constants from "../../config/constants";
import ModalEdit from "../../components/ModalEdit";
import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";
import IntegrationDataCreateForm from "../../components/IntegrationDataCreateForm";
import IntegrationDataCreateFormEdit from "../../components/IntegrationDataCreateFormEdit";
import history from "../../routes/history";
import { useDispatch } from "react-redux";
import { Actions as ConfigActions } from "../../store/ducks/configs";
import CustomDialogContent from "../../components/CustomDialogContent";
import isValidURL from "../../utils/isValidURL";
import ButtonClick from "../../components/ButtonClick";

function IntegrationTokenEdit({
  classes,
  match: {
    params: { id }
  }
}) {
  const {t} = useTranslation("config");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState();
  const [integrationDataTeam, setIntegrationDataTeam] = useState([]);
  // const [editable, setEditable] = useState(true);
  const [modalVisible, setModalVisible] = useState({
    open: false,
    variant: constants.snackbarVariants.SUCCESS,
    title: "Deseja realmente excluir esta integração?",
    labelConfirm: "Excluir",
    onConfirm: () => {
      handleDelete();
    }
  });
  const [newDataTeam, setNewDataTeam] = useState();

  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: constants.snackbarVariants.SUCCESS,
    title: "Confirme a ação"
  });
  const [
    createNewIntegrationDataTeam,
    setCreateNewIntegrationDataTeam
  ] = useState(false);
  const [originalData, setOriginalData] = useState();
  const [originalDataTeam, setOriginalDataTeam] = useState([]);

  const fetchData = async function() {
    try {
      const response = await api.get(`/v1/adm/token/team/integration/${id}`);

      const integrationsData = await api.get(
        "/v1/adm/integration/teams?perPage=1000"
      );

      setData({
        ...response.data,
        attributes: response.data.attributes
          ? JSON.parse(response.data.attributes)
          : []
      });
      setOriginalData(response.data);

      setIntegrationDataTeam(integrationsData.data.data);
      setOriginalDataTeam(integrationsData.data.data);
    } catch (error) {
      setSnackbar(old => ({
        ...old,
        open: true,
        message: "Erro ao obter dados",
        variant: constants.snackbarVariants.ERROR
      }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(data.token)
      .then(() => {
        setSnackbar(old => ({
          ...old,
          open: true,
          message: t("common:coppied_message"),
          variant: constants.snackbarVariants.SUCCESS
        }));
      })
      .catch(err => {
        console.error("Failed to copy text: ", err);
      });
  };

  const update = useCallback(
    async () => {
      try {
        if (!newDataTeam.whenCall.find(item => item.checked)) {
          setSnackbar(old => ({
            ...old,
            open: true,
            message: "Selecione as funções da API",
            variant: constants.snackbarVariants.WARNING
          }));
          return;
        }
        if (!isValidURL(newDataTeam.url)) {
          setSnackbar(old => ({
            ...old,
            open: true,
            message: "URL inválida",
            variant: constants.snackbarVariants.WARNING
          }));
          return;
        }

        setCreateNewIntegrationDataTeam(true);
        setLoading(true);

        // const existDataTeamUpdate = originalDataTeam.length
        //   ? originalDataTeam[0] === integrationDataTeam
        //   : true;

        // if (createNewIntegrationDataTeam && !newDataTeam) {
        //   setSnackbar(old => ({
        //     ...old,
        //     open: true,
        //     message: "Dados do webhook inválidos",
        //     variant: constants.snackbarVariants.WARNING
        //   }));
        //   return;
        // }

        await api.post("/v1/adm/integration/teams", {
          ...newDataTeam,
          headers: JSON.stringify(newDataTeam.headers),
          whenCall: newDataTeam.whenCall[1].checked
            ? newDataTeam.whenCall[1].id
            : newDataTeam.whenCall[0].id
        });

        // await api.put(`/v1/adm/token/team/integration/${id}`, {
        //   ...data,
        //   attributes: JSON.stringify(data.attributes)
        // });

        // if (!existDataTeamUpdate) {

        //   await api.put(
        //     `/v1/adm/integration/teams/${integrationDataTeam[0].id}`,
        //     integrationDataTeam[0]
        //   );
        // } else if (createNewIntegrationDataTeam) {
        //   await api.post("/v1/adm/integration/teams", {
        //     ...newDataTeam,
        //     headers: JSON.stringify(newDataTeam.headers)
        //   });
        // }

        // setEditable(true);
        setSnackbar(old => ({
          ...old,
          open: true,
          message: "Webhook criado com sucesso!",
          variant: constants.snackbarVariants.SUCCESS
        }));

        await fetchData();
        setCreateNewIntegrationDataTeam(false);
      } catch (err) {
        setSnackbar(old => ({
          ...old,
          open: true,
          message: "Erro ao atualizar",
          variant: constants.snackbarVariants.ERROR
        }));
      } finally {
        setLoading(false);
      }
    },
    [
      data,
      integrationDataTeam,
      originalData,
      originalDataTeam,
      newDataTeam,
      createNewIntegrationDataTeam
    ]
  );

  const handleDelete = useCallback(
    async () => {
      try {
        setLoading(true);
        setModalVisible(old => ({
          ...old,
          open: false
        }));

        await api.delete(`/v1/adm/token/team/integration/${data.id}`);

        history.push("/integrations");
        dispatch(
          ConfigActions.setToastVisible({
            toastVisible: true,
            toastMessage: "Integração apagada",
            toastVariant: constants.toastVariants.success
          })
        );
      } catch (err) {
        setSnackbar(old => ({
          ...old,
          open: true,
          message: "Erro ao deletar integração",
          variant: constants.snackbarVariants.ERROR
        }));
      } finally {
        setLoading(false);
      }
    },
    [data]
  );

  const handleDeleteWebhook = useCallback(
    async () => {
      try {
        setLoading(true);
        setModalVisible(old => ({
          ...old,
          open: false
        }));

        await api.delete(`/v1/adm/integration/teams/${originalDataTeam[0].id}`);

        setIntegrationDataTeam([]);
        setOriginalDataTeam([]);
        setCreateNewIntegrationDataTeam(false);

        // setEditable(true);
        await fetchData();
        setSnackbar(old => ({
          ...old,
          open: true,
          message: "Webhook apagado!",
          variant: constants.snackbarVariants.SUCCESS
        }));
      } catch (err) {
        setSnackbar(old => ({
          ...old,
          open: true,
          message: "Erro ao deletar",
          variant: constants.snackbarVariants.ERROR
        }));
      } finally {
        setLoading(false);
      }
    },
    [originalDataTeam, integrationDataTeam, data]
  );

  const handleChange = key => event => {
    setData(old => ({
      ...old,
      [key]: event.target.value
    }));
  };

  const renderHandoverToken = () => {
    return (
      <>
        {/* <EditorCurrentRegisterContainer
          disabled={loading || !editable}
          flexDirection="row-reverse"
        >
          <Grid container justify="flex-end" alignItems="center" spacing={2}>
            <ButtonEditorCurrentRegister
              label="Editar"
              onClick={() => {
                console.log("show edit");
                setEditable(false);
              }}
            />
          </Grid>
        </EditorCurrentRegisterContainer> */}
        <h4 className={classes.subTitle2}>API Handover</h4>
        <br />

        <h6 className={classes.subTitle}>
          {t("integrations.view_header_message")}
        </h6>

        {data && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  id="outlined-name"
                  label={t("integrations.integration_name")}
                  className={classes.textField}
                  value={data.name}
                  margin="normal"
                  variant="outlined"
                  disabled={true}
                  onChange={handleChange("name")}
                />
              </Grid>
            </Grid>

            <h6 className={classes.subTitle}>{t("integrations.contact_data")}</h6>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  id="outlined-name"
                  label={t("integrations.dev_name")}
                  className={classes.textField}
                  disabled={true}
                  value={data.contactName}
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange("contactName")}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="outlined-email"
                  label={t("integrations.email")}
                  className={classes.textField}
                  disabled={true}
                  value={data.contactEmail}
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange("contactEmail")}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="outlined-contact"
                  label={t("integrations.contact_label")}
                  className={classes.textField}
                  disabled={true}
                  value={data.contactNumber}
                  margin="normal"
                  variant="outlined"
                  onChange={handleChange("contactNumber")}
                  InputProps={{
                    inputComponent: TextMaskCustomMobile
                  }}
                />
              </Grid>
            </Grid>

            <h6 className={classes.subTitle}>{t("integrations.integration_data")}</h6>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-name"
                  label={t("integrations.token")}
                  className={classes.textField}
                  value={data.token}
                  margin="normal"
                  variant="outlined"
                  disabled={true}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ padding: 0 }}>
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="copy to clipboard"
                          onClick={handleCopyToClipboard}
                        >
                          <FileCopy />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>

            <h6 className={classes.subTitle}>{t("integrations.api_functions")}</h6>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormGroup row className={classes.defaultComponent}>
                  {data.attributes.map(item => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={!!item.value}
                          onChange={event => {
                            if (event && event.target) {
                              setData(old => ({
                                ...old,
                                attributes: old.attributes.map(attr => {
                                  if (attr.id === item.id) {
                                    return {
                                      ...attr,
                                      value:
                                        event &&
                                        Object.keys(event.target).includes(
                                          "checked"
                                        )
                                          ? event.target.checked
                                          : false
                                    }; // Return new attribute object
                                  }
                                  return attr;
                                })
                              }));
                            } else {
                              console.error(
                                "event.target is null or undefined"
                              );
                            }
                          }}
                          name={item.id}
                          color="primary"
                          disabled={true}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>

            <h6 className={classes.subTitle}>{t("integrations.documentation")}</h6>

            <Typography className={classes.root}>
              <Link
                className={classes.subTitle}
                href="https://handover-static-files.s3.amazonaws.com/api_docs/handover_api_v2_caixa_postal_inteligente.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://handover-static-files.s3.amazonaws.com/api_docs/handover_api_v2_caixa_postal_inteligente.pdf
              </Link>
            </Typography>

            <br />
            <ButtonClick
              variant="contained"
              onClick={() =>
                setModalVisible({
                  open: true,
                  variant: constants.snackbarVariants.SUCCESS,
                  title: `${t("integrations.remove_integration_confirmation")} ${data.name}?`,
                  labelConfirm: `${t("common:remove")}`,
                  onConfirm: () => {
                    handleDelete();
                  }
                })
              }
              disabled={loading || integrationDataTeam.length}
            >
              {t("integrations.remove_integration")}
            </ButtonClick>
          </>
        )}
      </>
    );
  };

  const renderDataTeam = () => {
    return (
      <>
        {integrationDataTeam.length ? (
          <>
            <br />
            <br />
            <IntegrationDataCreateFormEdit
              editable={false}
              disabled={true}
              defaultData={integrationDataTeam[0]}
              setIntegrationDataTeam={setIntegrationDataTeam}
              headers={
                integrationDataTeam[0].headers
                  ? JSON.parse(integrationDataTeam[0].headers)
                  : {}
              }
            />
          </>
        ) : createNewIntegrationDataTeam ? (
          <IntegrationDataCreateForm
            extractData={value => {
              setNewDataTeam(value);
            }}
          />
        ) : null}
      </>
    );
  };

  return (
    <Container title={""}>
      <Snackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() =>
          setSnackbar(old => ({
            ...old,
            open: false
          }))
        }
        variant={snackbar.variant}
      />
      <ModalEdit
        open={modalVisible.open}
        onClose={() =>
          setModalVisible(old => ({
            ...old,
            open: false
          }))
        }
        onConfirm={() => {
          modalVisible.onConfirm();
        }}
        classes={classes}
        loading={loading}
        title={modalVisible.title}
        subTitle={modalVisible.subTitle}
        labelConfirmButton={modalVisible.labelConfirm}
      />
      <ThemeTextFildGreen>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {renderHandoverToken()}

            {renderDataTeam()}

            {createNewIntegrationDataTeam && (
              <Button
                variant="contained"
                onClick={() => {
                  update();
                }}
                className={classes.button}
              >
                <SaveIcon className={classes.leftIcon} />
                salvar
              </Button>
            )}

            {!integrationDataTeam.length && (
              <CustomDialogContent
                title={t("integrations.create_webhook")}
                subTitle={t("integrations.webhook_subtitle")}
                onConfirmLabel={t("integrations.create_webhook")}
                externalLabel={t("integrations.create_webhook")}
                onConfirm={() => {
                  setCreateNewIntegrationDataTeam(true);
                  update();
                }}
                disabled={false}
                classes={classes}
              >
                <IntegrationDataCreateForm
                  extractData={value => {
                    setNewDataTeam(value);
                  }}
                />
              </CustomDialogContent>
            )}

            {integrationDataTeam.length ? (
              <ButtonClick
                onClick={() =>
                  setModalVisible({
                    open: true,
                    variant: constants.snackbarVariants.SUCCESS,
                    title: "Deseja realmente excluir este webhook?",
                    labelConfirm: "Excluir",
                    onConfirm: () => {
                      handleDeleteWebhook();
                    }
                  })
                }
              >
                excluir integração webhook
              </ButtonClick>
            ) : null}
          </>
        )}
      </ThemeTextFildGreen>
    </Container>
  );
}

export default withStyles(materialStyle)(IntegrationTokenEdit);
