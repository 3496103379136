export const Types = {
  loginRequest: 'login/Request',
  loginSuccess: 'login/Success',
  loginError: 'login/Error',
  loginSaveEmail: 'login/SaveEmail',
  logout: 'login/Logout',
  logoutError: 'logout/Error',
}

const INITAL_STATE = {
  token: '',
  error: null,
  loading: false,
  email: '',
  password: '',
  saveEmail: false,
  permissions: null,
}

export default function login(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.loginRequest:
      return {
        ...state,
        loading: true,
        error: null
      }

    case Types.loginSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        permissions: action.payload.data.permissions,
      };

    case Types.loginError:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }

    case Types.loginSaveEmail:
      return {
        ...state,
        saveEmail: !state.saveEmail
      }

    case Types.logout:
      return {
        ...state,
        token: null,
        error: false,
        email: '',
        password: ''
      }

    default:
      return state;
  }
}

export const Actions = {
  loginRequest: (email, password) => ({
    type: Types.loginRequest,
    payload: {
      email,
      password,
    }
  }),

  loginSuccess: data => ({
    type: Types.loginSuccess,
    payload: { data }
  }),

  loginError: (error) => ({
    type: Types.loginError,
    payload: { error },
  }),

  saveEmail: () => ({
    type: Types.loginSaveEmail,
  }),

  logout: () => ({
    type: Types.logout
  }),

  logoutError: err => ({
    type: Types.loginError,
    payload: { err },
  }),
}
