import queryString from "query-string";
import React, { Component } from "react";

// Material
import { Button, Grid, MenuItem, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Global
import Container from "../../../../components/Container";
import Spinner from "../../../../components/Spinner";
import { materialStyle } from "../../../../styles";
import LockersPaginationTable from "./components/LockersPaginationTable";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerActions } from "../../../../store/ducks/locker";

// Features roles
import featureRoles from "./roles";

// Help Functions
import { checkUserRoles } from "../../../../services/permissions";

import Snackbar from "../../../../components/Snackbar";
import ThemeTextFildGreen from "../../../../styles/ThemeTextFildGreen";
import { ContainerButtons } from "./styles";

class Lockers extends Component {
  state = {
    idLocker: "",
    lockerNumber: ""
  };

  componentWillMount() {
    const { lockersRequest, lockersSelectRequest } = this.props;

    const query = this.handleQueryString({
      perPage: 1000
      // isActive: 1
    });

    lockersRequest(query);
    lockersSelectRequest(query);
  }

  handleQueryString(query) {
    return queryString.stringify(query);
  }

  handleChange = key => event => {
    this.setState({ [key]: event.target.value });
  };

  handleClear = () => {
    const { lockersRequest } = this.props;
    const query = this.handleQueryString({
      perPage: 1000
      // isActive: 1
    });
    lockersRequest(query);

    this.setState({ idLocker: "", lockerNumber: "" });
  };

  handleSearch = () => {
    const { idLocker, lockerNumber } = this.state;
    const { lockersRequest } = this.props;

    if (!idLocker && !lockerNumber) return;

    lockersRequest(
      queryString.stringify({
        idLocker,
        lockerNumber
      })
    );
  };

  renderCreate = () => {
    const check = checkUserRoles(featureRoles.createLockerRole);

    return (
      check && (
        <>
          <h3>Criar Locker</h3>
          <Button
            variant="contained"
            onClick={() => this.props.history.push("/lockers/create")}
            style={{
              backgroundColor: "#006660",
              fontSize: 14,
              color: "#fff",
              textTransform: "lowercase",
              height: 35
            }}
          >
            Criar
          </Button>
          <br />
        </>
      )
    );
  };

  render() {
    const { history, lockers, classes, closeSnackbarLockers } = this.props;
    const { idLocker, lockerNumber } = this.state;

    return (
      <Container title="gestão interna dos lockers">
        <Snackbar
          message={lockers.snackbarMessage}
          open={lockers.snackbarVisible}
          variant={lockers.snackbarType}
          onClose={() => closeSnackbarLockers()}
        />
        {lockers.loading ? (
          <Spinner />
        ) : (
          <ThemeTextFildGreen>
            <div className="helper-spacing-top-mobile" />

            <Grid container spacing={2} className="first-grid">
              <Grid item xs={12} md={3} className="form-colaboradore-sm">
                <TextField
                  id="custom-css-outlined-input"
                  select
                  label="Locker"
                  className={classes.textFieldLocker1}
                  value={idLocker}
                  onChange={this.handleChange("idLocker")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {lockers.dataSelect &&
                    lockers.dataSelect.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.lockerNumber} - {item.address}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={2} className="form-colaboradore-sm">
                <TextField
                  id="outlined-field"
                  label="LID"
                  className={classes.textField}
                  value={lockerNumber}
                  onChange={this.handleChange("lockerNumber")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <ContainerButtons
              style={{ marginLeft: "-10px" }}
              className="marginButton"
            >
              <Button
                onClick={this.handleSearch}
                variant="contained"
                className={classes.button}
                style={{
                  backgroundColor: "#006660",
                  fontSize: 14,
                  color: "#fff",
                  textTransform: "lowercase",
                  height: 35
                }}
              >
                Pesquisar
              </Button>
              <Button
                onClick={this.handleClear}
                variant="contained"
                className={classes.button}
                style={{
                  backgroundColor: "#006660",
                  fontSize: 14,
                  color: "#fff",
                  textTransform: "lowercase",
                  height: 35
                }}
              >
                Limpar
              </Button>
            </ContainerButtons>
            <LockersPaginationTable history={history} />
          </ThemeTextFildGreen>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  lockers: state.locker,
  teams: state.teams
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(LockerActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(Lockers));
