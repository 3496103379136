import React, { Component } from "react";

// Material
import { TextField, Button, Grid, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";

// Global Components
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";

// Global Style
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions } from "../../store/ducks/laundry";
import { Actions as LockerAcions } from "../../store/ducks/locker";

//configs
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import Snackbar from "../../components/Snackbar";
import { constants } from "../../config";
import { withTranslation } from "react-i18next";

class CreateLaundryLockerItemOption extends Component {
  t = this.props.t
  state = {
    name: "",
    maxPerWash: 0,
    status: 1,
    notChange: true,
    showMessage: false,
    messageText: null,
    messageVariant: null,
    idLocker: ""
  };

  componentWillMount() {
    const { lockersRequest } = this.props;
    const query = "perPage=1000";
    lockersRequest(query);
  }

  handleChange = key => event => {
    let value = event.target.value;

    this.setState({
      [key]: value
    });
  };

  handleSave = () => {
    const { createLaundryLockerItemOptionsRequest, history } = this.props;
    const { name, maxPerWash, status, idLocker } = this.state;

    if (!name || !idLocker) {
      this.setState({
        showMessage: true
      });
      return;
    }

    createLaundryLockerItemOptionsRequest({
      name,
      maxPerWash,
      status,
      idLocker,
      pushFunction: history.push
    });
  };

  handleSelectValueLabel = (id, data) => {
    const check = data.find(item => item.id === id);

    if (!check) return "?";

    return check.name;
  };

  render() {
    const { classes, laundry, lockers } = this.props;
    const {
      name,
      maxPerWash,
      idLocker,
      status,
      messageVariant,
      showMessage,
      messageText
    } = this.state;

    return (
      <Container title="">
        <ThemeTextFildGreen>
          {laundry.loading ? (
            <Spinner />
          ) : (
            <>
              <Snackbar
                open={showMessage}
                onClose={() => this.setState({ showMessage: false })}
                message={messageText || "Campos inválidos ou vazios!"}
                variant={messageVariant || constants.snackbarVariants.WARNING}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={this.t('common:name')}
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                    margin="normal"
                    variant="outlined"
                    disabled={laundry.loading}
                  />
                </Grid>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="outlined-name"
                    label={this.t('maxByOrder')}
                    className={classes.textField}
                    value={maxPerWash}
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange("maxPerWash")}
                    type="number"
                    inputProps={{ min: "0" }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomAutoComplete
                    options={lockers.data}
                    value={lockers.data.find(item => item.id === idLocker)}
                    onChange={newValue => {
                      this.setState({
                        idLocker: newValue.id
                      });
                    }}
                    disabled={lockers.loading || !lockers.data.length}
                    loading={lockers.loading}
                    getOptionLabelKey="address"
                    label={this.t('common:locker')}
                  />
                </Grid>
                <Grid item xs={12} md={3} className="form-colaboradore-sm">
                  <TextField
                    id="custom-css-outlined-input"
                    select
                    label="Status"
                    className={classes.selectType}
                    value={status}
                    onChange={this.handleChange("status")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <MenuItem key={1} value={1}>
                    {this.t('common:enabled')}
                    </MenuItem>
                    <MenuItem key={0} value={0}>
                    {this.t('common:disabled')}
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              <Button
                variant="contained"
                onClick={() => this.handleSave()}
                disabled={laundry.loading}
                className={classes.button}
              >
                <SaveIcon className={classes.leftIcon} />
                {this.t('common:save')}

              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  this.setState({
                    edit: false
                  })
                }
                disabled={laundry.loading}
                className={classes.button}
              >
                <CancelIcon className={classes.leftIcon} />
                {this.t('common:dismiss')}

              </Button>
            </>
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  laundry: state.laundry,
  lockers: state.locker
});

const mapDispathToProps = dispatch =>
  bindActionCreators(
    {
      ...Actions,
      ...LockerAcions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withStyles(materialStyle)(withTranslation('laundry')(CreateLaundryLockerItemOption)));
