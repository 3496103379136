import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import { Actions as RentalActions } from "../ducks/rental";

export function* getRentalRequest(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/rental?${action.payload.data}`
    );

    yield put(RentalActions.getRentalSuccess(response.data));
  } catch (err) {
    yield put(RentalActions.getRentalError(err));
  }
}

export function* showRentalUserOrderRequest(action) {
  try {
    const response = yield call(api.get, `/v1/adm/rental/${action.payload.id}`);

    yield put(RentalActions.showRentalSuccess(response.data));
  } catch (err) {
    yield put(RentalActions.showRentalError(err));
  }
}

export function* updateRentalRequest(action) {
  try {
    yield call(api.put, `/v1/adm/rental/${action.payload.id}`);
    const response = yield call(api.get, `/v1/adm/rental/${action.payload.id}`);

    yield put(RentalActions.showRentalSuccess(response.data));
  } catch (err) {
    yield put(RentalActions.updateRentalError(err));
  }
}
