import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import queryString from "query-string";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import Logo from "../../assets/Lockup_Handover.svg";

import api from "../../services/api";

import {
  Container,
  Informations,
  Requeriment,
  Content,
  IconEye,
  Form
} from "./styles";

function resetPassword(props) {
  const [token, setToken] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(false);
  const [error, setError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [min, setMin] = useState(false);
  const [uppercase, setUppercase] = useState(false);
  const [alphaNumeric, setAlphaNumeric] = useState(false);
  const [validationPassword, setValidationPassword] = useState(false);

  useEffect(() => {
    setRedirectTo(false);
    const { token, type } = queryString.parse(props.location.search);
    if (token) {
      setToken(token);
    }

    if (type) {
      setType(type);
    }
  });

  async function sendNewPassword(e) {
    e.preventDefault();
    setLoading(true);
    if (password !== passwordConfirm) {
      setError("Senhas estão diferentes!");
    } else {
      try {
        const apiFunc = type === "user" ? api.post : api.put;
        await apiFunc(
          type === "user" ? "/reset_password" : "/v1/business/forgot_password",
          {
            token,
            password,
            password_confirmation: passwordConfirm
          },
          {
            headers: {
              public: true
            }
          }
        );
        setError(null);
        props.history.push(
          type === "user" ? "/reseted_password" : `/?action=reseted_pass`
        );
      } catch (error) {
        setError("Não foi possível prosseguir!");
      }
    }
    setLoading(false);
    setToken("");
    setPassword("");
    setPasswordConfirm("");
    setAlphaNumeric(false);
    setMin(false);
    setUppercase(false);
    setValidationPassword(false);
    setShowPassword(false);
  }

  function changePassword(e) {
    const reAlpha = new RegExp(/[a-z]/);
    const reNumber = new RegExp(/[0-9]/);
    const reUppercase = new RegExp(/[A-Z]/);

    const validationsPassword = {
      caracterMin: e.target.value.length > 6,
      caracterAlphaNumeric:
        e.target.value.match(reAlpha) && e.target.value.match(reNumber),
      caracterUppercase: e.target.value.match(reUppercase)
    };

    if (validationsPassword.caracterMin) {
      setMin(true);
    } else {
      setMin(false);
    }
    if (validationsPassword.caracterAlphaNumeric) {
      setAlphaNumeric(true);
    } else {
      setAlphaNumeric(false);
    }
    if (validationsPassword.caracterUppercase) {
      setUppercase(true);
    } else {
      setUppercase(false);
    }
    if (
      validationsPassword.caracterMin &&
      validationsPassword.caracterAlphaNumeric &&
      validationsPassword.caracterUppercase
    ) {
      setValidationPassword(true);
    } else {
      setValidationPassword(false);
    }

    setPassword(e.target.value);
  }

  function showPasswordHandle(typer) {
    if (typer === "password") {
      return setShowPassword(!showPassword);
    }
    return setShowPassword(!showPassword);
  }

  if (redirectTo) {
    return (
      <Redirect
        to={type === "user" ? "/reseted_password" : `/?action=reseted_pass`}
      />
    );
  }

  return (
    <Container>
      <Content>
        <img src={Logo} alt="Logo" width="120" height="120" />
        {type !== "user" && <h3>EMPRESAS</h3>}
        <div>
          <h1>Redefinir Senha</h1>
          <small>Preencha os campos com a nova senha.</small>
        </div>

        <Form
          onSubmit={sendNewPassword}
          loading={loading || !validationPassword}
          disabledButton={
            loading || !validationPassword || !password || !passwordConfirm
          }
        >
          <IconEye onClick={() => showPasswordHandle("password")}>
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconEye>
          <input
            type={showPassword ? "none" : "password"}
            placeholder="Digite nova senha"
            value={password}
            onChange={e => changePassword(e)}
          />

          <IconEye top={true} onClick={showPasswordHandle}>
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconEye>
          <input
            type={showPassword ? "none" : "password"}
            placeholder="Confirme nova senha"
            value={passwordConfirm}
            onChange={e => setPasswordConfirm(e.target.value)}
          />

          <small>{error}</small>

          <Informations>
            <h4>Crie uma senha segura:</h4>
            <Requeriment validation={min}>No mínimo 7 caracteres;</Requeriment>
            <Requeriment validation={alphaNumeric}>
              Um combinação de letras e números;
            </Requeriment>
            <Requeriment validation={uppercase}>
              Ao menos uma letra maiúscula e outra minúscula.
            </Requeriment>
          </Informations>

          <button
            disabled={
              loading || !validationPassword || !password || !passwordConfirm
            }
            type="submit"
          >
            {loading ? "Carregando..." : "Enviar"}
          </button>
          {type !== "user" && <Link to="/">Lembrei a senha</Link>}
        </Form>
      </Content>
    </Container>
  );
}

export default resetPassword;
