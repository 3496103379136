import React, { Component } from "react";
import queryString from "query-string";

// Global
import Container from "../../../../components/Container";
import { materialStyle } from "../../../../styles";
import CompartmentOpeningTable from "../../../../components/CompartmentOpeningTable";
import CompartmentSupplyTable from "../../../../components/CompartmentSupplyTable";
import DeliveryUpdateTable from "../../../../components/DeliveryUpdateTable";
import Spinner from "../../../../components/Spinner";
import Snackbar from "../../../../components/Snackbar";
import CustomerLogsTable from "../../../../components/CustomerLogsTable";
import DeliveryEmails from "../../../../components/DeliveryEmails";

// Material
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as DeliveryActions } from "../../../../store/ducks/delivery";
import { Actions as CustomerLogActions } from "../../../../store/ducks/customerLogs";
import { Actions as DeliveryEmailsActions } from "../../../../store/ducks/deliveryEmails";

// Local Components
import Tabs from "./components/Tab";
import formatDate from "../../../../functions/formatDate";
import SimpleTable from "../../../../components/SimpleTable";

class DeliveryEdit extends Component {
  t = this.props.t;

  state = {
    currentDelivery: null,
    isChanged: true
  };

  componentWillMount() {
    const {
      showDeliveryRequest,
      customerLogsRequest,
      getDeliveryEmailsRequest
    } = this.props;
    const { id } = this.props.match.params;

    showDeliveryRequest(id);
    customerLogsRequest(
      queryString.stringify({
        idDelivery: id
      })
    );
    getDeliveryEmailsRequest(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.delivery.currentDelivery !== this.state.currentDelivery) {
      this.setState({ currentDelivery: nextProps.delivery.currentDelivery });
    }
  }

  handleChange = key => event => {
    this.setState({
      ...this.state,
      isChanged: false,
      currentDelivery: {
        ...this.state.currentDelivery,
        [key]: event.target.value
      }
    });
  };

  handleSave = () => {
    const { updateDeliveryRequest } = this.props;

    const { currentDelivery } = this.state;

    updateDeliveryRequest({
      ...currentDelivery
    });
  };

  render() {
    const { delivery, history, setSnackbarVisibleDelivery } = this.props;
    const { currentDelivery } = this.state;

    return (
      <Container title="Histórico da entrega">
        <Snackbar
          message={delivery.snackbarMessage}
          open={delivery.snackbarVisible}
          variant={delivery.snackbarType}
          onClose={() => setSnackbarVisibleDelivery(false)}
        />
        {delivery.loading ? (
          <Spinner />
        ) : (
          currentDelivery && (
            <>
              <Tabs
                view1={<DeliveryUpdateTable history={history} />}
                view2={
                  <CompartmentOpeningTable
                    source={{
                      data: currentDelivery.compartment.opening,
                      total: currentDelivery.compartment.opening.length,
                      rowsPerPage: currentDelivery.compartment.opening.length,
                      page: 0
                    }}
                  />
                }
                view3={
                  1 === 2 ? (
                    <CompartmentSupplyTable />
                  ) : (
                    <SimpleTable
                      columns={[
                        {
                          id: Math.random(),
                          text: this.t("view.item")
                        },
                        {
                          id: Math.random(),
                          text: this.t("view.quantity")
                        }
                      ]}
                      rowValues={currentDelivery.laundryItems.map(item => {
                        const newItem = {};

                        Object.assign(newItem, {
                          id: item.id,
                          data: [
                            {
                              id: Math.random(),
                              value: item.item.name
                            },
                            {
                              id: Math.random(),
                              value: item.count
                            }
                          ]
                        });

                        return newItem;
                      })}
                    />
                  )
                }
                view4={<DeliveryEmails />}
                view5={<CustomerLogsTable />}
              />
            </>
          )
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  delivery: state.delivery
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...DeliveryActions,
      ...CustomerLogActions,
      ...DeliveryEmailsActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("delivery")(withStyles(materialStyle)(DeliveryEdit)));
