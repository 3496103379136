import React, { Component } from "react";

// Global
import Container from "../../components/Container";
import TeamsTable from "../../components/TeamsTable";
import Spinner from "../../components/Spinner";

// Styles
import { materialStyle } from "../../styles";

// Material
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as TeamActions } from "../../store/ducks/teams";

// Functions
import { checkUserRoles } from "../../services/permissions";

// Roles
import createTeamRole from "./roles/createTeam";

class Teams extends Component {
  componentWillMount() {
    const { teamsRequest } = this.props;

    teamsRequest("");
  }

  render() {
    const { history, teams } = this.props;

    return (
      <Container title="clientes corporativos" className="teams-sm">
        {teams.loading ? <Spinner /> : <TeamsTable />}
        {checkUserRoles(createTeamRole) && (
          <div className="teams-sm__criar">
            <Button
              onClick={() => history.push("/teams/create")}
              variant="contained"
              className="teams-sm__button"
              style={{
                background: "#4AC151",
                color: "#fff",
                marginTop: "20px"
              }}
            >
              Adicionar novo cliente
            </Button>
            <br />
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.teams
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(TeamActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(Teams));
