import React from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export default function AnimatedMultiSelector({
  onChange,
  options,
  defaultValue,
  labelKey
}) {
  return (
    <>
      <Select
        isSearchable={true}
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        defaultValue={defaultValue.map(item => {
          item.value = item.id;
          item.label = item[labelKey];
          return item;
        })}
        options={options.map(item => {
          item.value = item.id;
          item.label = item[labelKey];
          return item;
        })}
        onChange={onChange}
      />
    </>
  );
}

AnimatedMultiSelector.defaultProps = {
  defaultValue: [],
  options: [],
  labelKey: "id"
};
