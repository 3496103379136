import React, { Component } from "react";
import axios from "axios";

// Material
import { TextField, Button, MenuItem, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerActions } from "../../../../store/ducks/locker";

// Components
import Container from "../../../../components/Container";
import TextMaskCustomCEP from "../../../../components/TextMaskCustomCEP";
import TextMaskCustomMobile from "../../../../components/TextMaskCustomMobile";
import { materialStyle } from "../../../../styles";
import Snackbar from "../../../../components/Snackbar";

import ThemeTextFildGreen from "../../../../styles/ThemeTextFildGreen";

class LockerCreate extends Component {
  state = {
    idLockerAvailability: "",
    idLockerType: "",
    zipCode: "",
    idCountry: "",
    idState: "",
    idCity: "",
    address: "",
    streetName: "",
    streetNumber: "",
    url: "",
    emailLocker: "",
    pwdLocker: "",
    cameraUrl: "",
    pwdCamera: "",
    latitude: "",
    longitude: "",
    contactName: "",
    contactNumber: "",
    email: "",
    idTeam: JSON.parse(localStorage.getItem("auth")).currentTeam.id,
    locationInfo: null,
    validation: false,
    idLockerCapacity: "",
    emailValidation: false
  };

  componentWillMount() {
    const { getInfoLockersRequest } = this.props;

    // load all lockers info
    getInfoLockersRequest();
  }

  // update local state when get response for api
  componentWillReceiveProps(nextProps) {
    const {
      lockers: { locationInfo }
    } = nextProps;

    if (this.state.locationInfo !== locationInfo) {
      this.setState({
        locationInfo,
        idCountry: locationInfo.country.id,
        idState: locationInfo.state.id,
        idCity: locationInfo.city.id
        // streetName: locationInfo.logradouro,
        // address: locationInfo.logradouro,
      });
    }
  }

  // search CEP
  getCEP = async () => {
    const { getLocationRequest } = this.props;
    const { zipCode } = this.state;
    this.setState({ errorCEP: false });

    if (!zipCode) return;

    const response = await axios.get(
      `https://viacep.com.br/ws/${zipCode
        .replace(".", "")
        .replace("-", "")}/json/`
    );

    const { erro, ...data } = response.data;

    if (erro) {
      this.setState({ errorCEP: true });
      return;
    }

    this.setState({
      streetName: data.logradouro,
      address: data.logradouro
    });

    getLocationRequest({
      cityName: data.localidade,
      logradouro: data.logradouro
    });
  };

  // handle change global
  handleChange = key => event => {
    let value;

    if (key === "zipCode" || key === "contactNumber") {
      // get only numbers
      value = event.target.value
        .replace(".", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .trim();
    } else {
      value = event.target.value;
    }

    // validate email
    if (key === "email" || key === "emailLocker") {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)
      ) {
        this.setState({ emailValidation: true });
      } else {
        this.setState({ emailValidation: false });
      }
    }

    this.setState({
      [key]: value
    });
  };

  handleSave = () => {
    // const {
    //   idLockerAvailability,
    //   idLockerType,
    //   zipCode,
    //   address,
    //   streetName,
    //   streetNumber,
    //   url,
    //   emailLocker,
    //   pwdLocker,
    //   cameraUrl,
    //   pwdCamera,
    //   latitude,
    //   longitude,
    //   contactName,
    //   contactNumber,
    //   email,
    //   idLockerCapacity,
    //   emailValidation,
    //   idLockerStatus
    // } = this.state;
    const { createLockerRequest } = this.props;
    this.setState({ validation: false });

    /*if (
      !idLockerAvailability ||
      !idLockerStatus ||
      !idLockerType ||
      !zipCode ||
      !address ||
      !streetName ||
      !streetNumber ||
      !url ||
      !emailLocker ||
      !pwdLocker ||
      !cameraUrl ||
      !pwdCamera ||
      !latitude ||
      !longitude ||
      !contactName ||
      !contactNumber ||
      !email ||
      !idLockerCapacity ||
      !emailValidation
    ) {
      this.setState({ validation: true });
      return;
    }*/

    createLockerRequest({
      ...this.state
    });
  };

  render() {
    const {
      idLockerAvailability,
      idLockerType,
      zipCode,
      address,
      streetName,
      streetNumber,
      cameraUrl,
      pwdCamera,
      latitude,
      longitude,
      contactName,
      contactNumber,
      email,
      errorCEP,
      validation,
      idLockerCapacity,
      emailValidation,
      idLockerStatus,
      idTeam
    } = this.state;
    const { classes, lockers, closeSnackbarLockers, teams } = this.props;

    return (
      <Container title="Criar um locker">
        <ThemeTextFildGreen>
          <Snackbar
            open={validation}
            onClose={() => this.setState({ validation: false })}
            variant="warning"
            message="Preencha todos os campos"
          />
          <Snackbar
            open={lockers.snackbarVisible}
            onClose={closeSnackbarLockers}
            variant={lockers.snackbarType}
            message={lockers.snackbarMessage}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="custom-css-outlined-input"
                select
                label="Cliente"
                className={classes.selectType}
                value={idTeam}
                onChange={this.handleChange("idTeam")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                variant="outlined"
                disabled={teams.loading}
              >
                {teams.data.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {`${item.name} - ${item.cnpj}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {lockers.lockersInfo && (
              <>
                <Grid item xs={12} md={2}>
                  <TextField
                    required
                    disabled={lockers.loading}
                    id="custom-css-outlined-input"
                    select
                    label="Disponibilidade"
                    className={classes.selectType}
                    value={idLockerAvailability}
                    onChange={this.handleChange("idLockerAvailability")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {lockers.lockersInfo.availability.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.status}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    required
                    disabled={lockers.loading}
                    id="custom-css-outlined-input"
                    select
                    label="Status"
                    className={classes.selectType}
                    value={idLockerStatus}
                    onChange={this.handleChange("idLockerStatus")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {lockers.lockersInfo.status.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    required
                    id="custom-css-outlined-input"
                    select
                    label="Tipo"
                    className={classes.selectType}
                    value={idLockerType}
                    onChange={this.handleChange("idLockerType")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                    disabled={lockers.loading}
                  >
                    {lockers.lockersInfo.types.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    required
                    id="custom-css-outlined-input"
                    select
                    label="Capacidade"
                    className={classes.selectType}
                    value={idLockerCapacity}
                    onChange={this.handleChange("idLockerCapacity")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                    disabled={lockers.loading}
                  >
                    {lockers.lockersInfo.capacities.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </>
            )}
          </Grid>

          <h5 className={classes.textField}>Endereço</h5>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
              <TextField
                required
                error={errorCEP}
                id="outlined-name"
                label="CEP"
                className={classes.textField}
                value={zipCode}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange("zipCode")}
                InputProps={{
                  inputComponent: TextMaskCustomCEP
                }}
                disabled={lockers.loading}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                disabled={lockers.loading}
                variant="contained"
                onClick={this.getCEP}
                style={{ background: "#4AC151", color: "#fff" }}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="outlined-name"
                label="País"
                className={classes.textField}
                value={
                  lockers.locationInfo &&
                  lockers.locationInfo.country.countryName
                }
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="outlined-name"
                label="Estado"
                className={classes.textField}
                value={
                  lockers.locationInfo && lockers.locationInfo.state.stateName
                }
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="outlined-name"
                label="Cidade"
                className={classes.textField}
                value={
                  lockers.locationInfo && lockers.locationInfo.city.cityName
                }
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="outlined-name"
                label="Endereço"
                className={classes.textField}
                value={address}
                margin="normal"
                variant="outlined"
                disabled={lockers.loading}
                onChange={this.handleChange("address")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="outlined-name"
                label="Logradouro"
                className={classes.textField}
                value={streetName}
                margin="normal"
                variant="outlined"
                disabled={lockers.loading}
                onChange={this.handleChange("streetName")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="outlined-name"
                label="Número"
                className={classes.textField}
                value={streetNumber}
                margin="normal"
                variant="outlined"
                disabled={lockers.loading}
                onChange={this.handleChange("streetNumber")}
                type="number"
                inputProps={{ min: "0" }}
              />
            </Grid>
          </Grid>

          <h5 className={classes.textField}>Informações da câmera</h5>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="outlined-name"
                label="URL da câmera"
                className={classes.textField}
                value={cameraUrl}
                margin="normal"
                variant="outlined"
                disabled={lockers.loading}
                onChange={this.handleChange("cameraUrl")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="outlined-name"
                label="Senha câmera"
                className={classes.textField}
                value={pwdCamera}
                margin="normal"
                variant="outlined"
                disabled={lockers.loading}
                onChange={this.handleChange("pwdCamera")}
              />
            </Grid>
          </Grid>

          <h5 className={classes.textField}>Informações geográficas</h5>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="outlined-name"
                label="latitude"
                className={classes.textField}
                value={latitude}
                margin="normal"
                variant="outlined"
                disabled={lockers.loading}
                onChange={this.handleChange("latitude")}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="outlined-name"
                label="longitude"
                className={classes.textField}
                value={longitude}
                margin="normal"
                variant="outlined"
                disabled={lockers.loading}
                onChange={this.handleChange("longitude")}
                type="number"
              />
            </Grid>

            <h5 className={classes.textField}>Informações de Contato</h5>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  id="outlined-name"
                  label="Nome de contato"
                  className={classes.textField}
                  value={contactName}
                  margin="normal"
                  variant="outlined"
                  disabled={lockers.loading}
                  onChange={this.handleChange("contactName")}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  id="outlined-name"
                  label="Telefone de contato"
                  className={classes.textField}
                  value={contactNumber}
                  margin="normal"
                  variant="outlined"
                  disabled={lockers.loading}
                  onChange={this.handleChange("contactNumber")}
                  InputProps={{
                    inputComponent: TextMaskCustomMobile
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  required
                  error={emailValidation}
                  id="outlined-name"
                  label="Email de contato"
                  className={classes.textField}
                  value={email}
                  margin="normal"
                  variant="outlined"
                  disabled={lockers.loading}
                  onChange={this.handleChange("email")}
                />
              </Grid>
            </Grid>

            <Button
              onClick={this.handleSave}
              variant="contained"
              disabled={errorCEP || lockers.loading}
              style={{ background: "#4AC151", color: "#fff" }}
            >
              Salvar
            </Button>
          </Grid>
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  lockers: state.locker,
  teams: state.teams
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(LockerActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(LockerCreate));
