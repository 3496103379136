import React, { Component } from "react";

// Global Components
import Container from "../../components/Container";

// Material
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, MenuItem, Grid } from "@material-ui/core";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as ExternalProfileActions } from "../../store/ducks/externalProfile";

//configs
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import Spinner from "../../components/Spinner";

class ExternalProfileEdit extends Component {
  state = {
    name: "",
    externalProfileId: "",
    objectGUID: "",
    secret: ""
  };

  // Load Roles and teams
  componentWillMount() {
    const {
      externalProfileShowRequest,
      match: { params }
    } = this.props;

    externalProfileShowRequest(params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.externalProfile.currentExternalProfile !==
      this.state.currentExternalProfile
    ) {
      this.setState({
        ...nextProps.externalProfile.currentExternalProfile
      });
    }
  }

  // Handle Change global
  handleChange = name => event => {
    let value = event.target.value;

    this.setState({
      [name]: value
    });
  };

  handleUpdate = () => {
    const {
      externalProfileUpdateRequest,
      match: { params }
    } = this.props;

    externalProfileUpdateRequest(params.id, {
      secret: this.state.secret
    });
  };

  render() {
    const { classes, externalProfile } = this.props;
    const { name, secret, externalProfileId, objectGUID } = this.state;

    return externalProfile.loading ? (
      <Spinner />
    ) : (
      <Container title="Editar perfil externo">
        <ThemeTextFildGreen>
          <Grid className="first-grid" container spacing={2}>
            <Grid
              item
              xs={12}
              sm={3}
              className="form-colaboradore-sm"
              alignItems="flex-start"
            >
              <TextField
                required
                id="outlined-name"
                label="Nome"
                className={classes.textField}
                value={name}
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className="form-colaboradore-sm"
              alignItems="flex-start"
            >
              <TextField
                id="outlined-name"
                label="Perfil"
                className={classes.textField}
                value={externalProfileId}
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className="form-colaboradore-sm"
              alignItems="flex-start"
            >
              <TextField
                id="outlined-name"
                label="Identificador"
                className={classes.textField}
                value={objectGUID}
                margin="normal"
                variant="outlined"
                disabled={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className="form-colaboradore-sm"
              alignItems="flex-start"
            >
              <TextField
                required
                id="outlined-name"
                label="Secret"
                className={classes.textField}
                value={secret}
                onChange={this.handleChange("secret")}
                margin="normal"
                variant="outlined"
                disabled={externalProfile.loading}
              />
            </Grid>
          </Grid>

          <br />
          <Button
            disabled={externalProfile.loading}
            onClick={this.handleUpdate}
            variant="contained"
          >
            <SaveIcon className={classes.leftIcon} />
            Salvar
          </Button>
          <Button
            variant="contained"
            onClick={() => this.props.history.push("/things")}
            className={classes.button}
          >
            <CancelIcon className={classes.leftIcon} />
            Cancelar
          </Button>
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  externalProfile: state.externalProfile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ExternalProfileActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(ExternalProfileEdit));
