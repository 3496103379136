export const Types = {
  resetUserCreditTicketState: "resetUserCreditTicket/State",

  searchUserCreditTicketRequest: "searchUserCreditTicket/Request",
  searchUserCreditTicketSuccess: "searchUserCreditTicket/Success",
  searchUserCreditTicketError: "searchUserCreditTicket/Error"
};

const INITAL_STATE = {
  page: 0,
  perPage: 100,
  lastPage: 1,
  total: 0,
  data: [],
  loading: false,
  error: null
};

export default function userCreditTicket(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.searchUserCreditTicketRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.searchUserCreditTicketSuccess:
      return {
        ...state,
        ...action.payload.data,
        loading: false
      };

    case Types.searchUserCreditTicketError:
      return {
        ...state,
        error: action.payload.error
      };

    default:
      return state;
  }
}

export const Actions = {
  searchUserCreditTicketRequest: data => ({
    type: Types.searchUserCreditTicketRequest,
    payload: { data }
  }),

  searchUserCreditTicketSuccess: data => ({
    type: Types.searchUserCreditTicketSuccess,
    payload: { data }
  }),

  searchUserCreditTicketError: error => ({
    type: Types.searchUserCreditTicketError,
    payload: { error }
  })
};
