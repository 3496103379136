export const Types = {
  getDeliveryEmailsRequest: "getDeliveryEmails/Request",
  getDeliveryEmailsSuccess: "getDeliveryEmails/Success",
  getDeliveryEmailsError: "getDeliveryEmails/Error",
  resetLocation: "resetLocation"
};

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
  page: 1,
  perPage: 100
};

export default function location(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.getDeliveryEmailsRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.getDeliveryEmailsSuccess:
      return {
        ...state,
        ...action.payload.data,
        loading: false
      };

    case Types.getDeliveryEmailsError:
      return {
        ...state,
        error: action.payload.error,
        laoding: false
      };

    case Types.resetLocation:
      return {
        ...INITIAL_STATE
      };

    default:
      return state;
  }
}

export const Actions = {
  getDeliveryEmailsRequest: idDelivery => ({
    type: Types.getDeliveryEmailsRequest,
    idDelivery
  }),

  getDeliveryEmailsSuccess: data => ({
    type: Types.getDeliveryEmailsSuccess,
    payload: { data }
  }),

  getDeliveryEmailsError: error => ({
    type: Types.getDeliveryEmailsError,
    payload: { error }
  }),

  resetLocation: () => ({
    type: Types.resetLocation
  })
};
