import { call, put } from 'redux-saga/effects';
import api from '../../services/api';

import { Actions as UserActions } from '../ducks/users';

export function* searchEmailUser(action) {
  try {
    const response = yield call(api.get, '/v1/adm/users', {
      params: {
        ...action.payload.data
      }
    })

    yield put(UserActions.searchEmailSuccess(response.data))
  } catch (err) {
    yield put(UserActions.searchEmailError(err))
  }
}

export function* searchUser(action) {
  try {
    const response = yield call(api.get, `/v1/adm/users/${action.payload.id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });

    yield put(UserActions.searchUserSuccess(response.data))
  } catch (err) {
    yield put(UserActions.searchUserError(err))
  }
}

export function* updateUser(action) {
  try {
    const { id, data } = action.payload;

    yield call(api.put, `/v1/adm/users/${id}`, {
      ...data,
    });

    yield put(UserActions.updateUserSuccess({
      ...data
    }));
  } catch (err) {
    yield put(UserActions.updateUserError(err))
  }
}

export function* createUser(action) {
  try {
    const { data } = action.payload;

    yield call(api.post, '/v1/adm/users', {
      ...data,
    });

    yield put(UserActions.createUserRequest({
      ...data
    }));
  } catch (err) {
    yield put(UserActions.createUserError(err))
  }
}
