import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { CheckCircle } from "@material-ui/icons";

import formatDate from "../../../../functions/formatDate";

// Materials
import { Stepper, Step, StepLabel, StepConnector } from "@material-ui/core";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    // "& $line": {
    //   borderColor: "#784af4"
    // }
  },
  completed: {
    "& $line": {
      // borderColor: "#4AC151",
      borderColor: "#006660"
    }
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  // active: {
  //   color: "#784af4"
  // },
  circle: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "currentColor"
  },
  completed: {
    // color: '#784af4',
    color: "#006660",
    zIndex: 1,
    fontSize: 18,
    width: 25,
    height: 25
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? (
        <CheckCircle className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "-30px",
    marginBottom: "-45px"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export default function CustomizedSteppers({
  position,
  data,
  deploymentStatus
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={position + 1}
        connector={<QontoConnector />}
      >
        {deploymentStatus &&
          deploymentStatus.map(step => (
            <Step key={step.position}>
              <StepLabel
                StepIconComponent={props => QontoStepIcon(props, step.position)}
              >
                <p style={{ fontSize: "10px", marginBottom: 0 }}>
                  {step.description}
                </p>
                <p style={{ fontSize: "9px" }}>
                  {data &&
                    data[`step${step.position}`] &&
                    formatDate(data[`step${step.position}`])}
                </p>
              </StepLabel>
            </Step>
          ))}
      </Stepper>
    </div>
  );
}
