import React, { Component } from "react";

import apiDS from "../../services/apiDS";

// Material
import { Grid, Button, TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Alert, AlertTitle } from "@material-ui/lab";

import LocationTable from "./table";

import Container from "../../components/Container";
import time from "./utils/time";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";

class ToolsDatascience extends Component {
  state = {
    data: [],
    dataDS: [],
    origin: "",
    destination: "",
    open: true,
    openExpanded: true,
    mode: "driving",
    downtime: 5,
    teste: "",
    gmap_coord_waypoints: [],
    loading: false,
    error: null,
    columns: [
      { title: "Logradouro", field: "logradouro" },
      { title: "Número", field: "numero" },
      {
        title: "Estado",
        field: "estado",
        lookup: {
          Acre: "Acre",
          Alagoas: "Alagoas",
          Amapá: "Amapá",
          Amazonas: "Amazonas",
          Bahia: "Bahia",
          Ceará: "Ceará",
          "Espírito Santo": "Espírito Santo",
          Goias: "Goias",
          Maranhão: "Maranhão",
          "Mato Grosso": "Mato Grosso",
          "Mato Grosso do Sul": "Mato Grosso do Sul",
          "Minas Gerais": "Minas Gerais",
          Pará: "Pará",
          Paraíba: "Paraiba",
          Paraná: "Paraná",
          Pernambuco: "Pernambuco",
          Piauí: "Piauí",
          "Rio de Janeiro": "Rio de Janeiro",
          "Rio Grande do Norte": "Rio Grande do Norte",
          "Rio Grande do Sul": "Rio Grande do Sul",
          Rondônia: "Rondônia",
          Roraima: "Roraima",
          "Santa Catarina": "Santa Catarina",
          "São Paulo": "São Paulo",
          Sergipe: "Sergipe",
          Tocantins: "Tocantins",
          "Distrito Federal": "Distrito Federal"
        }
      }
    ]
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  returnDataString = data => {
    return data.map(d => `${d.logradouro}, ${d.numero}, ${d.estado}`);
  };

  fetchLocationRoute = async () => {
    try {
      const {
        origin,
        destination,
        data,
        mode,
        downtime,
        openExpanded
      } = this.state;

      const id_user = JSON.parse(localStorage.getItem("auth")).currentTeam.id;

      const localization = this.returnDataString(data);

      this.setState({
        loading: true,
        error: null
      });

      const response = await apiDS.post("/calc_truck/", {
        origin,
        gmap_coord_waypoints: localization,
        destination,
        mode,
        downtime,
        id_user
      });

      this.setState({
        dataDS: response.data.data,
        gmap_coord_waypoints: localization,
        loading: false,
        openExpanded: openExpanded ? false : true,
        open: false
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: err
      });
    }
  };

  clear = () => {
    this.setState({
      destination: "",
      origin: "",
      data: [],
      dataDS: [],
      gmap_coord_waypoints: []
    });
  };

  render() {
    const {
      dataDS,
      loading,
      origin,
      destination,
      gmap_coord_waypoints
    } = this.state;
    return (
      <Container title="Otimização de rotas para distribuição nos lockers">
        <ThemeTextFildGreen>
          <div className="helper-spacing-top-mobile" />
          <Alert severity="info" style={{ width: "100%" }}>
            <AlertTitle>Funcionalidades:</AlertTitle>
            <p style={{ fontSize: "16px" }}>
              Esta ferramenta possibilita formular os melhores trajetos para a
              entrega dos produtos, considerando possíveis imprevístos no
              trajeto (acidentes, trânsito, etc).
            </p>
            <AlertTitle>Observações: </AlertTitle>

            <span>
              As rotas apresentadas no mapa estão considerando que o entregador
              esta fazendo as entregas com veículo;
            </span>
            <br />
            <span>E permitido no máximo 25 endereços no arquivo;</span>
            <br />
            <br />

            <AlertTitle>Como usar: </AlertTitle>

            <ul>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Em <strong>'Dados básicos'</strong> adicionaremos o início do
                trajeto (<strong>Ponto de partida do entregador</strong>
                ), o final do trajeto (
                <strong>Ponto final do entregador</strong>
                ).
              </li>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Os campos de início e final do trajeto devem estar neste modelo:{" "}
                <strong>
                  logradouro (rua, avenida, viela, etc), número do logradouro,
                  estado do logradouro. Ex:{" "}
                </strong>
                Av. Paulista, 181, São Paulo.{" "}
              </li>
              <li style={{ fontSize: "14px", fontFamily: "Work Sans" }}>
                Após colocar estes dados, clique em <strong>Calcular</strong>.
                Após isso irá carregar o mapa com a melhor rota para a entrega
                dos produtos.
              </li>
            </ul>
          </Alert>

          <h4
            style={{
              marginBottom: -10,
              marginTop: 10,
              textAlign: "left",
              fontSize: "16px"
            }}
          >
            Dados básicos
          </h4>

          <Grid container spacing={2} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={5}>
              <TextField
                id="outlined-field"
                label="Ponto de partida do caminhão"
                fullWidth
                name="origin"
                value={this.state.origin}
                onChange={e => this.handleChange(e)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item={true} xs={12} md={5}>
              <TextField
                id="outlined-field"
                label="Ponto final do caminhão"
                fullWidth
                name="destination"
                value={this.state.destination}
                onChange={e => this.handleChange(e)}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                select
                fullWidth
                margin="normal"
                name="downtime"
                label="Tempo de parada por locker"
                value={this.state.downtime}
                onChange={e => this.handleChange(e)}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
              >
                {time.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <ExpansionPanel
            style={{
              background: "transparent",
              border: "0",
              boxShadow: "0"
            }}
            expanded={this.state.openExpanded}
          >
            <ExpansionPanelSummary
              expandIcon={
                <ExpandMoreIcon
                  onClick={() =>
                    this.setState({ openExpanded: !this.state.openExpanded })
                  }
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h4
                style={{
                  fontSize: "16px"
                }}
              >
                Endereço dos lockers
              </h4>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ padding: "0", display: "block" }}>
              <MaterialTable
                title=""
                columns={this.state.columns}
                data={this.state.data}
                localization={{
                  body: {
                    editRow: {
                      deleteText: "Deseja excluir este endereço?",
                      cancelTooltip: "Cancelar",
                      saveTooltip: "Salvar"
                    },
                    addTooltip: "Adicionar",
                    deleteTooltip: "Deletar",
                    editTooltip: "Editar",
                    emptyDataSourceMessage: "Nenhum endereço adicionado"
                  },
                  header: {
                    actions: ""
                  }
                }}
                editable={{
                  onRowAdd: newData =>
                    new Promise(resolve => {
                      setTimeout(() => {
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data.push(newData);
                          return { ...prevState, data };
                        });

                        resolve();
                      }, 1000);
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                      setTimeout(() => {
                        resolve();
                        if (oldData) {
                          this.setState(prevState => {
                            const data = [...prevState.data];
                            data[data.indexOf(oldData)] = newData;
                            return { ...prevState, data };
                          });
                        }
                      }, 1000);
                    }),
                  onRowDelete: oldData =>
                    new Promise(resolve => {
                      setTimeout(() => {
                        resolve();
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          return { ...prevState, data };
                        });
                      }, 1000);
                    })
                }}
                options={{
                  paging: false,
                  search: false,
                  actionsColumnIndex: -1,
                  toolbarButtonAlignment: "left"
                }}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <Button
            onClick={this.fetchLocationRoute}
            variant="contained"
            style={{ marginTop: 20, background: "#4AC151", color: "#fff" }}
          >
            Calcular
          </Button>
          <Button
            onClick={this.clear}
            variant="contained"
            style={{ marginTop: 20, marginLeft: 20 }}
          >
            Limpar pesquisa
          </Button>
          <br />
          <LocationTable
            data={{ dataDS, origin, destination, gmap_coord_waypoints }}
            loading={loading}
          />
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

export default ToolsDatascience;
