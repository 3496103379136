import axios from "axios";

export const getDashboardURL = () => {
  if (process.env.REACT_APP_STAGING) {
    return "http://sandbox.dashboard.oihandover.com";
  }

  return process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://empresas.oihandover.com";
};

const setHeaders = async request => {
  if (request.headers.public) {
    return request;
  }

  if (
    request.url !== "/business/login" &&
    request.url !== "/v1/business/forgot_password"
  ) {
    const token = await JSON.parse(localStorage.getItem("auth")).token;
    const currentTeam = await JSON.parse(localStorage.getItem("auth"))
      .currentTeam;

    if (currentTeam) {
      request.headers.TEAM = currentTeam.slug;
    }

    if (token) {
      request.headers.Authorization = `${token}`;
    }
  }

  return request;
};

const setEnv = () => {
  if (process.env.REACT_APP_STAGING) {
    return "https://sandbox.oihandover.com";
  }

  return process.env.NODE_ENV === "development"
    ? "http://localhost:3333"
    : "https://api.oihandover.com";
};

const api = axios.create({
  baseURL: setEnv(),
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});

api.interceptors.request.use(request => setHeaders(request));

export default api;
