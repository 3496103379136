export const Types = {
  getLockerStructureConditionOptionsRequest:
    "getLockerStructureConditionOptions/Request",
  getLockerStructureConditionOptionsSuccess:
    "getLockerStructureConditionOptions/Success",
  getLockerStructureConditionOptionsError:
    "getLockerStructureConditionOptions/Error"
};

const INITAL_STATE = {
  data: [],
  loading: false,
  error: null,
  total: null,
  perPage: null,
  lastPage: null,
  page: null
};

export default function lockerStructureOptions(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.getLockerStructureConditionOptionsRequest:
      return {
        ...state,
        loading: true,
        data: INITAL_STATE.data
      };

    case Types.getLockerStructureConditionOptionsSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case Types.getLockerStructureConditionOptionsError:
      return {
        ...state,
        loading: false,
        data: INITAL_STATE.data
      };

    default:
      return state;
  }
}

export const Actions = {
  getLockerStructureConditionOptionsRequest: payload => ({
    type: Types.getLockerStructureConditionOptionsRequest,
    payload
  }),

  getLockerStructureConditionOptionsSuccess: payload => ({
    type: Types.getLockerStructureConditionOptionsSuccess,
    payload
  }),

  getLockerStructureConditionOptionsError: error => ({
    type: Types.getLockerStructureConditionOptionsError,
    error
  })
};
