import React from "react";

import LogoImage from "../../assets/Lockup_Handover.svg";

import { Container, Header, Logo, TextError, Empty } from "./styles";
import QRCode from "react-qr-code";

export default function({
  match: {
    params: { token }
  }
}) {
  return (
    <Container>
      <Header>
        <Logo src={LogoImage} alt="logo-handover" loading="lazy" />
      </Header>
      {token ? (
        <>
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: 300,
              width: "100%"
            }}
          >
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={token}
              viewBox={`0 0 256 256`}
            />
          </div>
        </>
      ) : (
        <Empty>
          <TextError>Token inválido</TextError>
        </Empty>
      )}
    </Container>
  );
}
