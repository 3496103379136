export const Types = {
  getPaintConditionOptionsRequest: "getPaintConditionOptions/Request",
  getPaintConditionOptionsSuccess: "getPaintConditionOptions/Success",
  getPaintConditionOptionsError: "getPaintConditionOptions/Error"
};

const INITAL_STATE = {
  data: [],
  loading: false,
  error: null,
  total: null,
  perPage: null,
  lastPage: null,
  page: null
};

export default function paintOptions(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.getPaintConditionOptionsRequest:
      return {
        ...state,
        loading: true,
        data: INITAL_STATE.data
      };

    case Types.getPaintConditionOptionsSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case Types.getPaintConditionOptionsError:
      return {
        ...state,
        loading: false,
        data: INITAL_STATE.data
      };

    default:
      return state;
  }
}

export const Actions = {
  getPaintConditionOptionsRequest: payload => ({
    type: Types.getPaintConditionOptionsRequest,
    payload
  }),

  getPaintConditionOptionsSuccess: payload => ({
    type: Types.getPaintConditionOptionsSuccess,
    payload
  }),

  getPaintConditionOptionsError: error => ({
    type: Types.getPaintConditionOptionsError,
    error
  })
};
