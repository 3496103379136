import { call, put } from "redux-saga/effects";
import history from "../../routes/history";
import api from "../../services/api";

import { Actions as ThingTypeActions } from "../ducks/thingTypes";
import { Actions as ConfigActions } from "../ducks/configs";
import { constants } from "../../config";

export function* thingTypesRequest(action) {
  try {
    const response = yield call(api.get, "/v1/adm/thing/types");

    yield put(ThingTypeActions.thingTypesSuccess(response.data));
  } catch (err) {
    yield put(ThingTypeActions.thingTypesError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}

export function* showThingTypeRequest(action) {
  try {
    const response = yield call(
      api.get,
      `/v1/adm/thing/types/${action.payload.id}`
    );

    yield put(ThingTypeActions.showThingTypeSuccess(response.data));
  } catch (err) {
    yield put(ThingTypeActions.showThingTypeError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}

export function* updateThingTypeRequest(action) {
  try {
    const response = yield call(
      api.put,
      `/v1/adm/thing/types/${action.payload.data.id}`,
      action.payload.data
    );

    yield put(ThingTypeActions.updateThingTypeSuccess(response.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Tipo atualizado",
        toastVariant: constants.toastVariants.success
      })
    );
  } catch (err) {
    yield put(ThingTypeActions.updateThingTypeError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}

export function* createThingTypeRequest(action) {
  try {
    const response = yield call(
      api.post,
      "/v1/adm/thing/types",
      action.payload.data
    );

    history.push(`/thing/types/edit/${response.data.id}`);

    yield put(ThingTypeActions.createThingTypeSuccess(response.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Tipo criado",
        toastVariant: constants.toastVariants.success
      })
    );
  } catch (err) {
    yield put(ThingTypeActions.createThingTypeError(err.data));
    yield put(
      ConfigActions.setToastVisible({
        toastVisible: true,
        toastMessage: "Erro geral",
        toastVariant: constants.toastVariants.error
      })
    );
  }
}
