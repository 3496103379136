import React, { useState, useEffect, forwardRef, useCallback } from "react";

import MaterialTable from "material-table";
import { Check } from "@material-ui/icons";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  Cancel as CancelIcon,
  Save as SaveIcon,
  DeleteForever
} from "@material-ui/icons";

// Global
import Container from "../../components/Container";

// Material
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, MenuItem, TextField } from "@material-ui/core";

// Redux
import api from "../../services/api";
import Spinner from "../../components/Spinner";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import { materialStyle } from "../../styles";
import EditorCurrentRegisterContainer from "../../components/EditorCurrentRegisterContainer";
import ButtonEditorCurrentRegister from "../../components/ButtonEditorCurrentRegister";
import Snackbar from "../../components/Snackbar";
import constants from "../../config/constants";
import history from "../../routes/history";
import ModalEdit from "../../components/ModalEdit";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function IntegrationDataTeamEdit({
  classes,
  match: {
    params: { id }
  }
}) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [editable, setEditable] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: constants.snackbarVariants.SUCCESS
  });

  useEffect(() => {
    const fetchData = async function() {
      try {
        const response = await api.get(`/v1/adm/integration/teams/${id}`);

        setData(response.data);
      } catch (error) {
        setSnackbar(old => ({
          ...old,
          open: true,
          message: "Erro ao obter dados",
          variant: constants.snackbarVariants.ERROR
        }));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const update = useCallback(
    async () => {
      try {
        setLoading(true);

        await api.put(`/v1/adm/integration/teams/${id}`, data);

        setEditable(true);
        setSnackbar(old => ({
          ...old,
          open: true,
          message: "Atualizado"
        }));
      } catch (err) {
        setSnackbar(old => ({
          ...old,
          open: true,
          message: "Erro ao atualizar",
          variant: constants.snackbarVariants.ERROR
        }));
      } finally {
        setLoading(false);
      }
    },
    [data]
  );

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      await api.delete(`/v1/adm/integration/teams/${id}`);

      history.push("/integrations");
    } catch (err) {
      setSnackbar(old => ({
        ...old,
        open: true,
        message: "Erro ao deletar",
        variant: constants.snackbarVariants.ERROR
      }));
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Container title={""}>
      <Snackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() =>
          setSnackbar(old => ({
            ...old,
            open: false
          }))
        }
        variant={snackbar.variant}
      />
      <ModalEdit
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        onConfirm={() => {
          handleDelete();
        }}
        classes={classes}
        loading={loading}
        title={"Confirme a ação"}
        subTitle="Deseja realmente excluir esta integração?"
        labelConfirmButton="Excluir"
      />
      <ThemeTextFildGreen>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <EditorCurrentRegisterContainer
              disabled={loading || !editable}
              flexDirection="row-reverse"
            >
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                spacing={2}
              >
                <ButtonEditorCurrentRegister
                  label="Editar"
                  onClick={() => {
                    console.log("show edit");
                    setEditable(false);
                  }}
                />
              </Grid>
            </EditorCurrentRegisterContainer>
            <h5 className={classes.subTitle}>
              Dados para chamada de API externa
            </h5>

            {data && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                    <TextField
                      id="outlined-name"
                      label="URL"
                      className={classes.textField}
                      value={data.url}
                      margin="normal"
                      variant="outlined"
                      disabled={editable}
                      onChange={e =>
                        setData(oldState => ({
                          ...oldState,
                          url: e.target.value
                        }))
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={6} className="form-colaboradore-sm">
                    <TextField
                      select
                      disabled={editable}
                      className={classes.textFieldLocker}
                      value={data.whenCall}
                      onChange={e =>
                        setData(oldState => ({
                          ...oldState,
                          whenCall: e.target.value
                        }))
                      }
                      label="Quando ativada?"
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin="normal"
                      variant="outlined"
                    >
                      {["create", "create_collect"].map(status => (
                        <MenuItem key={status} value={status}>
                          {status === "create"
                            ? "Nova entrega"
                            : "Nova entrega e quando retirada"}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <TextField
                      id="outlined-name"
                      label="Método HTTP"
                      className={classes.textField}
                      value={"POST"}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>
                </Grid>

                <MaterialTable
                  icons={tableIcons}
                  disabled={loading}
                  title="Headers"
                  columns={[
                    {
                      title: "Chave",
                      field: "name",
                      emptyValue: false
                    },
                    {
                      title: "Valor",
                      field: "value",
                      emptyValue: false
                    }
                  ]}
                  data={Object.keys(JSON.parse(data.headers)).map(item => {
                    const _item = {
                      id: Math.random(),
                      name: item,
                      value: JSON.parse(data.headers)[item]
                    };
                    return _item;
                  })}
                  localization={{
                    body: {
                      editRow: {
                        deleteText: "Deseja excluir este endereço?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Salvar"
                      },
                      addTooltip: "Adicionar",
                      deleteTooltip: "Deletar",
                      editTooltip: "Editar",
                      emptyDataSourceMessage: "Nenhum cabeçalho adicionado"
                    },
                    header: {
                      actions: ""
                    }
                  }}
                  editable={{
                    isEditable: () => !editable, // Condição para edição
                    isDeletable: () => !editable,
                    onRowDelete: oldData =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          setData(
                            data.filter(item => item._id !== oldData._id)
                          );
                          resolve();
                        }, 1);
                      }),
                    onRowAdd: newData =>
                      new Promise(resolve => {
                        newData._id = Math.random();

                        setData([...data, newData]);

                        resolve();
                      }, 1),
                    onRowUpdate: (newData, oldData) =>
                      new Promise(resolve => {
                        setTimeout(() => {
                          const parsed = JSON.parse(data.headers);

                          if (newData.name !== oldData.name) {
                            delete parsed[oldData.name];
                          }

                          Object.assign(parsed, {
                            [newData.name]: newData.value
                          });
                          setData(oldState => ({
                            ...oldState,
                            headers: JSON.stringify(parsed)
                          }));
                          console.log({
                            oldData,
                            newData,
                            parsed
                          });
                          resolve();
                        }, 1);
                      })
                  }}
                  options={{
                    paging: false,
                    search: false,
                    actionsColumnIndex: -1,
                    toolbarButtonAlignment: "left"
                  }}
                />

                {!editable && (
                  <>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={update}
                      disabled={loading}
                    >
                      <SaveIcon className={classes.leftIcon} />
                      Salvar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setModalVisible(true)}
                      disabled={loading}
                      className={classes.button}
                    >
                      <DeleteForever className={classes.leftIcon} />
                      Deletar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setEditable(true)}
                      disabled={loading}
                      className={classes.button}
                    >
                      <CancelIcon className={classes.leftIcon} />
                      Cancelar
                    </Button>
                  </>
                )}
              </>
            )}
          </>
        )}
      </ThemeTextFildGreen>
    </Container>
  );
}

export default withStyles(materialStyle)(IntegrationDataTeamEdit);
