import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Edit";
import FavoriteIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import AdmIcon from "@material-ui/icons/Notes";
import TimelineIcon from "@material-ui/icons/Timeline";
import EmailIcon from "@material-ui/icons/Email";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  tabs: {
    [theme.breakpoints.down("xs")]: {
      width: "92vw"
    }
  },
  containerView: {
    [theme.breakpoints.down("xs")]: {
      "div:nth-child(0)": {
        padding: "0"
      }
    }
  },
  lowercaseLabel: {
    textTransform: "lowercase" // Isso transformará o texto em minúsculas
  }
}));

function ScrollableTabsButtonForce({ view1, view2, view3, view4, view5 }) {
  const { t } = useTranslation("delivery");
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#006660"
      },
      secondary: {
        main: "#006660"
      }
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
            className={classes.tabs}
          >
            <Tab
              className={classes.lowercaseLabel}
              label={t("view.updates_tab")}
              icon={<PhoneIcon />}
              {...a11yProps(0)}
            />
            <Tab
              className={classes.lowercaseLabel}
              label={t("view.ports_tab")}
              icon={<FavoriteIcon />}
              {...a11yProps(1)}
            />
            <Tab
              className={classes.lowercaseLabel}
              label={t("view.items_tab")}
              icon={<AdmIcon />}
              {...a11yProps(2)}
            />
            <Tab
              className={classes.lowercaseLabel}
              label={t("view.emails_tab")}
              icon={<EmailIcon />}
              {...a11yProps(3)}
            />
            <Tab
              className={classes.lowercaseLabel}
              label={t("view.log_tab")}
              icon={<TimelineIcon />}
              {...a11yProps(4)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} className={classes.containerView}>
          {view1}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {view2}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {view3}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {view4}
        </TabPanel>
        <TabPanel value={value} index={4}>
          {view5}
        </TabPanel>
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollableTabsButtonForce);
