import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Stepper from "../Stepper";

import api from "../../../../services/api";

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    width: "100%",
    height: "100%"
  }
});

export default function BasicTable({ lockers }) {
  const classes = useStyles();
  const [deploymentStatus, setDeploymentStatus] = useState(null);

  useEffect(() => {
    try {
      async function fetch() {
        const deploymentStatusData = await api.get(
          "/v1/adm/lockerDeployStatus"
        );
        setDeploymentStatus(deploymentStatusData.data);
      }

      fetch();
    } catch (err) {
      console.log("error load status");
      console.log(err);
    }
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <p style={{color: "#006660", fontSize: "18px", margin: 0, padding: 0}} >
                endereço do locker
              </p>
            </TableCell>

            <TableCell align="center">
              <p style={{color: "#006660", fontSize: "18px", margin: 0, padding: 0}} >
                status de implantação
              </p>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lockers.map(row => (
            <TableRow key={row.id}>
              <TableCell align="left">
                <p style={{ fontSize: 14, color:"#1b301b" }}>{row.address}</p>
              </TableCell>

              <TableCell align="left">
                {row.deployStatusNow && (
                  <Stepper
                    position={row.deployStatusNow.position}
                    data={JSON.parse(row.deployStatus)}
                    deploymentStatus={deploymentStatus}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
