export default {
  appbar: {
    my_profile: "My profile",
    create_delivery: "create delivery",
    logout: "Logout"
  },
  menu_list_items: {
    manage_deliveries: "deliveries",
    reports: "reports",
    laundry_items: "laundry items",
    laundry_clients: "laundry clients",
    locker_users: "locker users",
    lockers: "lockers",
    lockers_management: "manage internal lockers",
    rental: "reports",
    b2c: "b2c",
    income_statement: "income statement",
    coupons_usage: "cupons usage",
    zap: "zap usage",
    feedback: "clients feedback",
    tickets: "tickets validation",
    config: "configuration",
    units: "units",
    tools: "tools",
    knowledge_base: "knowledge base",
    support: "support chat",
    business: "organization",
    people: "staff",
    integrations: "integrations",
    tools_email: "send emails"
  },
  delivery_status: {
    waiting_to_deposit: "Waiting to receive",
    waiting_to_collect: "Waiting collect",
    finished: "Finished",
    waiting_to_return: "Waiting to return",
    returned_delivery: "Returned delivery",
    canceled: "Canceled",
    finished_by_manager: "Finished by manager"
  },
  table_actions: {
    view: "view",
    edit: "edit",
    finish: "finish"
  },
  login: {
    login_title: "Organization Access",
    forgot_password: "Forgot password?",
    password_field_label: "Password",
    error: "invalid email or password",
    submit: "Enter",
    updated_password: "Updated password!"
  },
  language_selector: {
    label: "Select Language"
  },
  forgot_password: {
    header: "Organization",
    title: "Reset password",
    subtitle:
      "Use your corporte e-mail to get instructions on how to generate a new password.",
    success_message: "Recovery email sent to",
    fail_message: "Sorry, failed to send message to",
    dismiss_btn: "Close",
    submit_btn: "Send",
    captcha_message: 'Validate: "I\'m not a robot""',
    loading_message: "Sending...",
    field_placeholder: "Email"
  },
  home: {
    title: "Delivery data",
    deliveries: "This month deliveries",
    waiting_deposit: "Waiting to receive",
    every_month: "all the months",
    waiting_collect: "Waiting to collect",
    waiting_to_finish: "Waiting to finish",
    finished: "Finished",
    performance_chart_title: "Deliveries performance this month",
    performance_chart_label: "total by day"
  },
  delivery: {
    title: "Use the filters below to search",
    delivery_number: "Delivery number",
    client: "Client",
    locker: "Locker",
    port: "Port",
    status: "Status",
    unit: "Unit",
    block: "Block",
    apartment: "Apartment",
    resident: "Resident",
    start_date: "Initial date",
    end_date: "End date",
    start_date_placeholder: "Delivery initial date",
    end_date_placeholder: "Delivery end date",
    search: "search",
    clear: "clear",
    expired: "expired",
    createTitle: "Create a delivery",
    deliveryInfo: "Delivery info",
    orderNumber: "Delivery number",
    accessCode: "Access code",
    establishment: "Establishment",
    bloc: "Square/Tower",
    clientName: "Client name",
    clientLastName: "Client last name",
    clientNumber: "Client number",
    clientEmail: "Client email",
    doorSize: "Door size",
    returnQuestion: "Returning a product?",
    depositee: "Who is delivering?",

    table: {
      id: "delivery",
      user: "user",
      createdAt: "created at",
      lockerAddress: "locker",
      unit: "unit",
      compartmentNumber: "port",
      status: "status",
      client: "client",
      finished: "finished",
      secretCode: "access code",
      note: "client experience",
      remaining_time: "remain time to colect",
      actions: "actions",
      subTeam: "main unit",
      subTeamCondominium: "condo",
      subTeamUnitsCondominium: "block",
      subTeamSubUnitsCondominium: "apartment",
      subTeamSubUnitsContactCondominium: "resident",
      lines_per_page: "Lines per page",
      of: "of",
      dialogButton: "Finish delivery",
      dialogTitle: "Attention",
      dialogMessage:
        "Ensure that all items inside the locker has been removed.",
      dialogSubMessage: "The notification will be sent through SMS.",
      dialogFinalMessage: "Are you sure you want to finish the delivery?"
    },
    usageTable: {
      client: "client",
      period: "period",
      total: "total"
    },
    view: {
      updates_tab: "delivery updates",
      ports_tab: "ports openings",
      items_tab: "items records",
      emails_tab: "emails sent",
      log_tab: "Logs",
      item: "item",
      quantity: "quantity"
    },
    updates: {
      user: "USER",
      date: "DATE",
      type: "TYPE",
      reason: "REASON"
    },
    emails: {
      user: "user",
      date: "date",
      type: "email type",
      status: "status"
    },
    logs: {
      delivery: "delivery",
      code: "log code",
      description: "description",
      block: "block",
      apartment: "apartment",
      resident: "resident",
      locker: "locker",
      door: "door",
      date: "date",
      openBlock: "Open block"
    }
  },
  reports: {
    filters: {
      period: "Month",
      select_locker: "Select a locker",
      place: "Place",
      search: "search",
      clear: "clear",
      export: "export to file"
    },
    metrics: {
      total: "Total",
      waiting_to_deposit: "Waiting to receive",
      waiting_to_collect: "Waiting collect",
      waiting_to_return: "Waiting return",
      returned_order: "Returned Order",
      finished: "Finished"
    },
    table: {
      delivery: "delivery",
      access_code: "access code",
      user: "user",
      main_unit: "main unit",
      unit: "unit",
      subTeamUnitsCondominium: "block",
      created_at: "created at",
      locker: "locker",
      door: "door",
      status: "status",
      lines_per_page: "Lines per page",
      of: "of"
    }
  },
  lockers: {
    filters: {
      client: "Client",
      locker: "Locker",
      search: "search",
      clear: "clear"
    },
    table: {
      address: "address",
      client: "client",
      status: "status",
      last_login: "last login",
      actions: "actions",
      manage: "manage",
      edit: "edit implantation status"
    },
    manage: {
      info: "locker informations",
      doors: "doors",
      log: "log",
      manage: "manage",
      camera: "camera",
      alarm: "alarm",
      open_all: "Open all locker doors",
      open_all_title: "Attention",
      open_all_message: "Do you really want to open all locker doors?",
      open_all_confirm: "Yes",
      open_all_dismiss: "Cancel"
    },
    info: {
      general: "general info",
      address: "Address",
      contact: "contact info at the locker location",
      name: "Name",
      phone: "Phone",
      hub: "Hub",
      access: "local access information",
      working_hours: "Working hours",
      access_place: "Local access"
    },
    doors_table: {
      lid: "lid",
      door: "door",
      status: "status",
      dimensions: "dimensions",
      actions: "actions",
      open_action: "Open door",
      edit_action: "Edit",
      attention: "Attention",
      confirm: "Open",
      confirm_message: "Do you really want to open the door?",
      cancel: "Cancel"
    },
    edit: {
      attach_door: "Attach door to a",
      door_number: "Door number",
      door_size: "Size",
      door_availability: "Availability",
      edit: "Edit",
      save: "Save",
      cancel: "Cancel",
      open: "open door"
    }
  },

  config: {
    business: {
      title: "Organization information",
      address: "Address",
      missing_info: "Missing information",
      accountable_info: "Accountable information",
      updatePwdError: "Error: could not update password!",
      newPwd: "New password",
      confirmPwd: "Confirm password",
      loading: "Loading...",
      update: "Update"
    },
    staff: {
      email: "email",
      document: "document (cpf)",
      search: "search",
      clear: "clear",
      add: "add staff member",
      name: "name",
      phone: "Phone number",
      store: "place",
      status: "status",
      actions: "actions",
      business: "organization",
      role: "role",
      title: "edit profile",
      rules_title: "usage rules",
      rule: "rules",
      change_password: "change password",
      login_field: "Login field",
      login_info: "Login informations",
      access_place: "Access place",
      type: "Type",
      create_action: "Create"
    },
    integrations: {
      active_integration: "Active Integration",
      custom_model: "Custom model",
      integrations_module: "Smartlocker integration module",
      create: "Create new Integration",
      integration_name: "Integration name",
      contact_data: "Contact data",
      dev_name: "Developer name",
      contact_label: "Contact",
      email: "Email",
      integration_data: "Integration data",
      token: "Token",
      api_functions: "API functions",
      api_functions_opt1: "Resident management (create, edit and disable)",
      view_header_message: "External API to access Handover API",
      documentation: "Documentation",
      remove_integration: "remove integration",
      remove_integration_confirmation:
        "Do you really want to remove the integration:",
      create_webhook: "create webhook",
      webhook_subtitle: "Fill the fields below to activate your webhook",
      webhook_url: "URL",
      webhook_method_label: "HTTP method",
      webhook_api_functions: "Handover API Functions (via webhook)",
      headers_key: "Key",
      headers_value: "Value",
      remove_header_message: "Do you want to remove this header?",
      empty_headers_message: "No headers added"
    }
  },
  condominium: {
    data_batch: "data batch"
  },
  subteamsubunitscontact: {
    create: {
      title: "Create a resident",
      name: "Resident name"
    },
    edit: {
      name: "Resident name",
      phone: "Resident phone",
      slack_id: "Slack member id"
    }
  },
  data_list: {
    feature: "Feature",
    instructions:
      "This tool helps you to create lists. You can use .xlsx files to load batch of entries.",
    create: "Create data list",
    total: "Total",
    error: "One or more items has errors. Check the table please",
    slack_id: "Slack ID",
    load_file_btn: "Load XLSX file"
  },

  modal: {
    create_resident_title: "Create a resident",
    create_resident_subtitle: "Fill the resident data below",
    create_subtitle: "Fill the info to create a",
    create_subteamsubunit_subtitle: "Fill the form with data for"
  },
  appRoles: {
    colectAccount: "Colect account",
    deliveryAccount: "Delivery account",
    mixed: "Mixed"
  },
  common: {
    all: "All",
    none: "none",
    permissions: "permissions",
    locker: "locker",
    multiemail_helper_text:
      "Type the email and press ENTER or space. Max 20 emails.",
    notification_email: "Notifications email.",
    contact_phone: "Contact phone",
    max_emails_reached: "Max of 20 emails reached",
    name: "Name",
    name_or_id: "Name or identifier of",
    company_registry: "Company registry",
    email: "email",
    phont: "phone",
    optional: "optional",
    status: "status",
    unit: "Unit",
    block: "Block",
    department: "Departament",
    apartment: "Apartment",
    resident: "Resident",
    subTeamSubUnitsContact: "addressee",
    actions: "actions",
    not_found_user: "User not found",
    language_selector: "Select Language",
    view: "view",
    search: "search",
    search_clear: "clear search",
    clear: "clear",
    save: "save",
    update: "update",
    create_a: "create a",
    create: "create",
    dismiss: "cancel",
    edit: "edit",
    back: "back",
    add: "add",
    remove: "delete",
    lines_per_page: "Rows per page",
    of: "of",
    password: "Password",
    password_confirmation: "Password confirmation",
    invalid_email: "Email inválido",
    coppied_message: "Valor copiado!",
    enabled: "enabled",
    disabled: "disabled",
    enable: "enable",
    disable: "disable",
    next: "next",
    loadingMsg: "Loading...",
    noInfo: "Empty table",
    cellphone: "phone",
    document: "document (cpf)",
    client: "client",
    door: "door",
    hours: "hours",
    date: "date",
    finishedAt: "finished at",
    user: "user",
    description: "description",
    total: "total",
    updated: "updated",
    zipcode: "zipcode",
    type: "type",
    fillEveryField: "Preencha todos os campos",
    select: "Select",
    info: "Informations"
  },
  camera: {
    error: "Error while getting camera images",
    viewCamera: "View camera",
    openCamera: "Open Camera",
    close: "Close"
  },
  customCancelDelivery: {
    openDoor: "Open door",
    dismiss: "no"
  },
  customerFeedbackTable: {
    bad: "Bad",
    good: "Good",
    great: "Great",
    operationType: "operation type",
    delivery: "delivery",
    deliveredStatus: "Delivered",
    returnedStatus: "Returned",
    title: "client feedback",
    startDate: "start date",
    endDate: "end date",
    feedback: "feedback"
  },
  password: {
    distinctPwd: "Passwords are not equal!",
    wrongPwd: "Wrong password! Check your data",
    modalTitle: "Change password",
    rulesMsg: "Your password must:",
    rule1: "Have 7 characters length.",
    rule2: "Have numbers and letter.",
    rule3: "At least one upper case character and one lower case.",
    oldPwd: "Old password",
    newPwd: "New password",
    confirmPwd: "Confirm password",
    changePwd: "Change the password"
  },
  laundry: {
    max: "max by order",
    maxByOrder: "max by order",
    registrationOrigin: "registration source",
    registrationDate: "registration date"
  },
  locker: {
    pwd: "password",
    creationDate: "creation date",
    expirationDate: "expiration date",
    createTitle: "Create a locker",
    availability: "Availability",
    type: "type",
    capacity: "capacity",
    infoTitle: "Locker system information",
    url: "locker url",
    email: "locker email",
    password: "locker password",
    cameraUrl: "camera url",
    cameraEmail: "camera email",
    cameraPassword: "camera password",
    contactName: "contato name",
    contactPhone: "contato phone",
    contactEmail: "contato email"
  },
  order: {
    renter: "renter",
    startDate: "start date"
  },
  team: {
    doorOpen: "OPEN DOORS",
    deliveries: "DELIVERIES",
    timeLimit: "Limit of time to take the products",
    timeInHours: "Time in hours",
    adminEmail: "Admin's email",
    integrations: "integrations",
    noLimit: "0 = NO TIME LIMIT",
    timeLimitLocation: "Limit of time to rent in delivery status",
    generalParams: "general parameters",
    admin: "Admin",
    city: "City",
    state: "State",
    country: "Country",
    address: "Address",
    addressLine1: "Address",
    addressLine2: "Address (line 2)",
    searchZip: "search zipcode",
    number: "number",
    formalName: "Company name",
    brandName: "Brand name",
    contract: "Contract"
  },
  spending: {
    initialDate: "initial date",
    rentalTime: "rental time",
    bonus: "bonus usage",
    wallet: "wallet usage",
    coupons: "coupons usage",
    value: "value taken from wallet",
    debit: "client debit",
    fee: "fees",
    income: "income"
  },
  credit: {
    value: "value",
    coupom: "coupom"
  },
  errors: {
    zipcodeNotFound: "Zipcode not found",
    userNotFound: "User not found",
    emptyField: "Empty fields!",
    error: "Error",
    invalidPhone: "Invalid phone number",
    invalidAccessCode: "Invalid access code",
    invalidEmail: "Invalid email format"
  },
  thing: {
    createThingType: "Crete thing type",
    thingsType: "Type of things",
    yes: "yes",
    no: "no",
    occupied: "occupied",
    free: "free"
  },
  roles: {
    createTitle: "Create new profile rule",
    ruleName: "New rule",
    createBtn: "Crreate new rule",
    editTitle: "Edit profile rule",
    listTitle: "Current rules",
    newRule: "New rule",
    rules: "rules",
    admin: "Admin"
  },
  business: {
    usersTitle: "Organization users",
    createUser: "Create user"
  },
  compartment: {
    createTitle: "Create door",
    doorNumber: "door number",
    dimensions: "dimensions",
    editTitle: "edit door"
  },
  notFound: {
    title: "page not found",
    btn: "back to home"
  },
  profile: {
    title: "Update profile"
  },
  subteamssubunit: {
    editMessage: "Fill the updated data below",
    changestatusMessage: "Do you want to",
    named: "named"
  },
  signup: {
    mainTitle: "Create your handover organization access",
    sideTitle:
      "Create you access to Handover's organization management platform",
    title: "Fill your organization data",
    companyName: "Company name",
    brandName: "Brand name",
    email: "Organization email",
    emailWarning: "Use you organization email. Do not use your personal email.",
    neighborhood: "neighborhood",
    street: "Street",
    addressComplement: "Address complement",
    useTerm: "Use term",
    cookies: "Cookies policy",
    privacy: "Privacy policy",
    step2Title: "Fill with your manager information",
    completeName: "Full name",
    managerDocument: "Manager document",
    managerBirthdayDate: "Birthdate",
    managerRequirement: "You need to be older than 18",
    step3Title: "access data",
    managerEmail: "Manager email",
    emailDescription: "This email will be used to access your account.",
    passwordInstruction: "The password need to have more than 8 characters",
    checkFields: "Check if your data is correct!",
    humanCheck: "Don't forget to confirm you're not a robot.",
    successMsg: "Your organization account was created.",
    successMsg2: "You'll receive an email confirming you access in 48 hours.",
    prev: "Previous",
    finish: "Finish"
  }
};
