import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AppBar from "../AppBar";

import { Actions as TeamsActions } from "../../store/ducks/teams";
// import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
// import { Fab } from "@material-ui/core";
// import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import { materialStyle } from "../../styles";
// import constants from "../../config/constants";

class Container extends Component {
  componentWillMount() {
    const { setLocalTeam, teamsRequest, setCurrentTeamRequest } = this.props;
    setLocalTeam();
    teamsRequest("perPage=1000");
    setCurrentTeamRequest();
  }
  render() {
    const { children, title } = this.props;
    return (
      <AppBar title={""}>
        {/* <ThemeTextFildGreen>
          <Fab
            size="small"
            className={this.props.classes.fab}
            color="primary"
            onClick={() => {
              window.open(constants.ticketURL, "_blank", "noopener,noreferrer");
            }}
          >
            <ContactSupportIcon style={{ fontSize: 30 }} />
          </Fab>
        </ThemeTextFildGreen> */}
        {children}
      </AppBar>
    );
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...TeamsActions
    },
    dispatch
  );
export default connect(
  null,
  mapDispatchToProps
)(withStyles(materialStyle)(Container));
