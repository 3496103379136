import React, { Component } from "react";
import PropTypes from "prop-types";

// Material
import {
  Button,
  TextField,
  CircularProgress,
  Grid,
  Typography,
  Box
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

// Global Components
import BusinessSimpleTable from "../../components/BusinessSimpleTable";
import Container from "../../components/Container";
import Snackbar from "../../components/Snackbar";
import TextMaskCustomCPF from "../../components/TextMaskCustomCPF";
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as BusinessActions } from "../../store/ducks/business";
import { Actions as TeamsActions } from "../../store/ducks/teams";

import history from "../../routes/history";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import FilterTitle from "../../components/FilterTitle";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3} className="box-tab-teams">
        {children}
      </Box>
    </Typography>
  );
}

class Business extends Component {
  static propTypes = {
    getBusinessRequest: PropTypes.func.isRequired
  };

  async componentWillMount() {
    const { getBusinessRequest } = this.props;

    const idTeam = await JSON.parse(localStorage.getItem("auth")).currentTeam
      .id;
    this.setState({ idTeam });
    getBusinessRequest(idTeam);
  }

  state = {
    email: "",
    value: 0,
    cpf: "",
    idTeam: ""
  };

  t = this.props.t;

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleChangeValue = (event, newValue) => {
    this.setState({ value: newValue });
  };

  clearSearchUser = () => {
    const { getBusinessRequest } = this.props;
    const { idTeam } = this.state;

    getBusinessRequest(`idTeam=${idTeam}`);
    this.setState({ email: "", cpf: "" });
  };

  handleSearchUser = () => {
    const { email, cpf } = this.state;
    const { getBusinessRequest } = this.props;
    const { idTeam } = this.state;

    const cpfValido = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/;

    if (!email && !cpf) return;

    if (cpf && !cpfValido.test(cpf)) return;

    getBusinessRequest(
      `idTeam=${idTeam}&email=${email}&cpf=${cpf
        .replace(".", "")
        .replace("-", "")
        .replace(".", "")}`
    );
  };

  render() {
    const { classes, business, setSnackbarVisible } = this.props;
    const { idTeam, email, cpf } = this.state;

    return (
      <Container title="colaboradores">
        <ThemeTextFildGreen>
          {business.loading ? (
            <CircularProgress />
          ) : (
            <>
              <TabPanel
                value={this.state.value}
                index={0}
                style={{ padding: 0, margin: 0 }}
              >
                <FilterTitle />
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label={this.t("staff.email")}
                      className={classes.textField}
                      value={this.state.email}
                      onChange={this.handleChange("email")}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className="form-colaboradore-sm">
                    <TextField
                      id="outlined-name"
                      label={this.t("staff.document")}
                      className={classes.textField}
                      value={this.state.cpf}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        inputComponent: TextMaskCustomCPF
                      }}
                      onChange={this.handleChange("cpf")}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      className={classes.button}
                      onClick={this.handleSearchUser}
                      variant="contained"
                      disabled={!email && !cpf}
                      style={{
                        backgroundColor: "#006660",
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "lowercase",
                        height: 35
                      }}
                    >
                      {business.loading ? <CircularProgress /> : this.t("staff.search")}
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#006660",
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "lowercase",
                        height: 35
                      }}
                      className={classes.button}
                      onClick={this.clearSearchUser}
                      variant="contained"
                      disabled={!email && !cpf}
                    >
                      {business.loading ? (
                        <CircularProgress />
                      ) : (
                        this.t("staff.clear")
                      )}
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={() => history.push("/business/create")}
                      variant="contained"
                      style={{
                        backgroundColor: "#006660",
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "lowercase",
                        height: 35
                      }}
                    >
                      {business.loading ? (
                        <CircularProgress />
                      ) : (
                        this.t("staff.add")
                      )}
                    </Button>
                  </Grid>
                </Grid>
                <BusinessSimpleTable history={history} idTeam={idTeam} />
              </TabPanel>

              <Snackbar
                open={business.snackbarVisible}
                variant="warning"
                onClose={setSnackbarVisible}
                message={this.t('error:userNotFound')}
              />
            </>
          )}
        </ThemeTextFildGreen>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  business: state.business
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...BusinessActions,
      ...TeamsActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("config")(withStyles(materialStyle)(Business)));
