export const Types = {
  externalProfileRequest: "externalProfile/Request",
  externalProfileSuccess: "externalProfile/Success",
  externalProfileError: "externalProfile/Error",

  externalProfileShowRequest: "externalProfileShow/Request",
  externalProfileShowSuccess: "externalProfileShow/Success",
  externalProfileShowError: "externalProfileShow/Error",

  externalProfileUpdateRequest: "externalProfileUpdate/Request",
  externalProfileUpdateSuccess: "externalProfileUpdate/Success",
  externalProfileUpdateError: "externalProfileUpdate/Error"
};

const INITAL_STATE = {
  data: [],
  total: 0,
  perPage: 20,
  page: 1,
  lastPage: 1,
  currentExternalProfile: null
};

export default function externalProfile(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.externalProfileRequest:
      return {
        ...state,
        currentExternalProfile: null,
        loading: true,
        error: null
      };

    case Types.externalProfileSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload.data
      };

    case Types.externalProfileError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.externalProfileShowRequest:
      return {
        ...state,
        currentExternalProfile: null,
        loading: true,
        error: null
      };

    case Types.externalProfileShowSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        currentExternalProfile: action.payload.data
      };

    case Types.externalProfileShowError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    case Types.externalProfileUpdateRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.externalProfileUpdateSuccess:
      return {
        ...state,
        loading: false,
        error: null,
        currentExternalProfile: action.payload.data
      };

    case Types.externalProfileUpdateError:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };

    default:
      return state;
  }
}

export const Actions = {
  externalProfileRequest: data => ({
    type: Types.externalProfileRequest,
    payload: {
      data
    }
  }),

  externalProfileSuccess: data => ({
    type: Types.externalProfileSuccess,
    payload: { data }
  }),

  externalProfileError: error => ({
    type: Types.externalProfileError,
    payload: { error }
  }),

  externalProfileShowRequest: id => ({
    type: Types.externalProfileShowRequest,
    payload: {
      id
    }
  }),

  externalProfileShowSuccess: data => ({
    type: Types.externalProfileShowSuccess,
    payload: { data }
  }),

  externalProfileShowError: error => ({
    type: Types.externalProfileShowError,
    payload: { error }
  }),

  externalProfileUpdateRequest: (id, data) => ({
    type: Types.externalProfileUpdateRequest,
    payload: {
      id,
      data
    }
  }),

  externalProfileUpdateSuccess: data => ({
    type: Types.externalProfileUpdateSuccess,
    payload: { data }
  }),

  externalProfileUpdateError: error => ({
    type: Types.externalProfileUpdateError,
    payload: { error }
  })
};
