import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import history from "../../routes/history";

export default function CustomizedDialogs({ data, updateNotification }) {
  const handleClickOpen = data => {
    if (data.readed === false) {
      updateNotification({ id: data._id });
    }

    if (data.notification.link) {
      history.push(data.notification.link);
    }
  };

  return (
    <>
      <ListItem
        button
        onClick={() => handleClickOpen(data)}
        alignItems="flex-start"
        style={{
          display: "flex",
          flexDirection: "column",
          borderBottom: "1px solid rgba(0, 0, 0, .1)",
          padding: 5
        }}
      >
        <ListItemText
          style={{
            width: "100%"
          }}
          primary={
            <Typography
              component="span"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 5
              }}
            >
              {data.notification.title}
              <div
                style={{
                  display: `${!data.readed ? "block" : "none"}`,
                  position: "relative",
                  backgroundColor: "#f00",
                  height: 7,
                  width: 7,
                  borderRadius: "50%"
                }}
              />
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                style={{
                  maxWidth: "80%",
                  width: "100%"
                }}
                color="textPrimary"
              >
                {data.notification.content}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    </>
  );
}
