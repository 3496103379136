import validateEmail from "./validateEmail";

export default function countEmailsAndFirstEmails(emails) {
  const count = String(emails).split(",").length;
  const splited = String(emails)
    .split(",")
    .filter(item => validateEmail(item));
  return {
    firstEmail: splited[0],
    rest: count - 1
  };
}
