import styled from "styled-components";

import { colors } from "../../styles";

export const Header = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 10px;
  height: 100%;
  width: 100%;
`;

export const Logo = styled.img`
  height: 200px;
  width: 200px;
`;

export const TextError = styled.h6`
  color: ${colors.error};
  margin-top: ${props => (props.margin ? "50px" : "10px")};
  text-align: ${props => props.align && "center"};
`;

export const Empty = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  align-content: center;
  height: 100%;
`;

export const QRCodeImage = styled.img`
  height: 300px;
  width: 300px;
  margin: 20px;
`;
