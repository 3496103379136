import React from "react";
import AWS from "aws-sdk";
import ReactPlayer from "react-player";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as LockerActions } from "../../store/ducks/locker";
import { Actions as ConfigActions } from "../../store/ducks/configs";

// Material
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, MenuItem, TextField } from "@material-ui/core";

// Global
import Spinner from "../../components/Spinner";

// Styles
import { materialStyle } from "../../styles";
import { ContainerImage, ImageCamera } from "./styles";
import { withTranslation } from "react-i18next";
// assets
import CameraVisor from "../../assets/images/camera_visor.39423d8dd.png";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import constants from "../../config/constants";

let timeCount = 60; // tempo que o video será reproduzido

const STREAM_TYPES = {
  live: "LIVE",
  onDemand: "ON_DEMAND"
};

class CameraView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      hlsUrl: null,
      timeCount,
      countBoolean: false,
      streamType: STREAM_TYPES.live,
      initialDate: null,
      finalDate: null
    };

    setInterval(() => {
      if (this.state.countBoolean) {
        if (this.state.timeCount === 0) {
          this.setState({
            ...this.state,
            hlsUrl: null,
            countBoolean: false,
            timeCount
          });
          return;
        }
        this.setState({
          ...this.state,
          timeCount: this.state.timeCount - 1
        });
      }
    }, 1000);
  }

  render() {
    const currentLocker = this.state.locker.currentLocker;
    const { loading } = this.state.locker;
    const { classes } = this.props;

    const setUrl = async () => {
      if (this.state.hlsUrl) {
        this.setState({
          ...this.state,
          hlsUrl: null,
          countBoolean: false,
          timeCount
        });
        return;
      }
      const { cameraUrl } = currentLocker;
      const streamName = cameraUrl;
      const { initialDate, finalDate } = this.state;

      const options = {
        accessKeyId: process.env.REACT_APP_KINESIS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_KINESIS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_KINESIS_REGION,
        signatureVersion: "v4"
      };
      const kinesisVideo = new AWS.KinesisVideo(options);
      const kinesisVideoArchivedContent = new AWS.KinesisVideoArchivedMedia(
        options
      );
      kinesisVideo.getDataEndpoint(
        {
          StreamName: streamName,
          APIName: "GET_HLS_STREAMING_SESSION_URL"
        },
        async (err, response) => {
          if (err) {
            return;
          }
          kinesisVideoArchivedContent.endpoint = new AWS.Endpoint(
            response.DataEndpoint
          );
          const playbackMode = this.state.streamType; // 'LIVE' or 'ON_DEMAND'
          const startTimestamp = new Date(initialDate); // For ON_DEMAND only
          const endTimestamp = new Date(finalDate); // For ON_DEMAND only
          const fragmentSelectorType = "SERVER_TIMESTAMP"; // 'SERVER_TIMESTAMP' or 'PRODUCER_TIMESTAMP'
          const SESSION_EXPIRATION_SECONDS = 60 * 60;
          kinesisVideoArchivedContent.getHLSStreamingSessionURL(
            {
              StreamName: streamName,
              PlaybackMode: playbackMode,
              HLSFragmentSelector: {
                FragmentSelectorType: fragmentSelectorType,
                TimestampRange:
                  playbackMode === "LIVE"
                    ? undefined
                    : {
                        StartTimestamp: startTimestamp,
                        EndTimestamp: endTimestamp
                      }
              },
              Expires: parseInt(SESSION_EXPIRATION_SECONDS)
            },
            (err, response) => {
              if (err) {
                this.props.setToastVisible({
                  toastVisible: true,
                  toastMessage: this.props.t('camera.error'),
                  toastVariant: constants.toastVariants.error
                });
                return;
              }

              const differenceInMilliseconds =
                this.state.finalDate - this.state.initialDate;
              const differenceInSeconds = differenceInMilliseconds / 1000;

              if (playbackMode === STREAM_TYPES.onDemand) {
                timeCount = differenceInSeconds;
              }

              this.setState({
                ...this.state,
                hlsUrl: response.HLSStreamingSessionURL,
                countBoolean: true,
                timeCount
              });
            }
          );
        }
      );
    };
    return (
      <>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Grid container spacing={2} className="first-grid">
              <Grid item xs={12} md={3}>
                <TextField
                  id="custom-css-outlined-input"
                  select
                  label="Captura da camera"
                  className={classes.textField1}
                  value={this.state.streamType}
                  onChange={e => this.setState({ streamType: e.target.value })}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                  disabled={this.state.hlsUrl}
                >
                  {Object.keys(STREAM_TYPES).map(status => (
                    <MenuItem
                      key={STREAM_TYPES[status]}
                      value={STREAM_TYPES[status]}
                    >
                      {STREAM_TYPES[status] === STREAM_TYPES.live
                        ? "Ao Vivo"
                        : "Intervalo"}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {this.state.streamType === STREAM_TYPES.onDemand ? (
                <>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} md={3}>
                      <KeyboardDateTimePicker
                        className={classes.textField1}
                        disableToolbar
                        format="dd/MM/yyyy HH:mm"
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-inline"
                        label="Data e hora inicial"
                        value={this.state.initialDate}
                        onChange={date => this.setState({ initialDate: date })}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        inputProps={{
                          margin: "normal"
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        placeholder="Data e hora inicial"
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <KeyboardDateTimePicker
                        className={classes.textField1}
                        disableToolbar
                        format="dd/MM/yyyy HH:mm"
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-inline"
                        label="Data Final"
                        value={this.state.finalDate}
                        onChange={data => this.setState({ finalDate: data })}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        inputProps={{
                          margin: "normal"
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        placeholder="Data final das entregas"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </>
              ) : null}
            </Grid>

            <Grid container spacing={2} className="first-grid">
              <Grid item xs={12} md={3}>
                {!this.state.hlsUrl ? (
                  <>
                    <Button
                      variant="contained"
                      className={this.state.classes.button}
                      onClick={setUrl}
                      style={{
                        // para os Buttons
                        backgroundColor:
                          this.state.streamType === STREAM_TYPES.onDemand &&
                          (!this.state.initialDate || !this.state.finalDate)
                            ? "#ccc"
                            : "#006660",
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "lowercase",
                        height: 35
                      }}
                      disabled={
                        this.state.streamType === STREAM_TYPES.onDemand &&
                        (!this.state.initialDate || !this.state.finalDate)
                      }
                    >
                      Ativar streaming
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    className={this.state.classes.button}
                    onClick={setUrl}
                    style={{
                      // para os Buttons
                      backgroundColor: "#006660",
                      fontSize: 14,
                      color: "#fff",
                      textTransform: "lowercase",
                      height: 35
                    }}
                  >
                    Parar streaming
                  </Button>
                )}
              </Grid>
            </Grid>

            {this.state.hlsUrl &&
            this.state.streamType === STREAM_TYPES.live ? (
              <h5 style={{ fontSize: 16 }}>
                O streaming pausará automaticamente após {timeCount} segundos.
                Para continuar visualizando o streaming, basta clicar novamente
                no botão ativar streaming.
              </h5>
            ) : null}

            <ContainerImage>
              {!this.state.hlsUrl ? (
                <ImageCamera id="camera" alt="" src={CameraVisor} />
              ) : (
                <ReactPlayer
                  url={this.state.hlsUrl}
                  playing={true}
                  height="54%"
                  width="54%"
                />
              )}
            </ContainerImage>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  locker: state.locker
});

const mapDispathToProps = dispatch =>
  bindActionCreators({ ...LockerActions, ...ConfigActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispathToProps
)(
  withStyles(materialStyle)(
    withTranslation("camera")(CameraView)
  )
);
