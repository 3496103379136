import React, { Component } from 'react';

// Global
import Container from '../../../components/Container';
import { withTranslation } from 'react-i18next';
class Permissions extends Component {
  render() {
    return (
      <Container title={this.props.t('common:permissions')}></Container>
    );
  }
}

export default withTranslation('common')(Permissions);
