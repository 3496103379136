export const Types = {
  getSubTeamsRequest: "getSubTeams/Request",
  getSubTeamsSuccess: "getSubTeams/Success",
  getSubTeamsError: "getSubTeams/Error",

  showSubTeamRequest: "showSubTeam/Request",
  showSubTeamSuccess: "showSubTeam/Success",
  showSubTeamError: "showSubTeam/Error",

  updateSubTeamRequest: "updateSubTeam/Request",
  updateSubTeamSuccess: "updateSubTeam/Success",
  updateSubTeamError: "updateSubTeam/Error",

  getSubTeamStatusRequest: "getSubTeamStatus/Request",
  getSubTeamStatusSuccess: "getSubTeamStatus/Success",
  getSubTeamStatusError: "getSubTeamStatus/Error",

  createSubTeamRequest: "createSubTeam/Request",
  createSubTeamSuccess: "createSubTeam/Success",
  createSubTeamError: "createSubTeam/Error",

  resetSubTeams: "resetSubTeams"
};

const INITAL_STATE = {
  loading: false,
  data: [],
  error: null,
  currentSubTeam: null,
  page: 0,
  perPage: 20,
  lastPage: 0,
  total: 0,
  subTeamStatus: []
};

export default function subTeams(state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.resetSubTeams:
      return INITAL_STATE;

    case Types.getSubTeamsRequest:
      return {
        ...state,
        loading: true,
        error: false
      };

    case Types.getSubTeamsSuccess:
      return {
        ...state,
        loading: false,
        ...action.payload.data
      };

    case Types.getSubTeamsError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.showSubTeamRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.showSubTeamSuccess:
      return {
        ...state,
        loading: false,
        currentSubTeam: action.payload.data
      };

    case Types.showSubTeamError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.updateSubTeamRequest:
      return {
        ...state,
        loading: true,
        error: null,
        snackbarVisible: false
      };

    case Types.updateSubTeamSuccess:
      return {
        ...state,
        loading: false,
        currentSubTeam: action.payload.data
      };

    case Types.updateSubTeamError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.getSubTeamStatusRequest:
      return {
        ...state,
        loading: true,
        error: null,
        snackbarVisible: false
      };

    case Types.getSubTeamStatusSuccess:
      return {
        ...state,
        loading: false,
        subTeamStatus: action.payload.data
      };

    case Types.getSubTeamStatusError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.createSubTeamRequest:
      return {
        ...state,
        loading: true,
        error: null
      };

    case Types.createSubTeamSuccess:
      return {
        ...state,
        loading: false,
        currentSubTeam: action.payload.data
      };

    case Types.createSubTeamError:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
}

export const Actions = {
  getSubTeamsRequest: data => ({
    type: Types.getSubTeamsRequest,
    payload: { data }
  }),

  getSubTeamsSuccess: data => ({
    type: Types.getSubTeamsSuccess,
    payload: { data }
  }),

  getSubTeamsError: error => ({
    type: Types.getSubTeamsError,
    payload: { error }
  }),

  showSubTeamRequest: id => ({
    type: Types.showSubTeamRequest,
    payload: { id }
  }),

  showSubTeamSuccess: data => ({
    type: Types.showSubTeamSuccess,
    payload: { data }
  }),

  showSubTeamError: error => ({
    type: Types.showSubTeamError,
    payload: { error }
  }),

  updateSubTeamRequest: data => ({
    type: Types.updateSubTeamRequest,
    payload: { data }
  }),

  updateSubTeamSuccess: data => ({
    type: Types.updateSubTeamSuccess,
    payload: { data }
  }),

  updateSubTeamError: error => ({
    type: Types.updateSubTeamError,
    payload: { error }
  }),
  getSubTeamStatusRequest: data => ({
    type: Types.getSubTeamStatusRequest,
    payload: { data }
  }),

  getSubTeamStatusSuccess: data => ({
    type: Types.getSubTeamStatusSuccess,
    payload: { data }
  }),

  getSubTeamStatusError: error => ({
    type: Types.getSubTeamStatusError,
    payload: { error }
  }),
  createSubTeamRequest: (data, pushFunction) => ({
    type: Types.createSubTeamRequest,
    payload: { data, pushFunction }
  }),

  createSubTeamSuccess: data => ({
    type: Types.createSubTeamSuccess,
    payload: { data }
  }),

  createSubTeamError: error => ({
    type: Types.createSubTeamError,
    payload: { error }
  }),

  resetSubTeams: () => ({
    type: Types.resetSubTeams
  })
};
