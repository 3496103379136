export const Types = {
  setSnackbarVisibleBusiness: 'business/setSnackbarVisibleBusiness',
  setSnackbarErrorVisible: 'business/setSnackbarErrorVisible',

  getBusinessRequest: 'getBusiness/Request',
  getBusinessSuccess: 'getBusiness/Success',
  getBusinessError: 'getBusiness/Error',

  showBusinessRequest: 'showBusiness/Request',
  showBusinessSuccess: 'showBusiness/Success',
  showBusinessError: 'showBusiness/Error',

  updateBusinessRequest: 'updateBusiness/Request',
  updateBusinessSuccess: 'updateBusiness/Success',
  updateBusinessError: 'updateBusiness/Error',

  createBusinessRequest: 'createBusiness/Request',
  createBusinessSuccess: 'createBusiness/Success',
  createBusinessError: 'createBusiness/Error',

  businessMeRequest: 'businessMe/Request',
  businessMeSuccess: 'businessMe/Success',
  businessMeError: 'businessMe/Error',

  businessUpdateRoleRequest: 'businessUpdateRole/Request',
  businessUpdateRoleSuccess: 'businessUpdateRole/Success',
  businessUpdateRoleError: 'businessUpdateRole/Error',

  resetBusinessState: 'resetBusinessState',
  setEditableBusiness: 'setEditable/Business',

  updateProfileRequest: 'updateProfileRequest',
  updateProfileSucess: 'updateProfileSucess',
  updateProfileError:  'updateProfileError',
  resetUpdateProfileSucess: 'resetUpdateProfileSucess',
  resetUpdateProfileError: 'resetUpdateProfileError'
};

const INITAL_STATE = {
  data: [],
  total: 0,
  perPage: 0,
  page: 0,
  lastPage: 0,
  loading: false,
  error: null,
  snackbarVisible: false,
  snackbarType: 'warning',
  snackbarErrorVisible: false,
  currentBusiness: null,
  me: null,
  setEditable: false,
  openSuccess: false,
  openError: false,
}

export default function business (state = INITAL_STATE, action) {
  switch (action.type) {
    case Types.resetBusinessState:
      return INITAL_STATE;

    case Types.setEditableBusiness:
      return {
        ...state,
        setEditable: action.payload.status,
      };

    case Types.setSnackbarVisibleBusiness:
      return {
        ...state,
        snackbarVisible: action.payload.status,
      };

    case Types.setSnackbarErrorVisible:
      return {
        ...state,
        snackbarErrorVisible: action.payload.status,
        error: INITAL_STATE.error,
      };

    case Types.getBusinessRequest:
      return {
        ...state,
        loading: true,
        error: null,
        data: [],
      };

    case Types.getBusinessSuccess:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        total: action.payload.data.total,
        perPage: action.payload.data.perPage,
        page: action.payload.data.page,
        lastPage: action.payload.data.lastPage,
      };

    case Types.getBusinessError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        snackbarVisible: true,
      };

    case Types.showBusinessRequest:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case Types.showBusinessSuccess:
      return {
        ...state,
        currentBusiness: action.payload.data,
        loading: false
      };

    case Types.showBusinessError:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.updateBusinessRequest:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case Types.updateBusinessSuccess:
      return {
        ...state,
        currentBusiness: {
          ...state.currentBusiness,
          ...action.payload.data,
        },
        loading: false,
        snackbarVisible: true,
        setEditable: INITAL_STATE.setEditable,
      };

    case Types.updateBusinessError:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

      case Types.createBusinessRequest:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case Types.createBusinessSuccess:
      return {
        ...state,
        loading: false,
        snackbarVisible: true,
      };

    case Types.createBusinessError:
      return {
        ...state,
        error: action.payload.error,
        snackbarErrorVisible: true,
        loading: false,
      };

    case Types.businessMeRequest:
      return {
        ...state,
        loading: true,
        me: INITAL_STATE.me,
        error: INITAL_STATE.error,
      };

    case Types.businessMeSuccess:
      return {
        ...state,
        me: action.payload.data,
        loading: false,
      };

    case Types.businessMeError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case Types.businessUpdateRoleRequest:
      return {
        ...state,
        loading: true,
        error: INITAL_STATE.error,
      };

    case Types.businessUpdateRoleSuccess:
      return {
        ...state,
        loading: false,
      };

    case Types.businessUpdateRoleError:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case Types.updateProfileRequest:
      return {
        ...state,
        loading: true
      };

    case Types.updateProfileSucess:
    return {
      ...state,
      loading: false,
      openSuccess: true
    };

    case Types.resetUpdateProfileSucess:
      return {
        ...state,
        openSuccess: false,
      };

    case Types.updateProfileError:
    return {
      ...state,
      loading: false,
      openError: true,
      error: action.payload.error,
    };

    case Types.resetUpdateProfileError:
      return {
        ...state,
        openError: false,
      };

    default:
      return state;
  }
}

export const Actions = {
  resetBusinessState: () => ({
    type: Types.resetBusinessState,
  }),

  setSnackbarVisibleBusiness: (status) => ({
    type: Types.setSnackbarVisibleBusiness,
    payload: { status },
  }),

  setSnackbarErrorVisible: status => ({
    type: Types.setSnackbarErrorVisible,
    payload: { status },
  }),

  getBusinessRequest: data => ({
    type: Types.getBusinessRequest,
    payload: { data },
  }),

  getBusinessSuccess: data => ({
    type: Types.getBusinessSuccess,
    payload: { data },
  }),

  getBusinessError: error => ({
    type: Types.getBusinessError,
    payload: { error },
  }),

  showBusinessRequest: id => ({
    type: Types.showBusinessRequest,
    payload: { id },
  }),

  showBusinessSuccess: data => ({
    type: Types.showBusinessSuccess,
    payload: { data },
  }),

  showBusinessError: error => ({
    type: Types.showBusinessError,
    payload: { error },
  }),

  updateBusinessRequest: (data, roles) => ({
    type: Types.updateBusinessRequest,
    payload: { data, roles },
  }),

  updateBusinessSuccess: data => ({
    type: Types.updateBusinessSuccess,
    payload: { data },
  }),

  updateBusinessError: error => ({
    type: Types.updateBusinessError,
    payload: { error },
  }),

  createBusinessRequest: data => ({
    type: Types.createBusinessRequest,
    payload: { data },
  }),

  createBusinessSuccess: data => ({
    type: Types.createBusinessSuccess,
    payload: { data },
  }),

  createBusinessError: error => ({
    type: Types.createBusinessError,
    payload: { error },
  }),

  businessMeRequest: () => ({
    type: Types.businessMeRequest,
  }),

  businessMeSuccess: data => ({
    type: Types.businessMeSuccess,
    payload: { data },
  }),

  businessMeError: data => ({
    type: Types.businessMeError,
    payload: { data },
  }),

  setEditableBusiness: status => ({
    type: Types.setEditableBusiness,
    payload: { status },
  }),

  updateProfileRequest: data => ({
    type: Types.updateProfileRequest,
    payload: data
  }),

  updateProfileSucess: data => ({
    type: Types.updateProfileSucess,
    payload: data
  }),

  resetUpdateProfileSucess: data => ({
    type: Types.resetUpdateProfileSucess,
    payload: data
  }),

  resetUpdateProfileError: data => ({
    type: Types.resetUpdateProfileError,
    payload: data
  }),

  updateProfileError: error => ({
    type: Types.updateProfileError,
    payload: error
  }),
};
