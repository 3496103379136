import React, { useCallback, useState } from "react";
import {
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import materialStyle from "../../styles/material"; // ajuste para importação correta do estilo
import api from "../../services/api";
import { Actions as ConfigActions } from "../../store/ducks/configs";
import { useDispatch } from "react-redux";
import constants from "../../config/constants";
import TextMaskCustomMobile from "../../components/TextMaskCustomMobile";
import { validateMobile } from "../../utils/validation";
import history from "../../routes/history";
import validateEmail from "../../utils/validateEmail";
import ButtonClick from "../../components/ButtonClick";
import { useTranslation } from "react-i18next";

const IntegrationCreate = ({ classes }) => {
  const { t } = useTranslation("config");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    attributes: constants.tokenActionOptions()
  });
  const dispatch = useDispatch();

  const handleChange = key => event => {
    setFormData({
      ...formData,
      [key]: event.target.value
    });
  };

  const handleSave = useCallback(
    async () => {
      try {
        const contactNumber = validateMobile(formData.contactNumber);
        if (
          !formData.name ||
          !formData.contactEmail ||
          !formData.contactName ||
          !contactNumber
        ) {
          dispatch(
            ConfigActions.setToastVisible({
              toastVisible: true,
              toastMessage: "Dados inválido ou vazios",
              toastVariant: constants.toastVariants.warning
            })
          );
          return;
        }

        if (!validateEmail(formData.contactEmail)) {
          dispatch(
            ConfigActions.setToastVisible({
              toastVisible: true,
              toastMessage: "Email inválido",
              toastVariant: constants.toastVariants.warning
            })
          );
          return;
        }

        setLoading(true);

        const response = await api.post("/v1/adm/token/team/integration", {
          ...formData,
          contactNumber,
          attributes: JSON.stringify(formData.attributes)
        });

        dispatch(
          ConfigActions.setToastVisible({
            toastVisible: true,
            toastMessage: "Criado",
            toastVariant: constants.toastVariants.success
          })
        );
        history.push(`/integrations/token/${response.data.id}`);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [formData]
  );

  return (
    <Container title="Editar porta">
      {loading ? (
        <Spinner />
      ) : (
        <ThemeTextFildGreen>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <TextField
                id="outlined-name"
                label={t("integrations.integration_name")}
                className={classes.textField}
                value={formData.name}
                margin="normal"
                variant="outlined"
                onChange={handleChange("name")}
              />
            </Grid>
          </Grid>

          <h5 className={classes.subTitle}>{t("integrations.contact_data")}</h5>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-name"
                label={t("integrations.dev_name")}
                className={classes.textField}
                value={formData.contactName}
                margin="normal"
                variant="outlined"
                onChange={handleChange("contactName")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-email"
                label={t("integrations.email")}
                className={classes.textField}
                value={formData.contactEmail}
                margin="normal"
                variant="outlined"
                onChange={handleChange("contactEmail")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-contact"
                label={t("integrations.contact_label")}
                className={classes.textField}
                value={formData.contactNumber}
                margin="normal"
                variant="outlined"
                onChange={handleChange("contactNumber")}
                InputProps={{
                  inputComponent: TextMaskCustomMobile
                }}
              />
            </Grid>
          </Grid>

          <h5 className={classes.subTitle}>{t("integrations.api_functions")}</h5>
          <Grid container spacing={2} />
          <FormGroup row className={classes.defaultComponent}>
            {formData.attributes.map(item => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.value}
                    onChange={event => {
                      setFormData(old => ({
                        ...old,
                        attributes: formData.attributes.map(attr => {
                          if (attr.id === item.id) {
                            attr.value = event.target.checked;
                          }
                          return attr;
                        })
                      }));
                    }}
                    name={item.id}
                    color="primary"
                  />
                }
                label={item.label}
              />
            ))}
          </FormGroup>
          <ButtonClick onClick={handleSave}>{t("integrations.create")}</ButtonClick>
        </ThemeTextFildGreen>
      )}
    </Container>
  );
};

export default withStyles(materialStyle)(IntegrationCreate);
