import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';

import SimpleActionButton from '../SimpleActionButton';
import defaultConfig from '../../config/default';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions as SubTeamActions } from '../../store/ducks/subTeam';
import { withTranslation } from 'react-i18next';

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

class subTeamsPaginationTable extends Component {
  state = {
    emptyRows: 0,
    page: 1,
    rowsPerPage: 5,
  };
  t = this.props.t;

  handleChangePage = (event, newPage) => {
    const { getSubTeamsRequest } = this.props;

    const query = `page=${newPage + 1}&perPage=${parseInt(
      this.state.rowsPerPage
    )}`;

    getSubTeamsRequest(query);
  };

  handleChangeRowsPerPage = event => {
    const { getSubTeamsRequest } = this.props;

    getSubTeamsRequest(`perPage=${parseInt(event.target.value)}`);

    this.setState({ rowsPerPage: parseInt(event.target.value) });
  };

  useStyles2 = () =>
    makeStyles(theme => ({
      root: {
        width: '100%',
        marginTop: theme.spacing(3),
      },
      table: {
        minWidth: 500,
      },
      tableWrapper: {
        overflowX: 'auto',
      },
    }));

  render() {
    const classes = this.useStyles2();
    const { subTeams, history } = this.props;

    const { emptyRows } = this.state;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className="tableheader">
              <TableRow align="left">
                <TableCell
                  className="tablecell-header tablecell-header__initial-table"
                  align="left"
                >
                  {this.t('common:name')}
                </TableCell>
                <TableCell
                  className="tablecell-header td-business-simple-table"
                  align="left"
                >
                  {this.t('common:email')}
                </TableCell>

                <TableCell className="tablecell-header" align="left">
                  {this.t('common:actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tbody-business-simple-table">
              {subTeams.data &&
                subTeams.data.map(row => (
                  <TableRow key={row.id}>
                    <TableCell
                      className="tablecell tablecell-header__initial-table"
                      align="left"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      className="tablecell td-business-simple-table"
                      align="left"
                    >
                      {row.email}
                    </TableCell>

                    <TableCell
                      id="table-sm__svg"
                      className="tablecell"
                      align="left"
                    >
                      <SimpleActionButton
                        id={Math.random()}
                        actions={[
                          () =>
                            history.push(`/subTeam/condominium/edit/${row.id}`),
                        ]}
                        options={[this.t('common:edit')]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter className="table-footer" align="right">
              <TableRow align="right">
                <TablePagination
                  align="right"
                  rowsPerPageOptions={defaultConfig.rowsPerPageOptions}
                  colSpan={3}
                  count={subTeams.total}
                  rowsPerPage={subTeams.perPage}
                  page={subTeams.page - 1}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  labelRowsPerPage={this.t('common:lines_per_page')}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to === -1 ? count : to} ${this.t(
                      'common:of'
                    )} ${count}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  subTeams: state.subTeams,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...SubTeamActions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('condominium')(subTeamsPaginationTable));
