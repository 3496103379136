import React, { Component } from "react";

// Global Components
import Container from "../../components/Container";

// Material
import { withStyles } from "@material-ui/core/styles";
import { Button, TextField, Grid } from "@material-ui/core";
import { Cancel as CancelIcon, Save as SaveIcon } from "@material-ui/icons";
import { materialStyle } from "../../styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as GroupActions } from "../../store/ducks/groups";
import { Actions as ThingTypesActions } from "../../store/ducks/thingTypes";

//configs
import ThemeTextFildGreen from "../../styles/ThemeTextFildGreen";
import EnhancedTable from "./temp";
import Spinner from "../../components/Spinner";

class GroupEdit extends Component {
  state = {
    name: "",
    description: "",
    thingTypes: []
  };

  // Load Roles and teams
  componentWillMount() {
    const {
      match: { params },
      showGroupRequest,
      thingTypesRequest
    } = this.props;

    thingTypesRequest();

    if (Object.keys(params).includes("id")) {
      showGroupRequest(params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.groups.currentGroup !== this.state.currentGroup) {
      this.setState({
        ...nextProps.groups.currentGroup
      });
    }
  }

  // Handle Change global
  handleChange = name => event => {
    let value = event.target.value;

    this.setState({ [name]: value });
  };

  handleCreate = () => {
    const {
      updateThingGroupRequest,
      groups: { currentGroup }
    } = this.props;

    updateThingGroupRequest(currentGroup.id, this.state.thingTypes);
  };

  render() {
    const { classes, groups } = this.props;

    return (
      <Container title="Criar tipo de thing">
        {groups.loading ? (
          <Spinner />
        ) : (
          <ThemeTextFildGreen>
            <h3 style={{ marginTop: 20 }}>
              {groups.currentGroup
                ? `Dados do grupo ${groups.currentGroup.search_base}`
                : "Dados do novo grupo"}
            </h3>
            {groups.currentGroup && (
              <>
                <Grid className="first-grid" container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    className="form-colaboradore-sm"
                    alignItems="flex-start"
                  >
                    <TextField
                      required
                      id="outlined-name"
                      label="Nome"
                      className={classes.textField}
                      value={groups.currentGroup.search_base}
                      // onChange={this.handleChange("name")}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>
                </Grid>

                <EnhancedTable
                  onChange={thingTypes => this.setState({ thingTypes })}
                />
              </>
            )}

            <br />
            <Button
              disabled={groups.loading}
              onClick={this.handleCreate}
              variant="contained"
            >
              <SaveIcon className={classes.leftIcon} />
              {groups.currentGroup ? "Salvar" : "Criar"}
            </Button>

            <Button
              variant="contained"
              onClick={() => this.props.history.push("/thing/types")}
              className={classes.button}
            >
              <CancelIcon className={classes.leftIcon} />
              Cancelar
            </Button>
          </ThemeTextFildGreen>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  groups: state.groups,
  thingTypes: state.thingTypes
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...GroupActions,
      ...ThingTypesActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(materialStyle)(GroupEdit));
